import moment from 'moment-timezone';
import { v4 } from 'uuid';
export default (data, date) => {
  const now = moment();
  const from = moment(date.from).format('DD MMM');
  const to = moment(date.to).format('DD MMM');
  const nowHours = now.format('HH:mm A');
  const nowDate = now.format('DD/MM/YY');
  const refNum = v4().slice(0, 5);
  const { name, gender, birthdate } = data?.basicInfo?.data;

  const timedAge = birthdate ? `${now.diff(birthdate, 'years')}` : '---';

  return `<section class="header-section">
      <div class="title">
        <h1 >COVID-19 Report</h1>
        <img
          class="image"
          alt="jawebone-health-logo"
          src="/all-health-logo.png"
        />
      </div>
      <div class="subinfo">
        <p>Report Ref #: ${refNum}</p>
        <p>
          Prepared for: ${name} | Age: ${timedAge} | Sex: ${gender}
        </p>
        <p>Dates: ${from} - ${to}</p>
        <p>Prepared at: ${nowHours} • ${nowDate}</p>
      </div>
    </section>`;
};
