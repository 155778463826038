import React from 'react';
import { Accordion } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import {
  fetchRecord,
  fetchFhirAllergyIntolerance,
  fetchFhirMedications,
  fetchFhirPrescriptions,
  fetchFhirClinicalImpressions,
  fetchFhirImmunizations,
  fetchFhirProcedures,
  fetchFhirDiagnosticReports,
  fetchFhirObservations,
  fetchFhirCommunications,
} from 'store/currentPatient/actions/fhir';
import {
  contactsParser,
  defaultParser,
  prescriptionsParser,
  diagnosticReportsParser,
  immunizationsParser,
  observationsParser,
} from 'utils/fhirData/parsingFunctions';

import HOCAccordion from './components/HOCAccordion';
import style from 'containers/patient/patient.module.scss';

const PatientRecord = () => {
  const {
    record: { isLoading, error, data: record },
    allergyIntolerance,
    medications,
    prescriptions,
    diagnosticReports,
    observations,
    clinicalImpressions,
    immunizations,
    procedures,
    communications,
    id,
  } = useSelector(state => state.currentPatient);

  const { data: isFhirUser } = useSelector(
    state => state.currentPatient.isFhirUser
  );

  const addCommonProps = components =>
    components.map(component => ({ ...component, isFhirUser, id }));

  let accordions = [
    {
      title: 'Contacts',
      fetcher: fetchRecord,
      parser: [contactsParser],
      source: { isLoading, error, data: record?.contact || [] },
    },
    {
      title: 'Allergy intolerance',
      fetcher: fetchFhirAllergyIntolerance,
      parser: [defaultParser],
      source: allergyIntolerance,
    },
    {
      title: 'Medications',
      fetcher: fetchFhirMedications,
      parser: [defaultParser],
      source: medications,
    },
    {
      title: 'Immunizations',
      fetcher: fetchFhirImmunizations,
      parser: [immunizationsParser],
      source: immunizations,
    },

    {
      title: 'Communications',
      fetcher: fetchFhirCommunications,
      parser: [defaultParser],
      source: communications,
    },
    {
      title: 'Prescriptions',
      fetcher: fetchFhirPrescriptions,
      parser: [prescriptionsParser],
      source: prescriptions,
    },
    {
      title: 'Procedures',
      fetcher: fetchFhirProcedures,
      parser: [diagnosticReportsParser],
      source: procedures,
    },
    {
      title: 'Diagnostic reports',
      fetcher: fetchFhirDiagnosticReports,
      parser: [diagnosticReportsParser],
      source: diagnosticReports,
    },
    {
      title: 'Clinical Impressions',
      fetcher: fetchFhirClinicalImpressions,
      parser: [defaultParser],
      source: {
        ...clinicalImpressions,
        data: clinicalImpressions.data.filter(item => item?.extension),
      },
    },
    {
      title: 'Observations',
      fetcher: fetchFhirObservations,
      parser: [observationsParser],
      source: observations,
    },
  ];

  accordions = addCommonProps(accordions);
  return (
    <section className={style.infoSection}>
      <Accordion fluid styled exclusive={false}>
        {accordions.map(accordion => (
          <HOCAccordion {...accordion} key={accordion.title} />
        ))}
      </Accordion>
    </section>
  );
};

export default PatientRecord;
