export const LOGIN_ENDPOINT = 'users/@me/tokens';
export const REFRESH_ENDPOINT = 'TBI';

export const PATIENT_IDS_ENDPOINT = 'care_team/patients';
export const NEW_PATIENTS_ENDPOINT = 'onboarding/new_patients';
export const CONFIRM_PATIENT_ENDPOINT = 'onboarding/accept_patient';
export const REHYDRATE_INTERVAL_TIME = 600000;

export const BAND_STATUS = {
  0: 'WEARING',
  1: 'REMOVED',
  2: 'CHARGING',
};

export const SEVERITY_TYPES = {
  MIN_GREEN: 'MIN_GREEN',
  UP_GREEN: 'UP_GREEN',
  UP_RED: 'UP_RED',
  DOWN_GREEN: 'DOWN_GREEN',
  DOWN_RED: 'DOWN_RED',
  NEW: 'NEW',
  GREEN: 'GREEN',
  YW: 'YELLOW',
  RED: 'RED',
};

export const TICK_DATA_TYPES = {
  sleep: 'sleep_depth',
  movement: 'steps',
  hr: 'background_hr',
  hrv: ['rmssd', 'rmssd_conf'],
  location: 'location',
  blood_pressure: ['systolic', 'diastolic'],
  weight: 'weight',
  glucose: 'glucose',
  insulin: ['bolus', 'basal'],
  resp_rate: ['resp_rate', 'resp_rate_conf'],
  temp: ['skin_temp', 'ambient_temp'],
  spo2: 'spo2',
  battery_level: 'battery_level',
  band_removed: 'band_removed',
  hba1c: 'HbA1c',
  ldl: 'LDL',
};

export const ALERT_TYPES = {
  P0: 'P0', // Today panel and Browser Notification
  P1: 'P1', // Today panel
  P2: 'P2', // Patient card
};

export const ALERT_TRESHOLDS = {
  'Covid 19': [
    {
      threshold: 0,
      floatThreshold: 0,
      severity: SEVERITY_TYPES.GREEN,
      risk: 'Low',
      type: ALERT_TYPES.P1,
      message: 'No COVID-19 risk.',
    },
    {
      threshold: 34,
      floatThreshold: 0.34,
      severity: SEVERITY_TYPES.YW,
      risk: 'Med',
      type: ALERT_TYPES.P0,
      message: 'COVID-19 risk increased to medium.',
    },
    {
      threshold: 68,
      floatThreshold: 0.68,
      severity: SEVERITY_TYPES.RED,
      risk: 'High',
      type: ALERT_TYPES.P0,
      message: 'COVID-19 risk increased to high.',
    },
  ],
  i_vuln: [
    {
      threshold: 0,
      floatThreshold: 0,
      severity: SEVERITY_TYPES.GREEN,
      risk: 'Low',
      type: ALERT_TYPES.P1,
      message: 'Vulnerability Risk decreased to low.',
    },
    {
      threshold: 30,
      floatThreshold: 0.3,
      severity: SEVERITY_TYPES.YW,
      risk: 'Med',
      type: ALERT_TYPES.P0,
      message: 'Vulnerability Risk increased to medium.',
    },
    {
      threshold: 60,
      floatThreshold: 0.6,
      severity: SEVERITY_TYPES.RED,
      risk: 'High',
      type: ALERT_TYPES.P0,
      message: 'Vulnerability Risk increased to high.',
    },
  ],
  SpO2: [
    {
      threshold: 92,
      floatThreshold: 0.92,
      severity: SEVERITY_TYPES.RED,
      risk: 'High',
      type: ALERT_TYPES.P0,
      message: 'SpO2 Out of Range.',
    },
  ],
  RR: [
    {
      threshold: 22,
      floatThreshold: 0.22,
      severity: SEVERITY_TYPES.RED,
      risk: 'High',
      type: ALERT_TYPES.P0,
      message: 'Respiration Rate Out of Range.',
    },
  ],
  HR: [
    {
      threshold: 180,
      floatThreshold: 1.8,
      severity: SEVERITY_TYPES.RED,
      risk: 'High',
      type: ALERT_TYPES.P0,
      message: 'Heart Rate Out of Range.',
    },
  ],
  RHR: [
    {
      threshold: 80,
      floatThreshold: 0.8,
      severity: SEVERITY_TYPES.RED,
      risk: 'High',
      type: ALERT_TYPES.P0,
      message: 'Resting Heart Rate Out of Range.',
    },
  ],
  'Type 2 Diabetes': [
    {
      threshold: 30,
      severity: SEVERITY_TYPES.YW,
      message: 'Diabetes risk increased to medium.',
    },
    {
      threshold: 50,
      severity: SEVERITY_TYPES.RED,
      message: 'Diabetes risk increased to high.',
    },
  ],
  'Obstructive Sleep Apnea': [
    {
      threshold: 40,
      severity: SEVERITY_TYPES.YW,
      message: 'Obstructive Sleep Apnea risk increased to medium.',
    },
    {
      threshold: 60,
      severity: SEVERITY_TYPES.RED,
      message: 'Obstructive Sleep Apnea risk increased to high.',
    },
  ],
  Hypertension: [
    {
      threshold: 40,
      severity: SEVERITY_TYPES.YW,
      message: 'Hypertension risk increased to medium.',
    },
    {
      threshold: 50,
      severity: SEVERITY_TYPES.RED,
      message: 'Hypertension risk increased to high.',
    },
  ],
  Angina: [
    {
      threshold: 15,
      severity: SEVERITY_TYPES.YW,
      message: 'Angina risk increased to medium.',
    },
    {
      threshold: 30,
      severity: SEVERITY_TYPES.RED,
      message: 'Angina risk increased to high.',
    },
  ],
  Depression: [
    {
      threshold: 20,
      severity: SEVERITY_TYPES.YW,
      message: 'Depression risk increased to medium.',
    },
    {
      threshold: 35,
      severity: SEVERITY_TYPES.RED,
      message: 'Depression risk increased to high.',
    },
  ],
  Hyperlipidemia: [
    {
      threshold: 50,
      severity: SEVERITY_TYPES.YW,
      message: 'Hyperlipidemia risk increased to medium.',
    },
    {
      threshold: 60,
      severity: SEVERITY_TYPES.RED,
      message: 'Hyperlipidemia risk increased to high.',
    },
  ],
};
