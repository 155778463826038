import React from 'react';
import * as d3 from 'd3';

import { alignAxis } from 'utils/chartUtils';
import Axis from './Axis';
import Dots from './Dots';
import Line from './Line';
import style from './Chart.module';

const WeightChart = ({
  data = [],
  dimensions,
  xData,
  tz = {},
  axisIndex,
  axisAmount,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const keyAccessor = (_, i) => i;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yData = [{ value: 0 }, ...data];
  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(yData, yAccessor))
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key="sleep-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.weightLabel}
      labelLine
      {...axisAligment}
    />,
    <Line
      style={{ ...style.line, ...style.weightLine }}
      data={data}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      interpolation={d3.curveLinear}
    />,
    <Dots
      style={style.weightCircles}
      data={data}
      keyAccessor={keyAccessor}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      radius={4}
      tz={tz}
    />,
  ];
};

const WeightChartDayTrend = ({
  data = [],
  dimensions,
  xData,
  axisAmount,
  axisIndex,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yData = [{ value: 0 }, ...data];
  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(yData, yAccessor))
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key={`overlayed-weight-y-axis-${axisIndex}`}
      dimension="y"
      scale={yScale}
      tickColor={style.weightLabel}
      labelLine
      {...axisAligment}
    />,
    <Line
      style={style.lineDailyWeightTrend}
      data={data}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      interpolation={d3.curveLinear}
    />,
  ];
};

export { WeightChart, WeightChartDayTrend };
