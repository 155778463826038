import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import {
  getDaysAvailability,
  clearDaysAvailability,
} from 'store/formsResources/actions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

const availableDaysReducer = createReducer(initialState, {
  [getDaysAvailability.STARTED]: handleActionStart,
  [getDaysAvailability.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload;
  },
  [clearDaysAvailability]: () => initialState,
  [getDaysAvailability.FAILED]: handleActionError,
});

export default availableDaysReducer;
