import React from 'react';
import cx from 'classnames';
import { Message } from 'semantic-ui-react';
import styles from 'containers/patient/patient.module.scss';
// eslint-disable-line
const EmrNotConnectedMessage = props => <Message {...props} />;
EmrNotConnectedMessage.defaultProps = {
  header: 'EMR not connected',
  content: (
    <p>
      {'Patient is missing connection to the EMR. Please '}
      <button className={cx(styles.emrConfigureLink, styles.linkBtn)}>
        {'configure their account.'}
      </button>
    </p>
  ),
};

export default EmrNotConnectedMessage;
