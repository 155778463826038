import React from 'react';
import cx from 'classnames';
import { Image } from 'semantic-ui-react';

import LogoType from 'assets/all-health-logo.svg';
import LogoSquare from 'assets/all-health-sm.svg';
import CCADLogo from 'assets/ccad-logo.png';
import SEHALogo from 'assets/seha-logo.png';
import UHGLogo from 'assets/uhg-logo.png';
import GLUCARELogo from 'assets/glucare-big-logo.png';
import useWindowSize from 'utils/useWindowSize';
import style from './Logo.module.scss';

const Logo = () => {
  const { width } = useWindowSize();
  const isGlucareEnv = process.env.REACT_APP_CUSTOMER_TENANT === 'glucare';

  const tenantLogo = (() => {
    const tenantCustomer = process.env.REACT_APP_CUSTOMER_TENANT;
    if (tenantCustomer === 'ccad') return CCADLogo;
    if (tenantCustomer === 'seha') return SEHALogo;
    if (tenantCustomer === 'uhg') return UHGLogo;
    if (tenantCustomer === 'glucare') return GLUCARELogo;
    return null;
  })();

  if (width <= 768) {
    return <Image src={LogoSquare} className={style.logoSquare} />;
  }

  return (
    <div
      className={cx(style.logoContainer, {
        [style.multipleLogos]: tenantLogo,
        [style.glucareTenant]: isGlucareEnv,
      })}
    >
      <Image src={LogoType} className={style.logoType} />
      {tenantLogo && <Image src={tenantLogo} className={style.tenantLogo} />}
    </div>
  );
};

export default Logo;
