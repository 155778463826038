import React, { useState, useEffect } from 'react';
import { Icon, Accordion, Feed, Button } from 'semantic-ui-react';
import { parseSurveyQuestions } from 'utils/surveyFactory';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import style from 'containers/patient/components/RightInfo/patientRightInfo.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { saveAnsweredSurveys } from 'store/currentPatient/actions';
import { Input, Checkbox, Radio } from '../FormSurvey';

const SurveyList = ({ survey }) => {
  const dispatch = useDispatch();

  const confirmedPatient = useSelector(
    state => !!state.currentPatient.isOnboarded?.data
  );

  const [open, setOpen] = useState(false);
  const [valuesChanged, setValuesChanged] = useState({});
  const questions = parseSurveyQuestions(survey);
  const dateSurvey = survey.time_completed
    ? moment(survey.time_completed * 1000).format('MM/DD/YYYY hh:mm A')
    : moment(survey.date.toString()).format('MM/DD/YYYY');

  const filledAllQuestions = () => {
    const preFilledAnswers = questions.reduce((acum = 0, question) => {
      const compiledAnswers = get(question, 'extraText.compiledAnswers', null);
      const shoudAdd = compiledAnswers !== null;
      if (shoudAdd) return acum + 1;
      return acum + 0;
    }, 0);
    return (
      confirmedPatient &&
      !isEmpty(valuesChanged) &&
      questions.length === Object.keys(valuesChanged).length + preFilledAnswers
    );
  };

  const getAnswer = (name, answer) => {
    if (Array.isArray(answer)) {
      setValuesChanged({ ...valuesChanged, [name]: answer });
    } else {
      setValuesChanged({ ...valuesChanged, [name]: [answer] });
    }
  };

  const saveSurvey = () => {
    const { uid, user_survey_id } = survey;

    dispatch(
      saveAnsweredSurveys(uid, user_survey_id, {
        time_answered: moment().unix(),
        answers: valuesChanged,
        as_ctm: true,
      })
    );

    setValuesChanged({});
  };

  useEffect(() => {
    setValuesChanged({});
  }, [survey]);

  return (
    <>
      <Accordion.Title
        className={style.surveyHeaderBox}
        onClick={() => setOpen(o => !o)}
        active={open}
      >
        <div>
          <Icon name="dropdown" />
          {`${survey.intro_header || 'Survey'} - (${survey.survey_id
            }) - ${dateSurvey}`}
        </div>
        <span>{survey.type}</span>
      </Accordion.Title>
      <Accordion.Content active={open}>
        <Feed>
          {questions.map(question => {
            return (
              <Feed.Event className={style.feedEvent} key={question.date}>
                <Feed.Content>
                  <Feed.Date>{question.date}</Feed.Date>
                  <Feed.Summary>{question.summary}</Feed.Summary>
                  <Feed.Extra>
                    <Answer
                      extraText={question.extraText}
                      setAnswer={option => getAnswer(question.date, option)}
                    />
                  </Feed.Extra>
                </Feed.Content>
              </Feed.Event>
            );
          })}
        </Feed>
        {!confirmedPatient && (
          <div className="ui warning message">Please confirm the patient</div>
        )}
        <Button
          className="ui blue button"
          disabled={!filledAllQuestions()}
          onClick={saveSurvey}
        >
          Save
        </Button>
      </Accordion.Content>
    </>
  );
};

const Answer = ({ extraText, setAnswer }) => {
  switch (extraText.type) {
    case 'text':
      return <Input setAnswer={setAnswer} extraText={extraText} />;

    case 'multiple_choice':
      return <Radio setAnswer={setAnswer} extraText={extraText} />;

    case 'checkbox':
      return <Checkbox setAnswer={setAnswer} extraText={extraText} />;
    default:
      return null;
  }
};

SurveyList.defaultProps = {
  surveys: [],
};
export default SurveyList;