import React, { useEffect } from 'react';
import { Header, Divider, Feed } from 'semantic-ui-react';
import style from 'containers/patient/components/RightInfo/patientRightInfo.module.scss';
import SurveyAnswersTable from '../SurveyAnswerTable';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCompletedSurveys,
} from 'store/currentPatient/actions';
import { previewQuestions } from 'utils/surveyFactory';

const SurveyAnswersPreview = () => {
  const dispatch = useDispatch();

  const { data: surveys, isLoading, error } = useSelector(
    state => state.currentPatient.surveys
  );
  const id = useSelector(state => state.currentPatient.id);

  useEffect(() => {
    if (id) {
      dispatch(fetchCompletedSurveys(id));
    }
  }, [id, dispatch]);

  const previewSurveys = previewQuestions(surveys);
  const data = previewSurveys;

  return (
    <section className={style.lastAnswersBox}>
      <div className={style.TitleAndModal}>
        <Header>Survey answers</Header>
        <SurveyAnswersTable surveys={surveys} />
      </div>
      <Divider />
      <Feed>
        {isLoading && <p>Loading...</p>}
        {!surveys.length && <p>Patient has no new answers.</p>}
        {error ? (
          <p>Something went wrong loading surveys for current patient.</p>
        ) : (
            data.map((answer, key) => (
              <Feed.Event className={style.feedEvent} key={key} {...answer} />
            ))
          )}
      </Feed>
    </section>
  );
};

export default SurveyAnswersPreview;
