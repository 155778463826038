import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Message } from 'semantic-ui-react';
import { updateUserGroups } from 'store/currentPatient/actions';
import styles from 'containers/proximity-log/proximityLog.module.scss';

const quarantineOptions = [
  {
    key: 'n/a',
    text: 'N/A',
    value: 'n/a',
  },
  {
    key: 'quarantine',
    text: 'Quarantine',
    value: 'quarantine',
  },
  {
    key: 'isolation',
    text: 'Isolation',
    value: 'isolation',
  },
  {
    key: 'social_distancing',
    text: 'Social Distancing',
    value: 'social_distancing',
  },
];

const quarantineStatusGroups = ['quarantine', 'social_distancing', 'isolation'];

const QuarantineStatus = ({ patientId }) => {
  const dispatch = useDispatch();
  const [quarantineStatus, setQuarantineStatus] = useState('');

  const {
    data: { groups },
  } = useSelector(state => state.currentPatient.groups);

  useEffect(() => {
    const newQuarantineStatus =
      groups.find(group => quarantineStatusGroups.includes(group)) || 'n/a';
    setQuarantineStatus(newQuarantineStatus);
  }, [groups]);

  const handleQuarantineChange = (_, { value }) => {
    const groupToRemove = quarantineStatus !== 'n/a' ? quarantineStatus : '';
    const groupToAdd = value !== 'n/a' ? value : '';
    dispatch(
      updateUserGroups({
        id: patientId,
        remove: groupToRemove,
        add: groupToAdd,
      })
    );
    setQuarantineStatus(value);
  };

  return (
    <Message className={styles.covidAlerts}>
      <Dropdown
        as="h4"
        onChange={handleQuarantineChange}
        options={quarantineOptions}
        fluid
        value={quarantineStatus}
      />
    </Message>
  );
};

export default QuarantineStatus;
