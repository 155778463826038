import moment from 'moment-timezone';

export const secondsToText = s => {
  if (s === 0) return '--';
  const baseDate = moment().set({
    second: 0,
    minute: 0,
    hour: 0,
  });
  const elapsedTime = baseDate.add(s, 's');
  if (elapsedTime.get('hour') !== 0) {
    return `${elapsedTime.hour()}hr ${baseDate.minute()}min`;
  }
  return `${elapsedTime.minute()}min`;
};

export const momentDateOnly = momentObject =>
  momentObject && momentObject.format('YYYY-MM-DD');

export const withTimeZone = (tz, setDate, setHour) => {
  const dateString = `${setDate} ${setHour}`;
  const momentDate = tz ? moment.tz(dateString, tz) : moment(dateString);
  return setDate && momentDate.format();
};

export const fromDayToTzHour = (tz, date, hour) => {
  const yyyyMmDd = momentDateOnly(date);
  return withTimeZone(tz, yyyyMmDd, hour);
};
