import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Header, Divider } from 'semantic-ui-react';

import { defaultParser } from 'utils/fhirData/parsingFunctions';
import { labelize } from 'utils/textFormats';

import style from '../../../patientSummary.module.scss';

const Nutrition = ({ withHeader = true }) => {
  const { data, isLoading } = useSelector(
    state => state.currentPatient.communications
  );
  const dietitianCommunication = data
    .map(item => defaultParser(item))
    .find(item => item.sender_display === 'Dietitian');

  let communication = {};

  if (dietitianCommunication) {
    const {
      sender_display,
      episodeId,
      status,
      ...rest
    } = dietitianCommunication;
    communication = { sender_display, episodeId, status };
    // eslint-disable-next-line
    for (let key in rest) {
      if (
        key.toLowerCase().includes('dietician') ||
        key.toLowerCase().includes('dietitian')
      ) {
        communication[key] = rest[key];
      }
    }
  }

  const itemsPerRow = 2;
  const sectionTitle = 'Nutrition Plan';
  return (
    <Grid.Row>
      <Grid.Column>
        {withHeader && (
          <>
            <div className={style.headerTitle}>
              <Header content={sectionTitle} />
            </div>
            <Divider />
          </>
        )}
        {isLoading ? <p>loading nutritions...</p> : null}
        <Grid columns={itemsPerRow}>
          <Grid.Row>
            {Object.entries(communication).map(([fieldName, fieldValue]) => (
              <Grid.Column key={fieldName} className={style.columnItems}>
                <Header
                  size="small"
                  content={labelize(fieldName)}
                  subheader={fieldValue}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Nutrition;
