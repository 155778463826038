import React from 'react';
import * as d3 from 'd3';

import { alignAxis } from 'utils/chartUtils';
import Axis from './Axis';
import Dots from './Dots';
import Line from './Line';
import style from './Chart.module';

const TemperatureChart = ({
  data = [],
  dimensions,
  xData,
  tz = {},
  axisIndex,
  axisAmount,
}) => {
  if (data.length !== 2) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const keyAccessor = (_, i) => i;

  const skinTemp = data[0].filter(tick => tick.value !== 0);
  const ambientTemp = data[1].filter(tick => tick.value !== 0);

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max([...skinTemp, ...ambientTemp], yAccessor)])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key="movement-y-axis"
      dimension="y"
      scale={yScale}
      labelLine
      tickColor={style.temperatureLabel}
      {...axisAligment}
    />,
    <Line
      style={{ ...style.line, ...style.skinTempLine }}
      data={skinTemp}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
    <Dots
      style={style.skinTempCircles}
      data={skinTemp}
      keyAccessor={keyAccessor}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      radius={4}
      tz={tz}
    />,
    <Line
      style={{ ...style.line, ...style.ambientTempLine }}
      data={ambientTemp}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
    <Dots
      style={style.ambientTempCircles}
      data={ambientTemp}
      keyAccessor={keyAccessor}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      radius={4}
      tz={tz}
    />,
  ];
};

const TemperatureChartDayTrend = ({
  data = [],
  dimensions,
  xData,
  axisAmount,
  axisIndex,
}) => {
  if (data.length !== 2) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;

  const skinTemp = data[0].filter(tick => tick.value !== 0);
  const ambientTemp = data[1].filter(tick => tick.value !== 0);

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max([...skinTemp, ...ambientTemp], yAccessor)])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key="movement-y-axis"
      dimension="y"
      scale={yScale}
      labelLine
      tickColor={style.temperatureLabel}
      {...axisAligment}
    />,
    <Line
      style={{ ...style.line, ...style.skinTempLineTrend }}
      data={skinTemp}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
    <Line
      style={{ ...style.line, ...style.ambientTempLineTrend }}
      data={ambientTemp}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
  ];
};

export { TemperatureChart, TemperatureChartDayTrend };
