import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import { Button, Header, Input, Loader, Segment } from 'semantic-ui-react';
import { Slider } from 'react-semantic-ui-range';
import { DateRangePicker } from 'react-dates';
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  AzureMapsProvider,
} from 'react-azure-maps';
import { AuthenticationType, ControlPosition, data } from 'azure-maps-control';

import BringDataIntoViewControl from './ControlHelper';
import {
  fetchPatientGeofencing,
  updatePatientGeofencing,
  clearSelectedLocation,
  refreshEffectiveness,
} from 'store/currentPatient/actions';
import { fetchPatientTicks } from 'store/patients/actions';
import useWindowSize from 'utils/useWindowSize';
import { fromDayToTzHour } from 'utils/timeToText';
import style from 'containers/patient/patient.module.scss';

const mapOptions = {
  authOptions: {
    authType: AuthenticationType.subscriptionKey,
    subscriptionKey: process.env.REACT_APP_AZURE_MAPS_KEY,
  },
  minZoom: 2,
  maxZoom: 15,
};

const mapCustomControls = [
  {
    control: new BringDataIntoViewControl({
      units: 'imperial',
    }),
    controlOptions: {
      position: ControlPosition.TopLeft,
    },
  },
];

const oldLocationLayerOptions = {
  iconOptions: {
    image: 'marker-red',
    allowOverlap: true,
    ignorePlacement: true,
  },
};

const baseLocationLayerOptions = {
  textOptions: {
    textField: ['get', 'title'],
    offset: [0, 1.2],
    allowOverlap: true,
    ignorePlacement: true,
  },
  iconOptions: {
    image: 'marker-blue',
    allowOverlap: true,
    ignorePlacement: true,
  },
};

const selectedOtherLocationLayerOptions = {
  iconOptions: {
    image: 'marker-yellow',
  },
  textOptions: {
    textField: ['get', 'title'],
    offset: [0, 1.2],
    allowOverlap: true,
    ignorePlacement: true,
  },
};

const baseLocationRadiusLayerOptions = radiusValue => {
  return {
    color: 'red',
    radius: radiusValue,
    opacity: 0.2,
    minZoom: 10,
    maxZoom: 16,
  };
};

const renderSymbol = (coordinates, properties = {}) => {
  if (!coordinates.length || (!coordinates[0] && !coordinates[1])) return null;

  const rendId = Math.random();
  return (
    <AzureMapFeature
      key={rendId}
      id={rendId.toString()}
      type="Point"
      coordinate={coordinates}
      properties={properties}
    />
  );
};

const UserLocation = ({ patientId }) => {
  const dispatch = useDispatch();
  const map = useRef(null);
  const [date, setDate] = useState({
    start: moment()
      .subtract(7, 'days')
      .startOf('day'),
    end: moment().endOf('day'),
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [baseLocation, setBaseLocation] = useState('0,0');
  const [notValidPosition, setValidPosition] = useState(false);
  const [baseLocationRadiusValue, setBaseLocationRadiusValue] = useState(12);
  const { width } = useWindowSize();
  const { tickData } = useSelector(state => state.patients);
  const {
    geofencing,
    covid19: {
      data: { selectedOtherLocation },
    },
  } = useSelector(state => state.currentPatient);
  const {
    data: { tz },
  } = useSelector(state => state.currentPatient.timeZone);
  const isSmallWindow = width <= 768;

  // eslint-disable-next-line
  useEffect(() => () => dispatch(clearSelectedLocation()), []);

  useEffect(() => {
    dispatch(fetchPatientGeofencing(patientId));
    const { location: locationData = [] } = tickData.data;
    if (!locationData.length) updateTicks();
    // eslint-disable-next-line
  }, [patientId]);

  useEffect(() => {
    updateTicks();
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    const { location: locationData = [] } = tickData.data;
    setMarkers(
      locationData
        .filter(tick => tick.value[0] && tick.value[1])
        .map(tick => new data.Position(tick.value[0], tick.value[1]))
    );
  }, [tickData.data]);

  useEffect(() => {
    const { data: geoData } = geofencing;
    if (!isEmpty(geoData)) {
      setBaseLocation(`${geoData.latitude}, ${geoData.longitude}`);
      if (map.current !== null && !selectedOtherLocation.length) {
        map.current.setCamera({
          center: [geoData.latitude, geoData.longitude],
          zoom: 14,
        });
      }
    }
    // eslint-disable-next-line
  }, [geofencing]);

  const updateTicks = debounce(() => {
    if (date.start !== null && date.end !== null) {
      const types = ['location'];
      const [startDate, endDate] = [date.start, date.end].map((date, idx) => {
        const hoursToSet = ['00:00', '23:59'];
        const dateOnTz = fromDayToTzHour(tz, date, hoursToSet[idx]);
        return new Date(dateOnTz).getTime();
      });
      dispatch(
        fetchPatientTicks({
          patientId,
          types,
          startDate,
          endDate,
        })
      );
    }
  }, 1000);

  const handleChangeDate = ({ startDate, endDate }) => {
    const newStartDate = moment(endDate?.valueOf())?.startOf('day');
    setDate({
      start:
        startDate === endDate
          ? newStartDate
          : startDate?.startOf('day') ?? null,
      end: endDate?.endOf('day') ?? null,
    });
  };

  const handleSelectedLocation = ({ position }) => {
    const fixedPosition = position.join(',');
    setBaseLocation(fixedPosition);
  };

  const handleInputLocation = ({ target }) => {
    setValidPosition(!target.validity.valid);
    setBaseLocation(target.value.replace(/\s/g, ''));
  };

  const handleUpdateBaseLocation = () => {
    const [latitude, longitude] = baseLocation.split(',').map(Number);
    dispatch(refreshEffectiveness());
    dispatch(
      updatePatientGeofencing(patientId, {
        latitude,
        longitude,
      })
    );
  };

  const handleLoadedMap = ({ map: mapInstance }) => {
    map.current = mapInstance;
    if (selectedOtherLocation.length) {
      console.log('Centering on', selectedOtherLocation);
      mapInstance.setCamera({
        center: new data.Position(
          selectedOtherLocation[0],
          selectedOtherLocation[1]
        ),
        zoom: 14,
      });
    }
  };

  const memoizedMarkerRender = useMemo(
    () => markers.map(marker => renderSymbol(marker)),
    [markers]
  );

  const markerBaseLocation = baseLocation.split(',').map(Number);

  const sliderLabel = (baseLocationRadiusValue * 250) / 60;

  const sliderSettings = {
    start: 12,
    min: 12,
    max: 60,
    step: 1.2,
    onChange: radiusValue => {
      setBaseLocationRadiusValue(radiusValue);
    },
  };

  return (
    <Segment>
      <Header className={style.locationControlContainer}>
        <section className={style.locationControlSection}>
          <span className={style.locationControlLabel}>Date</span>
          <DateRangePicker
            displayFormat="MMM D"
            endDate={date.end}
            endDateId="date.end"
            focusedInput={focusedInput}
            isOutsideRange={newDate => newDate > moment().endOf('day')}
            onDatesChange={handleChangeDate}
            onFocusChange={newFocusedInput => setFocusedInput(newFocusedInput)}
            startDate={date.start}
            startDateId="date.start"
            minimumNights={0}
            orientation={isSmallWindow ? `vertical` : 'horizontal'}
          />
        </section>
        <section className={style.locationControlSection}>
          <span className={style.locationControlLabel}>Base Location</span>
          <Input
            placeholder="Geofence center"
            value={baseLocation}
            pattern="^-?\d{1,3}\.\d+,\s*-?\d{1,3}\.\d+$"
            size="small"
            onChange={handleInputLocation}
            error={notValidPosition}
          />
        </section>
        <section className={style.radiusControlSection}>
          <span className={style.radiusControlLabel}>
            Radius: {sliderLabel.toFixed(0)}{' '}
          </span>
          <Slider
            baseLocationRadiusValue
            settings={sliderSettings}
            color="blue"
          />
        </section>
        <section className={style.locationControlButton}>
          <Button
            content="Save"
            onClick={handleUpdateBaseLocation}
            size="large"
            disabled={notValidPosition}
          />
        </section>
        {tickData.isLoading && (
          <section style={{ position: 'absolute', right: 50, top: 50 }}>
            <Loader active />
          </section>
        )}
      </Header>
      <div className={style.locationMapContainer}>
        <AzureMapsProvider>
          <AzureMap
            options={mapOptions}
            customControls={mapCustomControls}
            events={{
              click: handleSelectedLocation,
              ready: handleLoadedMap,
            }}
          >
            <AzureMapDataSourceProvider id="mapDataOldLocations">
              <AzureMapLayerProvider
                id="mapLayerOldLocations"
                options={oldLocationLayerOptions}
                type="SymbolLayer"
              />
              {memoizedMarkerRender}
            </AzureMapDataSourceProvider>
            <AzureMapDataSourceProvider id="mapDataBaseLocation">
              <AzureMapLayerProvider
                id="mapLayerBaseLocation"
                options={baseLocationLayerOptions}
                type={'SymbolLayer'}
              />
              {renderSymbol(markerBaseLocation, { title: 'Geofencing' })}
            </AzureMapDataSourceProvider>
            <AzureMapDataSourceProvider id="mapDataBaseLocationRadius">
              <AzureMapLayerProvider
                id="mapLayerBaseLocationRadius"
                options={baseLocationRadiusLayerOptions(
                  baseLocationRadiusValue
                )}
                type={'BubbleLayer'}
              />
              {renderSymbol(markerBaseLocation)}
            </AzureMapDataSourceProvider>
            <AzureMapDataSourceProvider id="mapDataSelectedOtherLocation">
              <AzureMapLayerProvider
                id="mapLayerSelectedOtherLocation"
                options={selectedOtherLocationLayerOptions}
                type={'SymbolLayer'}
              />
              {renderSymbol(selectedOtherLocation, {
                title: 'Selected Other\n Location',
              })}
            </AzureMapDataSourceProvider>
          </AzureMap>
        </AzureMapsProvider>
      </div>
    </Segment>
  );
};

export default UserLocation;
