import React from 'react';
import style from 'containers/patient/patient.module.scss';

const MealsCircles = ({
  meals,
  yScale,
  convertMealToPixels,
  onEnterBullet,
  onLeaveBullet,
  onBulletClick,
  hoverMeal,
}) => (
  <g>
    {meals.map(meal => (
      <circle
        key={meal.food_logging_id}
        r={8}
        cy={yScale(meal.value)}
        cx={convertMealToPixels(meal.meal_time)}
        className={
          hoverMeal && hoverMeal.food_logging_id === meal.food_logging_id
            ? style.selectedMeal
            : null
        }
        onMouseEnter={onEnterBullet.bind(this, meal)}
        onMouseLeave={onLeaveBullet}
        onClick={onBulletClick.bind(this, meal)}
      />
    ))}
  </g>
);

export default MealsCircles;
