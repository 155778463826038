import React from 'react';
import { capitalize } from 'lodash';
import { Table } from 'semantic-ui-react';
import style from 'containers/patient/patient.module.scss';
import { useSelector } from 'react-redux';
import { renderAdditionalInfo } from 'utils/reports';
import cx from 'classnames';

const DiseasesEvidence = () => {
  const { isLoading, error, data: diseasesEvidence } = useSelector(
    state => state.currentPatient.diseasesEvidence
  );

  return (
    <section className={cx(style.infoSection, style.scrollableSection)}>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="3">Diseases Evidence</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {error && (
            <Table.Row key={'status'}>
              <Table.Cell>status</Table.Cell>
              <Table.Cell>{error}</Table.Cell>
            </Table.Row>
          )}
          {isLoading ? (
            <Table.Row key={'status'}>
              <Table.Cell>status</Table.Cell>
              <Table.Cell>Loading...</Table.Cell>
            </Table.Row>
          ) : (
            Object.entries(diseasesEvidence).map(
              ([evidenceType, evidenceContent]) => {
                if (evidenceType === 'additionalInfo') return null;

                const keyRow = evidenceType.substr(2).replace(/_/g, ' ');

                return (
                  <Table.Row key={evidenceType}>
                    <Table.Cell>{capitalize(keyRow)}</Table.Cell>
                    <Table.Cell>
                      {capitalize(evidenceContent).replace(/_/g, ' ')}
                    </Table.Cell>
                    {diseasesEvidence?.additionalInfo && (
                      <Table.Cell>
                        {renderAdditionalInfo(
                          keyRow,
                          diseasesEvidence.additionalInfo
                        )}
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              }
            )
          )}
        </Table.Body>
      </Table>
    </section>
  );
};

export default DiseasesEvidence;
