import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import PieChart from 'components/Viz/PieChart';
import ReportTable from '../Viz/ReportTable';
import getBiometrics from '../../utils/biometrics';
import getIndicators from '../../utils/biometricIndicators';
import {
  getDisplayPassportStatus,
  roundHealthPassportValues,
} from 'utils/healthPassport';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

export default (patientsArray, signatureUrl) => {
  const mapChartData = patientsArray => {
    const vulnerabilityRisk = {
      green: 0,
      yellow: 0,
      red: 0,
    };
    const preSymptomaticDetection = {
      noChanges: {
        value: 0,
        indicator: 0,
      },
      changes: {
        value: 0,
        indicator: 0,
      },
    };
    const exposureRisk = {
      green: 0,
      yellow: 0,
      red: 0,
    };
    const healthPassport = {
      green: 0,
      yellow: 0,
      red: 0,
    };
    const employeeCapacity = {
      available: 0,
      quarantine: 0,
      selfIsolated: 0,
    };

    patientsArray.forEach(item => {
      const vulnerability = item.biometrics?.['i_vuln'] ?? null;
      if (vulnerability === 'GREEN') {
        vulnerabilityRisk.green++;
      } else if (vulnerability === 'YELLOW') {
        vulnerabilityRisk.yellow++;
      } else if (vulnerability === 'YELLOW') {
        vulnerabilityRisk.red++;
      }

      const biometrics = getBiometrics(item.biometrics);
      const indicators = getIndicators(item.biometrics);
      biometrics.green.forEach(greenItem => {
        if (greenItem.direction !== 'min') {
          preSymptomaticDetection.changes.value++;
          indicators.forEach(indicator => {
            if (
              indicator.name === greenItem.value &&
              indicator.direction === 'up'
            ) {
              preSymptomaticDetection.changes.indicator++;
            }
          });
        } else {
          preSymptomaticDetection.noChanges++;
        }
      });
      biometrics.red.forEach(redItem => {
        if (redItem.direction !== 'min') {
          preSymptomaticDetection.changes.value++;
          indicators.forEach(indicator => {
            if (
              indicator.name === redItem.value &&
              indicator.direction === 'up'
            ) {
              preSymptomaticDetection.changes.indicator++;
            }
          });
        } else {
          preSymptomaticDetection.noChanges.value++;
        }
      });

      const exposure = item.biometrics?.['i_exposure'] ?? null;
      if (exposure === 'GREEN') {
        exposureRisk.green++;
      } else if (exposure === 'YELLOW') {
        exposureRisk.yellow++;
      } else if (exposure === 'YELLOW') {
        exposureRisk.red++;
      }

      const parsedValue = roundHealthPassportValues(
        item?.healthPassport?.status
      );
      const displayPassportStatus = getDisplayPassportStatus(parsedValue);
      if (displayPassportStatus.text === 'Green') {
        healthPassport.green++;
      } else if (displayPassportStatus.text === 'Red') {
        healthPassport.red++;
      } else if (displayPassportStatus.text === 'Yellow') {
        healthPassport.yellow++;
      }

      if (item.quarantineType === 'Quarantine') {
        employeeCapacity.quarantine++;
      } else if (
        item.quarantineType === 'Isolation' ||
        item.quarantineType === 'Social Distancing'
      ) {
        employeeCapacity.selfIsolated++;
      } else if (item.quarantineType === 'N/A') {
        employeeCapacity.available++;
      }
    });

    return {
      vulnerabilityRisk: [
        { legend: 'Green', value: vulnerabilityRisk.green },
        { legend: 'Yellow', value: vulnerabilityRisk.yellow },
        { legend: 'Red', value: vulnerabilityRisk.red },
      ],
      preSymptomaticDetection: [
        {
          legend: 'No Changes',
          value: preSymptomaticDetection.noChanges.value,
        },
        {
          legend: 'Changes Detected',
          value: preSymptomaticDetection.changes.value,
          indicator: preSymptomaticDetection.changes.indicator,
        },
      ],
      exposureRisk: [
        { legend: 'Green', value: exposureRisk.green },
        { legend: 'Yellow', value: exposureRisk.yellow },
        { legend: 'Red', value: exposureRisk.red },
      ],
      healthPassport: [
        { legend: 'Green', value: healthPassport.green },
        { legend: 'Yellow', value: healthPassport.yellow },
        { legend: 'Red', value: healthPassport.red },
      ],
      employeeCapacity: [
        { legend: 'Available', value: employeeCapacity.available },
        { legend: 'Quarantine', value: employeeCapacity.quarantine },
        { legend: 'Self Isolation', value: employeeCapacity.selfIsolated },
      ],
    };
  };

  const colors = ['#BD6155', '#51ADA8', '#FFCF78'];
  const preSymptomaticColors = ['#BD6155', '#51ADA8'];
  const chartsData = mapChartData(patientsArray);

  const vulnerabilityGraph = renderToStaticMarkup(
    <PieChart
      data={chartsData.vulnerabilityRisk}
      colors={colors}
      width={180}
      height={160}
      innerRadius={45}
      outerRadius={70}
    />
  );
  const exposureGraph = renderToStaticMarkup(
    <PieChart
      data={chartsData.exposureRisk}
      colors={colors}
      width={180}
      height={160}
      innerRadius={45}
      outerRadius={70}
    />
  );
  const preSymptomaticGraph = renderToStaticMarkup(
    <PieChart
      data={chartsData.preSymptomaticDetection}
      colors={preSymptomaticColors}
      width={180}
      height={160}
      innerRadius={45}
      outerRadius={70}
    />
  );
  const healthPassportGraph = renderToStaticMarkup(
    <PieChart
      data={chartsData.healthPassport}
      colors={colors}
      width={180}
      height={160}
      innerRadius={45}
      outerRadius={70}
    />
  );
  const employeeCapacityGraph = renderToStaticMarkup(
    <PieChart
      data={chartsData.employeeCapacity}
      colors={colors}
      width={180}
      height={160}
      innerRadius={45}
      outerRadius={70}
    />
  );
  const mapEmployeeStatusData = patientsArray => {
    let healthy = 0;
    let pending = 0;
    let negative = 0;
    let positive = 0;
    let recovered = 0;
    patientsArray.forEach(item => {
      if (item.covidStatus === 'Positive') {
        positive++;
      } else if (item.covidStatus === 'Negative') {
        negative++;
      } else if (item.covidStatus === 'Antibody Positive') {
        recovered++;
      } else if (item.covidStatus === 'Pending') {
        pending++;
      } else {
        healthy++;
      }
    });
    return [
      {
        title: 'Healthy & Untested',
        indicator: null,
        number: healthy,
      },
      {
        title: 'Test: Pending',
        indicator: null,
        number: pending,
      },
      {
        title: 'Test: Negative',
        indicator: null,
        number: negative,
      },
      {
        title: 'Test: Positive',
        indicator: null,
        number: positive,
      },
      {
        title: 'Recovered',
        indicator: null,
        number: recovered,
      },
    ];
  };
  const mapProximityEventsData = patientsArray => {
    const interactions = {
      inStore: 0,
      elsewhere: 0,
    };
    patientsArray.forEach(item => {
      interactions.inStore =
        interactions.inStore + item.interactions?.outsideGeofenceValues.length;
      interactions.elsewhere =
        interactions.elsewhere + item.interactions?.insideGeofenceValues.length;
    });
    return [
      {
        title: 'Interactions Within user’s geofence',
        indicator: null,
        number: interactions.inStore,
      },
      {
        title: 'Interactions Elsewhere',
        indicator: null,
        number: interactions.elsewhere,
      },
    ];
  };
  const currentDate = moment();
  const currentTime = moment().format('hh:mm A');
  const oneWeekFromNowDate = moment(currentDate)
    .add(1, 'weeks')
    .format('MM/DD/YY');
  const userData = jwtDecode(localStorage.getItem('token'));
  const drName = `Dr. ${userData.id.last}`;
  const employeeStatusData = mapEmployeeStatusData(patientsArray);
  const proximityEventsData = mapProximityEventsData(patientsArray);
  const employeeStatusTable = renderToStaticMarkup(
    <ReportTable data={employeeStatusData} />
  );
  const proximityEventsTable = renderToStaticMarkup(
    <ReportTable data={proximityEventsData} />
  );
  const tenantName = (() => {
    const tenantCustomer = process.env.REACT_APP_CUSTOMER_TENANT;
    if (tenantCustomer === 'ccad') return 'Cleveland Clinic Abu Dhabi';
    if (tenantCustomer === 'seha') return 'Abu Dhabi Health Services Co';
    if (tenantCustomer === 'uhg') return 'United Health Group';
    if (tenantCustomer === 'glucare') return 'Glucare';
    return 'Unknown';
  })();
  const signatureData = signatureUrl
    ? `<img class="signature-image" src=${signatureUrl}>`
    : `<div class="signature-text">/${userData.id.first} ${userData.id.last}, NREMT/</div>`;
  return `
    <section>
      <div class="title">
          <h1 class="header">Employer Report</h1>
          <img class="image" alt="jawebone-health-logo" src="/all-health-logo.png" />
      </div>
      <div>
          <div>Report Ref #: 83729</div>
          <div>Prepared for: ${tenantName}</div>
          <div>Prepared at: ${currentTime} • ${currentDate.format(
    'MM/DD/YY'
  )}</div>
          <div>Date Range: ${currentDate.format(
            'MM/DD/YY'
          )} - ${oneWeekFromNowDate}</div>
      </div>
      <div class="certification-info-container">
          <div class="badge-container">
              <img src="/green-zone-icon.svg">
              <h2>Green Zone</h2>
          </div>
          <div class="certification">
              <h3>Certification</h3>
              <p>To: ${tenantName}</p>
              <p>Based from the findings this is a Green Zone business.
                  This business has executed the proper responses for anyone suspected of COVID-19
                  and has been compliant with the recommendations of the DHA.
              </p>
              <div class="signature-container">
                  ${signatureData}
                  <div>${drName} • ${currentDate.format('DD MMMM YYYY')}</div>
              </div>
          </div>
      </div>
      <div class="grid-container">
          <div class="grid-item">
              <h3>Health Passport Status</h3>
              ${healthPassportGraph}
          </div>
          <div class="grid-item">
              <h3>Employee Capacity</h3>
              ${employeeCapacityGraph}
          </div>
          <div class="grid-item">
              <h3>Vulnerability Risk</h3>
              ${vulnerabilityGraph}
          </div>
          <div class="grid-item">
              <h3>Exposure Risk</h3>
              ${exposureGraph}
          </div>
      </div>
    </section>
    <section class="page">
      <div class="title">
          <h1 class="header">Employer Report</h1>
          <img class="image" alt="jawebone-health-logo" src="/all-health-logo.png" />
      </div>
      <div>
          <div>Report Ref #: 83729</div>
          <div>Prepared for: ${tenantName}</div>
          <div>Prepared at: 9:38AM • ${currentDate.format('MM/DD/YY')}</div>
          <div>Date Range: 05/04/20 - 11/04/20</div>
      </div>
      <div class="grid-container">
          <div class="grid-item">
              <h3>Pre-symptomatic Detection</h3>
              ${preSymptomaticGraph}
          </div>
          <div class="grid-item">
              <h3>Employee Testing Status</h3>
              ${employeeStatusTable}
          </div>
          <div class="grid-item">
              <h3>Proximity Events</h3>
              ${proximityEventsTable}
          </div>
          <div class="grid-item blank-container">
              <h3 class="blank">THIS BOX WAS INTENTIONALLY LEFT BLANK</h3>
          </div>
      </div>
      <h3 class="report-end">END OF REPORT</h3>
    </section>
    `;
};
