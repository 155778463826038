import React, { createRef, useEffect, useState } from 'react';
import { Dropdown, Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { pdfjs } from 'react-pdf';

import { ECRTextEditor, TextEditor } from 'components';
import {
  fetchPatientAllTicks,
  fetchPatientSurveys,
  fetchPatientTicks,
  fetchPatientTicksSummary,
} from 'store/reports/actions';
import { fetchFhirReportDependencies } from 'store/currentPatient/actions/fhir';
import template from 'components/TextEditor/reportTemplate';
import employerTemplate from 'components/TextEditor/employerReportTemplate';
import ECRTemplate from 'components/EmployeeCovidReport/ECRTemplate';
import style from 'containers/patient/patient.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReportBox = () => {
  const dispatch = useDispatch();
  const editorRef = createRef();
  const covidEditorRef = createRef();
  const employerEditorRef = createRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const userData = useSelector(state => state.currentPatient);
  const userTicks = useSelector(state => state.reports);
  const { data: patientsInfo } = useSelector(
    state => state.patients.logPatients
  );
  const { data: authData } = useSelector(state => state.auth);
  const { data: timeZone = {} } = useSelector(
    state => state.currentPatient.timeZone
  );
  const isFhirUser = userData.isFhirUser.data;
  useEffect(() => {
    if (userData.id && isFhirUser) {
      dispatch(fetchFhirReportDependencies(userData.id));
    }
    // eslint-disable-next-line
  }, [userData.id, isFhirUser]);

  const isGlucareEnv = process.env.REACT_APP_CUSTOMER_TENANT === 'glucare';

  const tz = timeZone && !isEmpty(timeZone) ? timeZone.tz : '';

  const reportOptions = isGlucareEnv
    ? [{ text: 'Patient health', value: 'patient health' }]
    : [
        { text: 'Pre-Diabetes', value: 'pre-diabetes' },
        { text: 'COVID-19', value: 'covid-19' },
        { text: 'Executive Summary', value: 'executive-summary' },
      ];

  const todayDate = moment();
  const endDate = todayDate.endOf('day').valueOf();

  const startDate = todayDate
    .clone()
    .subtract(5, 'days')
    .startOf('day')
    .valueOf();
  const covidStart = todayDate
    .clone()
    .subtract(6, 'days')
    .startOf('day')
    .valueOf();
  const symptomStart = todayDate
    .clone()
    .subtract(14, 'days')
    .startOf('day')
    .valueOf();
  const data = template(
    userData,
    userTicks,
    {
      start: moment(startDate),
      end: moment(endDate),
    },
    tz,
    authData.signature
  );
  const covidReportData = ECRTemplate(
    userData,
    {
      from: covidStart,
      to: endDate,
    },
    userTicks,
    authData.signature,
    tz
  );

  const objectToArray = object => {
    return Object.keys(object).map(key => object[key]);
  };
  const patientsArray = objectToArray(patientsInfo);
  const employerReportData = employerTemplate(
    patientsArray,
    authData.signature
  );

  const preFetchDiabetes = () => {
    dispatch(
      fetchPatientTicks({
        patientId: userData.id,
        startDate,
        endDate,
      })
    );
    dispatch(
      fetchPatientTicksSummary({
        patientId: userData.id,
        startDate,
        endDate,
      })
    );
  };
  const preFetchCovid = () => {
    dispatch(
      fetchPatientAllTicks({
        patientId: userData.id,
        startDate: covidStart,
        endDate,
      })
    );
    dispatch(
      fetchPatientSurveys({
        patientId: userData.id,
        startDate: moment(symptomStart),
        endDate: moment(endDate),
      })
    );
  };

  const handleOpenModal = value => {
    setSelectedReport(value);
    setIsOpen(true);
  };

  const handleOpenReport = value => () => {
    if (value === 'pre-diabetes' || value === 'patient health')
      preFetchDiabetes();
    if (value === 'covid-19') preFetchCovid();
    handleOpenModal(value);
  };

  const renderContent = () => {
    switch (selectedReport) {
      case 'pre-diabetes':
      case 'patient health':
        return <TextEditor ref={editorRef} data={data} />;
      case 'covid-19':
        return <ECRTextEditor ref={covidEditorRef} data={covidReportData} />;
      case 'executive-summary':
        return <TextEditor ref={employerEditorRef} data={employerReportData} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      size="large"
      className={style.editorModal}
      trigger={
        <Dropdown button fluid text="Generate Report">
          <Dropdown.Menu>
            {reportOptions.map(({ text, value }) => (
              <Dropdown.Item
                key={value}
                onClick={handleOpenReport(value)}
                text={text}
                value={value}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      }
      closeIcon
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Modal.Header>{selectedReport} report</Modal.Header>
      <Modal.Content className={style.editorContent} scrolling>
        {renderContent()}
      </Modal.Content>
    </Modal>
  );
};

export default ReportBox;
