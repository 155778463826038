import moment from 'moment-timezone';

export const colorByStatus = {
  arrived: '#51ada8',
  planned: '#526b9e',
  finished: 'green',
  unknown: 'grey',
};

export const dataTypeOf = value => {
  if (['string', 'number'].includes(typeof value)) {
    return 'string';
  }
  if (Array.isArray(value)) {
    return 'array';
  }
  if (typeof value === 'object' && value !== null) {
    return 'object';
  }
  return 'unknown';
};

/**
 * @description Takes a valid date value and format it
 * @param {string,number} date
 * @description could be a string, epoch, or timestamp
 * @param {string} dateFormat
 */
export const formatDate = (date, dateFormat = 'YYYY MM DD') => {
  if (typeof date === 'number') {
    let timestamp = date + '';
    date = timestamp.length === 13 ? date : date * 1000;
  }
  return moment(date).format(dateFormat);
};
export const isArrayOfStrings = data =>
  Array.isArray(data) && data.every(item => typeof item === 'string');

export const channelParser = array =>
  array.reduce((a, b) => {
    const newA = { ...a };
    const label = b.system || 'unknown';
    const use = b.use ? `(${b.use})` : '';
    const value = `${b.value} ${use}`;

    newA[label] = newA[label] ? newA[label] + ` | ${value}` : value;
    return newA;
  }, {});

const stractExtensionValues = item => [
  item.url
    ?.split?.(':')
    ?.slice?.(-1)?.[0]
    ?.replace(/^[A-Z]/g, v => v.toLowerCase()),
  item.valueString,
];

export const rootExtensionToField = extensions =>
  extensions.map(stractExtensionValues);

export const joinArray = array => array?.join(' | ');

export const mapToValue = (array, value) =>
  array?.map(item => item?.[value] ?? '---');

export const addFromCodeObject = (data, name, newFields) => {
  const mapCoding = item => {
    const display = item.display || '';
    const code = item.code ? `(${item.code})` : '';
    return `${display}${code}` || '---';
  };

  newFields[`${name}Values`] = joinArray(data?.coding?.map(mapCoding)) || '---';
  newFields[`${name}Text`] = data.text ?? '---';
};

export const associatedDataParser = (
  data,
  associatedData = {},
  parent = ''
) => {
  // eslint-disable-next-line
  for (const key in data) {
    const name = parent ? `${parent}_${key}` : key;
    switch (dataTypeOf(data[key])) {
      case 'object':
      case 'array':
        if (key === 'display') {
          associatedData[name] = data[key];
        } else {
          associatedDataParser(data[key], associatedData, name);
        }
        break;
      default:
        break;
    }
  }
  return Object.keys(associatedData).length ? associatedData : null;
};
