import React from 'react';
import style from 'containers/patient/patient.module.scss';
import LabelValueText from 'components/LabelValueText';
import classNames from 'classnames';

const MealPreview = ({ meal }) => {
  const mealFields = ['meal_type', 'upload_time', 'meal_time'];
  const defaultMeal = {
    image_url:
      'https://lh3.googleusercontent.com/QTtbh-tXM2bWyj1hNwbiQ6-XInPbhYq2LtDTCnkl1a136vmmvKjWctbbopFH5q1hkLj6_II27G5-Fq-d1tfbeo6Q9oMgPY83LrQ2utcbQjcNyA6rb8l5FKnWTc_5plGiWiOJiT-wieBjjZC2ioXIiFhGP_Rno0cuXLqQ65Ta80bIZJQOavTcS1rgfXfFFkyooEbUY3eTZpuZoNY-3nZ6LYH8Ah4qAyM0erK6bVMKV4X7y14Ocrm5OPnedVbwZ-Td_WhrXglyKjfvRN5O67dRsLjjJ4lOvgzW30ZzqfSaJyDhOabhrRh14QbbZagiRjOcLGDPi3dlGmEL3vxR9yQ9Zs7k8iH4HI8teRekBeIACl6sIUUV3cqcP0RhzW8pKkRxWy-4VrUxtWNdtO6nCyCMeR_mPXTR9am_NUMfnB2-2FlzOBlzu3k7nqzNiUquAHVwgeb51iZYfryG4iPZ-rFVzFXiiDbOttxwCz_7S0xJRzgbr06kF-IEViCYeEK1ZyxaXfoVheCt1dwRekqTdlJvEQAGzrZ4E-qPK9qBPBeZzqiRWO5ocEunyLFZ_OxF7inYQwXStf4RFRadTo1iN3mGzsXMSTeVbfb83v0EadpMFFpMT7OPdMI2f7ixVCMNf9YZheiHLENruRMsvwC6HDrTi6ovZRYln8uRk_XDOO7j32IZ9v9AQ0G9AX5DIbpu8Fu5Up89elqWcFXGkiU97xkP22ni_zJcz6bs-ZLbj31z7ERoSw=s500-no',
    meal_type: 'Default',
    upload_time: new Date(),
    meal_time: new Date(),
  };
  const isMeal = !!meal;
  const actualMeal = isMeal ? meal : defaultMeal;
  return (
    <div
      className={classNames(style.mealTimelinePreview, {
        [style.previewShadow]: isMeal,
      })}
    >
      <img
        src={actualMeal.image_url}
        alt={actualMeal.meal_type}
        className={style.mealImage}
      />
      <div>
        {mealFields.map(field => (
          <LabelValueText
            key={field}
            label={field}
            value={actualMeal[field] || '---'}
            date={['upload_time', 'meal_time'].includes(field)}
            dateFormat="DD-MM-YYYY HH:mm"
          />
        ))}
      </div>
    </div>
  );
};

export default MealPreview;
