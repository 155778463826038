import React, { useRef, useState } from 'react';
import style from 'containers/patient/patient.module.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Modal, Header, Button, Icon, Form, Divider } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { postMealMetadata } from 'store/currentPatient/actions';
import OtherFieldSection from './Components/OtherFieldSection';

const MetadataForm = ({ mealId }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const formRef = useRef(null);

  const handleSave = () => {
    formRef.current.handleSubmit();
  };
  const options = [
    { key: 'F', text: 'Fat', value: 'FAT' },
    { key: 'C', text: 'Carbohydrate', value: 'CARBOHYDRATE' },
    { key: 'S', text: 'Sugar', value: 'SUGAR' },
    { key: 'P', text: 'Protein', value: 'PROTEIN' },
  ];

  const handleClose = () => setOpen(false);

  const handleSubmit = ({ value, type, other }) => {
    const body = {
      mealId,
      metadata: { value, type, other },
    };
    handleClose();
    dispatch(postMealMetadata(body));
  };

  const initialValues = {
    value: '',
    type: 'FAT',
    other: [],
    otherField: {
      name: '',
      description: '',
    },
  };

  const validationSchema = Yup.object().shape({
    value: Yup.string()
      .matches(
        /^\d+(\.\d{1,2})?$/,
        'Must be a number with a max of two decimals'
      )
      .required('Is required'),
    type: Yup.string().required('Type is required'),
  });

  return (
    <Modal
      size="tiny"
      open={open}
      trigger={
        <div className={style.addMetadataButton}>
          <Button color="blue" onClick={() => setOpen(true)}>
            Add Metadata
          </Button>
          <Button color="blue" onClick={() => console.log('csv')}>
            .csv
          </Button>
        </div>
      }
      closeIcon
      onClose={handleClose}
    >
      <Header icon="plus" size="small" content="Add Metadata" />
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => {
            const selectHandlers = {
              onChange: (e, { name, value }) => setFieldValue(name, value),
              onBlur: (e, { name, value }) => setFieldTouched(name, value),
            };
            const basicHandlers = {
              onChange: handleChange,
              onBlur: handleBlur,
            };
            return (
              <Form onSubmit={handleSubmit} ref={formRef}>
                <Form.Group widths="equal">
                  <Form.Select
                    error={touched.type && errors.type}
                    fluid
                    label="Type"
                    name="type"
                    options={options}
                    placeholder="Type"
                    value={values.type}
                    {...selectHandlers}
                  />
                  <Form.Input
                    fluid
                    error={touched.value && errors.value}
                    label="Value"
                    name="value"
                    placeholder="Value"
                    {...basicHandlers}
                    value={values.value}
                  />
                </Form.Group>
                <Divider />
                <OtherFieldSection
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" type="button" onClick={handleSave}>
          <Icon name="checkmark" /> Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default MetadataForm;
