import axios from 'axios';
import { isArray, isNil, isEmpty } from 'lodash';
import nanoid from 'nanoid';
import r from 'jsrsasign';

import configuredAxios from 'api/axios.config';

const GATEWAY_ENDPOINT = process.env.REACT_APP_GATEWAY_API_URL;
const USER_ENDPOINT = process.env.REACT_APP_USER_API_URL;
const USER_V2_ENDPOINT = '/user/api/v0.2';
const USER_V2_FOOD_LOGGING_ENDPOINT = `${USER_V2_ENDPOINT}/users/@me/foodlogging`;
const MYUSER_ENDPOINT = '/users/@me';
class UserAPI {
  async login({ email, password }) {
    const encodedCredentials = new Buffer.from(`${email}:${password}`).toString(
      'base64'
    );

    return axios
      .post(
        `${GATEWAY_ENDPOINT + USER_ENDPOINT}/users/@me/tokens`,
        {},
        {
          headers: {
            Authorization: `Basic ${encodedCredentials}`,
          },
        }
      )
      .then(response => response.data);
  }

  async azureLogin(token) {
    return axios
      .post(
        `${GATEWAY_ENDPOINT + USER_V2_ENDPOINT}/users/@me/tokens`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(response => response.data);
  }

  async getById(id) {
    const patientResponse = await configuredAxios.get(
      `${USER_ENDPOINT}/users?uid=${id}`
    );
    if (isArray(patientResponse) && !isNil(patientResponse[0])) {
      const biometrics = await configuredAxios.get(
        `${USER_ENDPOINT}/users/${id}/biometrics`
      );
      const { first, last, image } = patientResponse[0];
      const { dob, gender, metric, height } = biometrics;
      const parsedHeight = this.parseHeight({ height, metric });

      return {
        first,
        last,
        name: `${first} ${last}`,
        id,
        birthdate: dob,
        gender,
        height: parsedHeight,
        image,
        isMetric: metric,
      };
    }
    return {};
  }

  parseHeight({ height, metric }) {
    if (metric) {
      return `${height}cm`;
    }
    const totalFeet = height * 0.0328084;
    const feet = Math.floor(totalFeet);
    const inches = Math.floor((totalFeet - feet) * 12);
    return `${feet}'${inches}"`;
  }

  async getByIds(users) {
    const ids = users.map(patient => patient.uid);
    const params = ids.map(id => `uid=${id}`).join('&');
    const patientsResponse = await configuredAxios.get(
      `${USER_ENDPOINT}/users?${params}`
    );
    if (isArray(patientsResponse) && !isEmpty(patientsResponse)) {
      return patientsResponse
        .filter(this.isValid)
        .map(patient => this.mapPatientData(patient, users));
    }
    return [];
  }

  isValid(patient) {
    return !isNil(patient.id) && !isNil(patient.first) && !isNil(patient.last);
  }

  mapPatientData(patient, users) {
    const { id, first, last, image } = patient;
    const extraInfo = users.find(user => user.uid === id);
    return {
      id,
      first,
      last,
      image,
      ...extraInfo,
    };
  }

  updatePatientInfo(id, patientInfo) {
    const biometricsToUpdate = {
      set_dob: patientInfo.birthdate,
      set_gender: patientInfo.gender,
      set_height: patientInfo.metricHeight,
    };
    return configuredAxios.patch(
      `${USER_ENDPOINT}/users/${id}/biometrics`,
      biometricsToUpdate
    );
  }

  /* ---------------- Auth info ---------------- */

  getMyData() {
    return configuredAxios
      .get(`${USER_ENDPOINT + MYUSER_ENDPOINT}`)
      .catch(err => ({}));
  }

  getUserSavedAlerts() {
    return configuredAxios
      .get(`${USER_ENDPOINT}/alerts`)
      .then(data => (data.length ? data : []))
      .catch(err => []);
  }

  saveUserAlerts(alerts) {
    return configuredAxios
      .post(`${USER_ENDPOINT}/alerts`, alerts)
      .then(() => alerts)
      .catch(err => '');
  }

  postMySignature(image_file) {
    return configuredAxios
      .post(`${USER_ENDPOINT + MYUSER_ENDPOINT}/signature`, image_file)
      .then(res => res.url)
      .catch(err => '');
  }

  /**
   ------------------------------------------------Time zone
   */
  getPatientTimeZone(patientId) {
    return configuredAxios
      .get(`${GATEWAY_ENDPOINT + USER_ENDPOINT}/users/${patientId}/timezones`)
      .then(data => (data.length ? data[0] : {}))
      .catch(err => {});
  }

  /*
--------------------------------------------------Foodlogging
*/
  getPatientFoodLogging(patientId, date) {
    const params = new URLSearchParams();
    params.append('compatibility', 'false');
    if (date.from_date && date.to_date) {
      params.append('from_date', date.from_date);
      params.append('to_date', date.to_date);
    }
    return configuredAxios
      .get(`${USER_V2_ENDPOINT}/users/${patientId}/foodlogging?${params}`)
      .catch(err => []);
  }

  /* ---------------- Meal`s Notes ---------------- */

  postMealNote({ note, mealId }) {
    return configuredAxios
      .post(`${USER_V2_FOOD_LOGGING_ENDPOINT}/${mealId}/notes`, {
        note,
      })
      .catch(err => ({}));
  }

  setPublishedMealNote({ noteId, mealId }) {
    // check for method and url
    /*
    return configuredAxios
      .patch(`${USER_V2_FOOD_LOGGING_ENDPOINT}/${mealId}/notes/${noteId}`)
      .then(() => ({ noteId }))
      .catch(err => ({}));
    */
    return { noteId };
  }

  deleteMealNote({ noteId, mealId }) {
    return configuredAxios
      .delete(`${USER_V2_FOOD_LOGGING_ENDPOINT}/${mealId}/notes/${noteId}`)
      .then(() => ({ noteId }))
      .catch(err => ({}));
  }

  /* ---------------- Meal`s Metadata ---------------- */

  postMealMetadata({ metadata, mealId }) {
    return configuredAxios
      .post(`${USER_V2_FOOD_LOGGING_ENDPOINT}/${mealId}/metadata`, metadata)
      .catch(err => ({}));
  }

  setPublishedMealMetadata({ metadataId, mealId, published }) {
    return configuredAxios
      .put(
        `${USER_V2_FOOD_LOGGING_ENDPOINT}/${mealId}/metadata/${metadataId}`,
        { published }
      )
      .catch(err => ({}));
  }

  deleteMealMetadata({ metadataId, mealId }) {
    return configuredAxios
      .delete(
        `${USER_V2_FOOD_LOGGING_ENDPOINT}/${mealId}/metadata/${metadataId}`
      )
      .then(() => ({
        metadataId,
      }))
      .catch(err => ({}));
  }

  /* ---------------- Meal`s Metadata Notes ---------------- */
  postMetadataNote({ note, mealId, metadataId }) {
    return configuredAxios
      .post(
        `${USER_V2_FOOD_LOGGING_ENDPOINT}/${mealId}/metadata/${metadataId}/notes`,
        {
          note,
        }
      )
      .then(note => ({ metadataId, note }))
      .catch(err => ({}));
  }

  deleteMetadataNote({ noteId, mealId, metadataId }) {
    return configuredAxios
      .delete(
        `${USER_V2_FOOD_LOGGING_ENDPOINT}/${mealId}/metadata/${metadataId}/notes/${noteId}`
      )
      .then(() => ({ metadataId, noteId }))
      .catch(err => ({}));
  }

  /* ---------------- Messaging ---------------- */
  getMessagingToken(info) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_MESSAGING_WEBCHAT_SECRET}`,
      },
      json: true,
    };

    return fetch(
      'https://directline.botframework.com/v3/directline/tokens/generate',
      options
    )
      .then(res => res.json())
      .then(tokenResponse => {
        const response = {};
        response.userId = nanoid(4);
        response.userName = info.userName;
        response.connectorToken = tokenResponse.token;
        response.optionalAttributes = { age: 33 };
        response.isAgent = true;
        const sJWS = r.jws.JWS.sign(
          null,
          { alg: 'HS256', cty: 'JWT' },
          response,
          process.env.REACT_APP_MESSAGING_APP_SECRET
        );
        return sJWS;
      })
      .catch(err => {
        console.log('Failed fetching new token.', err);
      });
  }

  /* ---------------- Azure Groups ---------------- */
  getUserGroups(id) {
    return configuredAxios
      .get(`${USER_V2_ENDPOINT}/groups/${id}`)
      .then(this.cleanUpGroups)
      .catch(() => []);
  }

  updateUserGroups(id, updatedGroups, removedGroups) {
    const bodyToUpdate = {
      add: updatedGroups ? [updatedGroups] : undefined,
      remove: removedGroups ? [removedGroups] : undefined,
    };
    return configuredAxios
      .post(`${USER_V2_ENDPOINT}/groups/${id}`, bodyToUpdate)
      .then(this.cleanUpGroups)
      .catch(() => []);
  }

  cleanUpGroups(groups) {
    return groups.filter(group =>
      [
        'antibody_neg',
        'antibody_pos',
        'pcr_tested_pos',
        'pcr_tested_neg',
        'pcr_test_pending',
        'quarantine',
        'social_distancing',
        'isolation',
      ].includes(group)
    );
  }
}

export default UserAPI;
