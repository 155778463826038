import React, { useEffect } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { TICK_DATA_TYPES } from 'utils/constants';
import { secondsToText, fromDayToTzHour } from 'utils/timeToText';
import { fetchTickSummary } from 'store/patients/actions';
import SummaryInfoRow from './components/SummaryInfoRow';
import style from '../Viz.module.scss';

const SummarySection = ({ date, selectedCharts }) => {
  const dispatch = useDispatch();
  const { data: summaryData, isLoading } = useSelector(
    state => state.patients.summaryData
  );
  const { data: timeZone } = useSelector(
    state => state.currentPatient.timeZone
  );
  const patientId = useSelector(state => state.currentPatient.id);

  const tz = timeZone?.tz || '';

  useEffect(() => {
    patientId && updateSummary();
    // eslint-disable-next-line
  }, [patientId, selectedCharts[0], date, dispatch]);

  const updateSummary = () => {
    if (date.start !== null && date.end !== null && !isEmpty(selectedCharts)) {
      const startDate = moment(fromDayToTzHour(tz, date.start, '00:00')).format(
        'YYYY-MM-DD'
      );
      const endDate = moment(fromDayToTzHour(tz, date.end, '23:59')).format(
        'YYYY-MM-DD'
      );
      const tick = TICK_DATA_TYPES[selectedCharts[0]];
      dispatch(
        fetchTickSummary({
          patientId,
          tick,
          startDate,
          endDate,
        })
      );
    }
  };

  const renderSummary = () => {
    const selectedChart = selectedCharts[0];
    switch (selectedChart) {
      case 'movement': {
        const {
          longestActiveTime,
          longestIdleTime,
          totalActiveTime,
          activeBurnCal,
          restBurnCal,
          totalCal,
        } = summaryData;
        return [
          SummaryInfoRow({
            key: 1,
            topLeftText: 'Longest Active',
            topLeftValue: secondsToText(longestActiveTime),
            centerText: 'Active Time',
            centerValue: secondsToText(totalActiveTime),
            centerIcon: 'child',
            topRightText: 'Longest Idle',
            topRightValue: secondsToText(longestIdleTime),
          }),
          SummaryInfoRow({
            key: 2,
            topLeftText: 'Active Burn',
            topLeftValue: `${activeBurnCal ?? '--'} cal`,
            centerText: 'Total Burn',
            centerValue: `${totalCal ?? '--'} cal`,
            centerIcon: 'fire',
            topRightText: 'Resting Burn',
            topRightValue: `${restBurnCal ?? '--'} cal`,
          }),
        ];
      }

      case 'sleep': {
        const {
          awakeTime,
          deepTime,
          fellAsleepHour,
          lightTime,
          remTime,
          wokeUpHour,
          wokeUpTimes = 0,
          timeToSleep,
        } = summaryData;
        const sleepTime = awakeTime + lightTime + remTime + deepTime;
        const fellAsleepTime = sleepTime
          ? moment(fellAsleepHour * 1000).format('hh:mm a')
          : '--';
        const wokeUpTime = wokeUpHour
          ? moment(wokeUpHour * 1000).format('hh:mm a')
          : '--';
        return [
          SummaryInfoRow({
            key: 0,
            topLeftText: 'Fell Asleep',
            topLeftValue: fellAsleepTime,
            centerText: 'Time To Sleep',
            centerValue: secondsToText(timeToSleep),
            centerIcon: 'bed',
            topRightText: 'Woke Up',
            topRightValue: wokeUpTime,
          }),
          SummaryInfoRow({
            key: 1,
            topLeftText: 'Awake',
            topLeftValue: secondsToText(awakeTime),
            bottomLeftText: 'Light Sleep',
            bottomLeftValue: secondsToText(lightTime),
            centerText: 'Sleep',
            centerValue: secondsToText(sleepTime),
            centerIcon: 'moon',
            topRightText: 'REM Sleep',
            topRightValue: secondsToText(remTime),
            bottomRightText: 'Deep Sleep',
            bottomRightValue: secondsToText(deepTime),
          }),
          SummaryInfoRow({
            key: 2,
            topLeftText: '',
            topLeftValue: '',
            bottomLeftText: '',
            bottomLeftValue: '',
            centerText: 'Woke Up Times',
            centerValue: wokeUpTimes,
            centerIcon: 'sun',
            topRightText: '',
            topRightValue: '',
            bottomRightText: '',
            bottomRightValue: '',
          }),
        ];
      }

      case 'hr': {
        const { minHr, maxHr, avgHr } = summaryData;
        return SummaryInfoRow({
          topLeftText: 'Min HR',
          topLeftValue: `${minHr ?? '--'} bpm`,
          centerText: 'Active HR',
          centerValue: `${avgHr ?? '--'} bpm`,
          centerIcon: 'heartbeat',
          topRightText: 'Max HR',
          topRightValue: `${maxHr ?? '--'} bpm`,
        });
      }

      default:
        return [];
    }
  };

  return !isEmpty(summaryData) && !isEmpty(selectedCharts) ? (
    <section className={style.detailsSection}>
      <Header as="h3" className={style.detailsTitle}>
        {`${isLoading ? 'Loading ' : ''}Details`}
      </Header>
      {renderSummary()}
    </section>
  ) : null;
};

export default SummarySection;
