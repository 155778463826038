import React from 'react';
import { Form, Header } from 'semantic-ui-react';
import style from 'containers/patient/patient.module.scss';

const FirstStep = ({
  resourceOptions,
  handleChange,
  formState,
  visitOptions,
}) => {
  /*{
      label: 'Resource Type',
      name: 'resource_type',
      options: resourceOptions,
    },*/
  const selects = [
    { label: 'Visit Type', name: 'visit_reason_id', options: visitOptions },
  ];
  return (
    <Form>
      <Header content="Choose specialty" />
      <Form.Group widths="equal">
        {selects.map(config => (
          <Form.Select
            {...config}
            key={config.name}
            fluid
            search
            selection
            className={style.dropdownAppointment}
            onChange={handleChange}
            value={formState[config.name]}
          />
        ))}
      </Form.Group>
      <Form.TextArea
        label="Patient Comment"
        name="patient_comment"
        value={formState.patient_comment}
        placeholder="Routine checks..."
        onChange={handleChange}
      />
    </Form>
  );
};

export default FirstStep;
