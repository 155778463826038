import React, { Component } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import {
  Checkbox,
  Header,
  Loader,
  Segment,
  Button,
  Dimmer,
} from 'semantic-ui-react';
import { Action } from 'components';
import ChartSelector from './ChartSelector';
import { ChartsTable } from './components';
import { fetchPatientTicks } from 'store/patients/actions';
import { TICK_DATA_TYPES, SEVERITY_TYPES } from 'utils/constants';
import { fromDayToTzHour } from 'utils/timeToText';
import style from './Viz.module.scss';
import MetricsColorCode from './MetricsColorCode';
import SummarySection from './SummarySection';
import DatePicker from './DatePicker';
import ChartPicker from './ChartPicker';

class Viz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCharts: [],
      showDailyTrends: false,
      showTable: false,
      date: { start: null, end: null },
      chartFilters: {
        patientId: this.props.patientId,
        types: [],
        selectedCharts: [],
        date: { start: null, end: null },
        startDate: null,
        endDate: null,
      },
      tz:
        this.props.timeZone && !isEmpty(this.props.timeZone)
          ? this.props.timeZone.tz
          : '',
    };
    this.graphContainerRef = React.createRef(null);
  }

  isDateRange() {
    const { chartFilters } = this.state;
    return chartFilters.date.start && chartFilters.date.end
      ? chartFilters.date.start.format('DD/MM/YYYY') !==
      chartFilters.date.end.format('DD/MM/YYYY')
      : false;
  }

  handleChangeChart = (e, { value }) => {
    const types = value.flatMap(chart => TICK_DATA_TYPES[chart]);
    if (!value.length) this.setState({ selectedCharts: [] });
    this.setState(prevState => ({
      ...prevState,
      chartFilters: {
        ...prevState.chartFilters,
        types,
        selectedCharts: value,
      },
    }));
  };

  handleSubmitTicks = () => {
    this.updateTicks();
  };

  updateTicks = () => {
    const chartFilters = this.state.chartFilters;
    if (
      chartFilters.startDate !== null &&
      chartFilters.endDate !== null &&
      chartFilters.types.length
    ) {
      this.props.fetchPatientTicks(chartFilters);
      this.setState({
        selectedCharts: chartFilters.selectedCharts,
        showDailyTrends: false,
        date: chartFilters.date,
      });
    }
  };

  handleChangeDate = ({ startDate, endDate }) => {
    if (startDate === null && endDate === null)
      this.setState({
        date: { start: null, end: null },
      });
    const newStartDate = moment(endDate?.valueOf())?.startOf('day');
    const start = startDate?.startOf('day') ?? null;
    const end = endDate?.endOf('day') ?? null;

    this.setState(prevState => ({
      chartFilters: {
        ...prevState.chartFilters,
        date: {
          start,
          end,
        },
        startDate: fromDayToTzHour(this.state.tz, start, '00:00'),
        endDate: fromDayToTzHour(this.state.tz, end, '23:59'),
      },
    }));
  };

  render() {
    const {
      chartFilters,
      showTable,
      showDailyTrends,
      selectedCharts,
      tz,
      date,
    } = this.state;
    const { tickData, patientId } = this.props;
    return (
      <Segment className={style.container}>
        <Dimmer active={tickData.isLoading}>
          <Loader size="huge">Fetching data</Loader>
        </Dimmer>
        <Header id={style.vizHeader} dividing as="section">
          <section className={style.chartOptions}>
            <ChartPicker handleChangeChart={this.handleChangeChart} />
            <DatePicker
              date={chartFilters.date}
              handleChangeDate={this.handleChangeDate}
            />
            <section className={style.actionButtons}>
              <Button
                disabled={
                  chartFilters.startDate === null ||
                  chartFilters.endDate === null ||
                  !chartFilters.types.length
                }
                primary
                onClick={this.handleSubmitTicks}
              >
                Send
              </Button>
            </section>
            <section className={style.tableToggle}>
              <Checkbox
                toggle
                label="Show as Table"
                onChange={() =>
                  this.setState(prevState => ({
                    showTable: !prevState.showTable,
                    showDailyTrends: false,
                  }))
                }
                checked={showTable}
              />
            </section>
          </section>
        </Header>

        {showTable ? (
          <section className={style.tableContainer}>
            <ChartsTable
              date={date}
              data={tickData.data}
              charts={chartFilters.selectedCharts}
              isLoading={tickData.isLoading}
              patientId={patientId}
            />
          </section>
        ) : (
            <>
              {this.isDateRange() && (
                <section className={style.togglesContainer}>
                  <Checkbox
                    toggle
                    label="Show daily trends"
                    onChange={() =>
                      this.setState({ showDailyTrends: !showDailyTrends })
                    }
                    checked={showDailyTrends}
                  />
                </section>
              )}
              <section
                ref={this.graphContainerRef}
                className={style.graphContainer}
              >
                <ChartSelector
                  date={date}
                  tz={tz}
                  data={tickData.data}
                  charts={selectedCharts}
                  isLoading={tickData.isLoading}
                  showDailyTrends={showDailyTrends}
                  forcedDimensions={{
                    width: this.graphContainerRef?.current?.getBoundingClientRect()
                      .width,
                    height: this.graphContainerRef?.current?.getBoundingClientRect()
                      .height,
                  }}
                />
              </section>
              <MetricsColorCode selectedCharts={selectedCharts} />
            </>
          )}

        {!isEmpty(alert) && (
          <Action
            level={SEVERITY_TYPES.RED}
            buttonText="Set Up Appointment"
            message={`${alert.patient.name} ${alert.message}`}
            name={alert.patient.name}
            id={alert.patient.id}
          />
        )}
        <SummarySection date={date} selectedCharts={selectedCharts} />
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  return {
    timezone: state.currentPatient.timeZone,
    tickData: state.patients.tickData,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPatientTicks: chartFilters => dispatch(fetchPatientTicks(chartFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Viz);
