const toRound = num => Math.round(num);
const secToMin = sec => sec / 60;
const makePercentage = (val, expected) => (val * 100) / expected;

export default biometrics => {
  const {
    m_steps_nonzero_avg_day_previous_month: steps = 0,
    e_burn_calories_nonzero_avg_28d: cal = 0,
    m_active_nonzero_avg_30d: activeDayOnSec = 0,
    m_step_goal: expectedStepsPerDay = 8000,
  } = biometrics;
  const expectedActiveMinPerWeek = 150;
  const completeStepsPercentage = makePercentage(steps, expectedStepsPerDay);
  const daysOfWeek = 7;
  const avgWeekActimeTimeOnMin = toRound(secToMin(activeDayOnSec)) * daysOfWeek;
  const completeActivePercentage = makePercentage(
    avgWeekActimeTimeOnMin,
    expectedActiveMinPerWeek
  );

  const label = '30 Day Exercise Plan';

  const averages = [
    {
      content: 'Average total calories burn per day',
      subheader: `${toRound(cal)}`,
    },
    {
      content: 'Average steps per day',
      subheader: `${toRound(steps)} - ${toRound(
        completeStepsPercentage
      )}% of recommended ${expectedStepsPerDay} daily steps`,
    },
    {
      content: 'Total weekly average active time',
      subheader: `${toRound(avgWeekActimeTimeOnMin)} min - ${toRound(
        completeActivePercentage
      )}% of recommended ${expectedActiveMinPerWeek} min per week`,
    },
  ];
  return { averages, label };
};
