import axios from 'axios';
import moment from 'moment';

import store from 'store';
import { logout, handleApiError } from 'store/authState/actions';
import parseJwtToken from 'utils/parseJwtToken';

const authorizedInstance = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_API_URL,
});

const handleResponseErrors = errorResponse => {
  store.dispatch(handleApiError(errorResponse));
};

authorizedInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (!token) {
    return new Error('Unauthorized request');
  }

  const tokenData = parseJwtToken(token);
  const tokenExp = moment(tokenData.exp * 1000);
  const now = moment();
  const fiveMinsAsSecs = 5 * 60;
  if (tokenExp.diff(now, 'seconds') < fiveMinsAsSecs) {
    store.dispatch(logout());
    return false;
  }

  return {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...config.headers,
    },
  };
});

authorizedInstance.interceptors.response.use(
  response => response.data,
  error => {
    handleResponseErrors(error.response);
  }
);

export default authorizedInstance;
