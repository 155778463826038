import React from 'react';
import { Header } from 'semantic-ui-react';
import style from 'containers/patient/patient.module.scss';
import { useSelector } from 'react-redux';
import DiseasesListSection from './DiseasesListSection';

const Diseases = () => {
  const { isLoading, error, data: diseases } = useSelector(
    state => state.currentPatient.diseases
  );

  return (
    <section className={style.infoSection}>
      <Header as="h3">Calculated Disease Risks</Header>
      {error && <p>Something is wrong with disease data</p>}
      {isLoading && <p>Loading...</p>}
      {diseases.length ? (
        <DiseasesListSection diseases={diseases} />
      ) : (
        <p>This Patient has no disease`s insights</p>
      )}
    </section>
  );
};

export default Diseases;
