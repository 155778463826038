import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOptionsByField,
  filterSurveys,
  removeDuplicateOptions,
} from 'utils/surveyFactory';
import SurveyFilters from './components/SurveyFilters';
import SurveyList from './components/SurveyList';
import {
  fetchCompletedSurveys,
} from 'store/currentPatient/actions';

const SurveyAnswersTable = ({ surveys }) => {
  useEffect(() => {
    setFilters({
      headers: [],
      types: [],
      id: '',
      status: defaultStatus(),
      orderBy: 'newest',
    });
    // eslint-disable-next-line
  }, [surveys]);

  const dispatch = useDispatch();

  const id = useSelector(state => state.currentPatient.id);

  const defaultStatus = () => {
    if (surveys && surveys.length) {
      return surveys.some(survey => survey?.status === 'completed')
        ? 'completed'
        : surveys[0].status;
    }

    return null;
  };

  const [filters, setFilters] = useState({
    headers: [],
    types: [],
    id: '',
    status: null,
    orderBy: 'newest',
  });

  // Make options
  const valuesOptions = ['intro_header', 'type', 'survey_id', 'status']
    .map(createOptionsByField.bind(this, surveys))
    .map(removeDuplicateOptions);

  // Make Filter Props
  const [headersProps, typesProps, idProps, statusProps] = [
    'headers',
    'types',
    'id',
    'status',
  ].map((stateValue, idx) => ({
    options: valuesOptions[idx],
    onChange: (_, { value }) => {
      setFilters(state => ({ ...state, [stateValue]: value }));
    },
    value: filters[stateValue],
  }));

  // Custom Filter
  const customFilter = {
    orderByProps: {
      options: [
        {
          key: 0,
          text: 'Newest',
          value: 'newest',
        },
        {
          key: 1,
          text: 'Oldest',
          value: 'oldest',
        },
        {
          key: 2,
          text: 'Priority',
          value: 'priority',
        },
      ],
      onChange: (_, { value }) => {
        setFilters(state => ({ ...state, orderBy: value }));
      },
      value: filters.orderBy,
    },
  };

  const filtersProps = {
    headersProps,
    typesProps,
    idProps,
    statusProps,
    ...customFilter,
  };

  const filteredSurveys = filterSurveys(surveys, filters);

  const refreshSurveyForm = () => {
    dispatch(fetchCompletedSurveys(id));
  };

  return (
    <Modal
      trigger={
        <Button icon>
          <Icon name="external alternate" />
        </Button>
      }
      size="large"
      closeIcon
      onClose={refreshSurveyForm}
    >
      <Modal.Header>Survey Answers</Modal.Header>
      <Modal.Content>
        <SurveyFilters {...filtersProps} />
        <SurveyList surveys={filteredSurveys} />
      </Modal.Content>
    </Modal>
  );
};

export default SurveyAnswersTable;
