import React from 'react';
import { Grid, Header, Divider, List } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { diagnosticReportsParser } from 'utils/fhirData/parsingFunctions';

import style from '../../../patientSummary.module.scss';

const Diagnosis = () => {
  const { data, isLoading } = useSelector(
    state => state.currentPatient.diagnosticReports
  );

  const parseDiagnosis = data.map(item => diagnosticReportsParser(item));
  const primary =
    parseDiagnosis.find(
      item => item?.principalDiagnosis?.toLowerCase() === 'true'
    )?.codeValues ?? 'No principal diagnosis';

  const secondary = parseDiagnosis.filter(
    item =>
      item?.principalDiagnosis?.toLowerCase() === 'false' &&
      item?.currentDiagnosis?.toLowerCase() === 'true'
  );

  // Objects are unique (even with the same values), so we need this extra step
  // arr = array to filter, comp = comparison criteria i.e codeValues
  const removeDuplicates = (arr, comp) => {
    const unique = arr
      .map(item => item[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  };

  const secondaryList = removeDuplicates(secondary, 'codeValues').map(
    item => item.codeValues
  );

  const secondaryListToRender =
    secondary.length > 0 ? (
      <List className={style.diagnosisList}>
        {secondaryList.map(diagnosis => (
          <List.Item className={style.diagnosisListItem}>{diagnosis}</List.Item>
        ))}
      </List>
    ) : (
      'No secondary diagnosis'
    );

  return (
    <Grid.Row>
      <Grid.Column>
        <div className={style.headerTitle}>
          <Header content={`Diagnosis:`} />
          <p>{isLoading ? 'Loading...' : ''}</p>
        </div>
        <Divider />
        <Header
          content="Principal"
          size="small"
          subheader={isLoading ? '' : primary}
        />
        <Header
          content="Secondary"
          size="small"
          className={style.diagnosisHeader}
          subheader={isLoading ? '' : secondaryListToRender}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default Diagnosis;
