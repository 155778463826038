import { isEmpty } from 'lodash';
import { riskConceptRow } from 'components/EmployeeCovidReport/textComponents';
import { findAlert } from 'utils/alerts';

export default data => {
  const { age = 0, gender = '', bmi = 0, i_vuln: vulnerabilty } =
    data?.covid19?.data?.biometrics || {};

  const { data: diseases } = data?.diseases;

  let diseasesAlerts = data?.diseasesAlerts?.data;
  if (diseasesAlerts && Object.keys(diseasesAlerts).length === 0) {
    diseasesAlerts = [];
  }
  if (!diseasesAlerts) {
    diseasesAlerts = [];
  }
  const negativeAlerts = diseasesAlerts.filter(item => item.severity === 'RED');
  const mediumAlerts = diseasesAlerts.filter(
    item => item.severity === 'YELLOW'
  );

  const factors = {
    negative: [
      {
        factor: negativeAlerts.map(item => item.message).join(' ; '),
        flag: negativeAlerts.length,
      },
      {
        factor: 'age >= 65 years',
        flag: age >= 65,
      },
      {
        factor: 'male beetween 55 - 64 years',
        flag: age < 65 && age >= 55 && gender?.toLowerCase?.() === 'male',
      },
      {
        factor: 'obesity : BMI >=35',
        flag: bmi >= 35,
      },
    ],
    medium: [
      {
        factor: mediumAlerts.map(item => item.message).join(' ; '),
        flag: mediumAlerts.length,
      },
      {
        factor: 'BMI beetween 30 - 34',
        flag: bmi < 35 && bmi >= 30,
      },
    ],
    positive: [
      {
        factor: '',
        flag: true,
      },
    ],
    none: [
      {
        factor: 'unknown',
        flag: true,
      },
    ],
  };

  /**
   * Exposure
   */
  let exposureColor = 'none';
  let exposureText = 'N/A';
  if (!isEmpty(diseases)) {
    const diseasesList = diseases.flatMap(d => Object.entries(d));
    const covidDisease = diseasesList.find(
      ([diseaseName]) => diseaseName === 'Covid 19'
    );
    if (covidDisease) {
      const alert = findAlert(...covidDisease);
      exposureColor = (() => {
        if (!alert.severity) return 'none';
        if (alert.severity === 'GREEN') return 'positive';
        if (alert.severity === 'YELLOW') return 'medium';
        return 'negative';
      })();
      exposureText = alert.risk;
    }
  }

  /**
   * Vulnerability
   */

  const vulnerabiltyColor = (() => {
    if (!vulnerabilty) return 'none';
    if (vulnerabilty === 'GREEN') return 'positive';
    if (vulnerabilty === 'YELLOW') return 'medium';
    return 'negative';
  })();

  const factorsText =
    'Contributing Factors: ' +
    factors[vulnerabiltyColor]
      .filter(item => item.flag)
      .map(item => item.factor)
      .join(' ; ');

  return `<section class="report-section">
      <h2 class='section-title'>COVID-19 Risk</h2>
      ${riskConceptRow('Vulnerability Risk', factorsText, vulnerabiltyColor)}
      ${riskConceptRow('Exposure Risk', exposureText, exposureColor)}
    </section>`;
};
