import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import { labelize } from 'utils/textFormats';

import style from 'containers/patient/components/CentralInfo/components/Appointments/appointments.module.scss';

const EncounterInfo = ({ encounter }) => {
  const excludedFields = [
    'identifier',
    'status',
    'class',
    'clinicalImpressions',
  ];
  const dateFields = [
    'arrivalTime',
    'attendedTime',
    'episodeDate',
    'episodeTime',
  ];

  /*
  Actual date fields on this encounter
   */
  const showDateFields = Object.keys(encounter)
    .filter(fieldName => dateFields.includes(fieldName))
    .sort();

  const showFields = Object.keys(encounter)
    .filter(
      fieldName =>
        !dateFields.includes(fieldName) && !excludedFields.includes(fieldName)
    )
    .sort();

  return (
    <Grid columns={4} verticalAlign="middle">
      <Grid.Row>
        {showDateFields.map(fieldName => (
          <Grid.Column className={style.detailsCol} key={fieldName}>
            <Header
              content={labelize(fieldName, false)}
              subheader={encounter[fieldName]}
            />
          </Grid.Column>
        ))}
        {showFields.map(fieldName => (
          <Grid.Column className={style.detailsCol} key={fieldName}>
            <Header
              content={labelize(fieldName, false)}
              subheader={encounter[fieldName]}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

export default EncounterInfo;
