import moment from 'moment-timezone';
import {
  riskConceptRow,
  dailySymptomsRow,
  symptomsAlerts,
  sectionFooter,
} from 'components/EmployeeCovidReport/textComponents';
import { makeCirclesArray, makeSymptomsConcept } from 'utils/EmployeeCovidR';

export default (data, date, reportData, tz) => {
  const lastWeekDate = {
    from: moment(date.from)
      .subtract(7, 'days')
      .valueOf(),
    to: moment(date.to)
      .subtract(7, 'days')
      .valueOf(),
  };
  const {
    completedSurveys: completed1,
    circleArray: circles1,
    reasons: reasons1,
  } = makeCirclesArray(lastWeekDate, reportData.surveys.data, tz);
  const {
    completedSurveys: completed2,
    circleArray: circles2,
    reasons: reasons2,
  } = makeCirclesArray(date, reportData.surveys.data, tz);
  const week1 = {
    from: moment(lastWeekDate.from).format('DD MMM'),
    to: moment(lastWeekDate.to).format('DD MMM'),
    circleArray: circles1,
  };
  const week2 = {
    from: moment(date.from).format('DD MMM'),
    to: moment(date.to).format('DD MMM'),
    circleArray: circles2,
  };

  const covidRisk = makeSymptomsConcept(reportData.surveys.data, 'covid');
  const ariRisk = makeSymptomsConcept(reportData.surveys.data, 'ari');
  let dailySymptomsReasons = reasons1;
  if (reasons2) {
    dailySymptomsReasons = dailySymptomsReasons + ' | ' + reasons2;
  }
  return `<section class="report-section">
      <h2 class="section-title">Symptom Tracking</h2>
      ${riskConceptRow(
        'COVID-19 Baseline Risk',
        covidRisk.message,
        covidRisk.status,
        covidRisk.reasons
      )}
      ${riskConceptRow(
        'ARI Baseline Symptoms',
        ariRisk.message,
        ariRisk.status,
        ariRisk.reasons
      )}
      ${dailySymptomsRow(
        'ARI Daily Symptoms',
        `${completed1 + completed2}/14 complete`,
        `${dailySymptomsReasons}`,
        [week1, week2]
      )}
      ${symptomsAlerts(data)}
      ${sectionFooter()}
    </section>`;
};
