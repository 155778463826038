export default prescriptions => {
  // console.log('newob', prescriptions);
  const parsedPrescriptions = prescriptions.map(
    ({
      id,
      category,
      dosageInstruction,
      medicationCodeableConcept,
      authoredOn,
      extension,
    }) => {
      const dosage = dosageInstruction?.[0] || {};

      // checking extensions for EpisodeId, we need the highest number
      // representing the most recent visit
      const ext = extension.filter(ep => ep.url.includes('EpisodeId'));
      return {
        id,
        category: category !== null ? category.join(',') : '---',
        name: medicationCodeableConcept || '---',
        dose: dosage?.text || '---',
        indications: dosage?.patientInstruction || '---',
        date: authoredOn || '---',
        episode: ext[0].valueString,
      };
    }
  );

  const categoriesObject = parsedPrescriptions.reduce((a, b) => {
    const category = b.category || 'unknown';
    a[category] = !a[category] ? [b] : [...a[category], b];
    return a;
  }, {});

  const categories = Object.keys(categoriesObject);

  const categoriesOptions = categories.map(name => ({
    key: name,
    value: name,
    text: name,
  }));

  const headerFields = ['Name', 'Dose', 'Indication', 'Start Date'];

  return {
    categories,
    categoriesObject,
    categoriesOptions,
    headerFields,
  };
};
