import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { fetchNewPatients, resetNewPatients } from 'store/patients/actions';
import { removeNewPatient } from '../actions';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  allowMore: true,
  currentPage: 0,
};

export default createReducer(initialState, {
  [fetchNewPatients.STARTED]: handleActionStart,
  [fetchNewPatients.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (state.currentPage >= action.payload.currentPage) {
      state.data = action.payload.data;
    } else {
      state.data = state.data.concat(action.payload.data);
    }
    state.allowMore = action.payload.data.length === 50;
  },
  [fetchNewPatients.FAILED]: handleActionError,
  [removeNewPatient]: (state, action) => {
    state.data = state.data.filter(
      patient => patient.id !== Number(action.payload)
    );
  },
  [resetNewPatients]: state => {
    state.data = [];
  },
});
