import React from 'react';
import { Form, Select } from 'semantic-ui-react';

const HoursPicker = ({ startHour, setStartHour, endHour, setEndHour }) => {
  const hoursOptions = new Array(24).fill(1).map((item, index) => ({
    key: `${index}`,
    text: `${index < 10 ? '0' : ''}${index}:00 hr`,
    value: `${index < 10 ? '0' : ''}${index}`,
  }));
  const startIndex = hoursOptions.findIndex(
    option => option.value === startHour
  );
  const endIndex = hoursOptions.findIndex(option => option.value === endHour);
  const startOptions = hoursOptions.slice(0, endIndex + 1);
  const endOptions = hoursOptions.slice(startIndex);

  const onChangeStart = (e, { value }) => {
    if (Number(value) <= Number(endHour)) {
      setStartHour(value);
    }
  };
  const onChangeEnd = (e, { value }) => {
    if (Number(value) >= Number(startHour)) {
      setEndHour(value);
    }
  };

  return (
    <section>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            control={Select}
            label="From"
            options={startOptions}
            placeholder="Start"
            value={startHour}
            onChange={onChangeStart}
          />
          <Form.Field
            control={Select}
            label="To"
            options={endOptions}
            placeholder="End"
            value={endHour}
            onChange={onChangeEnd}
          />
        </Form.Group>
      </Form>
    </section>
  );
};

export default HoursPicker;
