const parseSmaSlope = (slope2d, name) => {
  if (slope2d < 0) return { color: 'red', value: name, direction: 'down' };
  if (slope2d > 0) return { color: 'green', value: name, direction: 'up' };
  return null;
};

const getBiometrics = (facts = {}, isAlert = false, isExpanded = false) => {
  const {
    h_hrv_slope_2d: hrvSlope2d,
    s_duration_sma_slope_2d: sleepDurationSlope2d,
    s_deep_sma_slope_2d: sleepDeepSlope2d,
    m_active_time_sma_slope_2d: moveActiveTimeSlope2d,
    h_hr_sma_slope_2d: hrSlope2d,
    h_rhr_sma_slope_2d: rhrSlope2d,
    h_rr_sma_slope_2d: rrSlope2d,
    t_skin_sma_slope_2d: tempSkinSlope2d,
    b_spo2_sma_slope_2d: spo2Slope,
  } = facts;
  const hrvBiometrics = parseSmaSlope(hrvSlope2d, 'HRV');
  const sleepBiometrics = parseSmaSlope(sleepDurationSlope2d, 'Sleep Duration');
  const deepSleepBiometrics = parseSmaSlope(sleepDeepSlope2d, 'SWS');
  const activityBiometrics = parseSmaSlope(moveActiveTimeSlope2d, 'Activity');
  const hrBiometrics = parseSmaSlope(hrSlope2d, 'HR');
  const rhrBiometrics = parseSmaSlope(rhrSlope2d, 'RHR');
  const rrBiometrics = parseSmaSlope(rrSlope2d, 'RR');
  const tempBiometrics = parseSmaSlope(tempSkinSlope2d, 'T°');
  const spO2Biometrics = parseSmaSlope(spo2Slope, 'SpO2');

  let alerts = [
    hrvBiometrics,
    sleepBiometrics,
    deepSleepBiometrics,
    activityBiometrics,
    hrBiometrics,
    rhrBiometrics,
    rrBiometrics,
    tempBiometrics,
    spO2Biometrics,
  ].filter(alert => !!alert);

  if (isAlert) {
    const fields = [
      'HRV',
      'Sleep Duration',
      'SWS',
      'Activity',
      'HR',
      'RHR',
      'RR',
      'T°',
      'SpO2',
    ];
    fields.forEach(fieldName => {
      const exist = alerts.find(alert => alert.value === fieldName);
      if (!exist) {
        alerts.push({ color: 'green', value: fieldName, direction: 'min' });
      }
    });
  }

  if (isExpanded) {
    const expandedFields = {
      HRV: 'Heart Rate Variability (HRV)',
      'Sleep Duration': 'Sleep Duration',
      SWS: 'Slow Wave Sleep (SWS)',
      Activity: 'Activity',
      HR: 'Heart Rate (HR)',
      RHR: 'Resting Heart Rate (RHR)',
      RR: 'Respiration Rate (RR)',
      'T°': 'Temperature (Tº)',
      SpO2: 'SpO2',
    };

    alerts = alerts.map(alert => ({
      ...alert,
      value: expandedFields[alert.value],
    }));
  }

  return {
    red: alerts
      .filter(alert => alert.color === 'red')
      .map(({ value, direction }) => ({ value, direction })),
    green: alerts
      .filter(alert => alert.color === 'green')
      .map(({ value, direction }) => ({ value, direction })),
  };
};

export default getBiometrics;
