import { createAction } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { createActionThunk } from 'redux-thunk-actions';
import { FhirApi } from 'api';
/*
--- Existance
*/
export const checkForFhirUser = createActionThunk(
  'currentPatient/CHECK_FHIR_ID',
  async (id, { dispatch }) => {
    const isFhirUser = await FhirApi.isFhirUser(id);
    if (isFhirUser) {
      dispatch(fetchRecord(id));
    }
    return { id, data: isFhirUser };
  }
);

export const reconcilePatientEMR = createActionThunk(
  'currentPatient/RECONCILE_EMR',
  async (id, { dispatch }) => {
    const status = await FhirApi.reconcileEMR(id);
    if (status) {
      dispatch(fetchRecord(id));
      dispatch(fetchFhirObservations(id));
      dispatch(fetchFhirLabExams(id));
      dispatch(fetchFhirEncounters(id));
      dispatch(fetchAppointments(id));
    }
  }
);

/*
--- Record
*/
export const fetchRecord = createActionThunk(
  'currentPatient/GET_RECORD',
  async id => {
    const record = await FhirApi.getPatientRecord(id);
    return { id, data: record };
  }
);
export const fetchFhirAllergyIntolerance = createActionThunk(
  'currentPatient/GET_FHIR_ALLERGY',
  async id => {
    const data = await FhirApi.getPatientAllergyIntolerance(id);
    return data;
  }
);

export const fetchFhirDiagnosticReports = createActionThunk(
  'currentPatient/GET_FHIR_DIAGNOSTIC',
  async id => {
    const data = await FhirApi.getPatientDiagnosticReports(id);
    return data;
  }
);
export const fetchFhirMedications = createActionThunk(
  'currentPatient/GET_FHIR_MEDICATIONS',
  async id => {
    const data = await FhirApi.getPatientMedications(id);
    return data;
  }
);

export const fetchFhirPrescriptions = createActionThunk(
  'currentPatient/GET_FHIR_PRESCRIPTIONS',
  async id => {
    const data = await FhirApi.getPatientPrescriptions(id);
    return data;
  }
);

export const fetchFhirClinicalImpressions = createActionThunk(
  'currentPatient/GET_FHIR_CLINICAL_IMPRESSIONS',
  async id => {
    const data = await FhirApi.getPatientClinicalImpressions(id);
    return data;
  }
);

export const fetchFhirObservations = createActionThunk(
  'currentPatient/GET_FHIR_OBSERVATIONS',
  async id => {
    const data = await FhirApi.getPatientObservations(id);
    return data;
  }
);
export const fetchFhirImmunizations = createActionThunk(
  'currentPatient/GET_FHIR_IMMUNIZATIONS',
  async id => {
    const data = await FhirApi.getPatientImmunizations(id);
    return data;
  }
);
export const fetchFhirProcedures = createActionThunk(
  'currentPatient/GET_FHIR_PROCEDURES',
  async id => {
    const data = await FhirApi.getPatientProcedures(id);
    return data;
  }
);
export const fetchFhirLabExams = createActionThunk(
  'currentPatient/GET_FHIR_LAB_EXAMS',
  async id => {
    const data = await FhirApi.getPatientLabExams(id);
    return data;
  }
);
export const fetchFhirLabTicks = createActionThunk(
  'currentPatient/GET_FHIR_LAB_TICKS',
  async ({ patientId, types, startDate, endDate }) => {
    const data = await FhirApi.getPatientLabTicks(
      patientId,
      types,
      moment(startDate).format('X'),
      moment(endDate).format('X')
    );
    return data;
  }
);

export const fetchFhirCommunications = createActionThunk(
  'currentPatient/GET_FHIR_COMMUNICATIONS',
  async id => {
    const data = await FhirApi.getPatientCommunications(id);
    return data;
  }
);

export const fetchFhirDocuments = createActionThunk(
  'currentPatient/GET_FHIR_DOCUMENTS',
  async id => {
    const data = await FhirApi.getPatientDocuments(id);
    return data;
  }
);
export const fetchFhirDocumentContent = createActionThunk(
  'currentPatient/GET_FHIR_DOCUMENTS_CONTENT',
  async (id, docId, type) => {
    const file = await FhirApi.getPatientDocumentsContent(id, docId);
    return { file, type };
  }
);
export const clearCurrentDocument = createAction(
  'currentPatient/CLEAR_FHIR_DOCUMENTS_CONTENT'
);
/*
  ---------- Encounters ----------
*/
export const fetchFhirEncounters = createActionThunk(
  'currentPatient/GET_FHIR_ENCOUNTERS',
  async id => {
    const data = await FhirApi.getPatientEncounters(id);
    return data;
  }
);
/*
  ---------- Current Encounter ----------
*/
export const clearCurrentEncounter = createAction(
  'currentPatient/CLEAR_CURRENT_ENCOUNTER'
);

export const selectCurrentEncounter = createActionThunk(
  'currentPatient/SELECT_CURRENT_ENCOUNTER',
  async (patientId, encounter) => {
    const clinicalImpressions = await FhirApi.getSingleEncounterClinicalImpressions(
      patientId,
      encounter.id
    );
    return { data: { ...encounter, clinicalImpressions } };
  }
);
export const createClinicalImpression = createActionThunk(
  'currentPatient/CREATE_CLINICAL_IMPRESSION',
  async (patientId, encounterId, body) => {
    const clinicalImpression = await FhirApi.createSingleEncounterClinicalImpressions(
      patientId,
      encounterId,
      body
    );
    return { data: clinicalImpression };
  }
);

export const fetchFhirReportDependencies = createActionThunk(
  'currentPatient/GET_FHIR_REPORT_DEPENDENCIES',
  async (id, { dispatch }) => {
    dispatch(fetchFhirMedications(id));
    dispatch(fetchFhirAllergyIntolerance(id));
  }
);

/*
--- Appointments
*/

export const fetchAppointments = createActionThunk(
  'currentPatient/FETCH_APPOINTMENTS',
  async patientId => {
    const data = await FhirApi.getPatientAppointments(patientId);
    return { id: patientId, data };
  }
);

export const requestAppointment = createActionThunk(
  'currentPatient/REQUEST_NEW_APPOINTMENT',
  async (id, newAppointment) => {
    const appointment = await FhirApi.requestAppointment(id, newAppointment);
    return { data: appointment };
  }
);
