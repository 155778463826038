import React, { useEffect, useState } from 'react';
import { Divider, Grid, Header, Icon, Table } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { labelize } from 'utils/textFormats';
import { TablePaginationFooter } from 'components';
import StatusIcon from '../StatusIcon';
import EncountersFilters from './EncountersFilters';
import {
  fetchFhirEncounters,
  selectCurrentEncounter,
} from 'store/currentPatient/actions/fhir';
import { colorByStatus } from 'utils/fhirData';
import { encountersParser } from 'utils/fhirData/parsingFunctions';
import style from 'containers/patient/components/CentralInfo/components/Appointments/appointments.module.scss';

const EncountersTable = () => {
  const [filterIdentifier, setFilterIdentifier] = useState('');
  const [filterStatus, setFilterStatus] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();
  const id = useSelector(state => state.currentPatient.id);
  const { data, isLoading } = useSelector(
    state => state.currentPatient.encounters
  );
  useEffect(() => {
    if (id) {
      dispatch(fetchFhirEncounters(id));
    }
  }, [id, dispatch]);

  let encounters = [];
  if (data && data.length > 0) {
    encounters = data.map(item => encountersParser(item));
  }

  const handlePageChange = (_, { activePage }) => setActivePage(activePage);

  const handleDetailsClick = encounter =>
    dispatch(selectCurrentEncounter(id, encounter));

  const filterEncounters = ({ identifier, status }) => {
    const identifierCheck =
      filterIdentifier === '' ? true : identifier === filterIdentifier;
    const statusCheck =
      filterStatus.length === 0 ? true : filterStatus.includes(status);
    return identifierCheck && statusCheck;
  };

  const filteredEncounters = encounters.filter(filterEncounters);
  /**
   * ----Pagination
   */
  const encountersByPage = 10;
  const totalPages = Math.ceil(filteredEncounters.length / encountersByPage);
  const from = (activePage - 1) * encountersByPage;
  const to = activePage * encountersByPage;
  const encountersRange = filteredEncounters.slice(from, to);

  const fields = [
    'identifier',
    'carePackageDescription',
    'class',
    'episodeDate',
    'episodeTime',
    'scheduledToSee',
    'status',
  ];
  const miniFields = ['identifier', 'status'];
  const makeColSpan = val => (miniFields.includes(val) ? 1 : 2);
  const makeAlign = val => (miniFields.includes(val) ? 'center' : 'left');
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column className={style.headerTitle}>
          <Header content="Encounters" />
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="1">
        <Grid.Column className={style.headerFilters}>
          <EncountersFilters
            encounters={encounters}
            setFilterIdentifier={setFilterIdentifier}
            setFilterStatus={setFilterStatus}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table celled fixed columns={fields.length}>
            <Table.Header>
              <Table.Row>
                {fields.map((name, idx) => (
                  <Table.HeaderCell key={idx} colSpan={makeColSpan(name)}>
                    {labelize(name, false)}
                  </Table.HeaderCell>
                ))}
                <Table.HeaderCell>{`Details`}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {isLoading ? (
              <p>loading...</p>
            ) : (
              <Table.Body>
                {encountersRange.map(encounter => (
                  <Table.Row key={encounter.id}>
                    {fields.map(fieldName => (
                      <Table.Cell
                        colSpan={makeColSpan(fieldName)}
                        textAlign={makeAlign(fieldName)}
                        key={`${encounter.id}-${fieldName}`}
                      >
                        {fieldName === 'status' ? (
                          <StatusIcon
                            text={encounter[fieldName]}
                            color={colorByStatus[encounter[fieldName]]}
                          />
                        ) : (
                          encounter[fieldName]
                        )}
                      </Table.Cell>
                    ))}
                    <Table.Cell textAlign="center">
                      <Icon
                        name="external alternate"
                        className={style.openDetails}
                        onClick={() => handleDetailsClick(encounter)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
            <TablePaginationFooter
              colSpan={12}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EncountersTable;
