import React, { createRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { Header, Divider } from 'semantic-ui-react';

import { TextEditor } from 'components';
import {
  fetchPatientNotes,
  updatePatientNotes,
  cleanNotes,
} from 'store/currentPatient/actions';

const editorConfig = {
  toolbarGroups: [
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
    },
    { name: 'styles' },
    { name: 'colors' },
  ],
};

const Notes = ({ className, patientId }) => {
  const editorRef = createRef();
  const dispatch = useDispatch();
  const [localValue, setLocalValue] = useState('');
  const [debouncedValue] = useDebounce(localValue, 1000);
  const { isLoading, error, data: notes } = useSelector(
    state => state.currentPatient.notes
  );

  useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientNotes(patientId));
    }
    return () => dispatch(cleanNotes());
  }, [patientId, dispatch]);

  useEffect(() => {
    if (debouncedValue && notes?.id) {
      dispatch(updatePatientNotes(notes.id, debouncedValue));
    }
  }, [debouncedValue, dispatch, notes]);

  const handleChange = event => {
    const data = event.editor.getData();
    setLocalValue(data);
  };

  return (
    <section className={className}>
      <Header>Notes</Header>
      <Divider />
      {error && <p>Something went wrong loading notes for current patient.</p>}
      {isLoading ? (
        <p>Loading notes...</p>
      ) : notes && Object.keys(notes).length ? (
        <>
          <TextEditor
            ref={editorRef}
            config={editorConfig}
            data={notes?.content}
            onChange={handleChange}
          />
        </>
      ) : null}
    </section>
  );
};
/**
  
 */
export default Notes;
