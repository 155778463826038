import React from 'react';
import { Message } from 'semantic-ui-react';

const ImageError = ({ error }) => {
  return error ? (
    <Message negative>
      <Message.Header>Ups! something went wrong</Message.Header>
      <p>{error}</p>
    </Message>
  ) : null;
};

export default ImageError;
