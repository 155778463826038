import React from 'react';
import style from 'containers/patient/patient.module.scss';
import classNames from 'classnames';
import { Button, Icon, Checkbox } from 'semantic-ui-react';

const LockAndDeleteButtons = ({
  handleDelete,
  handleCheck,
  checked,
  containerClassName,
  canDelete,
  canCheck,
  children,
}) => {
  return (
    <div className={classNames(style.leftButtonBox, containerClassName)}>
      {children}
      {canCheck && (
        <div className={style.publishedCheckBox}>
          <b>{`Published:`}</b>
          <Checkbox
            toggle
            defaultChecked={checked}
            color={`blue`}
            onChange={handleCheck}
            className={style.checkbox}
          />
        </div>
      )}
      {canDelete && (
        <Button icon onClick={handleDelete} size="mini" color="red">
          <Icon name={'trash'} />
        </Button>
      )}
    </div>
  );
};

export default LockAndDeleteButtons;
