import React from 'react';

const Row = ({ data }) => (
  <div className="row">
    <h4>{data.title}</h4>
    <div className="indicator">{data.indicator}</div>
    {data.indicator ? <div className="arrow up" /> : null}
    <div className="number">{data.number}</div>
  </div>
);

const ReportTable = props => (
  <div className="table-container">
    {props.data.map(d => (
      <Row data={d} key={d.title} />
    ))}
  </div>
);

export default ReportTable;
