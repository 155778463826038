import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import style from 'containers/patient/components/CentralInfo/components/covid.module.scss';

const IconLabel = ({ text, color, label }) => {
  return (
    <Grid.Column
      verticalAlign="middle"
      width={5}
      className={cx(style.covidHeaderCol, style.title, style.hightLight)}
    >
      <span className={cx(style.iconLabel, color)}>
        <Icon name="circle" /> {text}
      </span>
      <span>{label}</span>
    </Grid.Column>
  );
};

export default IconLabel;
