import React, { useState } from 'react';
import { Grid, Header, Table, Divider, Dropdown } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/fhirData';
import { prescriptionsFactory } from 'utils/patientSummaryReport/factory';
import style from '../../../patientSummary.module.scss';

const MedicationList = ({ tableStyle = {}, withHeader = true }) => {
  const [filteredCategories, setFilteredCategories] = useState([]);
  const { isLoading, data: prescriptions } = useSelector(
    state => state.currentPatient.prescriptions
  );
  const {
    categories,
    categoriesObject,
    categoriesOptions,
    headerFields,
  } = prescriptionsFactory(prescriptions);

  const removeDuplicates = (arr, comp) => {
    const unique = arr
      .map(item => item[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  };
  // eslint-disable-next-line
  Object.keys(categoriesObject).map(key => {
    categoriesObject[key] = removeDuplicates(categoriesObject[key], 'name');
  });

  const filterCategories = name =>
    filteredCategories.length ? filteredCategories.includes(name) : true;

  const handleChange = (_, data) => {
    setFilteredCategories(data.value);
  };

  return (
    <Grid>
      {withHeader && (
        <Grid.Row>
          <Grid.Column className={style.headerTitle}>
            <section className={style.headerSeccion}>
              <Header content="Medication List" />
              <Dropdown
                multiple
                search
                clearable
                selection
                options={categoriesOptions}
                onChange={handleChange}
                placeholder={`Categories`}
              />
            </section>
            <Divider />
          </Grid.Column>
        </Grid.Row>
      )}

      <Grid.Row>
        <Grid.Column>
          <Table celled fixed columns={4}>
            <Table.Header>
              <Table.Row>
                {headerFields.map(name => (
                  <Table.HeaderCell key={name}>{name}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
          </Table>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className={style.tableBody} style={tableStyle}>
              <Table celled fixed columns={4}>
                <Table.Body>
                  {categories.filter(filterCategories).flatMap(category => [
                    <Table.Row key={category}>
                      <Table.Cell colSpan={4}>
                        <Header content={category} as="h4" />
                      </Table.Cell>
                    </Table.Row>,
                    categoriesObject[category].map(
                      ({ name, dose, indications, date, id }) => (
                        <Table.Row key={id}>
                          <Table.Cell>{name}</Table.Cell>
                          <Table.Cell>{dose}</Table.Cell>
                          <Table.Cell>{indications}</Table.Cell>
                          <Table.Cell>{formatDate(date)}</Table.Cell>
                        </Table.Row>
                      )
                    ),
                  ])}
                </Table.Body>
              </Table>
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default MedicationList;
