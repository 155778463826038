import React, { useEffect } from 'react';
import cx from 'classnames';
import { writeStorage } from '@rehooks/local-storage';
import { Button, Header, Image, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert } from 'components';
import Logo from 'assets/all-health-logo.svg';
import CCADLogo from 'assets/ccad-logo.png';
import SEHALogo from 'assets/seha-logo.png';
import UHGLogo from 'assets/uhg-logo.png';
import GLUCARELogo from 'assets/glucare-big-logo.png';
import { authenticateAzure, cleanupBeforeLogin } from 'store/authState/actions';
import { SEVERITY_TYPES } from 'utils/constants';
import style from './auth.module.scss';

const Login = () => {
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector(state => state.auth);

  const handleSubmitAzure = () => {
    dispatch(authenticateAzure()).then(token => {
      token && writeStorage('token', token);
    });
  };

  useEffect(() => {
    dispatch(cleanupBeforeLogin());
    // eslint-disable-next-line
  }, []);
  const isGlucareEnv = process.env.REACT_APP_CUSTOMER_TENANT === 'glucare';

  const tenantLogo = (() => {
    const tenantCustomer = process.env.REACT_APP_CUSTOMER_TENANT;
    if (tenantCustomer === 'ccad') return CCADLogo;
    if (tenantCustomer === 'seha') return SEHALogo;
    if (tenantCustomer === 'uhg') return UHGLogo;
    if (tenantCustomer === 'glucare') return GLUCARELogo;
    return null;
  })();

  return (
    <div className={style.pageContainer}>
      <div className={style.bodyContainer}>
        <div
          className={cx(style.logoContainer, {
            [style.logoContainerMultiple]: tenantLogo,
            [style.glucareTenant]: isGlucareEnv,
          })}
        >
          <Image
            src={Logo}
            className={cx(style.logo, {
              [style.logoMultiple]: tenantLogo,
            })}
          />
          {tenantLogo && (
            <Image src={tenantLogo} className={style.tenantImage} />
          )}
        </div>
        <Header as="h2" color="teal" textAlign="center">
          Log in to your account
        </Header>
        {isLoading ? (
          <Alert message="Loading..." severity={SEVERITY_TYPES.GREEN} />
        ) : error ? (
          <Alert message={error} severity={SEVERITY_TYPES.RED} />
        ) : null}
        <Segment stacked className={style.formContainer}>
          <div className={style.azureButton}>
            <Button
              content="Login with Azure"
              onClick={handleSubmitAzure}
              color="blue"
              fluid
              size="large"
            />
          </div>
        </Segment>
      </div>
    </div>
  );
};

export default Login;
