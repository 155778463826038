import { createReducer } from '@reduxjs/toolkit';
import {
  createTab,
  updateTab,
  closeTab,
  clearTabs,
  toggleChat,
  sendWelcomeMessage,
  clearWelcomeMessage,
} from 'store/appState/actions';

const initialState = {
  patientTabs: [],
  chatOpen: true,
};

const appStateReducer = createReducer(initialState, {
  [createTab]: (state, action) => {
    state.patientTabs.push(action.payload);
  },
  [closeTab]: (state, action) => {
    state.patientTabs = state.patientTabs.filter(
      tab => tab.id !== action.payload
    );
  },
  [clearTabs]: state => {
    state.patientTabs = [];
  },
  [updateTab]: (state, action) => {
    state.patientTabs = state.patientTabs.map(tab =>
      tab.id === action.payload.id ? action.payload : tab
    );
  },
  [toggleChat]: (state, action) => {
    state.chatOpen = !state.chatOpen;
  },
  [sendWelcomeMessage]: (state, action) => {
    state.welcomeMessage = action.payload;
  },
  [clearWelcomeMessage]: (state, action) => {
    state.welcomeMessage = null;
  },
});

export default appStateReducer;
