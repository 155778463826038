import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import { Header } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { enumerateDaysBetweenDates } from 'utils/timezone';
import {
  getDaysAvailability,
  clearDaysAvailability,
} from 'store/formsResources/actions';

const AvailabilityChecker = ({
  resource_type,
  setDaysVisibility,
  clearDate,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState({ start: null, end: null });
  const [focusedInput, setFocusedInput] = useState(null);

  const handleChangeDate = ({ startDate, endDate }) =>
    setDate({
      start: startDate,
      end: endDate,
    });

  useEffect(() => {
    if (date.start && date.end) {
      const datesArray = enumerateDaysBetweenDates(date.start, date.end);
      dispatch(getDaysAvailability(datesArray, resource_type));
      setDaysVisibility(true);
    } else {
      dispatch(clearDaysAvailability());
      setDaysVisibility(false);
      clearDate();
    }
    // eslint-disable-next-line
  }, [date, resource_type, dispatch]);

  return (
    <section>
      <Header content="Check Availability" />
      <DateRangePicker
        block
        showDefaultInputIcon
        showClearDates
        openDirection="up"
        displayFormat="MMM D"
        startDateId="date.start"
        endDateId="date.end"
        numberOfMonths={1}
        minimumNights={0}
        startDate={date.start}
        endDate={date.end}
        focusedInput={focusedInput}
        onDatesChange={handleChangeDate}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
      />
    </section>
  );
};

export default AvailabilityChecker;
