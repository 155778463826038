import React from 'react';

import { Grid, Divider, Ref } from 'semantic-ui-react';
import style from '../patientSummary.module.scss';
import {
  HeaderInfo,
  Diagnosis,
  Plans,
  MonitoringDevices,
  MedicationList,
  Labs,
} from './components';

const Summary = ({
  data,
  date,
  medicationsTableStyle,
  medicationsWrapper,
  tz,
  tickData,
}) => {
  return (
    <Grid className={style.summaryContainer} stackable>
      <HeaderInfo record={data.record} />
      <Diagnosis />
      <Grid.Row columns={2}>
        <Ref innerRef={medicationsWrapper}>
          <Grid.Column>
            <MedicationList tableStyle={medicationsTableStyle} />
          </Grid.Column>
        </Ref>
        <Grid.Column>
          <Plans />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <MonitoringDevices date={date} tz={tz} tickData={tickData} />
        </Grid.Column>
        <Grid.Column>
          <Labs labExamsData={data.labExams} showHeader />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Summary;
