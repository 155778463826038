import React from 'react';
import { Grid, Header, Divider } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import { exercisePlanFactory } from 'utils/patientSummaryReport/factory';
import style from '../../../patientSummary.module.scss';

const Exercise = ({ withHeader = true }) => {
  const {
    data: { biometrics },
  } = useSelector(state => state.currentPatient.covid19);
  const { averages, label } = exercisePlanFactory(biometrics);
  return (
    <Grid.Row>
      <Grid.Column>
        {withHeader && (
          <>
            <div className={style.headerTitle}>
              <Header content={label} />
            </div>
            <Divider />
          </>
        )}

        <Grid>
          <Grid.Row>
            <Grid.Column className={style.columnItems}>
              {averages.map((props, idx) => (
                <Header key={idx} size="small" {...props} />
              ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};
export default Exercise;
