import React from 'react';
import MetadataCard from './components/MetadataCard';
import MetadataForm from './components/MetadataForm';

const MetadataSection = ({ data, id, isLoading, ownerId, clinicianId }) => {
  //const mealOwnerId = ownerId;
  return (
    <section>
      <MetadataForm mealId={id} />
      {isLoading && <p>Sending...</p>}
      {data.map((metadata, idx) => (
        <MetadataCard
          key={idx}
          metadata={metadata}
          mealId={id}
          clinicianId={clinicianId}
        />
      ))}
    </section>
  );
};

MetadataSection.defaultProps = {
  data: [],
};

export default MetadataSection;
