import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { capitalizeWords } from 'utils/textFormats';
import style from 'containers/patient/patient.module.scss';
import Viz from 'components/Viz';
import {
  Info,
  Timeline,
  Meals,
  Appointments,
  UserLocation,
  Covid19,
} from './components';
import Report from './components/Report';

const CentralInfo = () => {
  const id = useSelector(state => state.currentPatient.id);

  const {
    isLoading,
    data: { name },
  } = useSelector(state => state.currentPatient.basicInfo);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const isGlucareEnv = process.env.REACT_APP_CUSTOMER_TENANT === 'glucare';

  const handleTabChange = (_, { activeIndex, ...props }) => {
    setActiveTabIndex(activeIndex);
  };

  const handleSetActiveTabIndex = index =>
    handleTabChange(null, { activeIndex: index });

  const panes = [
    {
      menuItem: isLoading ? 'Loading...' : capitalizeWords(name) || '',
      render: () => <Info setActiveTabIndex={handleSetActiveTabIndex} />,
    },
    {
      menuItem: 'COVID-19',
      render: () => <Covid19 setActiveTabIndex={handleSetActiveTabIndex} />,
    },
    {
      menuItem: 'Generated Data',
      render: () => <Viz patientId={id} />,
    },
    {
      menuItem: 'Meals',
      render: Meals,
    },
    {
      menuItem: 'Appointments',
      render: Appointments,
    },
    {
      menuItem: 'Location',
      render: () => <UserLocation patientId={id} />,
    },
  ];

  const secondPane = isGlucareEnv
    ? {
        menuItem: 'Report',
        render: () => <Report setActiveTabIndex={handleSetActiveTabIndex} />,
      }
    : { menuItem: 'Timeline', render: Timeline };

  panes.splice(1, 0, secondPane);
  return (
    <main className={style.patientMainContainer}>
      <Tab
        className={style.patientTabbedInfo}
        panes={panes}
        menu={{ borderless: true, attached: false, tabular: false }}
        activeIndex={activeTabIndex}
        onTabChange={handleTabChange}
      />
    </main>
  );
};

export default CentralInfo;
