import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { debounce, find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import {
  Button,
  Grid,
  Header,
  Statistic,
  Loader,
  Input,
  Icon,
} from 'semantic-ui-react';

import { Feed, PatientTableRow } from 'components';
import {
  fetchConfirmedPatients,
  fetchNewPatients,
  fetchPatientInfoFull,
  alertsNotified,
} from 'store/patients/actions';
import { createTab } from 'store/appState/actions';
import styles from './today.module.scss';
import { notifyMe } from '../../utils/notifications';
import { ALERT_TYPES, REHYDRATE_INTERVAL_TIME } from '../../utils/constants';

import { saveUserAlerts } from '../../store/authState/actions';

const headers = [
  'Name',
  'Age',
  'Biometrics',
  'Vulnerability',
  'Exposure',
  'COVID-19',
  'Band',
  'Band Battery',
  'Proximity',
];

const Today = () => {
  const dispatch = useDispatch();
  const {
    confirmedPatients: {
      data: confirmedPatients,
      notified,
      currentPage,
      allowMoreConfirmed,
      isLoading: loadingConfirmed,
    },
    newPatients: {
      data: newPatients,
      allowMore,
      currentPage: currentPageNewPatients,
    },
  } = useSelector(state => state.patients);
  const [currentPageConfirmed, setCurrentPageConfirmed] = useState(currentPage);
  const [currentPageNew, setCurrentPageNew] = useState(currentPageNewPatients);
  const [loadConfirmedPatients, setLoadConfirmedPatients] = useState(false);
  const [loadNewPatients, setLoadNewPatients] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const pageSize = 50;

  const { data: patientsInfo } = useSelector(
    state => state.patients.logPatients
  );
  const { patientTabs } = useSelector(state => state.appState);
  const { data: chatMessages } = useSelector(state => state.chatMessages);
  const { data: authData } = useSelector(state => state.auth);

  useEffect(() => {
    notifyAlerts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer;
    if (loadConfirmedPatients) {
      timer = setInterval(() => {
        dispatch(fetchConfirmedPatients(0));
        fetchConfirmedPatientsData({ to: 1 });
        dispatch(fetchNewPatients(0));
      }, REHYDRATE_INTERVAL_TIME);
    }
    return () => {
      if (loadConfirmedPatients && timer) {
        clearInterval(timer);
      }
    };
  }, [loadConfirmedPatients]); // eslint-disable-line

  useEffect(() => {
    const hasCacheData = !!confirmedPatients.length;
    let timerFirstRender;

    if (hasCacheData) {
      if (currentPageConfirmed === 0 && !loadConfirmedPatients) {
        timerFirstRender = setTimeout(() => {
          dispatch(fetchConfirmedPatients(currentPageConfirmed));
        }, REHYDRATE_INTERVAL_TIME);
      }

      if (loadConfirmedPatients) {
        fetchConfirmedPatientsData({ to: currentPageConfirmed + 1 });
      }
    } else {
      dispatch(fetchConfirmedPatients(currentPageConfirmed));
    }

    setLoadConfirmedPatients(true);

    return () => {
      if (timerFirstRender) clearTimeout(timerFirstRender);
    };
    // eslint-disable-next-line
  }, [confirmedPatients.length, currentPageConfirmed]);

  useEffect(() => {
    const hasCacheData = !!newPatients.length;
    let timerFirstRender;
    if (hasCacheData) {
      if (currentPageNew === 0 && !loadNewPatients) {
        timerFirstRender = setTimeout(() => {
          fetchNewPatientsData();
          dispatch(fetchNewPatients(currentPageNew));
        }, REHYDRATE_INTERVAL_TIME);
      }
    } else {
      dispatch(fetchNewPatients(currentPageNew));
    }

    setLoadNewPatients(true);

    return () => {
      if (timerFirstRender) clearTimeout(timerFirstRender);
    };
    // eslint-disable-next-line
  }, [newPatients.length, currentPageNew]);

  const notifyAlerts = () => {
    if (notificationAlerts && notificationAlerts.length > 0 && !notified) {
      dispatch(alertsNotified());
      dispatch(
        saveUserAlerts([...authData.savedAlerts, ...notificationAlerts])
      );
      notificationAlerts.forEach(notification => {
        setTimeout(function() {
          notifyMe(notification.summary);
        }, 200);
      });
    }
  };

  const fetchConfirmedPatientsData = debounce(({ from = 0, to }) => {
    confirmedPatients
      .slice(from * pageSize, to * pageSize)
      .forEach(patient => dispatch(fetchPatientInfoFull(patient.id)));
  }, 1000);

  const fetchNewPatientsData = debounce(() => {
    newPatients
      .slice(currentPageNew * pageSize, (currentPageNew + 1) * currentPageNew)
      .forEach(patient => dispatch(fetchPatientInfoFull(patient.id)));
  }, 1000);

  const openTab = ({ id, name }) => {
    dispatch(createTab({ id, name }));
  };

  const addAlert = alertToRemove => {
    const updatedAlert = {
      ...alertToRemove,
      status: 'read',
    };
    return [...authData.savedAlerts, updatedAlert];
  };

  const handlePatientClick = ({ id, name, alert }) => () => {
    const encodedId = btoa(id);
    if (alert) {
      const newAlerts = addAlert(alert);
      dispatch(saveUserAlerts(newAlerts));
    }
    const existingTab = patientTabs.find(tab => tab.id === encodedId);
    if (!existingTab) {
      openTab({ id: encodedId, name });
    }
    navigate(`/patient/${encodedId}`);
  };

  const handleLoadMore = () => {
    const nextPage = currentPageConfirmed + 1;
    setLoadConfirmedPatients(false);
    fetchConfirmedPatientsData({
      from: currentPageConfirmed,
      to: nextPage,
    });
    dispatch(fetchConfirmedPatients(nextPage));
    setCurrentPageConfirmed(nextPage);
  };

  const searchPatient = patient => {
    const patientName = patient.first;
    const patientLastname = patient.last;

    if (searchValue) {
      return (
        patientName.includes(searchValue) ||
        patientLastname.includes(searchValue)
      );
    }

    return true;
  };

  const confirmedPatientsBody = confirmedPatients
    .filter(patient => searchPatient(patient))
    .map(patient => {
      const patientKey = patient.id;
      const patientInfo = patientsInfo[patientKey];

      if (!patientKey) return null;

      return (
        <PatientTableRow
          key={patientKey}
          basicData={patient}
          data={patientInfo}
          onClick={handlePatientClick({ id: patientKey, name: patient.first })}
        />
      );
    });

  const newPatientsBody = newPatients
    .filter(patient => searchPatient(patient))
    .map(patient => {
      const patientKey = patient.id;
      const patientInfo = patientsInfo[patientKey];

      if (!patientKey) return null;

      return (
        <PatientTableRow
          key={patientKey}
          basicData={patient}
          data={patientInfo}
          onClick={handlePatientClick({ id: patientKey, name: patient.first })}
          showEmpty
        />
      );
    });

  const todayDate = moment().format('MMMM Do, YYYY');

  const nextActivities = [];

  const isNewAlert = alert => {
    const value = authData.savedAlerts.filter(
      savedAlert =>
        savedAlert.id === alert.id &&
        savedAlert.summary === alert.summary &&
        savedAlert.type.toUpperCase() === alert.type.toUpperCase() &&
        savedAlert.status === 'read' &&
        savedAlert.date === moment().format('MM/DD/YY')
    );
    return value.length === 0;
  };

  const handleMoreNew = () => {
    dispatch(fetchNewPatients(currentPageNew + 1));
    fetchNewPatientsData();
    setCurrentPageNew(prevState => prevState + 1);
  };

  const handleSearch = event => {
    setSearchValue(event.target.value);
  };

  const messagesActivity = Object.keys(chatMessages)
    .filter(patientId => chatMessages[patientId].newMessages)
    .map(patientId => {
      const name = (() => {
        const findFunc = patient => btoa(patient.id) === patientId;
        const inNewPatients = find(newPatients, findFunc);
        if (inNewPatients) {
          return `${inNewPatients.first} ${inNewPatients.last}`;
        }
        const inConfirmedPatients = find(confirmedPatients, findFunc);
        if (inConfirmedPatients) {
          return `${inConfirmedPatients.first} ${inConfirmedPatients.last}`;
        }
        return `Patient with id ${patientId}`;
      })();

      return {
        id: atob(patientId),
        summary: `${name} has new messages.`,
        date: moment().format('MM/DD/YY'),
        type: 'MESSAGES',
      };
    })
    .filter(alert => !!alert && isNewAlert(alert));

  const alertsActivity = confirmedPatients
    .flatMap(patient => {
      const patientInfo = patientsInfo[patient.id];
      const name = `${patient.first} ${patient.last}`;
      if (
        !patientInfo ||
        patientInfo.error ||
        !patientInfo.alerts ||
        !patientInfo.alerts.length
      )
        return null;

      const alertsMessage = patientInfo.alerts
        .map(({ message }) => message)
        .join(' ')
        .replace('. ', ', ');
      const severity = patientInfo.alerts
        .map(alert => alert.severity)
        .reduce((acc, next) => {
          if (!acc) return next;
          if (acc === 'GREEN' && next === 'YELLOW') return next;
          if (acc === 'YELLOW' && next === 'RED') return next;
          return acc;
        }, '');
      return {
        type: 'ALERT',
        id: patient.id,
        summary: `${name}'s ${alertsMessage}`,
        severity,
        date: moment().format('MM/DD/YY'),
      };
    })
    .filter(alert => !!alert && isNewAlert(alert));

  const batteryLevelActivity = confirmedPatients
    .flatMap(patient => {
      const patientInfo = patientsInfo[patient.id];
      const name = `${patient.first} ${patient.last}`;
      if (
        !patientInfo ||
        !patientInfo.bandBatteryLevel ||
        patientInfo.bandBatteryLevel > 20
      )
        return null;
      return {
        type: 'BATTERY_LOW',
        id: patient.id,
        summary: `${name}'s band battery level is ${patientInfo.bandBatteryLevel}%`,
        severity: 'RED',
        date: moment().format('MM/DD/YY'),
      };
    })
    .filter(alert => !!alert && isNewAlert(alert));

  const activity = [
    ...messagesActivity,
    ...alertsActivity,
    ...batteryLevelActivity,
  ];

  const notificationAlerts = confirmedPatients
    .flatMap(patient => {
      const patientInfo = patientsInfo[patient.id];
      const name = `${patient.first} ${patient.last}`;
      if (
        !patientInfo ||
        patientInfo.error ||
        !patientInfo.alerts ||
        !patientInfo.alerts.length
      )
        return null;

      const alertsMessage = patientInfo.alerts
        .filter(alert => alert.type === ALERT_TYPES.P0)
        .map(({ message }) => message)
        .join(' ')
        .replace('. ', ', ');
      return {
        id: patient.id,
        summary: `${name}'s ${alertsMessage}`,
        type: 'BROWSER_NOTIFICATION',
        date: moment().format('MM/DD/YY'),
        status: 'read',
      };
    })
    .filter(alert => !!alert && isNewAlert(alert));

  return (
    <div className={styles.container}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={13}>
            <section className={styles.bodyContainer}>
              <Header as="h1" className={styles.title}>
                {todayDate}
              </Header>
              <Statistic.Group widths="two" id="patientCountContainer">
                {confirmedPatients.length > 0 && (
                  <>
                    <Statistic>
                      <Statistic.Value>
                        {confirmedPatients.length}
                      </Statistic.Value>
                      <Statistic.Label>
                        Patients I'm responsible for
                      </Statistic.Label>
                    </Statistic>

                    <Statistic>
                      <Statistic.Value>{newPatients.length}</Statistic.Value>
                      <Statistic.Label>
                        New patients last 7 days
                      </Statistic.Label>
                    </Statistic>
                  </>
                )}
              </Statistic.Group>
              <Grid.Row className={styles.searchContainer}>
                <Input
                  placeholder="Search Patient"
                  onChange={handleSearch}
                  icon={<Icon name="search" />}
                />
              </Grid.Row>
              <section className={styles.bodyContainer}>
                <Header as="h2">
                  Assigned Patients ({confirmedPatients.length})
                </Header>
                <PatientTableRow data={headers} isHeader />
                <div className={styles.proximityTable}>
                  <>
                    {confirmedPatientsBody}
                    {loadingConfirmed && <Loader active />}
                  </>
                </div>
                {allowMoreConfirmed && (
                  <div className={styles.loadMoreContainer}>
                    <Button
                      disabled={loadingConfirmed}
                      onClick={handleLoadMore}
                      basic
                    >
                      Load more
                    </Button>
                  </div>
                )}
              </section>
              <Header as="h2">
                Unconfirmed New Patients ({newPatients.length})
              </Header>
              <div className={styles.proximityTable}>{newPatientsBody}</div>
              {allowMore && (
                <div className={styles.loadMoreContainer}>
                  <Button onClick={handleMoreNew} basic>
                    Load more
                  </Button>
                </div>
              )}
            </section>
          </Grid.Column>
          <Grid.Column width={3}>
            <Feed
              next={nextActivities}
              activity={activity}
              onEventClick={handlePatientClick}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Today;
