import React, { useState } from 'react';
import { Button, Modal, Accordion, Icon, Grid } from 'semantic-ui-react';
import Exercise from 'containers/patient/components/CentralInfo/components/Report/components/Summary/components/Plans/Exercise';
import Nutrition from 'containers/patient/components/CentralInfo/components/Report/components/Summary/components/Plans/Nutrition';
import { MedicationList } from 'containers/patient/components/CentralInfo/components/Report/components/Summary/components';
import Diseases from 'containers/patient/components/CentralInfo/components/info/components/Diseases';
import SingleChart from 'components/SingleChart';
import SummarySection from 'components/Viz/SummarySection';
import VitalSigns from './VitalSigns';
import LinkedButtons from './LinkedButtons';
import ReportChart from '../ReportChart';
import style from '../patientSummary.module.scss';

const idxByTabs = {
  info: 0,
  generatedData: 3,
  meals: 4,
  appointments: 5,
};
const Conversation = props => {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { patientData, date, tz, setActiveTabIndex } = props;
  const conversationTitle = 'Diabetes Conversation';
  const handleClose = () => setOpen(false);
  const makeButton = (content, tab) => {
    let onClick;
    if (tab === 'report') {
      onClick = handleClose;
    } else {
      onClick = () => setActiveTabIndex(idxByTabs[tab]);
    }
    return {
      key: content,
      content,
      size: 'small',
      primary: true,
      onClick,
    };
  };

  const hba1cProps = {
    date,
    tz,
    tickData: patientData.emrTicks,
  };
  const accordions = [
    {
      title: 'HbA1c',
      buttonList: [makeButton('Report', 'report')],
      Component: () => <ReportChart chart="hba1c" {...hba1cProps} />,
    },
    {
      title: 'Time in range',
      buttonList: [makeButton('Drilldown', 'generatedData')],
      Component: () => <ReportChart chart="glucose" {...props} />,
    },
    {
      title: 'Vital signs',
      buttonList: [],
      Component: () => <VitalSigns {...props} makeButton={makeButton} />,
    },
    {
      title: 'Exercise plan',
      buttonList: [makeButton('Report', 'report')],
      Component: () => (
        <Grid>
          <Exercise withHeader={false} />
        </Grid>
      ),
    },
    {
      title: 'Nutrition and diet',
      buttonList: [
        makeButton('Meals', 'meals'),
        makeButton('Notes', 'appointments'),
      ],
      Component: () => (
        <Grid>
          <Nutrition withHeader={false} />
        </Grid>
      ),
    },
    {
      title: 'Sleep and recovery',
      buttonList: [makeButton('Drilldown', 'generatedData')],
      Component: () => (
        <>
          <SingleChart chart="sleep" {...props} />
          <SummarySection {...props} selectedCharts={['sleep']} />
        </>
      ),
    },
    {
      title: 'Calculated disease risk',
      buttonList: [makeButton('Info', 'info')],
      Component: Diseases,
    },
    {
      title: 'Recommend treatment options',
      buttonList: [makeButton('Report', 'report')],
      Component: () => <MedicationList withHeader={false} />,
    },
  ];

  const handleAccordionClick = key => {
    setActiveIndex(key);
  };
  const handleChange = action => {
    if (action === 'prev' && activeIndex !== 0) {
      setActiveIndex(activeIndex - 1);
    } else if (action === 'next' && activeIndex !== accordions.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  return (
    <Modal
      open={open}
      trigger={
        <Button content="Conversation" primary onClick={() => setOpen(true)} />
      }
      onClose={handleClose}
    >
      <Modal.Header>{conversationTitle}</Modal.Header>
      <Modal.Content scrolling>
        <Accordion fluid styled>
          {accordions.map(({ title, Component, buttonList }, key) => [
            <Accordion.Title
              key={key}
              active={activeIndex === key}
              onClick={() => handleAccordionClick(key)}
            >
              <Icon name="dropdown" />
              {title}
            </Accordion.Title>,
            <Accordion.Content key={`${key}-2`} active={activeIndex === key}>
              <LinkedButtons buttonList={buttonList}>
                <Component />
              </LinkedButtons>
            </Accordion.Content>,
          ])}
        </Accordion>
      </Modal.Content>
      <Modal.Actions>
        <div className={style.conversationActionsBox}>
          <Button primary onClick={() => handleChange('prev')}>
            Prev
          </Button>
          <Button onClick={() => handleChange('next')} primary>
            Next
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default Conversation;
