import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { capitalize, isEmpty } from 'lodash';
import { Dimmer, Icon, Image, Loader } from 'semantic-ui-react';

import { Biometrics } from 'components';
import getBiometrics from 'utils/biometrics';
import DefaultImage from 'assets/default-image.png';
import styles from './patientTableRow.module.scss';

const COLOR_STYLES = {
  none: styles.noneValue,
  low: styles.lowValue,
  medium: styles.mediumValue,
  high: styles.highValue,
};

const COVID_STATUS_COLOR = {
  Positive: styles.highValue,
  'Antibody Positive': styles.highValue,
  Negative: styles.lowValue,
  'Antibody Negative': styles.lowValue,
};

const BAND_COLORS = {
  WEARING: styles.lowValue,
  CHARGING: styles.mediumValue,
  REMOVED: styles.highValue,
  'N/A': styles.noneValue,
};

const PatientTableRow = ({ basicData, data, isHeader, onClick, showEmpty }) => {
  const renderRow = ({ key, data, isHeader }) => (
    <div
      key={key}
      className={cx(styles.tableRow, {
        [styles.tableHeaderRow]: isHeader,
        [styles.rowClickable]: !isHeader && onClick,
      })}
      onClick={onClick}
    >
      {data.map((cell, idx) => {
        const isCentered = idx !== 0;
        return (
          <div
            key={`${key}-${idx}`}
            className={cx({
              [styles.tableHeader]: isHeader,
              [styles.centeredCell]: isCentered,
            })}
          >
            {cell}
          </div>
        );
      })}
    </div>
  );

  const renderEmptyRow = ({ key, name, image, isLoading }) => (
    <div
      key={key}
      className={cx(styles.tableRow, styles.rowClickable)}
      onClick={onClick}
    >
      <div>
        <Image key={`${key}-img`} src={image} avatar />
        <span key={`${key}-name`} className={styles.nameContainer}>
          {name}
        </span>
      </div>
      {isLoading && (
        <div className={styles.emptyRow}>
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        </div>
      )}
    </div>
  );

  const renderErrorRow = ({ key, name, image }) => (
    <div
      key={key}
      className={cx(styles.tableRow, styles.rowClickable)}
      onClick={onClick}
    >
      <div>
        <Image key={`${key}-img`} src={image} avatar />
        <span key={`${key}-name`} className={styles.nameContainer}>
          {name}
        </span>
      </div>
      <div className={styles.emptyRow}>
        <span className={styles.highValue}>
          Something went wrong fetching data for this patient.
        </span>
      </div>
    </div>
  );

  const patientKey = basicData.id;
  const name = `${capitalize(basicData.last)}, ${capitalize(basicData.first)}`;
  const image = basicData.image ?? data.image ?? DefaultImage;

  if (isHeader) return renderRow({ data, isHeader: true });
  if (showEmpty) return renderEmptyRow({ key: patientKey, name, image });

  if (!data || isEmpty(data))
    return renderEmptyRow({
      key: patientKey,
      name,
      image,
      isLoading: true,
    });

  if (!basicData) return renderErrorRow({ key: patientKey, name, image });

  const age = basicData.biometrics?.age ?? '--';

  let biometricsValue;
  if (data.hasBeenUsingBand) {
    const biometrics = getBiometrics(basicData.facts);
    biometricsValue = (
      <Biometrics patientId={patientKey} biometrics={biometrics} />
    );
  } else {
    biometricsValue = <span className={styles.noneValue}>N/A</span>;
  }

  const vulnerabilityLevel = (() => {
    const patientCovidVulnerability = basicData?.facts?.['i_vuln'] ?? null;
    if (!patientCovidVulnerability) return 'none';
    if (patientCovidVulnerability === 'GREEN') return 'low';
    if (patientCovidVulnerability === 'YELLOW') return 'medium';
    return 'high';
  })();
  const vulnerabilityColor =
    COLOR_STYLES[vulnerabilityLevel] ?? styles.noneValue;

  const exposureLevel = (() => {
    const patientCovidExposure = basicData?.facts?.['i_covid_19'] ?? null;
    if (!patientCovidExposure && patientCovidExposure !== 0) return 'none';
    if (patientCovidExposure < 0.33) return 'low';
    if (patientCovidExposure < 0.66) return 'medium';
    return 'high';
  })();
  const exposureColor = COLOR_STYLES[exposureLevel] ?? styles.noneValue;

  const covidColor = COVID_STATUS_COLOR[data.covidStatus] ?? styles.noneValue;

  const bandColor = BAND_COLORS[data.bandStatus] ?? styles.noneValue;

  const batteryLevel = data?.bandBatteryLevel;

  const bandBatteryColor = (() => {
    if (!batteryLevel) return styles.noneValue;
    if (batteryLevel <= 20) return styles.highValue;
    return styles.lowValue;
  })();

  const proximityColor = (() => {
    const patientProximity = data.proximity ?? 0;

    if (patientProximity < 3) return styles.lowValue;
    if (patientProximity < 5) return styles.mediumValue;
    return styles.highValue;
  })();

  return renderRow({
    data: [
      [
        <Image key={`${patientKey}-img`} src={image} avatar />,
        <span key={`${patientKey}-name`} className={styles.nameContainer}>
          {name}
        </span>,
      ],
      age,
      biometricsValue,
      [
        <Icon
          key={`${patientKey}-vul`}
          name="circle"
          className={vulnerabilityColor}
        />,
        <span key={`${patientKey}-vul-text`} className={vulnerabilityColor}>
          {vulnerabilityLevel === 'none'
            ? 'N/A'
            : capitalize(vulnerabilityLevel)}
        </span>,
      ],
      [
        <Icon
          key={`${patientKey}-exp`}
          name="circle"
          className={exposureColor}
        />,
        <span key={`${patientKey}-exp-text`} className={exposureColor}>
          {exposureLevel === 'none' ? 'N/A' : capitalize(exposureLevel)}
        </span>,
      ],
      <span className={covidColor}>{data.covidStatus}</span>,
      <Icon name="circle" className={bandColor} />,
      [
        <Icon
          key={`${patientKey}-btl`}
          name="circle"
          className={bandBatteryColor}
        />,
        <span key={`${patientKey}-btl-text`} className={bandBatteryColor}>
          {batteryLevel === undefined ? 'N/A' : `${batteryLevel} %`}
        </span>,
      ],
      <Icon name="circle" className={proximityColor} />,
    ],
  });
};

PatientTableRow.propTypes = {
  basicData: PropTypes.shape({}),
  data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  isHeader: PropTypes.bool,
  onClick: PropTypes.func,
  showEmpty: PropTypes.bool,
};

PatientTableRow.defaultProps = {
  basicData: {},
  data: {},
  isHeader: false,
  onClick: null,
  showEmpty: false,
};

export default PatientTableRow;
