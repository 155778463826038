import moment from 'moment-timezone';

/**
 * Takes an array of appointment`s visit types (HL7 Fhir schema)
 * make an "options array" to be used on Form.select (semantic-ui-component)
 * @param {array} staticData
 */
export const staticToOptions = (staticData = []) =>
  staticData.map((item, key) => ({
    key,
    text: item.description,
    value: item.id,
  }));

/**
 * This class holds the logic and helper funtions in order to display appointments
 */
export class TableFields {
  constructor() {
    this.arrayOfObjectFields = ['Practitioner'];
    this.dateFields = ['Date', 'Time'];
    this.colorFields = ['Status'];

    this.decoder = new Decoder();
    this.values = [
      { show: true, label: 'Status', accessProperty: 'status' },
      { show: true, label: 'Resource Type', accessProperty: 'specialty' },
      {
        show: true,
        label: 'Practitioner',
        accessProperty: 'practitioners',
      },
      { show: true, label: 'Visit Reason', accessProperty: 'appointment_type' },
      {
        show: true,
        label: 'Patient Comment',
        accessProperty: 'comment',
      },
      { show: true, label: 'Date', accessProperty: 'start' },
      { show: true, label: 'Period', accessProperty: 'period' },
      { show: true, label: 'Time', accessProperty: 'start' },
      { show: false, label: 'Time Window', accessProperty: 'time_window' },
    ];
  }

  get labels() {
    return this.values.filter(val => val.show).map(val => val.label);
  }

  isChild(label) {
    return this.childFields.includes(label);
  }

  isColor(label) {
    return this.colorFields.includes(label);
  }

  isDate(label) {
    return this.dateFields.includes(label);
  }

  isArrayOfObject(label) {
    return this.arrayOfObjectFields.includes(label);
  }

  isPopup(label) {
    return ['Period', ...this.colorFields].includes(label);
  }

  getAccessProperty(label) {
    return this.values.find(val => val.label === label).accessProperty;
  }

  color(label, appointment) {
    const accessProperty = this.getAccessProperty(label);
    return this.decoder.getColorByValue(
      accessProperty,
      appointment[accessProperty]
    );
  }

  text(label, appointment) {
    const accessProperty = this.getAccessProperty(label);

    if (this.isArrayOfObject(label)) {
      return this.decoder.getArrayObjectsName(
        accessProperty,
        appointment[accessProperty]
      );
    }

    if (this.isDate(label)) {
      return this.decoder.getDateText(
        label,
        appointment[accessProperty],
        appointment
      );
    }

    return appointment[accessProperty] || '---';
  }

  value(label, appointment) {
    return this.isColor(label)
      ? this.color(label, appointment)
      : this.text(label, appointment);
  }

  secondaryValue(label, appointment) {
    return label === 'Period'
      ? this.text('Time Window', appointment)
      : this.text(label, appointment);
  }

  /**
   * @param {array} appointments
   * @param {string} method
   * @description method is 'max' or 'min'
   */
  sortAppointmentsByDate(appointments = [], method = 'max') {
    return appointments.slice().sort((a, b) => {
      const diff = b.start - a.start;
      const multiplier = method === 'max' ? 1 : -1;
      return diff * multiplier;
    });
  }
}

class Decoder {
  constructor() {
    this.colorParser = {
      status: val => {
        switch (val.toLowerCase()) {
          case 'booked':
          case 'arrived':
          case 'fulfilled':
          case 'checked-in':
            return 'green';
          case 'proposed':
          case 'wailist':
          case 'pending':
            return 'blue';
          case 'entered-in-error':
          case 'cancelled':
            return 'red';
          default:
            return 'blue';
        }
      },
    };
    this.childParser = [
      {
        label: 'Date',
        parser: start => moment(start * 1000).format('YYYY/MM/DD'),
      },
      {
        label: 'Time',
        parser: (start, appointment) =>
          appointment.status !== 'proposed'
            ? moment(start * 1000).format('HH:mm')
            : null,
      },
    ];
  }

  getArrayObjectsName(accessProperty, value) {
    return value.map(item => item.name).join(', ');
  }

  getColorByValue(accessProperty, value) {
    const parser = this.colorParser[accessProperty];
    return parser ? parser(value) : 'black';
  }

  getDateText(label, value, appointment) {
    const child = this.childParser.find(child => child.label === label);
    let childValue;
    if (child) {
      childValue = child.parser(value, appointment);
    }
    return childValue || '---';
  }
}
/**
 * ----- Available Days
 */

export class AvailableDates {
  constructor(availableDates) {
    this.rawDates = availableDates;
    this.periods = ['MORNING', 'MIDDAY', 'AFTERNOON', 'EVENING'];
    this.colorsByAvailability = {
      FULL: 'red',
      BUSY: 'orange',
      LOADED: 'yellow',
      FREE: 'green',
      UNAVAILABLE: 'grey',
    };
  }

  get dates() {
    return Object.keys(this.rawDates).map(key => ({
      date: moment(Number(key) * 1000),
      periods: this.parsePeriods(this.rawDates[key]),
    }));
  }

  parsePeriods(periods) {
    return this.periods.map(slot => ({
      slot,
      availability: periods[slot] || 'unknown',
      color: this.getColor(periods[slot]),
    }));
  }

  getColor(avalability) {
    return this.colorsByAvailability[avalability] || 'black';
  }
}
