import { getBiometricAlerts } from 'utils/alerts';
import generateIconColorFromSeverity from 'utils/generateIconColorFromSeverity';

const alert = ({ message, severity }) => {
  const notificationType = generateIconColorFromSeverity(severity, true);
  const icon = {
    minus: '-',
    'arrow up': '&#8593',
    'arrow down': '&#8595',
  };
  return `<div class='covid-alert biometrics'>
      <h4 style='color:${notificationType.color}'>
      ${icon[notificationType.icon]} ${message}
      </h4>
    </div>`;
};
export default data => {
  const {
    data: { biometrics },
  } = data.covid19;
  const { redAlerts, greenAlerts } = getBiometricAlerts(biometrics, true);
  const allAlerts = [...redAlerts, ...greenAlerts];
  let rightAlerts = [];
  let leftAlerts = [];
  if (allAlerts.length > 5) {
    rightAlerts = allAlerts.slice(0, 5);
    leftAlerts = allAlerts.slice(5);
  } else {
    rightAlerts = allAlerts;
  }
  rightAlerts = rightAlerts.reduce((a, b) => a + alert(b), '');
  leftAlerts = leftAlerts.reduce((a, b) => a + alert(b), '');

  return `
  <div class='report-sub-section'>
  <h3>Pre-Symptomatic Detection</h3>
  <div class='presymptomatic-alerts'>
      <div>
      ${rightAlerts}
      </div>
      <div>
      ${leftAlerts}
      </div>
    </div>
    </div>`;
};
