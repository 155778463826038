import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { createClinicalImpression } from 'store/currentPatient/actions/fhir';
import EncounterInfo from './EncounterInfo';
import EncounterNotes from './EncounterNotes';
// eslint-disable-next-line import/no-webpack-loader-syntax

const TabPanel = ({ encounter }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const dispatch = useDispatch();
  const id = useSelector(state => state.currentPatient.id);
  const handleSubmit = note => {
    const body = {
      note,
    };
    dispatch(createClinicalImpression(id, encounter.id, body));
  };
  const panes = [
    {
      menuItem: 'Info',
      render: () => <EncounterInfo encounter={encounter} />,
    },
    {
      menuItem: 'Notes',
      render: () => (
        <EncounterNotes encounter={encounter} handleSubmit={handleSubmit} />
      ),
    },
  ];

  const handleTabChange = (e, { activeIndex }) => {
    setActiveTabIndex(activeIndex);
  };

  return (
    <Tab
      panes={panes}
      menu={{ borderless: true, attached: false, tabular: false }}
      activeIndex={activeTabIndex}
      onTabChange={handleTabChange}
    />
  );
};

export default TabPanel;
