import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const EncountersFilters = ({
  setFilterIdentifier,
  setFilterStatus,
  encounters,
}) => {
  const handleChangeIdentifier = (_, data) => {
    setFilterIdentifier(data.value);
  };
  const handleChangeStatus = (_, data) => {
    setFilterStatus(data.value);
  };

  const identifierOptions = encounters.map(encounter => ({
    key: encounter.id,
    text: encounter.identifier,
    value: encounter.identifier,
  }));
  const statusOptions = encounters
    .map(encounter => encounter.status)
    .reduce((a, b) => (a.includes(b) ? a : [...a, b]), [])
    .sort()
    .map(statusName => ({
      key: statusName,
      text: statusName,
      value: statusName,
    }));

  return (
    <>
      <Dropdown
        search
        clearable
        selection
        multiple
        options={statusOptions}
        onChange={handleChangeStatus}
        placeholder={`Status`}
      />
      <Dropdown
        search
        clearable
        selection
        options={identifierOptions}
        onChange={handleChangeIdentifier}
        placeholder={`Episode Id`}
      />
    </>
  );
};
export default EncountersFilters;
