import { createReducer } from '@reduxjs/toolkit';

import { pushCachedPatient } from 'store/patients/actions';

const initialState = {
  data: {},
};
export default createReducer(initialState, {
  [pushCachedPatient]: (state, action) => {
    state.data[action.payload.id] = { ...action.payload };
  },
});
