import moment from 'moment-timezone';
import { utcToTz } from './timezone';

export const sortDates = (a, b) => {
  const aUtc = utcToTz(a);
  const bUtc = utcToTz(b);
  return moment(aUtc).diff(moment(bUtc)) * -1;
};

export const sortDateByProperty = property => (a, b) =>
  sortDates(a[property], b[property]);

export const getTzFormat = (timeZoneDate, tz, format = 'HH:mm') =>
  tz
    ? moment.tz(timeZoneDate, tz).format(format)
    : moment(timeZoneDate).format(format);

export const AddValuesToMeals = (sortedMeals, sortedGlucose) =>
  sortedMeals.map(meal => {
    const { meal_time } = meal;
    const glucoseMin = sortedGlucose
      .slice()
      .find(({ time }) => sortDates(time, meal_time) >= 0);
    const glucoseMax = sortedGlucose
      .slice()
      .reverse()
      .find(({ time }) => sortDates(time, meal_time) < 0);
    const glucoseValues = [glucoseMin, glucoseMax].filter(g => !!g);
    const howManyExist = glucoseValues.length;
    let value;
    switch (howManyExist) {
      case 2:
        value = glucoseValues.reduce((a, b) => a.value + b.value) / 2;
        break;
      case 1:
        value = glucoseValues[0].value;
        break;
      default:
        value = 100;
    }
    return { ...meal, value };
  });

export const populateDay = (tz, dateField, dateObject, target, source) => {
  const onTimeZone = utcToTz(source[dateField], tz);

  const day = getTzFormat(onTimeZone, tz, 'YYYY-MM-DD');
  if (!dateObject[day]) {
    dateObject[day] = { meals: [], glucose: [] };
  }
  dateObject[day][target].push(source);
};
