import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, Loader } from 'semantic-ui-react';
import { connect, useDispatch } from 'react-redux';

import {
  fetchPatientInteractions,
  fetchUserGroups,
  fetchPatientBiometrics,
  setSelectedLocation,
} from 'store/currentPatient/actions';
import CovidHeader from '../CovidHeader';
import TimeAtHome from './components/TimeAtHome';
import OtherLocations from './components/OtherLocations';
import PeopleInteracted from './components/PeopleInteracted';

const Covid19 = ({
  setActiveTabIndex,
  patientId,
  data,
  isLoading,
  error,
  timezone,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserGroups(patientId));
    dispatch(fetchPatientInteractions({ patientId }));
    dispatch(fetchPatientBiometrics(patientId));
  }, [dispatch, patientId]);

  const handleLocationClick = location => {
    setActiveTabIndex(6);
    dispatch(setSelectedLocation(location));
  };

  if (isLoading) return <Loader active />;

  const otherLocations = data.location.flatMap(day => day.otherLocations);

  return (
    <>
      <Header as="h2">Quarantine Summary</Header>
      <CovidHeader patientId={patientId} />
      {!error ? (
        <>
          <Header as="h3">Interactions</Header>
          <PeopleInteracted interactions={data.interactions} />
          <Header as="h3">Quarantine Status</Header>
          <TimeAtHome
            data={data.location}
            effectiveness={data.effectiveness}
            timezone={timezone}
          />
          <Header as="h3">Locations Out-of-Quarantine</Header>
          <OtherLocations
            otherLocations={otherLocations}
            locationClick={handleLocationClick}
            timezone={timezone}
          />
        </>
      ) : (
        <p>Something was wrong fetching user data.</p>
      )}
    </>
  );
};

Covid19.propTypes = {
  patientId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    location: PropTypes.array,
    otherLocation: PropTypes.array,
    effectiveness: PropTypes.number,
    interactions: PropTypes.shape({
      total: PropTypes.number,
      diagnosed: PropTypes.number,
      healthCareWorkers: PropTypes.number,
      atRisk: PropTypes.number,
    }),
  }),
  timezone: PropTypes.string,
  setActiveTabIndex: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
};

Covid19.defaultProps = {
  data: {
    location: [],
    otherLocation: [],
    effectiveness: 0,
    interactions: {
      total: 0,
      diagnosed: 0,
      healthCareWorkers: 0,
      atRisk: 0,
    },
  },
  timezone: '',
  setActiveTabIndex: () => null,
  isLoading: false,
  error: null,
};

const mapStateToProps = state => ({
  patientId: state.currentPatient.id,
  data: state.currentPatient.covid19.data,
  isLoading: state.currentPatient.covid19.isLoading,
  error: state.currentPatient.covid19.error,
  timezone: state.currentPatient.timeZone.data.tz,
});

export default connect(mapStateToProps)(Covid19);
