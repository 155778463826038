import { ALERT_TRESHOLDS } from 'utils/constants';
import style from '../containers/patient/components/CentralInfo/components/covid.module.scss';

export const roundHealthPassportValues = val =>
  ALERT_TRESHOLDS['Covid 19']
    .map(({ floatThreshold }) => floatThreshold)
    .filter(optionValue => optionValue <= val)
    .reverse()[0];

export const getDisplayPassportStatus = value => {
  if (value < 0.3)
    return {
      text: 'Green',
      color: style.lowValue,
    };
  if (value < 0.6)
    return {
      text: 'Yellow',
      color: style.mediumValue,
    };
  return {
    text: 'Red',
    color: style.highValue,
  };
};
