import React, { useState } from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';
import generateIconColorFromSeverity from 'utils/generateIconColorFromSeverity';
import { SEVERITY_TYPES } from 'utils/constants';
import SendMessage from './SendMessage';
import style from './Action.module.scss';

const Action = ({ level, message, buttonText, name, id, handleAction }) => {
  const [expanded, setExpanded] = useState(false);
  const notificationType = generateIconColorFromSeverity(level);

  const Head = (
    <header id={style.actionHead}>
      <Icon
        name={notificationType.icon}
        id={style.icon}
        size="large"
        color={notificationType.color}
      />
      {message}
      {Boolean(!expanded) && (
        <Button
          color={notificationType.color}
          onClick={() => {
            setExpanded(true);
          }}
          id={style.cta}
        >
          {buttonText}
        </Button>
      )}
    </header>
  );

  const sendAlertMessage = () => {};

  const handleSend = (values, callback) => {
    if (level === SEVERITY_TYPES.NEW) {
      handleAction(values);
    } else {
      sendAlertMessage();
    }
    callback();
  };

  return (
    <Message size="big" icon id={style.notification}>
      {Head}
      {Boolean(expanded) && (
        <SendMessage
          level={level}
          notificationType={notificationType}
          patientName={name}
          onSubmit={handleSend}
        />
      )}
    </Message>
  );
};

export default Action;
