import React from 'react';

import { Divider } from 'semantic-ui-react';
import style from 'containers/patient/patient.module.scss';
import PatientInfo from './components/PatientInfo';
import ReportBox from './components/ReportBox';
import PatientCard from './components/PatientCard';

const LeftInfo = () => {
  return (
    <div className={style.leftInfoContainer}>
      <aside className={style.patientInfo}>
        <PatientInfo />
        <Divider />
        <ReportBox />
        <Divider />
        <PatientCard />
      </aside>
    </div>
  );
};

export default LeftInfo;
