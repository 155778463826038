import React from 'react';
import { useDispatch } from 'react-redux';
import {
  postMealNote,
  deleteMealNote,
  setPublishedMealNote,
} from 'store/currentPatient/actions';
import NotesList from 'components/NotesList';
const NotesSection = ({ notes, clinicianId, ownerId, id, isLoading }) => {
  const dispatch = useDispatch();
  const mealId = id;

  const handleDelete = noteId => {
    const body = { mealId, noteId };
    dispatch(deleteMealNote(body));
  };
  const handleCheck = noteId => {
    const body = { mealId, noteId };
    dispatch(setPublishedMealNote(body));
  };

  const handleSubmit = value => {
    const body = { mealId, note: value };
    dispatch(postMealNote(body));
  };

  const dataProps = {
    notes,
    clinicianId,
    ownerId,
    handleDelete,
    handleCheck,
    handleSubmit,
    canCheck: false,
  };
  return (
    <>
      {isLoading && <p>Sending note ...</p>}
      <NotesList {...dataProps} />
    </>
  );
};

export default NotesSection;

NotesSection.defaultProps = {
  notes: [],
};
