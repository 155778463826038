import React from 'react';
import { Grid } from 'semantic-ui-react';

import { formatDate } from 'utils/fhirData';
import { labelize } from 'utils/textFormats';
import style from '../../../patientSummary.module.scss';

const HeaderInfo = ({ record = {} }) => {
  const { data: headerInfo } = record;
  const headerRows = [
    ['name', 'gender'],
    ['age', 'birthDate'],
  ];
  const getValue = field => {
    switch (field) {
      case 'age':
        return headerInfo[field]?.text || '---';
      case 'birthDate':
        return formatDate(headerInfo[field]);
      default:
        return headerInfo[field] || '---';
    }
  };
  return (
    <Grid.Row columns={3} className={style.bottomFrame}>
      <Grid.Column>
        <Grid>
          <Grid.Row columns={2}>
            {headerRows.map((headerFields, idx) => (
              <Grid.Column key={idx}>
                {headerFields.map(field => (
                  <p key={field}>
                    <b>{labelize(field, true)}</b>
                    {getValue(field)}
                  </p>
                ))}
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column></Grid.Column>
      <Grid.Column verticalAlign="top" textAlign="right">
        <p>
          <b>MRN</b>
        </p>
      </Grid.Column>
    </Grid.Row>
  );
};

export default HeaderInfo;
