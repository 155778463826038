import React from 'react';
import { useChartDimensions } from './Chart';
import style from './Chart.module';

const GridLine = ({
  dimension = 'x',
  lineClass,
  label,
  labelRightAligned,
  position,
  dashed,
}) => {
  const dimensions = useChartDimensions();

  const lineDimensions = () => {
    if (dimension === 'x')
      return {
        x2: dimensions.boundedWidth,
        transform: `translate(0, ${position})`,
      };
    return {
      y2: dimensions.boundedHeight,
      transform: `translate(${position}, 0)`,
    };
  };

  const transformLabel = () => {
    if (dimension === 'x') {
      if (labelRightAligned)
        return `translate(${dimensions.boundedWidth - 35}, ${position + 10})`;
      return `translate(0, ${position - 10})`;
    }
    return '';
  };

  return [
    <line
      style={{ ...style.gridLine, ...lineClass }}
      {...lineDimensions()}
      {...(dashed && { strokeDasharray: '10 5' })}
    />,
    label && (
      <text
        transform={transformLabel()}
        style={{
          fontSize: '0.6em',
        }}
      >
        {label}
      </text>
    ),
  ];
};

export default GridLine;
