import * as d3 from 'd3';

export const makeXScale = (xData, range) =>
  d3
    .scaleLinear()
    .domain(d3.extent(xData))
    .range(range)
    .nice();

export const makeYScale = range =>
  d3
    .scaleLinear()
    .domain([40, 180])
    .range(range);

export const getUniqueTimes = data =>
  data
    .flatMap(val => val)
    .map(tick => tick.time)
    .reduce((a, b) => {
      return a.includes(b) ? a : [...a, b];
    }, []);

export const getUniqueValues = data => {
  const [systolic, diastolic] = data;
  const uniqueTimes = getUniqueTimes(data);

  return uniqueTimes.map(time => {
    const hasDiastolic = diastolic.find(d => d.time === time);
    const hasSystolic = systolic.find(d => d.time === time);
    return {
      time,
      diastolic: hasDiastolic ? hasDiastolic.value : null,
      systolic: hasSystolic ? hasSystolic.value : null,
    };
  });
};
