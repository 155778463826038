import React from 'react';
import { Popup } from 'semantic-ui-react';
import { utcToTzFormat } from 'utils/timezone';

const Dots = ({
  data,
  keyAccessor,
  xAccessor,
  yAccessor,
  radius = 5,
  tz,
  ...props
}) => {
  return data.map((d, i) => {
    return (
      <Popup
        key={i}
        content={
          <p>
            {d.duration !== 0 && (
              <div>
                <b>Duration:</b>
                {` ${d.duration}`}
                <br />
              </div>
            )}
            <b>Value:</b>
            {` ${d.value.toFixed(2)}`}
          </p>
        }
        header={utcToTzFormat(d.time, tz, 'MM/DD hh:mm A')}
        trigger={
          <g>
            <circle
              {...props}
              key={keyAccessor(d, i)}
              cx={xAccessor(d, i)}
              cy={yAccessor(d, i)}
              r={typeof radius === 'function' ? radius(d) : radius}
            />
          </g>
        }
      />
    );
  });
};
export default Dots;
