import React, { useEffect } from 'react';
import { Redirect, Router } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { SemanticToastContainer } from 'react-semantic-toasts';

import { fetchAuthData, apiErrorReset } from 'store/authState/actions';
import { Chat, Header, ErrorBoundary } from 'components';
import { getCookie } from 'utils/cookies';
import { Login, Patient, Proximity, Today } from '..';
import style from './app.module.scss';
import env from '../../config';

const App = () => {
  const dispatch = useDispatch();
  let [token] = useLocalStorage('token');
  const cookieToken = getCookie('token');
  const apiErrors = useSelector(state => state.auth.apiErrors);
  const isSahaEnv = env.customerTenant.toLowerCase() === 'seha';

  useEffect(() => {
    if (token) {
      dispatch(fetchAuthData());
    }
  }, [token]); // eslint-disable-line

  useEffect(() => {
    if (apiErrors) {
      dispatch(apiErrorReset());
    }
  }, [apiErrors]); // eslint-disable-line

  if (cookieToken && !token) {
    writeStorage('token', cookieToken);
    token = cookieToken;
  }

  if (!token) {
    const { href, origin } = window.location;
    if (href !== `${origin}/`) {
      window.location.href = `${origin}/`;
    }
    return <Login />;
  }

  return (
    <ErrorBoundary>
      <Header />
      {!isSahaEnv && <Chat />}
      <SemanticToastContainer />
      <main className={style.mainAppContainer}>
        <Router>
          <Redirect from="/" to="/today" noThrow />
          <Today path="/today" />
          <Proximity path="/panel" />
          <Patient path="/patient/:id" />
        </Router>
      </main>
    </ErrorBoundary>
  );
};

export default App;
