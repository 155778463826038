import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { isEmpty } from 'lodash';

import {
  postMealNote,
  deleteMealNote,
  postMetadataNote,
  deleteMetadataNote,
  postMealMetadata,
  deleteMealMetadata,
  setPublishedMealMetadata,
  setPublishedMealNote,
  setCurrentMeal,
  clearCurrentMeal,
} from 'store/currentPatient/actions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [postMealNote.STARTED]: handleActionStart,
  [setPublishedMealNote.STARTED]: handleActionStart,
  [deleteMealNote.STARTED]: handleActionStart,
  [postMetadataNote.STARTED]: handleActionStart,
  [setPublishedMealMetadata.STARTED]: handleActionStart,
  [deleteMetadataNote.STARTED]: handleActionStart,
  [postMealMetadata.STARTED]: handleActionStart,
  [deleteMealMetadata.STARTED]: handleActionStart,
  [postMealNote.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data.notes = [...state.data.notes, action.payload.data];
    }
  },
  [setPublishedMealNote.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data.notes = state.data.notes.map(note =>
        note.id === action.payload.data.noteId
          ? {
              ...note,
              published: !note.published,
            }
          : note
      );
    }
  },
  [deleteMealNote.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data.notes = state.data.notes.filter(
        note => note.id !== action.payload.data.noteId
      );
    }
  },
  [postMetadataNote.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data.metadata = state.data.metadata.map(metadata =>
        metadata.id === action.payload.data.metadataId
          ? {
              ...metadata,
              notes: [...metadata.notes, action.payload.data.note],
            }
          : metadata
      );
    }
  },
  [deleteMetadataNote.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data.metadata = state.data.metadata.map(metadata =>
        metadata.id === action.payload.data.metadataId
          ? {
              ...metadata,
              notes: metadata.notes.filter(
                note => note.id !== action.payload.data.noteId
              ),
            }
          : metadata
      );
    }
  },
  [postMealMetadata.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data.metadata = [...state.data.metadata, action.payload.data];
    }
  },
  [setPublishedMealMetadata.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data.metadata = state.data.metadata.map(metadata =>
        metadata.id === action.payload.data.id ? action.payload.data : metadata
      );
    }
  },
  [deleteMealMetadata.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data.metadata = state.data.metadata.filter(
        metadata => metadata.id !== action.payload.data.metadataId
      );
    }
  },
  [postMealNote.FAILED]: handleActionError,
  [setPublishedMealNote.FAILED]: handleActionError,
  [deleteMealNote.FAILED]: handleActionError,
  [postMetadataNote.FAILED]: handleActionError,
  [setPublishedMealMetadata.FAILED]: handleActionError,
  [deleteMetadataNote.FAILED]: handleActionError,
  [postMealMetadata.FAILED]: handleActionError,
  [deleteMealMetadata.FAILED]: handleActionError,
  [clearCurrentMeal]: () => initialState,
  [setCurrentMeal]: (state, action) => {
    state.data = action.payload;
    state.isLoading = false;
    state.error = null;
  },
});
