import React from 'react';
import { Column, Table } from 'react-virtualized';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import 'react-virtualized/styles.css';

export default function(props) {
  const { data, columns } = props;
  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <Table
            width={width > 0 ? width : 0}
            height={height}
            headerHeight={20}
            rowHeight={30}
            rowCount={data.length}
            rowGetter={({ index }) => data[index]}
          >
            {columns.map(col => (
              <Column
                key={col.key}
                label={col.label}
                dataKey={col.key}
                width={width / columns.length > 0 ? width / columns.length : 0}
              />
            ))}
          </Table>
        );
      }}
    </AutoSizer>
  );
}
