import React from 'react';
import * as d3 from 'd3';
import { BAND_STATUS } from 'utils/constants';
import Axis from './Axis';
import Dots from './Dots';
import style from './Chart.module';

const BandStateChart = ({
  data = [],
  dimensions,
  xData,
  tz = {},
  axisAmount,
  axisIndex,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const keyAccessor = (_, i) => i;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain([0, 2])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  return [
    <Axis
      key="bs-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.bandStatusLabel}
      labelLine
      tickValues={[0, 1, 2]}
      formatTick={tick => `${BAND_STATUS[tick].toLowerCase()} (${tick})`}
    />,
    <Dots
      style={style.bandStatusCircles}
      data={data}
      keyAccessor={keyAccessor}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      radius={4}
      tz={tz}
    />,
  ];
};

const BandStateChartDayTrend = ({
  data = [],
  dimensions,
  xData,
  tz = {},
  axisAmount,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const keyAccessor = (_, i) => i;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain([0, 2])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  return [
    <Axis
      key="bs-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.bandStatusLabel}
      labelLine
      tickValues={[0, 1, 2]}
      formatTick={tick => tick}
    />,
    <Dots
      style={style.bandStatusCircles}
      data={data}
      keyAccessor={keyAccessor}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      radius={4}
      tz={tz}
    />,
  ];
};

export { BandStateChart, BandStateChartDayTrend };
