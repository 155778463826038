import { createReducer } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { handleActionStart, handleActionError } from 'store/utils';
import {
  fetchCompletedSurveys,
  setCurrentPatient,
  clearCurrentPatient,
  saveAnsweredSurveys,
  fetchPatientSurveysAnswers,
} from 'store/currentPatient/actions';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  answers: [],
};

export default createReducer(initialState, {
  [fetchCompletedSurveys.STARTED]: handleActionStart,
  [fetchCompletedSurveys.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload.data;
  },
  [saveAnsweredSurveys.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data = {
        ...state.data,
        ...action.payload.data,
      };
    }
  },
  [fetchPatientSurveysAnswers.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.answers = action.payload;
  },
  [fetchCompletedSurveys.FAILED]: handleActionError,
  [setCurrentPatient]: (_, action) => action.payload.surveys,
  [clearCurrentPatient]: () => initialState,
});
