import { combineReducers } from 'redux';

import ticks from './ticks';
import ticksSummary from './ticks-summary';
import surveys from './surveys';

export default combineReducers({
  ticks,
  ticksSummary,
  surveys,
});
