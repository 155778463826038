import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPatientTimeZone,
  setCurrentPatient,
  clearCurrentPatient,
} from 'store/currentPatient/actions';

import { handleActionStart, handleActionError } from 'store/utils';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchPatientTimeZone.STARTED]: handleActionStart,
  [fetchPatientTimeZone.SUCCEEDED]: (state, action) => {
    state.data = action.payload.data;
    state.isLoading = false;
    state.error = null;
  },
  [setCurrentPatient]: (_, action) => action.payload.timeZone,
  [clearCurrentPatient]: () => initialState,
  [fetchPatientTimeZone.FAILED]: handleActionError,
});
