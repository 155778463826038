import React from 'react';
import CardInfo from './CardInfo';
import { useSelector } from 'react-redux';
import { makeFieldObject } from '../utils';

const JhhCard = () => {
  const { isLoading, error, data } = useSelector(
    state => state.currentPatient.basicInfo
  );
  const excludedFields = ['first', 'last', 'image'];

  const excludeFields = ([fieldName, fieldValue]) =>
    !excludedFields.includes(fieldName);

  const fieldsAndValues = Object.entries(data)
    .filter(excludeFields)
    .map(makeFieldObject);

  return (
    <>
      {error && <p>Something was wrong with the patient record</p>}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        fieldsAndValues.map((field, idx) => <CardInfo key={idx} {...field} />)
      )}
    </>
  );
};

export default JhhCard;
