import React from 'react';
import { Grid } from 'semantic-ui-react';
import Preventive from './Preventive';
import Exercise from './Exercise';
import Nutrition from './Nutrition';
import FamilyHistory from './FamilyHistory';

const Plans = () => {
  return (
    <Grid>
      <FamilyHistory />
      <Nutrition />
      <Exercise />
      <Preventive />
    </Grid>
  );
};
export default Plans;
