import React from 'react';
import { List, Popup, Progress } from 'semantic-ui-react';
import style from 'containers/patient/patient.module.scss';

const DiseasesList = ({ data }) => {
  return (
    <div className={style.diseasesProgressList}>
      <List>
        {data.map(d => {
          const [name, probability] = Object.entries(d)[0];
          const roundedProbability = Math.round(probability);
          const fixedProbability = probability?.toFixed(2);
          return (
            <Popup
              key={name}
              content={`${fixedProbability || 0} %`}
              position="top right"
              trigger={
                <Progress percent={roundedProbability} color="blue" progress>
                  {name}
                </Progress>
              }
            />
          );
        })}
      </List>
    </div>
  );
};

export default DiseasesList;
