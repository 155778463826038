import React from 'react';
import style from 'containers/patient/patient.module.scss';

const YAxis = ({ reference, xRange, config }) => (
  <g>
    <g
      ref={reference}
      className={style.axis}
      transform={`translate(${xRange[0]},0)`}
    ></g>
    <text
      transform="rotate(-90)"
      y={-xRange[0]}
      x={-config.height / 2}
      dy="1em"
      className={style.yText}
    >
      Glucose (mg/dl)
    </text>
  </g>
);
export default YAxis;
