import { preventionsFactory } from 'utils/patientSummaryReport/factory';

export default data => {
  const { data: immunizations } = data.immunizations;
  const { data: observations } = data.observations;

  const { preventiveExams, headerFields } = preventionsFactory(
    immunizations,
    observations
  );

  return `<section class='report-section'>
  <h2 class='section-title'> Prevention </h2>
  <table class="info-table" >
    <thead class="">
        <tr class="table-header">
        ${headerFields.map(({ text }) => `<th class="">${text}</th>`).join('')}
        </tr>
    </thead>
    <tbody class="">
    ${preventiveExams
      .map(
        item =>
          `<tr class="">
          ${headerFields.map(({ field }) => `<td>${item[field]}</td>`).join('')}
        </tr>`
      )
      .join('')}
    </tbody>
</table>
  </section>`;
};
