import configuredAxios from 'api/axios.config';

const FACTUARY_ENDPOINT = process.env.REACT_APP_FACTUARY_API_URL;
const USER_ENDPOINT = '/users';
const USER_FACTS_ENDPOINT = '/user_facts';

class FactuaryApi {
  getPatientCompletedSurveys(
    jhhId,
    status = 'completed|in_progress|delivered|created'
  ) {
    const params = new URLSearchParams();
    params.append('status', status);
    params.append(
      'filter_type',
      'baselining|hypertension|sleep|arrhythmia|heart|diabetes|ad_hoc|review|single_question'
    );

    return configuredAxios
      .get(
        `${FACTUARY_ENDPOINT}${USER_ENDPOINT}/${jhhId}/surveys?${params}&order_by=time_completed_desc%7Cdate_desc`
      )
      .then(data => data.surveys.reverse())
      .catch(err => []);
  }

  getPatientSurveys(jhhId) {
    return configuredAxios
      .get(`${FACTUARY_ENDPOINT}${USER_ENDPOINT}/${jhhId}/surveys`)
      .then(data => data.surveys)
      .catch(err => []);
  }

  getAnswers(patientId, startDate, endDate) {
    if (startDate && endDate) {
      return configuredAxios
        .get(
          `${FACTUARY_ENDPOINT}/bayes/${patientId}/answers?start_time=${startDate.format(
            'X'
          )}&end_time=${endDate.format('X')}&deduplicate=0`
        )
        .catch(err => []);
    }

    return configuredAxios
      .get(`${FACTUARY_ENDPOINT}/bayes/${patientId}/answers`)
      .catch(err => []);
  }

  getUserFacts(id) {
    return configuredAxios
      .get(`${FACTUARY_ENDPOINT}${USER_FACTS_ENDPOINT}/${id}`)
      .then(response => {
        if (typeof response === 'string') {
          const parsedResponse = JSON.parse(
            response.replace(/\bNaN\b/g, 'null')
          );
          return parsedResponse.facts;
        }
        return response.facts;
      })
      .catch(err => console.log(err));
  }

  setAnswersSurveys(jhhId, surveyId, body) {
    return configuredAxios
      .post(
        `${FACTUARY_ENDPOINT}${USER_ENDPOINT}/${jhhId}/surveys/${surveyId}/answers`,
        body
      )
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }
}

export default FactuaryApi;
