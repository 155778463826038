import React from 'react';
import { useSelector } from 'react-redux';
import { makeFieldObject } from '../utils';
import CardInfo from './CardInfo';
import { parseRecord } from 'utils/fhirData/parsingFunctions';

const FhirCard = () => {
  const { isLoading, error, data } = useSelector(
    state => state.currentPatient.record
  );

  const fields = parseRecord({
    ...data,
    emr_available: data?.emr_available ? 'Yes' : 'No',
  });

  const fieldsAndValues = Object.entries(fields)
    .map(fieldArray => makeFieldObject(fieldArray))
    .sort();

  return (
    <>
      {error && <p>Something was wrong with patient`s fhir record</p>}
      {isLoading ? (
        <p>Loading fhir data...</p>
      ) : (
        fieldsAndValues.map((field, idx) => <CardInfo key={idx} {...field} />)
      )}
    </>
  );
};

export default FhirCard;
