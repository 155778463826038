import React, { useState } from 'react';
import { Message } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import moment from 'moment';
import * as d3 from 'd3';
import useResizeObserver from 'use-resize-observer';

import Chart from './Chart';
import Axis from './Axis';
import { HeartRateChart, HeartRateChartDayTrend } from './HeartRateChart';
import Hba1cChart from './Hba1cChart';
import LdlChart from './LdlChart';
import { WeightChart, WeightChartDayTrend } from './WeightChart';
import {
  SleepDepthLevelChart,
  SleepDepthLevelChartDayTrend,
} from './SleepDepthLevelChart';
import { MovementChart, MovementChartDayTrend } from './MovementChart';
import BloodPressureChart from './BloodPressureChart';
import { RmssdChart, RmssdChartDayTrend } from './RmssdChart';
import { GlucoseChart, GlucoseChartDayTrend } from './GlucoseChart';
import { InsulinChart } from './InsulinChart';
import {
  RespirationRateChart,
  RespirationRateChartDayTrend,
} from './RespirationRateChart';
import { TemperatureChart, TemperatureChartDayTrend } from './TemperatureChart';
import { SpO2Chart, SpO2ChartDayTrend } from './SpO2Chart';
import {
  combineChartDimensions,
  formatHour,
  formatDayMonth,
} from 'utils/chartUtils';
import { TICK_DATA_TYPES } from 'utils/constants';
import style from './Viz.module.scss';
import {
  BatteryLevelChart,
  BatteryLevelChartDayTrend,
} from './BatteryLevelChart';
import { BandStateChart, BandStateChartDayTrend } from './BandStateChart';

const ChartTypeComponents = {
  hr: HeartRateChart,
  hrv: RmssdChart,
  sleep: SleepDepthLevelChart,
  movement: MovementChart,
  blood_pressure: BloodPressureChart,
  weight: WeightChart,
  glucose: GlucoseChart,
  insulin: InsulinChart,
  resp_rate: RespirationRateChart,
  temp: TemperatureChart,
  spo2: SpO2Chart,
  battery_level: BatteryLevelChart,
  band_removed: BandStateChart,
  hba1c: Hba1cChart,
  ldl: LdlChart,
};

const OverlayedChartTypeComponents = {
  [TICK_DATA_TYPES['hr']]: HeartRateChartDayTrend,
  hrv: RmssdChartDayTrend,
  [TICK_DATA_TYPES['sleep']]: SleepDepthLevelChartDayTrend,
  [TICK_DATA_TYPES['movement']]: MovementChartDayTrend,
  blood_pressure: BloodPressureChart,
  [TICK_DATA_TYPES['weight']]: WeightChartDayTrend,
  [TICK_DATA_TYPES['glucose']]: GlucoseChartDayTrend,
  insulin: InsulinChart,
  resp_rate: RespirationRateChartDayTrend,
  temp: TemperatureChartDayTrend,
  spo2: SpO2ChartDayTrend,
  battery_level: BatteryLevelChartDayTrend,
  band_removed: BandStateChartDayTrend,
};

const composedCharts = [
  { chart: 'blood_pressure', metrics: ['systolic', 'diastolic'] },
  { chart: 'hrv', metrics: ['rmssd', 'rmssd_conf'] },
  { chart: 'insulin', metrics: ['basal', 'bolus'] },
  { chart: 'resp_rate', metrics: ['resp_rate', 'resp_rate_conf'] },
  { chart: 'temp', metrics: ['skin_temp', 'ambient_temp'] },
];

const ChartSelector = ({
  charts,
  date,
  data = {},
  tz = '',
  xTicks,
  showDailyTrends,
  forcedDimensions = {},
}) => {
  const [dimensions, setDimensions] = useState({
    marginLeft: 48,
    marginRight: 60,
    marginTop: 16,
    marginBottom: 48,
    width: 0,
    height: 0,
    boundedHeight: 0,
    boundedWidth: 0,
    ...forcedDimensions,
  });
  const {
    ref,
    width = forcedDimensions.width || 100,
    height = forcedDimensions.height || 100,
  } = useResizeObserver();
  React.useEffect(() => {
    if (
      width &&
      height &&
      (width !== dimensions.width || height !== dimensions.height)
    ) {
      const newDimensions = combineChartDimensions({
        ...dimensions,
        width: width - 40,
        height: height - 40,
        boundedHeight: height - 48 - 60,
        boundedWidth: width - 16 - 48,
      });
      setDimensions(newDimensions);
    }
  }, [width, height]); // eslint-disable-line

  const allChartsData = {};

  charts.forEach(chart => {
    const composedChart = composedCharts.find(config => config.chart === chart);

    const chartData = composedChart
      ? composedChart.metrics.map(metric => data[metric] || [])
      : data[TICK_DATA_TYPES[chart]] || [];

    allChartsData[chart] = chartData;
  });

  const allChartsTicks = Object.values(allChartsData).flatMap(chartDataSet =>
    chartDataSet.flatMap(set => set)
  );

  const dataHasValues = !isEmpty(allChartsTicks);

  if (!dataHasValues || !charts.length || !date.start || !date.end) {
    return (
      <div className={style.noDataContainer}>
        <Message>
          <Message.Header>No Tick Data</Message.Header>
          <p>
            We couldn't find tick data for the selected filters, please try with
            a different set.
          </p>
        </Message>
      </div>
    );
  }

  const renderChart = () => {
    const leftAxisAmount = charts.filter(chart => chart !== 'sleep').length;
    const lowerXRange = leftAxisAmount > 1 ? 30 : 0;

    if (showDailyTrends) {
      const groupedByDays = Object.entries(data)
        .map(([metric, values]) => {
          const amountDays = date.end.diff(date.start, 'days');
          const result = [];
          for (let i = 0; i <= amountDays; i++) {
            const actualDay = moment(date.start.valueOf()).add(i, 'days');
            const startDate = actualDay.startOf('day').valueOf();
            const endDate = actualDay.endOf('day').valueOf();
            const dayValues = values.filter(
              value => value.time >= startDate && value.time <= endDate
            );
            result.push({
              start: startDate,
              end: endDate,
              metrics: {
                [metric]: dayValues,
              },
            });
          }

          return result;
        })
        .reduce(merge);

      const dailyTrendsDimensions = {
        ...dimensions,
        height: 200,
        boundedHeight: 200 - dimensions.marginTop - dimensions.marginBottom,
        boundedWidth:
          dimensions.width - dimensions.marginLeft - dimensions.marginRight,
      };
      return groupedByDays.map(day => {
        const xDataDaily = [day.start, day.end];
        const xScaleDaily = d3
          .scaleLinear()
          .domain(d3.extent(xDataDaily))
          .range([lowerXRange, dailyTrendsDimensions.boundedWidth]);
        let ChartComponents = Object.entries(day.metrics).flatMap(
          ([chart, chartData], i) => {
            const OverlayedChartTypeComponent =
              OverlayedChartTypeComponents[chart];
            if (!OverlayedChartTypeComponent) return null;
            return OverlayedChartTypeComponent({
              data: chartData,
              dimensions: dailyTrendsDimensions,
              tz,
              xData: xDataDaily,
              axisIndex: i,
              axisAmount: leftAxisAmount,
            });
          }
        );

        const ComposedComponents = composedCharts
          .map(chartConfig => {
            const data = chartConfig.metrics.map(field => day.metrics[field]);
            const hasValues = data.some(metric => !!metric);
            if (hasValues) {
              return OverlayedChartTypeComponents[chartConfig.chart]({
                data,
                dimensions: dailyTrendsDimensions,
                xData: xDataDaily,
                tz,
                axisIndex: ChartComponents.length,
                axisAmount: leftAxisAmount,
              });
            }
            return null;
          })
          .filter(Boolean);

        ChartComponents = ChartComponents.concat(ComposedComponents);
        const dayLabel = `${moment(day.start).format('MM/DD')} - ${moment(
          day.end
        ).format('MM/DD')}`;

        return (
          <div className={style.trendChartContainer}>
            <span className={style.trendChartTitle}>{dayLabel}</span>
            <Chart
              style={{ backgroundColor: 'rgb(244, 244, 244)' }}
              dimensions={dailyTrendsDimensions}
            >
              <Axis
                dimension="x"
                scale={xScaleDaily}
                ticksAmount={xTicks}
                formatTick={date => formatHour(date, tz)}
              />
              {ChartComponents}
            </Chart>
          </div>
        );
      });
    }

    const timestamps = allChartsTicks
      .map(tick => tick.time)
      .sort((a, b) => b - a);

    const xData = [
      timestamps[0] || date.start.valueOf(),
      timestamps.slice(-1)[0] || date.end.valueOf(),
    ];

    const ChartComponents = charts.flatMap((chart, i) => {
      const ChartTypeComponent = ChartTypeComponents[chart];
      if (!ChartTypeComponent) return null;
      return ChartTypeComponent({
        data: allChartsData[chart],
        dimensions,
        xData,
        tz,
        axisIndex: i,
        axisAmount: leftAxisAmount,
      });
    });

    const xScale = d3
      .scaleLinear()
      .domain(d3.extent(xData))
      .range([lowerXRange, dimensions.boundedWidth]);

    return (
      <div ref={ref} className={style.graph}>
        <Chart dimensions={dimensions}>
          <Axis
            dimension="x"
            scale={xScale}
            ticksAmount={xTicks}
            formatTick={date => formatHour(date, tz)}
          />
          <Axis
            dimension="x"
            scale={xScale}
            second
            ticksAmount={xTicks}
            formatTick={date => formatDayMonth(date, tz)}
          />
          {ChartComponents}
        </Chart>
      </div>
    );
  };

  return renderChart();
};

export default ChartSelector;
