import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import ReportChart from '../../ReportChart';

import LinkedButtons from '../LinkedButtons';

const VitalSigns = ({ date, tz, tickData, patientData, makeButton }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const accordions = [
    {
      title: 'LDL',
      buttonList: [makeButton('Report', 'report')],
      Component: () => (
        <ReportChart
          date={date}
          chart={'ldl'}
          tz={tz}
          tickData={patientData.emrTicks}
        />
      ),
    },
    {
      title: 'Blood Pressure',
      buttonList: [makeButton('Drilldown', 'generatedData')],
      Component: () => (
        <ReportChart
          date={date}
          chart={'blood_pressure'}
          tz={tz}
          tickData={tickData}
        />
      ),
    },
    {
      title: 'Weight',
      buttonList: [makeButton('Drilldown', 'generatedData')],
      Component: () => (
        <ReportChart date={date} chart={'weight'} tz={tz} tickData={tickData} />
      ),
    },
  ];

  const handleAccordionClick = key => {
    if (activeIndex === key) {
      setActiveIndex(null);
    } else {
      setActiveIndex(key);
    }
  };

  return (
    <Accordion fluid styled>
      {accordions.map(({ title, Component, buttonList }, key) => {
        const accordionIndex = `${key}-vital`;
        return [
          <Accordion.Title
            key={accordionIndex}
            active={activeIndex === accordionIndex}
            onClick={() => handleAccordionClick(accordionIndex)}
          >
            <Icon name="dropdown" />
            {title}
          </Accordion.Title>,
          <Accordion.Content
            key={`${accordionIndex}-2`}
            active={activeIndex === accordionIndex}
          >
            <LinkedButtons buttonList={buttonList}>
              <Component />
            </LinkedButtons>
          </Accordion.Content>,
        ];
      })}
    </Accordion>
  );
};

export default VitalSigns;
