export const handleActionStart = state => {
  state.isLoading = true;
};

/* let state.error with a boolean value for the momment until knowing that every call to this function can handle an "action.payload" value */

export const handleActionError = (state, action) => {
  state.isLoading = false;
  state.error = action.error;
};
