import React, { useState } from 'react';

const Input = ({ extraText, setAnswer }) => {
  const [textInput, setTextInput] = useState(extraText.compiledAnswers);

  const handleChangeInputs = e => {
    setAnswer(e.target.value);
    setTextInput(e.target.value);
  };

  return (
    <div className="ui input">
      <input
        type="text"
        value={textInput || ''}
        onChange={handleChangeInputs}
      />
    </div>
  );
};

export default Input;
