import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import NotesSection from '../NotesSection';
import MetadataSection from '../MetadataSection';
import { useLocalStorage } from '@rehooks/local-storage';
import parseJwtToken from 'utils/parseJwtToken';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-webpack-loader-syntax
import style from 'containers/patient/patient.module.scss';

const InfoSection = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { data: meal, isLoading } = useSelector(
    state => state.currentPatient.currentMeal
  );
  const { notes, metadata, food_logging_id: id, uid: ownerId } = meal;

  const [token] = useLocalStorage('token');
  const tokenData = parseJwtToken(token);
  const clinicianId = tokenData.id.uid;

  const ids = {
    id,
    ownerId,
    clinicianId,
  };

  const panes = [
    {
      menuItem: 'Notes',
      render: () => (
        <NotesSection notes={notes} isLoading={isLoading} {...ids} />
      ),
    },
    {
      menuItem: 'Metadata',
      render: () => (
        <MetadataSection data={metadata} isLoading={isLoading} {...ids} />
      ),
    },
  ];

  const handleTabChange = (e, { activeIndex }) => {
    setActiveTabIndex(activeIndex);
  };

  return (
    <Tab
      className={style.mealTabInfo}
      panes={panes}
      menu={{ borderless: true, attached: false, tabular: false }}
      activeIndex={activeTabIndex}
      onTabChange={handleTabChange}
    />
  );
};

export default InfoSection;
