import React from 'react';
import { Header, Icon, Message } from 'semantic-ui-react';

import generateIconColorFromSeverity from 'utils/generateIconColorFromSeverity';

const Alert = ({ message, severity, haveHeaderColor, ...rest }) => {
  const notificationType = generateIconColorFromSeverity(severity, true);
  const AlertColor = { color: notificationType.color };
  return (
    <Message {...rest}>
      <Header as="h4" style={haveHeaderColor && AlertColor}>
        <Icon name={notificationType.icon} style={AlertColor} />
        <Header.Content>{message}</Header.Content>
      </Header>
    </Message>
  );
};

export default Alert;
