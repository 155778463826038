import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Loader, Grid } from 'semantic-ui-react';
import { Alert } from 'components';
import { SEVERITY_TYPES } from 'utils/constants';
import { useSelector } from 'react-redux';

const AlertBox = () => {
  const {
    data: { name, birthdate },
    isLoading,
  } = useSelector(state => state.currentPatient.basicInfo);
  const { data: diseasesAlerts } = useSelector(
    state => state.currentPatient.diseasesAlerts
  );

  const [otherAlerts, setOtherAlerts] = useState([]);

  useEffect(() => {
    const todayDate = moment().format('DD/MM');
    if (!isLoading) {
      const birthDate = moment(birthdate).format('DD/MM');
      if (todayDate === birthDate) {
        const birthDateAlert = {
          severity: SEVERITY_TYPES.GREEN,
          message: `Today is ${name}'s birthday`,
        };
        setOtherAlerts([birthDateAlert]);
      }
    }
  }, [name, birthdate, isLoading]);

  const alertsHalf = Math.ceil(diseasesAlerts.length / 2);
  const leftAlerts = diseasesAlerts.slice(0, alertsHalf);
  const rightAlerts = diseasesAlerts.slice(alertsHalf);
  const centerAlerts = otherAlerts;
  if (isLoading) return <Loader active />;
  return (
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Grid>
            <Grid.Row columns={1}>
              <Grid.Column>
                {centerAlerts.map((a, idx) => (
                  <Alert key={idx} {...a} />
                ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                {leftAlerts.map(a => (
                  <Alert key={a.message} {...a} />
                ))}
              </Grid.Column>
              <Grid.Column>
                {rightAlerts.map(a => (
                  <Alert key={a.message} {...a} />
                ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AlertBox;
