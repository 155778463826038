import {
  allergiesTable,
  conditionsTable,
  diseasesEvidenceTable,
  familyHistoryTable,
  formatData,
  medicalHistoryList,
  medicationTable,
  socialHistoryTable,
  substanceUseTable,
  vitalSignsTable,
} from 'utils/reports';
import parseJwtToken from 'utils/parseJwtToken';

import renderCharts from './renderCharts';

const renderDisease = disease =>
  `has a ${disease.value}% risk for ${disease.name}`;

const renderMedicalHistory = data => {
  if (data.allergies.length || data.conditions.length) {
    return `
      <h3 class="header-3 divider">Medical History</h3>
      ${allergiesTable(data.allergies)}
      ${conditionsTable(data.conditions)}
    `;
  }
  return '';
};

export default (data, ticksData, date, tz, signatureUrl) => {
  const formatedData = formatData(data);
  const chartSections = renderCharts(data, date, ticksData);
  const token = localStorage.getItem('token');
  let clinician = {
    first: 'John',
    last: 'Doe',
    active_credential: 'jdoe@all.health',
    phone: '---',
  };
  if (token) {
    const tokenData = parseJwtToken(token);
    clinician = { ...clinician, ...tokenData.id };
  }
  const signatureData = signatureUrl
    ? `<img class="signature-image" src=${signatureUrl}>`
    : `<div class="signature-text">/${clinician.first} ${clinician.last}, NREMT/</div>`;
  const isGlucareEnv = process.env.REACT_APP_CUSTOMER_TENANT === 'glucare';
  const logoImg = isGlucareEnv
    ? '<img class="image" alt="glucare-logo" src="/glucare-logo-small.png" />'
    : '<img class="image" alt="all-health-logo" src="/all-health-logo.png" />';
  const headerTitle = isGlucareEnv
    ? 'Patient Health Report'
    : 'Prediabetes Report';
  return `
    <div class="title">
      <h1 class="header">${headerTitle}</h1>
      ${logoImg}
    </div>

    <h2 class="date-header">${formatedData.dateToday}</h2>

    <section class="user-info-container">
      <h2 class="user-name">${formatedData.fullName}</h2>
      <div class="general-info">
        <p class="info-line">
          <strong>MRN:</strong> ${formatedData.mrn}
        </p>
        <p class="info-line">
          ${formatedData.gender} | <strong>DOB:</strong> ${formatedData.dob}
        </p>
        <p class="info-line">
          ${formatedData.height && `Height: ${formatedData.height}`}
          ${formatedData.weight ? ` | ${formatedData.weight} lbs` : ''}
          ${
            formatedData.bmi
              ? ` | <span class="bmi-info">BMI ${formatedData.bmi}</span>`
              : ''
          }
        </p>
      </div>
    </section>

    <p>Dear Dr. Jerry,</p>
    <p>
      Your patient ${
        formatedData.fullName
      } is a member of the all.health service and we
      have screened them for the risk of certain chronic conditions including
      prediabetes. We collected important information included below about their
      medical history as well as wearable and survey data.${
        formatedData.diseases.length
          ? `Based on this information, <strong>${
              formatedData.name
            } ${formatedData.diseases
              .map(renderDisease)
              .join(', ')} (positive predictive value)</strong>.`
          : ''
      }
      Our prediabetes screen is 83% sensitive.
    </p>
    <p>
      We are available to ${
        formatedData.name
      } through the all.health App if you have any questions or concerns.
    </p>
    <p>
      Thank you,
    </p>
    ${signatureData}
    <p>
      ${formatedData.drName}
    </p>
    <p>
      ${formatedData.drContactNumber}
    </p>
    <p>
      <a href="mailto:${formatedData.drContactEmail}">${
    formatedData.drContactEmail
  }</a>
    </p>
    ${vitalSignsTable(formatedData.vitalSigns)}
    ${renderMedicalHistory(formatedData)}
    ${medicalHistoryList(formatedData.vitalSigns)}
    ${medicationTable(formatedData.medications)}
    ${familyHistoryTable(formatedData.familyHistory)}
    ${socialHistoryTable(formatedData.socialHistory)}
    ${substanceUseTable(formatedData.substanceUse)}
    ${diseasesEvidenceTable(formatedData.diseasesEvidence)}
    ${chartSections}
  `;
};
