import React, { useState } from 'react';
import moment from 'moment-timezone';
import { DateRangePicker } from 'react-dates';

import style from '../patientSummary.module.scss';

const DatePickerSection = ({ date, handleChangeDate }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  return (
    <div className={style.datePickerBox}>
      <DateRangePicker
        displayFormat="MMM D"
        endDate={date.end}
        endDateId="date.end"
        focusedInput={focusedInput}
        isOutsideRange={date => date > moment().endOf('day')}
        onDatesChange={handleChangeDate}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        startDate={date.start}
        startDateId="date.start"
        minimumNights={0}
      />
    </div>
  );
};

export default DatePickerSection;
