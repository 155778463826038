import React from 'react';
import { alignAxis } from 'utils/chartUtils';
import Axis from '../Axis';
import style from '../Chart.module';
import MiddleLine from './components/MiddleLine';
import BarAndCircleList from './components/BarAndCircleList';
import { makeXScale, makeYScale, getUniqueValues } from './utils';

const BloodPressureChart = ({
  data = [],
  dimensions,
  xData,
  tz = {},
  axisIndex,
  axisAmount,
}) => {
  if (data.length !== 2) return null;

  const uniqueValues = getUniqueValues(data);

  const lowerXRange = axisAmount > 1 ? 30 : 0;
  const xRange = [lowerXRange, dimensions.boundedWidth];
  const xScale = makeXScale(xData, xRange);

  const yScale = makeYScale([dimensions.boundedHeight, 0]);

  const axisAligment = alignAxis(axisIndex, axisAmount);

  const middleProps = {
    yScale,
    xRange,
  };
  const barProps = {
    values: uniqueValues,
    xScale,
    yScale,
    tz,
  };

  return [
    <Axis
      key="bloodPressure-y-axis"
      dimension="y"
      scale={yScale}
      labelLine
      tickColor={style.bloodPressureLabel}
      {...axisAligment}
    />,
    <MiddleLine {...middleProps} />,
    <BarAndCircleList {...barProps} />,
  ];
};

export default BloodPressureChart;
