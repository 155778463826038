import React from 'react';

import { Notes } from 'components';
import Activity from './components/activity';
import SurveyAnswersPreview from './components/SurveyAnswersPreview';
// eslint-disable-next-line import/no-webpack-loader-syntax
import style from 'containers/patient/patient.module.scss';
import { useSelector } from 'react-redux';
const RightInfo = () => {
  const { id } = useSelector(state => state.currentPatient);
  return (
    <aside className={style.patientFeedContainer}>
      <Activity />
      <SurveyAnswersPreview />
      <Notes patientId={id} />
    </aside>
  );
};

export default RightInfo;
