import React, { useState } from 'react';
import { Accordion } from 'semantic-ui-react';

import AccordionData from './components/AccordionData';
import style from 'containers/patient/patient.module.scss';

const AccountAndInsurance = () => {
  const [activeAccordions, setActiveAccordions] = useState([]);

  const toggleAccordion = index => () => {
    if (activeAccordions.includes(index)) {
      setActiveAccordions([...activeAccordions.filter(item => index !== item)]);
    } else {
      setActiveAccordions([...activeAccordions, index]);
    }
  };

  const fields = [
    {
      label: 'Account Owner',
      value: 'individualId',
    },
    {
      label: 'Individual Id',
      value: 'medications',
    },
    {
      label: 'Account Name',
      value: 'accountName',
    },
    {
      label: 'Source System',
      value: 'sourceSystem',
    },
    {
      label: 'Medical Record Number',
      value: 'medicalRecordNumber',
    },
    {
      label: 'Source System Id',
      value: 'sourceSystemId',
    },
    {
      label: 'Date of Birth',
      value: 'birthdate',
    },
  ];
  return (
    <>
      <section className={style.infoSection}>
        <Accordion fluid styled exclusive={false}>
          <AccordionData
            active={activeAccordions.includes(0)}
            index={0}
            onClick={toggleAccordion(0)}
            fields={fields}
            title="Patient Account Details"
            iconName="dropdown"
          />
          <AccordionData
            active={activeAccordions.includes(1)}
            index={1}
            onClick={toggleAccordion(1)}
            fields={fields}
            title="Insurance Details"
            iconName="dropdown"
          />
        </Accordion>
      </section>
    </>
  );
};

export default AccountAndInsurance;
