import React from 'react';
import SingleChart from 'components/SingleChart';
import SummaryInfoRow from 'components/Viz/SummarySection/components/SummaryInfoRow';
import style from 'components/Viz/Viz.module.scss';
import { TICK_DATA_TYPES } from 'utils/constants';

import {
  glucoseSummary,
  bpSummary,
  defaultSummary,
} from 'utils/patientSummaryReport/factory/chartSummaries';

const ReportChart = ({ date, tz, tickData, chart }) => {
  const summaryByChart = {
    glucose: glucoseSummary(tickData),
    blood_pressure: bpSummary(tickData),
    weight: defaultSummary(TICK_DATA_TYPES[chart], 'Weight', tickData, 'lb'),
    hba1c: defaultSummary(TICK_DATA_TYPES[chart], 'HbA1c', tickData, '%'),
    ldl: defaultSummary(TICK_DATA_TYPES[chart], 'LDL', tickData, 'mg/dL'),
  };
  const summaryProps = summaryByChart[chart];
  const showDetails = date.end && date.start;
  return (
    <>
      <SingleChart date={date} chart={chart} tz={tz} tickData={tickData} />
      {showDetails && (
        <section className={style.detailsSection}>
          {summaryProps.map((rowProps, idx) => (
            <SummaryInfoRow key={idx} {...rowProps} />
          ))}
        </section>
      )}
    </>
  );
};

export default ReportChart;
