import React from 'react';
import style from 'containers/patient/patient.module.scss';
import MetadataNotes from '../MetadataNotes';
import { useDispatch } from 'react-redux';
import {
  deleteMealMetadata,
  setPublishedMealMetadata,
} from 'store/currentPatient/actions';
import LockAndDeleteButtons from 'components/LockAndDeleteButtons';
import RowFlexInfoSection from 'components/RowFlexInfoSection';
import LabelValueText from 'components/LabelValueText';

const MetadataCard = ({ metadata, mealId, clinicianId }) => {
  const dispatch = useDispatch();

  const handleDelete = metadataId => {
    const body = { metadataId, mealId };
    dispatch(deleteMealMetadata(body));
  };
  const handleCheck = () => {
    const body = {
      metadataId: metadata.id,
      mealId,
      published: !metadata.published,
    };
    dispatch(setPublishedMealMetadata(body));
  };

  const fields = ['type', 'value', 'unit', 'datetime'];
  const metadataOwnerId = metadata && metadata.user && metadata.user.id;

  return (
    <div className={style.metadataCard}>
      <LockAndDeleteButtons
        checked={metadata.published}
        canDelete={metadataOwnerId === clinicianId}
        handleDelete={handleDelete.bind(this, metadata.id)}
        handleCheck={handleCheck}
        canCheck
      />
      <RowFlexInfoSection title="Standard Information">
        {fields.map((field, idx) => (
          <LabelValueText
            key={idx}
            label={field}
            value={metadata[field] || '---'}
            className={style.metadataField}
            date={field === 'datetime'}
            dateFormat="DD-MM-YYYY HH:mm"
          />
        ))}
      </RowFlexInfoSection>
      <RowFlexInfoSection title="Added Information">
        {metadata.other.map((field, idx) => {
          const name = Object.keys(field)[0];
          return (
            <LabelValueText
              key={idx}
              label={name}
              value={field[name]}
              className={style.metadataField}
            />
          );
        })}
      </RowFlexInfoSection>
      <MetadataNotes
        notes={metadata.notes}
        id={metadata.id}
        ownerId={metadataOwnerId}
        clinicianId={clinicianId}
        mealId={mealId}
      />
    </div>
  );
};

MetadataCard.defaultProps = {
  metadata: [],
};

export default MetadataCard;
