import headerSection from './headerSection';
import introLetter from './introLetter';
import covidRiskSection from './covidRiskSection';
import symptomTrackingSection from './symptomTrackingSection';
import chartsSection from './chartsSection';
import activitySection from './activitySection';

export default (data, date, reportData, signatureUrl, tz) => {
  return `<section>
     ${headerSection(data, date)}
     ${introLetter(data, signatureUrl)}
     ${covidRiskSection(data)}
     ${symptomTrackingSection(data, date, reportData, tz)}
     ${chartsSection(data, date, reportData)}
     ${activitySection()}
    </section>`;
};
