import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'store';
import { createBrowserHistory } from 'history';
import { Loader } from 'semantic-ui-react';
import App from 'containers/app';
import 'react-dates/initialize';
import 'semantic-ui-less/semantic.less';
import 'styles/index.scss';

require('dotenv').config();

const target = document.querySelector('#root');
const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  history.replace(path);
}

render(
  <Provider store={store}>
    <PersistGate loading={<Loader active />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  target
);
