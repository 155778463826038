import React, {
  useState,
  createRef,
  useRef,
  useLayoutEffect,
  useEffect,
} from 'react';
import moment from 'moment-timezone';
import { isEmpty, debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { TICK_DATA_TYPES } from 'utils/constants';
import { fromDayToTzHour } from 'utils/timeToText';
import { fetchPatientTicks } from 'store/patients/actions';
import {
  fetchFhirImmunizations,
  fetchFhirObservations,
  fetchFhirPrescriptions,
  fetchFhirDiagnosticReports,
  fetchFhirCommunications,
  fetchFhirLabExams,
  fetchFhirLabTicks,
} from 'store/currentPatient/actions/fhir';
import Summary from './components/Summary';
import Conversation from './components/Conversation';
import DatePickerSection from './components/DatePickerSection';
import { Modal, Button } from 'semantic-ui-react';
import SummaryTemplate from 'components/PatientSummaryReport/SummaryTemplate';
import SummaryTextEditor from 'components/PatientSummaryReport';
import style from 'containers/patient/patient.module.scss';

const Report = ({ setActiveTabIndex }) => {
  const dispatch = useDispatch();
  const summaryEditorRef = createRef();
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState({ start: null, end: null });
  const [tableHeight, setTableHeight] = useState('40vh');
  const medicationsWrapper = useRef();
  const patientId = useSelector(state => state.currentPatient.id);
  const patientData = useSelector(state => state.currentPatient);
  const { data: timeZone = {} } = useSelector(
    state => state.currentPatient.timeZone
  );
  const tz = timeZone && !isEmpty(timeZone) ? timeZone.tz : '';
  const tickData = useSelector(state => state.patients.tickData);

  const hasEMR = useSelector(state => state.currentPatient.isFhirUser.data);

  const updateTicks = debounce(() => {
    if (date.start !== null && date.end !== null) {
      const startDate = fromDayToTzHour(tz, date.start, '00:00');
      const endDate = fromDayToTzHour(tz, date.end, '23:59');
      const types = ['glucose', 'blood_pressure', 'weight', 'sleep'].flatMap(
        chart => TICK_DATA_TYPES[chart]
      );
      const emrTypes = ['hba1c', 'ldl'].map(chart => TICK_DATA_TYPES[chart]);
      dispatch(
        fetchPatientTicks({
          patientId,
          types,
          startDate,
          endDate,
        })
      );
      dispatch(
        fetchFhirLabTicks({
          patientId,
          types: emrTypes,
          startDate,
          endDate,
        })
      );
    }
  }, 1000);

  useEffect(() => {
    if (patientId) {
      updateTicks();
    }
    // eslint-disable-next-line
  }, [patientId, date, dispatch]);

  useEffect(() => {
    if (patientId && hasEMR) {
      dispatch(fetchFhirImmunizations(patientId));
      dispatch(fetchFhirObservations(patientId));
      dispatch(fetchFhirLabExams(patientId));
      dispatch(fetchFhirPrescriptions(patientId));
      dispatch(fetchFhirDiagnosticReports(patientId));
      dispatch(fetchFhirCommunications(patientId));
    }
    // eslint-disable-next-line
  }, [patientId, hasEMR]);

  // eslint-disable-next-line
  useLayoutEffect(() => {
    const height = medicationsWrapper?.current?.offsetHeight;
    const newHeight = `${height}px`;
    if (height && newHeight !== tableHeight) {
      setTableHeight(newHeight);
    }
  });

  const handleChangeDate = ({ startDate, endDate }) => {
    const newStartDate = moment(endDate?.valueOf())?.startOf('day');
    setDate({
      start:
        startDate === endDate
          ? newStartDate
          : startDate?.startOf('day') || null,
      end: endDate?.endOf('day') || null,
    });
  };

  const medicationsTableStyle = {
    maxHeight: tableHeight,
  };

  const datePickerProps = { date, handleChangeDate };
  const summaryProps = {
    data: patientData,
    date,
    handleChangeDate,
    medicationsTableStyle,
    medicationsWrapper,
    tz,
    tickData,
  };
  const conversationProps = {
    patientData,
    date,
    tz,
    tickData,
    setActiveTabIndex,
  };
  const summaryReportData = SummaryTemplate(patientData, date, tz, tickData);

  const handlePrintClick = () => setIsOpen(true);
  const renderContent = () => {
    return (
      <SummaryTextEditor ref={summaryEditorRef} data={summaryReportData} />
    );
  };

  return (
    <section>
      <div className={style.buttonsHeaderBox}>
        <Conversation {...conversationProps} />
        <Button content="Print" secondary onClick={handlePrintClick} />
      </div>
      <DatePickerSection {...datePickerProps} />
      <Summary {...summaryProps} />
      <Modal
        size="large"
        className={style.editorModal}
        closeIcon
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Modal.Header>{'Patient Summary Report'}</Modal.Header>
        <Modal.Content className={style.editorContent} scrolling>
          {renderContent()}
        </Modal.Content>
      </Modal>
    </section>
  );
};

export default Report;
