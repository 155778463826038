import React from 'react';
import * as d3 from 'd3';
import { callAccessor } from 'utils/chartUtils';

const Bars = ({
  data,
  keyAccessor,
  xAccessor,
  yAccessor,
  width,
  widthAccessor,
  heightAccessor,
  rounded,
  fill = () => 1,
  style,
  ...props
}) => (
  <>
    {data.map((d, i) => {
      const height = d3.max([callAccessor(heightAccessor, d, i), 0]);
      return (
        <rect
          {...props}
          key={keyAccessor(d, i)}
          x={callAccessor(xAccessor, d, i)}
          y={callAccessor(yAccessor, d, i)}
          width={width || d3.max([callAccessor(widthAccessor, d, i), 0])}
          height={height}
          style={{
            fillOpacity: fill(height),
            ...style,
          }}
          rx={rounded ? 3 : 0}
          ry={rounded ? 3 : 0}
        />
      );
    })}
  </>
);

export default Bars;
