import { formatDate } from 'utils/fhirData';
import { prescriptionsFactory } from 'utils/patientSummaryReport/factory';

export default currentPatientPrescriptions => {
  const { data: prescriptions } = currentPatientPrescriptions;

  const { categories, categoriesObject, headerFields } = prescriptionsFactory(
    prescriptions
  );

  return `<section class='report-section'>
    <h2 class='section-title'> Medication List </h2>
    <table class="info-table" >
        <thead class="">
            <tr class="table-header">
            ${headerFields
              .map(content => `<th class="">${content}</th>`)
              .join('')}
            </tr>
        </thead>
        <tbody class="">
        ${categories
          .map(
            category => `
            <tr class="medications-category">
                <td>${category}</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            ${categoriesObject[category]
              .map(
                ({ name, dose, indications, date }) => `
            <tr >
                <td>
                  ${name || '---'}
                </td>
                <td>${dose}</td>
                <td>${indications}</td>
                <td>
                  ${formatDate(date)}
                </td>
              </tr>`
              )
              .join('')}
        `
          )
          .join('')}
        
        </tbody>
    </table>
    </section>`;
};
