import { combineReducers } from 'redux';

import newPatients from './new-patients';
import confirmedPatients from './confirmed-patients';
import cachedPatients from './cached-patients';
import tickData from './tick-data';
import summaryData from './summary-data';
import logPatients from './log-patients';

export default combineReducers({
  newPatients,
  confirmedPatients,
  cachedPatients,
  tickData,
  summaryData,
  logPatients,
});
