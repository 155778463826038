import { createReducer } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { handleActionStart, handleActionError } from 'store/utils';
import {
  tryAuthentication,
  failAunthentication,
  logoutUser,
  fetchAuthData,
  postAuthSignature,
  removeAlert,
  apiError,
  apiErrorReset,
} from 'store/authState/actions';
import { saveUserAlerts } from '../actions';

const initialState = {
  isLoading: false,
  error: null,
  data: {},
  apiErrors: null,
};

const authStateReducer = createReducer(initialState, {
  [tryAuthentication]: handleActionStart,
  [failAunthentication]: (state, action) => {
    state.error = action.payload;
    state.isLoading = false;
    state.data = {};
  },
  [logoutUser]: () => initialState,
  [fetchAuthData.STARTED]: handleActionStart,
  [fetchAuthData.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = false;
    state.data = action.payload;
  },
  [fetchAuthData.FAILED]: handleActionError,
  [postAuthSignature.STARTED]: handleActionStart,
  [postAuthSignature.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = false;
    state.data.signature = action.payload;
  },
  [postAuthSignature.FAILED]: handleActionError,
  [saveUserAlerts.STARTED]: handleActionStart,
  [saveUserAlerts.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = false;
    state.data.savedAlerts = action.payload;
  },
  [saveUserAlerts.FAILED]: handleActionError,
  [removeAlert]: (state, action) => {
    const alertToRemove = { ...action.payload };
    const newAlerts = state.data.savedAlerts.filter(
      alert =>
        alertToRemove.id !== alert.id && alertToRemove.summary !== alert.summary
    );
    state.data = {
      ...state.data,
      savedAlerts: [...newAlerts, alertToRemove],
    };
  },
  [apiError]: (state, action) => {
    if (!isEqual(state.apiError, action.payload.error)) {
      state.apiErrors = action.payload.error;
    }
  },
  [apiErrorReset]: state => {
    state.apiErrors = null;
  },
});

export default authStateReducer;
