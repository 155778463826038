import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { clearCurrentPatient } from 'store/currentPatient/actions';

import {
  fetchFhirDocumentContent,
  clearCurrentDocument,
} from 'store/currentPatient/actions/fhir';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchFhirDocumentContent.STARTED]: handleActionStart,
  [fetchFhirDocumentContent.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload;
  },
  [clearCurrentDocument]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = {};
  },
  [fetchFhirDocumentContent.FAILED]: handleActionError,
  [clearCurrentPatient]: () => initialState,
});
