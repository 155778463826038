import { SEVERITY_TYPES } from './constants';

/**
 *
 * @param {string} level
 * @description severity type leves
 * @param {bool} isAlert
 * @description if it is true color return on hex
 *
 */
const generateIconColorFromSeverity = (level, isAlert = false) => {
  switch (level) {
    case SEVERITY_TYPES.NEW:
      return {
        color: isAlert ? '#526B9E' : 'blue',
        icon: 'certificate',
      };
    case SEVERITY_TYPES.MIN_GREEN:
      return {
        color: isAlert ? '#51ADA8' : 'green',
        icon: 'minus',
      };
    case SEVERITY_TYPES.UP_GREEN:
      return {
        color: isAlert ? '#51ADA8' : 'green',
        icon: 'arrow up',
      };
    case SEVERITY_TYPES.UP_RED:
      return {
        color: isAlert ? '#E17764' : 'red',
        icon: 'arrow up',
      };
    case SEVERITY_TYPES.DOWN_RED:
      return {
        color: isAlert ? '#E17764' : 'red',
        icon: 'arrow down',
      };
    case SEVERITY_TYPES.DOWN_GREEN:
      return {
        color: isAlert ? '#51ADA8' : 'green',
        icon: 'arrow down',
      };
    case SEVERITY_TYPES.GREEN:
      return {
        color: isAlert ? '#51ADA8' : 'green',
        icon: 'exclamation triangle',
      };

    case SEVERITY_TYPES.YW:
      return {
        color: isAlert ? '#FFCF78' : 'orange',
        icon: 'exclamation triangle',
      };

    case SEVERITY_TYPES.RED:
    default:
      return {
        color: isAlert ? '#E17764' : 'red',
        icon: 'exclamation triangle',
      };
  }
};

export default generateIconColorFromSeverity;
