import { utcToTzFormat, utcToTzMomentObject } from './timezone';
export const callAccessor = (accessor, d, i) =>
  typeof accessor === 'function' ? accessor(d, i) : accessor;

export const combineChartDimensions = dimensions => {
  const parsedDimensions = {
    marginTop: 40,
    marginRight: 30,
    marginBottom: 40,
    marginLeft: 75,
    ...dimensions,
  };

  return {
    ...parsedDimensions,
    boundedHeight: Math.max(
      parsedDimensions.height -
      parsedDimensions.marginTop -
      parsedDimensions.marginBottom,
      0
    ),
    boundedWidth: Math.max(
      parsedDimensions.width -
      parsedDimensions.marginLeft -
      parsedDimensions.marginRight,
      0
    ),
  };
};

export const formatHour = (date, tz) => utcToTzFormat(date, tz, 'h:mm a');
export const formatDayMonth = (date, tz) => utcToTzFormat(date, tz, 'DD MMM');

export const alignAxis = (index, amountAxis) => {
  if (amountAxis <= 1) return {};
  if (index === 1)
    return {
      internalLabel: true,
    };

  return {};
};

let lastId = 0;
export const useUniqueId = (prefix = '') => {
  lastId++;
  return [prefix, lastId].join('-');
};

const divideTicksByDayHour = (tz, tick) => {
  const patientMomentPerspective = utcToTzMomentObject(tick.time, tz);
  const dayAndHour = patientMomentPerspective
    .clone()
    .minutes(0)
    .seconds(0);

  const time = dayAndHour.valueOf();

  const nextHour = dayAndHour.clone().add(1, 'hour');
  const secondsToHour = nextHour.diff(patientMomentPerspective) / 1000;

  if (secondsToHour < tick.duration) {
    const valuesPerSeconds = tick.value / tick.duration;
    const actualValue = valuesPerSeconds * secondsToHour;

    const actualTick = {
      time,
      value: actualValue,
    };

    const newTick = {
      time: nextHour.valueOf(),
      value: tick.value - actualValue,
      duration: tick.duration - secondsToHour,
    };
    return [actualTick].concat(divideTicksByDayHour(tz, newTick));
  }
  return { ...tick, time };
};

const groupInsulinTicksByTime = (ticksArray, metric, dateObject) => {
  ticksArray.forEach(tick => {
    if (!dateObject[tick.time]) {
      dateObject[tick.time] = {
        time: tick.time,
        basal: 0,
        bolus: 0,
      };
    }
    dateObject[tick.time][metric] += tick.value;
  });
};

export const combineInsulinMetrics = (metricsArray, tz) => {
  const dateByHours = {};
  const [basal, bolus] = metricsArray;

  const basalByDateHour = basal.flatMap(divideTicksByDayHour.bind(this, tz));
  const bolusByDateHour = bolus.flatMap(divideTicksByDayHour.bind(this, tz));

  groupInsulinTicksByTime(basalByDateHour, 'basal', dateByHours);
  groupInsulinTicksByTime(bolusByDateHour, 'bolus', dateByHours);
  return Object.values(dateByHours);
};
