import React from 'react';
import { Grid, Header, Table, Divider } from 'semantic-ui-react';
import { labsFactory } from 'utils/patientSummaryReport/factory';

import style from '../../../patientSummary.module.scss';

const Labs = ({ labExamsData, showHeader }) => {
  const { data: labExams, isLoading } = labExamsData;
  const {
    examsNames,
    episodeDates,
    episodeNames,
    episodesObject,
  } = labsFactory(labExams);

  return (
    <Grid>
      {showHeader ? (
        <Grid.Row>
          <Grid.Column className={style.headerTitle}>
            <Header content={'Labs'} />
            <Divider />
          </Grid.Column>
        </Grid.Row>
      ) : null}
      <Grid.Row>
        <Grid.Column>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <Table celled columns={4}>
              <Table.Header>
                <Table.Row>
                  {['Value', ...episodeDates].map((name, idx) => (
                    <Table.HeaderCell key={`${name}-${idx}`}>
                      {name === 'null' || name === 'Value' ? (
                        name
                      ) : (
                        <p>
                          {name}
                          <br />
                          Value - Unit - Ref Range
                        </p>
                      )}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {examsNames.map((name, key) => (
                  <Table.Row key={key}>
                    <Table.Cell>{name}</Table.Cell>
                    {episodeNames.map((episodeId, idx) => (
                      <Table.Cell key={episodeId + idx}>
                        {episodesObject[episodeId]?.[name] || '---'}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Labs;
