import React, { useState } from 'react';

import { Button, Confirm } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { useDispatch } from 'react-redux';
import { Redirect } from '@reach/router';
import {
  resetConfirmedPatients,
  resetNewPatients,
} from '../../../../../../../../store/patients/actions';
import style from '../../../../../../patient.module.scss';
import { HealthcloudApi } from '../../../../../../../../api';

export const DischargePatient = ({ patientId, name }) => {
  const [open, setOpen] = useState(false);
  const [goToTodayView, setGoToTodayView] = useState(false);

  const dispatch = useDispatch();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleDischargePatient = () => {
    HealthcloudApi.dischargePatient(patientId)
      .then(() => {
        toast({
          type: 'info',
          icon: 'check circle outline',
          title: 'Patient Discharged',
          description: `${name} was discharged`,
          time: 3000,
        });
        setOpen(false);
        dispatch(resetConfirmedPatients());
        dispatch(resetNewPatients());
        setGoToTodayView(true);
      })
      .catch(() => {
        toast({
          type: 'error',
          icon: 'close',
          title: 'Patient discharge failed',
          description: `Please try again later.`,
          time: 3000,
        });
      });
  };

  if (goToTodayView) return <Redirect to="/today" noThrow />;
  return (
    <>
      <Button
        content="Discharge Patient"
        primary
        onClick={toggleOpen}
        icon="clipboard outline"
        className={style.contactSupportButton}
      />
      <Confirm
        header={`You are about to discharge patient ${name}`}
        open={open}
        onCancel={toggleOpen}
        onConfirm={handleDischargePatient}
      />
    </>
  );
};
