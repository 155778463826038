import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appState from './appState/reducer';
import patients from './patients/reducer';
import auth from './authState/reducer';
import chatMessages from './chatMessages/reducer';
import currentPatient from './currentPatient/reducer';
import reports from './reports/reducer';
import staticData from './staticData/reducer';
import formsResources from './formsResources/reducer';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  auth,
  appState,
  currentPatient,
  patients,
  chatMessages,
  reports,
  formsResources,
  staticData,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
