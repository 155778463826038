import React, { useEffect, useState } from 'react';
import { Loader, Table, Icon, Message, Popup } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { TableFields } from 'utils/appointments/factory';
import AppointmentForm from '../AppointmentForm';
import { fetchAppointments } from 'store/currentPatient/actions/fhir';
import { setAppointmentStaticData } from 'store/staticData/actions';

import style from 'containers/patient/patient.module.scss';
import TablePaginationFooter from 'components/TablePaginationFooter';

const AppointmentsTable = () => {
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState(1);

  const { isLoading, error, data: appointments } = useSelector(
    state => state.currentPatient.appointments
  );
  const id = useSelector(state => state.currentPatient.id);

  /**
   * ----Pagination
   */
  const appointmentsByPage = 10;
  const appointmentsLength = appointments || 0;
  const totalPages = Math.ceil(appointmentsLength / appointmentsByPage);
  const from = (activePage - 1) * appointmentsByPage;
  const to = activePage * appointmentsByPage;

  /**
   * ----Visible Data
   */
  const fields = new TableFields();
  const appointmentsRange = fields
    .sortAppointmentsByDate(appointments)
    .slice(from, to);

  const handlePageChange = (_, { activePage }) => setActivePage(activePage);

  useEffect(() => {
    dispatch(setAppointmentStaticData());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) dispatch(fetchAppointments(id));
  }, [dispatch, id]);

  if (error) {
    return <Message color="red" header="Error" content={error} />;
  }
  return (
    <>
      {isLoading && (
        <Loader active inline="centered">
          Loading
        </Loader>
      )}
      {!isLoading && isEmpty(appointments) ? (
        <section className={style.appointmentSection}>
          <Table celled size="small" compact definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell>
                  <Message
                    header="Status"
                    content="Patient does not have appointments"
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <TablePaginationFooter colSpan={fields.labels.length + 1}>
              <AppointmentForm />
            </TablePaginationFooter>
          </Table>
        </section>
      ) : (
        <section className={style.appointmentSection}>
          <Table celled size="small" compact definition>
            <Table.Header>
              <Table.Row>
                {fields.labels.map((label, idx) => (
                  <Table.HeaderCell textAlign="center" key={`${label}-${idx}`}>
                    {label}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {appointmentsRange.map(appointment => (
                <Table.Row key={appointment.id}>
                  {fields.labels.map((label, idx) => (
                    <Table.Cell
                      key={`${label}-${idx}`}
                      textAlign={fields.isColor(label) ? 'center' : 'left'}
                    >
                      {fields.isPopup(label) ? (
                        <Popup
                          size="tiny"
                          content={fields.secondaryValue(label, appointment)}
                          trigger={
                            fields.isColor(label) ? (
                              <Icon
                                color={fields.value(label, appointment)}
                                name="circle"
                                size="large"
                              />
                            ) : (
                              <span className={style.dateValue}>
                                {fields.value(label, appointment)}
                              </span>
                            )
                          }
                        />
                      ) : (
                        <span
                          className={
                            fields.isDate(label) ? style.dateValue : {}
                          }
                        >
                          {fields.value(label, appointment)}
                        </span>
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
            <TablePaginationFooter
              colSpan={fields.labels.length}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            >
              <AppointmentForm />
            </TablePaginationFooter>
          </Table>
        </section>
      )}
    </>
  );
};

export default AppointmentsTable;
