import React from 'react';
import * as d3 from 'd3';
import { useChartDimensions } from './Chart';
import style from './Chart.module';

const axisComponentsByDimension = {
  x: AxisHorizontal,
  y: AxisVertical,
};
const Axis = ({ dimension = 'x', formatTick = d3.format(','), ...props }) => {
  const dimensions = useChartDimensions();
  const Component = axisComponentsByDimension[dimension];
  if (!Component) return null;

  return (
    <Component dimensions={dimensions} formatTick={formatTick} {...props} />
  );
};

export default Axis;

function AxisHorizontal({
  dimensions,
  ticksAmount,
  label,
  formatTick,
  scale,
  second,
  ...props
}) {
  const numberOfTicks = ticksAmount || dimensions.boundedWidth / 60;
  const ticks = scale.ticks(numberOfTicks);
  const heightPosition = Number(dimensions.boundedHeight) + (second ? 15 : 0);
  return (
    <g
      style={{ ...style.axis, ...style.axisHorizontal }}
      transform={`translate(0, ${heightPosition})`}
      {...props}
    >
      {second ? null : (
        <line style={style.axisLine} x2={dimensions.boundedWidth} />
      )}

      {ticks.map((tick, idx) => (
        <text
          key={`${tick}-${idx}`}
          style={style.axisTick}
          transform={`translate(${scale(tick)},25 )`}
        >
          {formatTick(tick)}
        </text>
      ))}

      {label && (
        <text
          style={style.axisLabel}
          transform={`translate(${dimensions.boundedWidth / 2}, 60)`}
        >
          {label}
        </text>
      )}
    </g>
  );
}

function AxisVertical({
  dimensions,
  formatTick,
  grid,
  internalLabel,
  label,
  labelLine,
  lineClass = {},
  noLine,
  rightAligned,
  rotate,
  scale,
  tickColor = {},
  showTicks = true,
  tickValues,
  ...props
}) {
  const numberOfTicks = dimensions.boundedHeight / 70;
  let ticks;
  if (tickValues) {
    ticks = tickValues;
  } else {
    ticks = scale.ticks ? scale.ticks(numberOfTicks) : scale.domain();
  }

  const transformLine = rightAligned
    ? `translate(${dimensions.boundedWidth})`
    : '';

  return (
    <g style={{ ...style.axis, ...style.axisVertical }} {...props}>
      {!noLine && (
        <line
          transform={transformLine}
          style={{ ...style.axisLine, ...lineClass }}
          y2={dimensions.boundedHeight}
        />
      )}

      {ticks.map((tick, i) => {
        const translateLabel = (() => {
          let xPosition = -10;
          if (rightAligned) xPosition = dimensions.boundedWidth + 25;
          if (rightAligned && !rotate) xPosition = dimensions.boundedWidth + 45;
          if (internalLabel) xPosition = 25;
          let yPosition = scale(tick);
          if (rotate) yPosition -= 50;
          if (!rotate) yPosition -= 10;
          return `translate(${xPosition}, ${yPosition})`;
        })();
        const rotateLabel = (() => {
          if (rotate && !rightAligned) return 'rotate(-90)';
          if (rotate && rightAligned) return 'rotate(90)';
          return '';
        })();
        const transformLabel = `${translateLabel} ${rotateLabel}`;

        const translateLabelLineX = (() => {
          if (rightAligned) return dimensions.boundedWidth;
          if (internalLabel) return 0;
          return -16;
        })();
        return [
          showTicks && (
            <text
              key={tick}
              style={{ ...style.axisTick, ...tickColor }}
              transform={transformLabel}
            >
              {formatTick(tick)}
            </text>
          ),
          grid && i !== ticks.length && (
            <line
              key={`${tick}-grid-line`}
              transform={`translate(0, ${scale(tick)})`}
              style={{ ...style.gridLine, ...lineClass }}
              x2={dimensions.boundedWidth}
            />
          ),
          labelLine && i !== ticks.length && (
            <line
              key={`${tick}-label-line`}
              transform={`translate(${translateLabelLineX}, ${scale(tick)})`}
              style={{ ...style.gridLine, ...lineClass }}
              x2={16}
            />
          ),
        ];
      })}

      {label && (
        <text
          style={{
            ...style.axisLabel,
            transform: `translate(-56px, ${dimensions.boundedHeight /
              2}px) rotate(-90deg)`,
          }}
        >
          {label}
        </text>
      )}
    </g>
  );
}
