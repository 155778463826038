import React from 'react';
import { Link } from '@reach/router';
import { Button, Icon } from 'semantic-ui-react';
import style from '../../header.module.scss';
const PatientsTab = ({ getHeaderText, handleClose, patientTabs, location }) => {
  return (
    <div className={style.tabsView}>
      {patientTabs.map(tab => (
        <Link to={`/patient/${tab.id}`} key={tab.id}>
          <span className={style.tabText}>{getHeaderText(tab)}</span>
          <Button
            icon
            className={style.tabClose}
            onClick={e => handleClose(e, tab.id, location)}
          >
            <Icon name="close" />
          </Button>
        </Link>
      ))}
    </div>
  );
};

export default PatientsTab;
