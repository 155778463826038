import React from 'react';
import * as d3 from 'd3';
import Axis from './Axis';
import style from './Chart.module';

const sleepPhases = {
  1: 'AWAKE',
  2: 'LIGHT',
  3: 'REM',
  4: 'DEEP',
};

const sleepColors = {
  '1': style.awakeColor,
  '2': style.lightColor,
  '3': style.remColor,
  '4': style.deepColor,
};

const mapRawData = data => {
  const { time: firstTimestamp, value: firstValue } = data[0];
  const initialValue = {
    startTime: firstTimestamp,
    phase: firstValue.toString(),
  };
  const validValues = Object.keys(sleepPhases);
  return data.reduce(
    (mappedData, currentTick) => {
      const lastIndex = mappedData.length - 1;
      const { phase: previousValue } = mappedData[lastIndex];
      const {
        time: currentTickTimestamp,
        value: currentTickValue,
      } = currentTick;
      if (
        previousValue !== currentTickValue.toString() &&
        validValues.includes(currentTickValue.toString())
      ) {
        mappedData[lastIndex].endTime = currentTickTimestamp;
        const newSegment = {
          startTime: currentTickTimestamp,
          phase: currentTickValue.toString(),
        };
        mappedData.push(newSegment);
      }
      return mappedData;
    },
    [initialValue]
  );
};

const SleepDepthLevelChart = ({ data = [], dimensions, xData, axisAmount }) => {
  if (!data.length) return null;
  const mappedData = mapRawData(data);
  const lastTickIndex = mappedData.length - 1;
  mappedData[lastTickIndex].endTime = data[data.length - 1].time;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleLinear()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const barHeight = dimensions.boundedHeight / 4;

  const yScale = d3
    .scaleOrdinal()
    .domain(['DEEP', 'REM', 'LIGHT', 'AWAKE'])
    .range([dimensions.boundedHeight, barHeight * 3, barHeight * 2, barHeight]);

  return [
    <Axis
      key="sleep-y-axis"
      dimension="y"
      scale={yScale}
      formatTick={s => s.toString()}
      noLine
      grid
      rotate
      rightAligned
    />,
    ...mappedData.map((d, i) => {
      const { startTime, endTime, phase } = d;
      const sleepPhase = sleepPhases[phase];
      if (!sleepPhase) return null;
      return (
        <rect
          style={sleepColors[phase]}
          key={`${phase}-${i}`}
          x={xScale(startTime)}
          y={yScale(sleepPhase) - barHeight}
          width={xScale(endTime) - xScale(startTime)}
          height={barHeight}
          data-index={i}
        />
      );
    }),
  ];
};

const SleepDepthLevelChartDayTrend = ({
  data = [],
  dimensions,
  xData,
  axisIndex,
  axisAmount,
}) => {
  if (!data.length) return null;
  const mappedData = mapRawData(data);
  const lastTickIndex = mappedData.length - 1;
  mappedData[lastTickIndex].endTime = data[data.length - 1].time;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleLinear()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const barHeight = dimensions.boundedHeight / 4;

  const yScale = d3
    .scaleOrdinal()
    .domain(['DEEP', 'REM', 'LIGHT', 'AWAKE'])
    .range([dimensions.boundedHeight, barHeight * 3, barHeight * 2, barHeight]);

  return [
    <Axis
      key={`overlayed-sleep-y-axis-${axisIndex}`}
      dimension="y"
      scale={yScale}
      formatTick={s => s.toString()}
      lineClass={style.whiteAxisLine}
      noLine
      grid
      rightAligned
    />,
    ...mappedData.map((d, i) => {
      const { startTime, endTime, phase } = d;
      const sleepPhase = sleepPhases[phase];
      if (!sleepPhase) return null;
      return (
        <rect
          style={sleepColors[phase]}
          key={`${phase}-${i}`}
          x={xScale(startTime)}
          y={yScale(sleepPhase) - barHeight}
          width={xScale(endTime) - xScale(startTime)}
          height={barHeight}
        />
      );
    }),
  ];
};

export { SleepDepthLevelChart, SleepDepthLevelChartDayTrend };
