import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Message } from 'semantic-ui-react';
import cx from 'classnames';
import { updateUserGroups } from 'store/currentPatient/actions';
import { setCovidStatus } from 'store/patients/actions';
import styles from 'containers/proximity-log/proximityLog.module.scss';

const statusOptions = [
  {
    key: 'n/a',
    text: 'Does not qualify',
    value: 'n/a',
  },
  {
    key: 'pcr_tested_pos',
    text: 'Positive',
    value: 'pcr_tested_pos',
  },
  {
    key: 'pcr_tested_neg',
    text: 'Negative',
    value: 'pcr_tested_neg',
  },
  {
    key: 'pcr_test_pending',
    text: 'Pending',
    value: 'pcr_test_pending',
  },
  {
    key: 'antibody_pos',
    text: 'Antibody Positive',
    value: 'antibody_pos',
  },
  {
    key: 'antibody_neg',
    text: 'Antibody Negative',
    value: 'antibody_neg',
  },
];

const testGroups = [
  'antibody_neg',
  'antibody_pos',
  'pcr_tested_pos',
  'pcr_tested_neg',
  'pcr_test_pending',
];

const CovidTest = ({ patientId }) => {
  const dispatch = useDispatch();
  const [testStatus, setTestStatus] = useState('');
  const {
    data: { groups },
  } = useSelector(state => state.currentPatient.groups);

  useEffect(() => {
    const newTestStatus =
      groups.find(group => testGroups.includes(group)) || 'n/a';
    setTestStatus(newTestStatus);
  }, [groups]);

  const handleStatusChange = (_, { value }) => {
    let covidStatus = statusOptions.find(option => option.value === value).text;
    if (covidStatus === 'Does not qualify') {
      covidStatus = 'N/A';
    }
    const groupToRemove = testStatus !== 'n/a' ? testStatus : '';
    const groupToAdd = value !== 'n/a' ? value : '';
    dispatch(
      updateUserGroups({
        id: patientId,
        remove: groupToRemove,
        add: groupToAdd,
      })
    );
    dispatch(setCovidStatus({ id: patientId, data: covidStatus }));
    setTestStatus(value);
  };

  return (
    <Message className={cx(styles.covidAlerts, styles.biometrics, styles.test)}>
      <Dropdown
        as="h4"
        onChange={handleStatusChange}
        options={statusOptions}
        fluid
        value={testStatus}
      />
    </Message>
  );
};

export default CovidTest;
