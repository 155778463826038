import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { fetchPatientTicks, fetchPatientAllTicks } from 'store/reports/actions';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchPatientTicks.STARTED]: handleActionStart,
  [fetchPatientAllTicks.STARTED]: handleActionStart,
  [fetchPatientTicks.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload;
  },
  [fetchPatientAllTicks.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload;
  },
  [fetchPatientTicks.FAILED]: handleActionError,
  [fetchPatientAllTicks.FAILED]: handleActionError,
});
