import React from 'react';
import { Icon, Popup, Accordion } from 'semantic-ui-react';
import style from 'containers/patient/patient.module.scss';

const DayTitle = ({ day, active, onClick }) => {
  const { date, periods } = day;

  return (
    <Accordion.Title
      className={style.accordionTitle}
      active={active}
      onClick={onClick}
    >
      <section className={style.availableDayTitle}>
        <span>
          <Icon name="dropdown" />
          {date.format('YYYY/MM/DD')}
        </span>
        <div className={style.availableDayTitleCircles}>
          {periods.map(({ slot, color }) => (
            <Popup
              content={slot}
              trigger={<Icon name="circle" color={color} />}
            />
          ))}
        </div>
      </section>
    </Accordion.Title>
  );
};

export default DayTitle;
