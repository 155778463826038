/*
 * 1 longitude/latitude degree is equals to ~111 km
 * so 1/1000 of a degree is ~ 111 m, if we make a circle of this radius
 * around the geofence point, we will have a 250m of movement available
 * for the user.
 */
const areClose = (basePoint, comparePoint) => {
  const lowerBaseLatBound = basePoint[0] - 1 / 1000;
  const upperBaseLatBound = basePoint[0] + 1 / 1000;
  const lowerBaseLngBound = basePoint[1] - 1 / 1000;
  const upperBaseLngBound = basePoint[1] + 1 / 1000;

  return (
    comparePoint[0] > lowerBaseLatBound &&
    comparePoint[0] < upperBaseLatBound &&
    comparePoint[1] > lowerBaseLngBound &&
    comparePoint[1] < upperBaseLngBound
  );
};

export { areClose };
