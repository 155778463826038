import React from 'react';
import { Modal, Header } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { colorByStatus } from 'utils/fhirData';
import { clearCurrentEncounter } from 'store/currentPatient/actions/fhir';
import TabPanel from './components/TabPanel';
import StatusIcon from '../StatusIcon';
import style from 'containers/patient/components/CentralInfo/components/Appointments/appointments.module.scss';

const SingleEncounterModal = () => {
  const dispatch = useDispatch();
  const { isLoading, data: encounter } = useSelector(
    state => state.currentPatient.currentEncounter
  );
  const handleClose = () => dispatch(clearCurrentEncounter());

  const open = isLoading || !isEmpty(encounter);

  return (
    <Modal size="small" closeIcon onClose={handleClose} open={open}>
      <Modal.Header>{`Encounter's details ( Identifier: ${
        isLoading ? '...' : encounter.identifier
      })`}</Modal.Header>
      <Modal.Content scrolling>
        {isLoading ? <p>Loading...</p> : null}
        {isEmpty(encounter) ? null : (
          <section className={style.detailsHeader}>
            <StatusIcon
              text={encounter.status}
              color={colorByStatus[encounter.status]}
            />
            <Header content={encounter.class} />
          </section>
        )}
        <TabPanel encounter={encounter} />
      </Modal.Content>
      <Modal.Actions />
    </Modal>
  );
};

export default SingleEncounterModal;
