import React from 'react';
import { associatedDataParser } from 'utils/fhirData';
import FhirFieldDrawer from './FhirFieldDrawer';

const HOCFieldParser = (fieldParser, component) => ({ data = [] }) => {
  const dataParser = item => ({
    fields: fieldParser(item),
    associatedData: associatedDataParser(item),
  });

  const parsedData = Array.isArray(data)
    ? data.map(item => dataParser(item))
    : [];
  const Renderer = component || FhirFieldDrawer;
  return <Renderer data={parsedData} />;
};

export default HOCFieldParser;
