import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import {
  fetchUserGroups,
  setCurrentPatient,
  clearCurrentPatient,
  updateUserGroups,
} from 'store/currentPatient/actions';

const initialState = {
  data: {
    groups: [],
  },
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchUserGroups.STARTED]: handleActionStart,
  [updateUserGroups.STARTED]: handleActionError,
  [fetchUserGroups.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data.groups = action.payload.data;
  },
  [updateUserGroups.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data.groups = action.payload.data;
  },
  [fetchUserGroups.FAILED]: handleActionError,
  [updateUserGroups.FAILED]: handleActionError,
  [setCurrentPatient]: (_, action) => action.payload.data,
  [clearCurrentPatient]: () => initialState,
});
