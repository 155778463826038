import React from 'react';
import cx from 'classnames';
import moment from 'moment-timezone';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import style from '../../covid.module.scss';

const OtherLocations = ({ otherLocations, locationClick, timezone }) => {
  const sortedOtherLocations = otherLocations
    .slice()
    .sort((a, b) => moment(a.starTime).diff(moment(b.starTime)) * -1);

  const renderRow = (location, key) => {
    if (isEmpty(location) || !location) return null;
    const { center, startTime, endTime, interactions } = location;
    const [latitude, longitude] = center;
    const totalDuration = moment(endTime).diff(moment(startTime), 'minutes');
    const durationHrs = Math.round(totalDuration / 60);
    const durationMins = Math.round(totalDuration % 60);
    const duration = durationHrs
      ? `${durationHrs} hrs ${durationMins} min`
      : `${durationMins || 1} min`;
    const date = moment(startTime)
      .tz(timezone)
      .format('YYYY-MM-DD HH:mm');

    return (
      <div
        key={`location-${key}`}
        className={cx(style.rowContainer, style.row)}
      >
        <div className={style.locationContainer}>
          <Icon
            name="map marker alternate"
            size="big"
            onClick={() => locationClick([latitude, longitude])}
            className={style.locationIcon}
          />
          <div className={style.viewLocation}>
            <p>{latitude}</p>
            <p>{longitude}</p>
          </div>
        </div>
        <div>{duration}</div>
        <div>{date}</div>
        <div>{interactions} People</div>
      </div>
    );
  };

  const renderHeader = () => (
    <div className={cx(style.rowContainer, style.header)}>
      <div>Location</div>
      <div>Duration</div>
      <div>Date & Time</div>
      <div>Interactions</div>
    </div>
  );

  return (
    <section className={style.otherLocations}>
      {renderHeader()}
      {sortedOtherLocations.map(renderRow)}
    </section>
  );
};

OtherLocations.propTypes = {
  otherLocations: PropTypes.arrayOf(
    PropTypes.shape({
      center: PropTypes.array,
      startTime: PropTypes.number,
      endTime: PropTypes.number,
      interactions: PropTypes.number,
    })
  ),
  locationClick: PropTypes.func,
  timezone: PropTypes.string,
};

OtherLocations.defaultProps = {
  otherLocations: [],
  locationClick: () => null,
  timezone: '',
};

export default OtherLocations;
