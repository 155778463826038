import React from 'react';
const MiddleLine = ({ xRange, yScale }) => (
  <g>
    <line
      x1={xRange[0]}
      y1={yScale(110)}
      x2={xRange[1]}
      y2={yScale(110)}
      stroke={'darkgray'}
      stroke-dasharray={'4'}
    />
    <text
      transform={`translate(${xRange[0] + 5},${yScale(125)})`}
      fill={'#545454'}
      style={{
        fontSize: '0.8em',
      }}
    >
      Sys
    </text>
    <text
      transform={`translate(${xRange[0] + 5},${yScale(95)})`}
      fill={'#545454'}
      style={{
        fontSize: '0.8em',
      }}
    >
      Dia
    </text>
  </g>
);

export default MiddleLine;
