import React from 'react';
import cx from 'classnames';
import { Icon } from 'semantic-ui-react';
import style from 'components/Viz/Viz.module.scss';

const SummaryInfoRow = ({
  key,
  topLeftText,
  topLeftValue,
  bottomLeftText,
  bottomLeftValue,
  centerText,
  centerValue,
  centerIcon,
  topRightText,
  topRightValue,
  bottomRightText,
  bottomRightValue,
}) => (
  <div key={key} className={style.detailsInfoSection}>
    <div className={style.infoSection}>
      <p className={style.infoValue}>{topLeftValue}</p>
      <p className={style.infoLabel}>{topLeftText}</p>
      {bottomLeftText && [
        <p className={style.infoValue}>{bottomLeftValue}</p>,
        <p className={style.infoLabel}>{bottomLeftText}</p>,
      ]}
    </div>
    <div className={cx(style.middleInfoSection, style.infoSection)}>
      <p className={style.infoValue}>{centerValue}</p>
      <p className={style.infoLabel}>{centerText}</p>
      <Icon disabled name={centerIcon} size="large" />
    </div>
    <div className={cx(style.rightInfoSection, style.infoSection)}>
      <p className={style.infoValue}>{topRightValue}</p>
      <p className={style.infoLabel}>{topRightText}</p>
      {bottomRightText && [
        <p className={style.infoValue}>{bottomRightValue}</p>,
        <p className={style.infoLabel}>{bottomRightText}</p>,
      ]}
    </div>
  </div>
);

export default SummaryInfoRow;
