import React from 'react';
import * as d3 from 'd3';
import { combineInsulinMetrics } from 'utils/chartUtils';
import GridLine from '../GridLine';
import style from '../Chart.module';
import CombinedInsulinBars from './components/CombinedInsulinBars';

const InsulinChart = ({ data = [], dimensions, xData, axisAmount, tz }) => {
  if (data.length !== 2) return null;

  const newData = combineInsulinMetrics(data, tz);

  const xAccessor = d => d.time;
  const yAccessor = (d, value) => d[value];

  const keyAccessor = (value, i) => `${value}-${i}`;

  const lowerXRange = axisAmount > 1 ? 30 : 0;
  const xDomain = d3.extent(xData);

  const xScale = d3
    .scaleTime()
    .domain(xDomain)
    .range([lowerXRange, dimensions.boundedWidth]);

  const sectionSize = dimensions.boundedHeight / 4;

  const yScale = d3
    .scaleLinear()
    .domain([0, 20, 60, 200])
    .range([sectionSize * 4, sectionSize * 3, sectionSize, 0])
    .nice();

  const barPadding = 4;

  const oneHourWidth = xScale(xDomain[0] + 3600000) - xScale(xDomain[0]);
  const barWidth = oneHourWidth > 6 ? oneHourWidth : 6;

  const xAccessorScaled = d => xScale(xAccessor(d)) + barPadding;
  const yAccessorScaled = (d, value, forHeight) => {
    if (forHeight) {
      return yScale(yAccessor(d, value));
    }
    return value === 'bolus'
      ? yScale(d.basal + d.bolus)
      : yScale(yAccessor(d, value));
  };

  const heightAccessorScaled = (d, value) => {
    const height = dimensions.boundedHeight;
    return height - yAccessorScaled(d, value, true);
  };

  return [
    <GridLine
      lineClass={style.insulinGridLine}
      position={yScale(60)}
      label="60 U"
      dashed
      labelRightAligned
    />,
    <GridLine
      lineClass={style.insulinGridLine}
      position={yScale(20)}
      label="20 U"
      dashed
      labelRightAligned
    />,
    <CombinedInsulinBars
      data={newData}
      heightAccessor={heightAccessorScaled}
      keyAccessor={keyAccessor}
      width={barWidth}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      tz={tz}
    />,
  ];
};

export { InsulinChart };
