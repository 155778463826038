import React from 'react';
import { Link } from '@reach/router';
import style from '../../header.module.scss';
const MenuTab = () => {
  return (
    <section className={style.tabsView}>
      <Link to="/today">
        <span className={style.tabText}>Today</span>
      </Link>
      <Link to="/panel">
        <span className={style.tabText}>Panel View</span>
      </Link>
    </section>
  );
};

export default MenuTab;
