import React from 'react';
import { labelize } from 'utils/textFormats';
import { getTzFormat } from 'utils/mealsUtils';
import { utcToTz } from 'utils/timezone';
import { useSelector } from 'react-redux';
const LabelValueText = ({ label, value, date, dateFormat, ...rest }) => {
  const {
    data: { tz },
  } = useSelector(state => state.currentPatient.timeZone);
  return (
    <p {...rest}>
      <b>{`${labelize(label)} `}</b>
      {date ? getTzFormat(utcToTz(value, tz), tz, 'YYYY-MM-DD HH:mm') : value}
    </p>
  );
};

LabelValueText.defaultProps = {
  metadata: [],
};

export default LabelValueText;
