import { merge } from 'lodash';
import configuredAxios from 'api/axios.config';

const TIMEBANDINT_ENDPOINT = process.env.REACT_APP_TIMEBANDIT_API_URL;

class TimebanditAPI {
  async getTickData({ patientId, date, startDate, endDate, types = [] }) {
    if (!types.length) return [];
    try {
      const metrics = types.join(',');

      const params = new URLSearchParams();
      if (date) params.append('date', date);
      if (startDate) params.append('start_time', startDate);
      if (endDate) params.append('end_time', endDate);
      params.append('metrics', metrics);

      const tickData = await configuredAxios.get(
        TIMEBANDINT_ENDPOINT + `/users/${patientId}/ticks?${params}`
      );
      return types
        .map(type => {
          if (!tickData[type]) return { [type]: [] };
          if (type === 'location') {
            const ticks = tickData[type]
              .map(this.transformTickData)
              .filter(this.cleanLocationTick);
            return {
              [type]: ticks,
            };
          }

          if (type === 'band_removed') {
            return {
              [type]: tickData[type].map(this.transformTickData),
            };
          }

          return {
            [type]: tickData[type]
              .map(this.transformTickData)
              .filter(tick => tick.value !== 0),
          };
        })
        .reduce(merge);
    } catch (error) {
      return {};
    }
  }

  async getSleepSummary({ patientId, startDate, endDate }) {
    try {
      const params = new URLSearchParams();
      params.append('start_date', startDate);
      params.append('end_date', endDate);

      const sleepResponse = await configuredAxios.get(
        TIMEBANDINT_ENDPOINT + `/users/${patientId}/sleeps?${params}`
      );
      return sleepResponse.map(sleep => ({
        awakeTime: sleep.awake ?? 0,
        lightTime: sleep.light ?? 0,
        remTime: sleep.rem ?? 0,
        deepTime: sleep.deep ?? 0,
        fellAsleepHour: sleep.time_started ?? '--',
        wokeUpHour: sleep.time_completed ?? '--',
        wokeUpTimes: sleep.short_disturbances ?? 0,
        timeToSleep: sleep.smoothed_stages[0][0]
          ? sleep.time_started - sleep.smoothed_stages[0][0]
          : 0,
      }));
    } catch (error) {
      return {};
    }
  }

  async getMoveSummary({ patientId, startDate, endDate }) {
    try {
      const params = new URLSearchParams();
      params.append('start_date', startDate);
      params.append('end_date', endDate);

      const movesResponse = await configuredAxios.get(
        TIMEBANDINT_ENDPOINT + `/users/${patientId}/moves?${params}`
      );
      return movesResponse.map(moves => ({
        longestActiveTime: moves.longest_active ?? 0,
        longestIdleTime: moves.longest_idle ?? 0,
        totalActiveTime: moves.total_active ?? 0,
        activeBurnCal: moves.active_burn_calories ?? '0',
        restBurnCal: moves.rest_burn_calories ?? '0',
        totalCal: moves.tot_calories ?? '0',
      }));
    } catch (error) {
      return {};
    }
  }

  async getHeartRateSummary({ patientId, startDate, endDate }) {
    try {
      const params = new URLSearchParams();
      params.append('start_date', startDate);
      params.append('end_date', endDate);

      const hrResponse = await configuredAxios.get(
        TIMEBANDINT_ENDPOINT + `/users/${patientId}/heartrates?${params}`
      );
      return hrResponse.map(heartRate => ({
        minHr: heartRate.min_bg ?? '0',
        maxHr: heartRate.max_bg ?? '0',
        avgHr: heartRate.avg_bg ?? '0',
      }));
    } catch (error) {
      return {};
    }
  }

  transformTickData(tick) {
    return {
      time: tick[0] * 1000,
      value: tick[1],
      duration: tick[2],
    };
  }

  cleanLocationTick(tick) {
    return tick.value[0] !== 0 && tick.value[1] !== 0;
  }
}

export default TimebanditAPI;
