import React, { useEffect, useState, useRef } from 'react';
import { Form } from 'semantic-ui-react';

const Checkbox = ({ extraText, setAnswer }) => {
  const defaultAnswer = extraText.compiledAnswers || [];
  const [arrayOptions, setArrayOptions] = useState(defaultAnswer);
  const [messageError, setMessageError] = useState(false);
  const initialMount = useRef(true);

  useEffect(() => {
    if (!initialMount.current) {
      initialMount.current = false;
    } else if (arrayOptions.length) {
      setMessageError(false);
      setAnswer(arrayOptions);
    }
  }, [arrayOptions]); // eslint-disable-line

  const checkBoxChange = (e, { value }) => {
    if (value !== undefined) {
      if (arrayOptions.includes(value)) {
        if (arrayOptions.length > 1) {
          setArrayOptions(arrayOptions.filter(item => item !== value));
        } else {
          setMessageError(true);
        }
      } else {
        setArrayOptions([...arrayOptions, value]);
      }
    }
  };

  return (
    <>
      <Form>
        <Form.Group>
          {extraText.options.map(option => (
            <Form.Checkbox
              key={option.id}
              onChange={checkBoxChange}
              label={option.text}
              value={option.position - 1}
              checked={arrayOptions.includes(option.position - 1)}
            />
          ))}
        </Form.Group>
      </Form>
      {messageError && (
        <div className="ui warning message">You must select at least one</div>
      )}
    </>
  );
};

export default Checkbox;
