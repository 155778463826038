import React from 'react';
import cx from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import style from '../../covid.module.scss';

const TimeAtHome = ({ data, effectiveness, timezone }) => {
  const renderBar = ({ date, effectiveness: dayEffectiveness }) => {
    const percentage = data.length ? `${dayEffectiveness}%` : 0;
    const percentageLabel = dayEffectiveness
      ? `${dayEffectiveness.toFixed(0)}%`
      : 'N/A';

    return [
      <div key={date} className={style.bar}>
        <span
          className={cx(style.barLabel, {
            [style.barEmptyLabel]: !dayEffectiveness,
          })}
        >
          {percentageLabel}
        </span>
        <div className={style.barFiller} style={{ height: percentage }} />
        <span className={style.barDateLabel}>
          {moment(date)
            .tz(timezone)
            .format('MM.DD')}
        </span>
      </div>,
      <div key={`${date}-separator`} className={style.barSeparator} />,
    ];
  };

  return (
    <section className={cx(style.covidSection, style.timeAtHome)}>
      <div className={style.barsContainer}>
        {data.map(renderBar)}
        <div className={style.effectivenessContainer}>
          <span className={style.effectivenessPercentage}>
            {effectiveness.toFixed(1)}%
          </span>
          <span>Quarantine Effectiveness: 7 Days</span>
        </div>
      </div>
    </section>
  );
};

TimeAtHome.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  effectiveness: PropTypes.number,
  timezone: PropTypes.string,
};

TimeAtHome.defaultProps = {
  data: [],
  effectiveness: 0,
  timezone: '',
};

export default TimeAtHome;
