import { capitalize } from 'lodash';
import moment from 'moment';

export const labelize = (text, withDots = true) =>
  `${capitalize(
    text
      .replace(/_/g, ' ')
      .replace(/[A-Z]/g, v => ` ${v}`)
      .trim()
  )}${withDots ? ' :' : ''}`;

export const format = (date, stringFormat = 'YYYY-MM-DD') =>
  moment(date).format(stringFormat);

export const capitalizeWords = text =>
  typeof text === 'string' && text
    ? text.replace(/^[a-z]|\s[a-z]/g, val => val.toUpperCase())
    : text;
