import { createReducer } from '@reduxjs/toolkit';
import {
  notifyNewMessages,
  clearNewMessages,
} from 'store/chatMessages/actions';

// initialState is set this way in order to make it scalable
const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [notifyNewMessages]: (state, action) => {
    state.data[action.payload] = {
      newMessages: true,
    };
  },
  [clearNewMessages]: (state, action) => {
    state.data[action.payload] = {
      newMessages: false,
    };
  },
});
