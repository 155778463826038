import React, { useState, useEffect } from 'react';
import { Grid, Icon, Button, Dropdown, Popup } from 'semantic-ui-react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize } from 'lodash';
import { setHealthPassport } from 'store/currentPatient/actions';
import { ALERT_TRESHOLDS } from 'utils/constants';
import style from 'containers/patient/components/CentralInfo/components/covid.module.scss';

const roundHealthPassportValues = val =>
  ALERT_TRESHOLDS['Covid 19']
    .map(({ floatThreshold }) => floatThreshold)
    .filter(optionValue => optionValue <= val)
    .reverse()[0] || 0.01;

const getDisplayPassportStatus = value => {
  if (value < 0.34)
    return {
      text: 'Green',
      color: style.lowValue,
    };
  if (value < 0.68)
    return {
      text: 'Yellow',
      color: style.mediumValue,
    };
  return {
    text: 'Red',
    color: style.highValue,
  };
};

const HealthPassport = ({ patientId }) => {
  const dispatch = useDispatch();
  const [passportStatus, setPassportStatus] = useState(0.01);
  const [displayPassportStatus, setDisplayPassportStatus] = useState({});
  const [editablePassport, setEditablePassport] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);

  /**
   * ---- STATE
   */

  const {
    healthPassport,
    data: { biometrics },
  } = useSelector(state => state.currentPatient.covid19);

  useEffect(() => {
    const parsedValue = roundHealthPassportValues(healthPassport.status);
    if (parsedValue !== passportStatus) {
      if (healthPassport.overridden) {
        setPassportStatus(parsedValue);
      } else {
        setPassportStatus('system_value');
      }
    }

    const newDisplayPassportStatus = getDisplayPassportStatus(parsedValue);

    setDisplayPassportStatus({
      ...newDisplayPassportStatus,
      overridden: healthPassport.overridden,
    });
    // eslint-disable-next-line
  }, [healthPassport]);

  const handlePassportChange = (_, { value }) => {
    if (value !== passportStatus) {
      setConfirmStatus(true);
      setPassportStatus(value);
    } else {
      setConfirmStatus(false);
    }
  };

  const handleConfirmPassport = () => {
    setConfirmStatus(false);
    setEditablePassport(false);
    if (passportStatus === 'system_value') {
      dispatch(setHealthPassport({ patientId, status: '' }));
    } else {
      dispatch(setHealthPassport({ patientId, status: passportStatus }));
    }
  };

  const systemPassportValue = biometrics?.['i_passport'];

  const passportOptions = [
    {
      key: 'system_value',
      text: `${capitalize(systemPassportValue)} (System)`,
      value: 'system_value',
      content: (() => {
        const systemColor = (() => {
          if (systemPassportValue === 'GREEN') return style.lowValue;
          if (systemPassportValue === 'YELLOW') return style.mediumValue;
          return style.highValue;
        })();

        return (
          <div>
            <Icon name="circle" size="small" className={systemColor} />
            {`${capitalize(systemPassportValue)} (System)`}
          </div>
        );
      })(),
    },
    ...ALERT_TRESHOLDS['Covid 19'].map(({ floatThreshold, severity }) => {
      const color = (() => {
        if (severity === 'GREEN') return `${style.lowValue}`;
        if (severity === 'YELLOW') return `${style.mediumValue}`;
        return `${style.highValue}`;
      })();
      const text = capitalize(severity);
      return {
        key: text,
        text: text,
        value: floatThreshold || 0.01,
        content: (
          <div>
            <Icon name="circle" size="small" className={color} />
            {text}
          </div>
        ),
      };
    }),
  ];

  return (
    <Grid.Column
      width={6}
      className={cx(
        style.covidHeaderCol,
        style.title,
        style.hightLight,
        style.healthPassport
      )}
    >
      <span className={cx(style.iconLabel, displayPassportStatus.color)}>
        <Icon name="circle" /> {displayPassportStatus.text}
      </span>
      <span style={{ color: `black`, marginLeft: '0.5rem' }}>
        {`Health Passport ${displayPassportStatus.overridden ? '*' : ''}`}
      </span>
      <Popup
        open={editablePassport}
        position={'bottom left'}
        trigger={
          <Button
            className={style.passportButton}
            onClick={() => setEditablePassport(true)}
            compact
            floated="right"
            basic
          >
            Edit
          </Button>
        }
      >
        <Popup.Content>
          <div className={style.passportActionsContainer}>
            <Dropdown
              className={style.fieldDropdown}
              onChange={handlePassportChange}
              options={passportOptions}
              selection
              floating
              value={passportStatus}
            />
            <Button.Group basic compact>
              <Button
                onClick={handleConfirmPassport}
                disabled={!confirmStatus}
                className={style.passportButton}
              >
                Confirm
              </Button>
              <Button
                className={style.passportButton}
                onClick={() => setEditablePassport(false)}
              >
                Cancel
              </Button>
            </Button.Group>
          </div>
        </Popup.Content>
      </Popup>
    </Grid.Column>
  );
};

export default HealthPassport;
