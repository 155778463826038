import React from 'react';
import * as d3 from 'd3';

import { alignAxis } from 'utils/chartUtils';
import Dots from './Dots';
import Axis from './Axis';
import Line from './Line';
import style from './Chart.module';

const RespirationRateChart = ({
  data = [],
  dimensions,
  xData,
  tz = {},
  axisAmount,
  axisIndex,
}) => {
  if (data.length !== 2) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const keyAccessor = (_, i) => i;
  const [respRate] = data;
  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain([0, 40])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key="rr-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.respirationLabel}
      labelLine
      {...axisAligment}
    />,
    <Line
      style={{ ...style.line, ...style.respirationLine }}
      data={respRate}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
    <Dots
      style={style.respirationCircles}
      data={respRate}
      keyAccessor={keyAccessor}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      radius={4}
      tz={tz}
    />,
    /* <Line
      style={{ ...style.line, ...style.respirationLineConf }}
      data={respRateConf}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />, */
  ];
};

const RespirationRateChartDayTrend = ({
  data = [],
  dimensions,
  xData,
  axisAmount,
  axisIndex,
}) => {
  if (data.length !== 2) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const [respRate] = data;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain([0, 40])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key="rr-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.respirationLabel}
      labelLine
      {...axisAligment}
    />,
    <Line
      style={{ ...style.line, ...style.respirationLineTrend }}
      data={respRate}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
  ];
};

export { RespirationRateChart, RespirationRateChartDayTrend };
