import { diagnosticReportsParser } from 'utils/fhirData/parsingFunctions';

export default (diagnosticReports = []) => {
  const { data } = diagnosticReports;
  const parseDiagnosis = data.map(item => diagnosticReportsParser(item));
  const primary =
    parseDiagnosis.find(
      item => item?.principalDiagnosis?.toLowerCase() === 'true'
    )?.codeValues ?? 'No principal diagnosis';

  const suspect =
    parseDiagnosis.find(
      item => item?.suspectDiagnosis?.toLowerCase() === 'true'
    )?.codeValues ?? 'No suspect diagnosis';

  return `
<section class='report-section'>
     <h2 class='section-title'> Diagnosis</h2>
     <h3>Principal</h3>
     <p>${primary}<p>
     <h3>Suspect</h3>
     <p>${suspect}<p>
     </section>
`;
};
