import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import RightInfo from './components/RightInfo';
import LeftInfo from './components/LeftInfo';
import CentralInfo from './components/CentralInfo';
import { createTab, updateTab } from 'store/appState/actions';
import { updateCachedPatient } from 'store/patients/actions';
import {
  fetchBasicInfo,
  fetchInitialPatientData,
  setCurrentPatient,
  updateCacheAndClearCurrent,
  updateCachedData,
} from 'store/currentPatient/actions';
import style from './patient.module.scss';

const Patient = ({ id }) => {
  const dispatch = useDispatch();

  const patientTabs = useSelector(state => state.appState.patientTabs);

  const { data: cachedPatients } = useSelector(
    state => state.patients.cachedPatients
  );
  const currentId = useSelector(state => state.currentPatient.id);

  const basicInfo = useSelector(state => state.currentPatient.basicInfo);

  const decodedId = atob(id);
  const isCurrentFetched = decodedId === currentId;
  const isCached = !!cachedPatients[decodedId];

  useEffect(() => {
    if (!isCurrentFetched) {
      dispatch(updateCachedPatient());
      if (isCached) {
        dispatch(setCurrentPatient(cachedPatients[decodedId]));
        dispatch(updateCachedData(decodedId));
      } else {
        dispatch(fetchInitialPatientData(decodedId));
      }
    }
  }, [id]); // eslint-disable-line

  useEffect(() => {
    const hasTab = patientTabs.find(tab => tab.id === id);
    const { isLoading, data: basicInfoData } = basicInfo;

    const { first, id: basicId } = basicInfoData;

    if (!isLoading && isEmpty(basicInfoData)) {
      dispatch(fetchBasicInfo(decodedId));
    }

    if (hasTab) {
      if (basicId === decodedId && hasTab.name !== first) {
        dispatch(updateTab({ id, name: first }));
      }
    } else {
      dispatch(createTab({ id, name: id }));
    }
    // eslint-disable-next-line
  }, [id, dispatch, basicInfo]);

  useEffect(() => {
    if (basicInfo.error) {
      dispatch(fetchBasicInfo(decodedId));
    }
    // eslint-disable-next-line
  }, [id]);

  /*
   * If we return the clear function inside one of the previus useEffect, it fires every time.
   * (It seems to be a hot loader issue.)
   */

  useEffect(
    () => () => {
      dispatch(updateCacheAndClearCurrent());
    },
    [dispatch]
  );
  return (
    <div className={style.container}>
      {isCurrentFetched ? (
        <>
          <LeftInfo />
          <CentralInfo />
          <RightInfo />
        </>
      ) : (
        <Loader active />
      )}
    </div>
  );
};

export default Patient;
