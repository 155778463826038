import React from 'react';
import { Segment } from 'semantic-ui-react';
import ChartSelector from 'components/Viz/ChartSelector';
import style from 'components/Viz/Viz.module.scss';
import 'react-dates/lib/css/_datepicker.css';

const SingleChart = ({ chart, date, tz, tickData, forcedDimensions }) => {
  return (
    <Segment className={style.container}>
      <section className={style.graphContainer}>
        <ChartSelector
          date={date}
          tz={tz}
          data={tickData.data}
          charts={[chart]}
          isLoading={tickData.isLoading}
          showDailyTrends={false}
          forcedDimensions={forcedDimensions}
        />
      </section>
    </Segment>
  );
};

export default SingleChart;
