import { createReducer } from '@reduxjs/toolkit';

import { handleActionStart } from 'store/utils';
import {
  fetchPatientInfoFull,
  fetchPatientInfo,
  setCovidStatus,
} from 'store/patients/actions';

const initialState = {
  data: {},
  isLoading: false,
  error: false,
  errorTicks: false,
};

export default createReducer(initialState, {
  [fetchPatientInfoFull.STARTED]: handleActionStart,
  [fetchPatientInfoFull.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = false;
    state.data[action.payload.id] = {
      ...state.data[action.payload.id],
      ...action.payload.data,
      error: false,
    };
  },
  [fetchPatientInfoFull.FAILED]: (state, action) => {
    state.data[action.payload.message] = {
      error: true,
    };
  },
  [fetchPatientInfo.STARTED]: handleActionStart,
  [fetchPatientInfo.SUCCEEDED]: (state, action) => {
    let bandStatus = state.data[action.payload.id]
      ? state.data[action.payload.id].bandStatus
      : null;

    const incomingBandStatus = action.payload.data.bandStatus;

    if (incomingBandStatus) {
      bandStatus = incomingBandStatus;
    }

    state.isLoading = false;
    state.error = false;
    state.data[action.payload.id] = {
      ...state.data[action.payload.id],
      ...action.payload.data,
      bandStatus,
      error: false,
    };
  },
  [fetchPatientInfo.FAILED]: (state, action) => {
    state.data[action.payload.message] = {
      error: true,
    };
  },
  [setCovidStatus]: (state, action) => {
    state.data[action.payload.id] = {
      ...state.data[action.payload.id],
      covidStatus: action.payload.data,
      error: false,
    };
  },
});
