import configuredAxios from 'api/axios.config';

const HEALTHCLOUD_ENDPOINT = process.env.REACT_APP_HEALTHCLOUD_API_URL;
const CONFIRMED_PATIENT_IDS_ENDPOINT = '/dashboard/patients';
const NEW_PATIENT_IDS_ENDPOINT = '/care_team/patients/all';
const CONFIRM_PATIENT_ENDPOINT = '/onboarding/accept_patient';
const DISCHARGE_PATIENT_ENDPOINT = 'action/discharge';
const DISEASES_ENDPOINT = '/facts/diseases/raw';
const HEALTH_PASSPORT_ENDPOINT = '/facts/diseases/covid-19';
const GET_PATIENT_BY_ID_ENDPOINT = '/care_team/patient';
const SEND_MESSAGE_ENDPOINT = '/care_team/send_message';
const PUSH_NOTIFICATION_ENDPOINT = '/notifications';
const NOTES_ENDPOINT = '/notes';
const GEOFENCING_ENDPOINT = '/geo_fence';

class HealthcloudAPI {
  getConfirmedPatientIds(currentPageNew, pageSize = 50) {
    return configuredAxios.get(
      `${HEALTHCLOUD_ENDPOINT}${CONFIRMED_PATIENT_IDS_ENDPOINT}?page=${currentPageNew}&size=${pageSize}`
    );
  }

  getNewPatientIds(currentPageNew) {
    return configuredAxios
      .get(
        `${HEALTHCLOUD_ENDPOINT}${NEW_PATIENT_IDS_ENDPOINT}/${currentPageNew}?onboarded=false`
      )
      .then(response => response.reverse());
  }

  getDiseases(id) {
    return configuredAxios
      .get(`${HEALTHCLOUD_ENDPOINT}${DISEASES_ENDPOINT}/${id}`)
      .catch(() => ({}));
  }

  confirmPatient(id) {
    return configuredAxios.post(
      `${HEALTHCLOUD_ENDPOINT}${CONFIRM_PATIENT_ENDPOINT}/${id}`
    );
  }

  dischargePatient(id) {
    return configuredAxios.patch(
      `${HEALTHCLOUD_ENDPOINT}/patients/${id}/${DISCHARGE_PATIENT_ENDPOINT}`
    );
  }

  getPatientById(id) {
    return configuredAxios.get(
      `${HEALTHCLOUD_ENDPOINT}${GET_PATIENT_BY_ID_ENDPOINT}/${id}`
    );
  }

  getHealthPassport(id) {
    return configuredAxios
      .get(`${HEALTHCLOUD_ENDPOINT}${HEALTH_PASSPORT_ENDPOINT}/${id}`)
      .catch(err => ({}));
  }

  setHealthPassport(id, value) {
    let sendValue = undefined;
    if (value !== '') sendValue = value;
    if (value === 0) sendValue = 0.0;
    return configuredAxios.post(
      `${HEALTHCLOUD_ENDPOINT}${HEALTH_PASSPORT_ENDPOINT}`,
      {
        patient_id: id,
        'covid-fact': sendValue,
      }
    );
  }

  // -------- Messaging --------

  sendMessage({ from, to, subject, message }) {
    return configuredAxios.post(HEALTHCLOUD_ENDPOINT + SEND_MESSAGE_ENDPOINT, {
      from,
      to,
      subject,
      message,
    });
  }

  sendPushNotification(id) {
    return configuredAxios.post(
      `${HEALTHCLOUD_ENDPOINT}${PUSH_NOTIFICATION_ENDPOINT}/${id}`
    );
  }

  // -------- Notes --------

  getPatientNotes(id) {
    return configuredAxios.get(
      `${HEALTHCLOUD_ENDPOINT}${NOTES_ENDPOINT}/${id}`
    );
  }

  createPatientNotes(id, content) {
    return configuredAxios.post(HEALTHCLOUD_ENDPOINT + NOTES_ENDPOINT, {
      patient_id: parseInt(id, 10),
      content,
    });
  }

  updatePatientNotes(id, content) {
    return configuredAxios.patch(
      `${HEALTHCLOUD_ENDPOINT}${NOTES_ENDPOINT}/${id}`,
      {
        content,
      }
    );
  }

  // -------- Geofencing --------

  getGeofencing(id) {
    return configuredAxios.get(
      `${HEALTHCLOUD_ENDPOINT}${GEOFENCING_ENDPOINT}/${id}`
    );
  }

  createGeofencing(id, data) {
    return configuredAxios.post(
      `${HEALTHCLOUD_ENDPOINT}${GEOFENCING_ENDPOINT}/${id}`,
      data
    );
  }

  updateGeofencing(id, data) {
    return configuredAxios.patch(
      `${HEALTHCLOUD_ENDPOINT}${GEOFENCING_ENDPOINT}/${id}`,
      data
    );
  }

  /* ---------------- Contact Us ---------------- */
  contactSupport(message, patientId) {
    return configuredAxios.patch(
      `${HEALTHCLOUD_ENDPOINT}/chat/${patientId}/handoff`,
      { message }
    );
  }
}

export default HealthcloudAPI;
