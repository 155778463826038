import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPatientGeofencing,
  updatePatientGeofencing,
  setCurrentPatient,
  clearCurrentPatient,
  clearPatientGeofencing,
} from 'store/currentPatient/actions';

import { handleActionStart, handleActionError } from 'store/utils';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchPatientGeofencing.STARTED]: handleActionStart,
  [updatePatientGeofencing.STARTED]: handleActionStart,
  [fetchPatientGeofencing.SUCCEEDED]: (state, action) => {
    state.data = action.payload.data;
    state.isLoading = false;
    state.error = null;
    return state;
  },
  [updatePatientGeofencing.SUCCEEDED]: (state, action) => {
    state.data = action.payload.data;
    state.isLoading = false;
    state.error = null;
    return state;
  },
  [setCurrentPatient]: (_, action) => action.payload.geofencing,
  [clearCurrentPatient]: () => initialState,
  [clearPatientGeofencing]: () => initialState,
  [fetchPatientGeofencing.FAILED]: handleActionError,
  [updatePatientGeofencing.FAILED]: handleActionError,
});
