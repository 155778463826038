import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import {
  setCurrentPatient,
  clearCurrentPatient,
} from 'store/currentPatient/actions';

import { checkForFhirUser } from 'store/currentPatient/actions/fhir';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [checkForFhirUser.STARTED]: handleActionStart,
  [checkForFhirUser.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload.data;
  },
  [checkForFhirUser.FAILED]: handleActionError,
  [setCurrentPatient]: (_, action) => action.payload.isFhirUser,
  [clearCurrentPatient]: () => initialState,
});
