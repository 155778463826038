import React from 'react';
import style from 'containers/patient/patient.module.scss';
import { FieldArray } from 'formik';
import { Button, Form } from 'semantic-ui-react';
import LeftIconButton from 'components/LeftIconButton';
import LabelValueText from 'components/LabelValueText';

const OtherFieldSection = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const basicHandlers = {
    onChange: handleChange,
    onBlur: handleBlur,
  };
  return (
    <FieldArray name={`other`}>
      {arrayHelpers => {
        const handleClickAdd = () => {
          if (!!values.otherField.name) {
            const newField = {
              [values.otherField.name]: values.otherField.description,
            };
            arrayHelpers.push(newField);
            setFieldValue('otherField', {
              name: '',
              description: '',
            });
          }
        };
        const handleClickRemove = key => arrayHelpers.remove(key);
        return (
          <div>
            <h4>Custom information</h4>
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="Field Name"
                name={'otherField.name'}
                placeholder="allergies"
                {...basicHandlers}
                value={values.otherField.name}
              />
              <Form.Input
                name={'otherField.description'}
                fluid
                label="Description"
                placeholder="..."
                {...basicHandlers}
                value={values.otherField.description}
              />
            </Form.Group>
            <Button
              fluid
              type="button"
              icon
              color="blue"
              onClick={handleClickAdd}
            >
              {'Add info'}
            </Button>

            {values.other && values.other.length ? (
              <>
                <h5>Added Fields</h5>
                {values.other.map((otherField, key) => {
                  const name = Object.keys(otherField)[0];
                  return (
                    <LeftIconButton
                      key={key}
                      type={'button'}
                      handleClick={handleClickRemove.bind(this, key)}
                      containerClassName={style.metadataOtherField}
                    >
                      <LabelValueText
                        label={name}
                        value={otherField[name]}
                        className={style.noMargin}
                      />
                    </LeftIconButton>
                  );
                })}
              </>
            ) : null}
          </div>
        );
      }}
    </FieldArray>
  );
};

export default OtherFieldSection;
