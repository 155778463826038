import { createActionThunk } from 'redux-thunk-actions';
import { FhirApi } from 'api';

export const setAppointmentStaticData = createActionThunk(
  'SET_APPOINTMENT_STATIC_DATA',
  async () => {
    const lsStaticData = localStorage.getItem('appointmentStatic');
    let response;
    if (lsStaticData) {
      response = JSON.parse(lsStaticData);
    } else {
      response = await FhirApi.getAppointmentsFormStaticData();
      localStorage.setItem('appointmentStatic', JSON.stringify(response));
    }
    return response;
  }
);
