import riskDescription from './riskDescription';
import weekStats from './weekStats';
export default (concept, description, reasons, weeks) => {
  const stats = weeks.reduce((a, b) => a + weekStats(b), '');
  return `<div class="risk-box">
${riskDescription(concept, description, reasons)}
<div class="risk-daily-box">
  ${stats}
</div>
</div>`;
};
