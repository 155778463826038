import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { chartOptions } from 'utils/charts/factory';
import style from './Viz.module.scss';

const ChartPicker = ({ handleChangeChart }) => {
  return (
    <section className={style.overlaySelector}>
      <span className={style.vizControlLabel}>Charts</span>
      <Dropdown
        selection
        multiple
        clearable
        placeholder="Charts"
        onChange={handleChangeChart}
        options={chartOptions}
      />
    </section>
  );
};

export default ChartPicker;
