export default {
  covid: [
    { question: 'COVID19_001', negativeValue: val => val === null },
    {
      question: 'COVID19_002',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'COVID-19 test positive',
    },
    { question: 'COVID19_003', negativeValue: val => val === null },
    {
      question: 'COVID19_004',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'COVID-19 suspicion',
    },
    {
      question: 'COVID19_005',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    {
      question: 'COVID19_006',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Close contact with person with flu',
    },
    {
      question: 'COVID19_007',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    {
      question: 'COVID19_008',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Close contact with infected person',
    },
    {
      question: 'COVID19_009',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Traveled abroad',
    },
    { question: 'COVID19_010', negativeValue: val => val === null },
    {
      question: 'COVID19_011',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Essential worker',
    },
    {
      question: 'COVID19_012',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Admitted to hospital',
    },
    {
      question: 'COVID19_013',
      negativeValue: val => val?.toLowerCase?.() === 'no',
      reason: 'Flu shot',
    },
    {
      question: 'COVID19_014',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Pregnant',
    },
    {
      question: 'COVID19_015',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Vitamin D deficiency',
    },
    {
      question: 'COVID19_016',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Medical condition',
    },
    { question: 'COVID19_017', negativeValue: val => val === null },
    {
      question: 'COVID19_018',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    {
      question: 'COVID19_019',
      negativeValue: val =>
        val?.toLowerCase?.() === 'a' || val?.toLowerCase?.() === 'ab',
    },
  ],
  ari: [
    {
      question: 'ARISX_001',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Fever',
    },
    {
      question: 'ARISX_002',
      negativeValue: val => Number(val) >= 38 && Number(val) <= 150,
    },
    { question: 'ARISX_003', negativeValue: val => val === null },
    {
      question: 'ARISX_004',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_005', negativeValue: val => val === null },
    { question: 'ARISX_006', negativeValue: val => val === null },
    {
      question: 'ARISX_007',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Cough',
    },
    {
      question: 'ARISX_008',
      negativeValue: val => val?.toLowerCase?.() === 'dry cough',
      reason: 'Dry cough',
    },
    {
      question: 'ARISX_009',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Couphing up blood',
    },
    { question: 'ARISX_010', negativeValue: val => val === null },
    {
      question: 'ARISX_011',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_012', negativeValue: val => val === null },
    {
      question: 'ARISX_013',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Shortness of breath',
    },
    {
      question: 'ARISX_014',
      negativeValue: val =>
        [
          'blue color in the lips or face',
          'wheezing',
          'shortness of breath worsened with exertion',
        ].includes(val?.toLowerCase?.()),
    },
    { question: 'ARISX_015', negativeValue: val => val === null },
    {
      question: 'ARISX_016',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_017', negativeValue: val => val === null },
    {
      question: 'ARISX_018',
      negativeValue: val =>
        [
          'chills',
          'night sweats',
          'feeling tired or run down',
          'muscle or body aches',
          'joint pain',
        ].includes(val?.toLowerCase?.()),
    },
    { question: 'ARISX_019', negativeValue: val => val === null },
    {
      question: 'ARISX_020',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_021', negativeValue: val => val === null },
    { question: 'ARISX_022', negativeValue: val => val === null },
    {
      question: 'ARISX_023',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_024', negativeValue: val => val === null },
    { question: 'ARISX_025', negativeValue: val => val === null },
    {
      question: 'ARISX_026',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_027', negativeValue: val => val === null },
    { question: 'ARISX_028', negativeValue: val => val === null },
    {
      question: 'ARISX_029',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_030', negativeValue: val => val === null },
    { question: 'ARISX_031', negativeValue: val => val === null },
    {
      question: 'ARISX_032',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_033', negativeValue: val => val === null },
    {
      question: 'ARISX_034',
      negativeValue: val =>
        ['nasal congestion', 'runny nose', 'sore throat'].includes(
          val?.toLowerCase?.()
        ),
    },
    { question: 'ARISX_035', negativeValue: val => val === null },
    {
      question: 'ARISX_036',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_037', negativeValue: val => val === null },
    { question: 'ARISX_038', negativeValue: val => val === null },
    {
      question: 'ARISX_039',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_040', negativeValue: val => val === null },
    { question: 'ARISX_041', negativeValue: val => val === null },
    {
      question: 'ARISX_042',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_043', negativeValue: val => val === null },
    {
      question: 'ARISX_044',
      negativeValue: val =>
        [
          'sneezing',
          'redness or discharge from the eyes',
          'loss of the sense of smell',
          'change in the sense of taste',
        ].includes(val?.toLowerCase?.()),
    },
    { question: 'ARISX_045', negativeValue: val => val === null },
    {
      question: 'ARISX_046',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_047', negativeValue: val => val === null },
    { question: 'ARISX_048', negativeValue: val => val === null },
    {
      question: 'ARISX_049',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_050', negativeValue: val => val === null },
    { question: 'ARISX_051', negativeValue: val => val === null },
    {
      question: 'ARISX_052',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_053', negativeValue: val => val === null },
    { question: 'ARISX_054', negativeValue: val => val === null },
    {
      question: 'ARISX_055',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_056', negativeValue: val => val === null },
    {
      question: 'ARISX_057',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Headache',
    },
    {
      question: 'ARISX_058',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Neck pain or stiffness',
    },
    { question: 'ARISX_059', negativeValue: val => val === null },
    {
      question: 'ARISX_060',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_061', negativeValue: val => val === null },
    {
      question: 'ARISX_062',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Chest pain',
    },
    {
      question: 'ARISX_063',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_064', negativeValue: val => val === null },
    {
      question: 'ARISX_065',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_066', negativeValue: val => val === null },
    {
      question: 'ARISX_067',
      negativeValue: val =>
        [
          'nausea and vomiting',
          'loss of appetite',
          'diarrhea',
          'constipation',
          'feeling thirsty or dehydrated',
        ].includes(val?.toLowerCase?.()),
    },
    { question: 'ARISX_068', negativeValue: val => val === null },
    {
      question: 'ARISX_069',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_070', negativeValue: val => val === null },
    { question: 'ARISX_071', negativeValue: val => val === null },
    {
      question: 'ARISX_072',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_073', negativeValue: val => val === null },
    { question: 'ARISX_074', negativeValue: val => val === null },
    {
      question: 'ARISX_075',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_076', negativeValue: val => val === null },
    {
      question: 'ARISX_077',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_078', negativeValue: val => val === null },
    {
      question: 'ARISX_079',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_080', negativeValue: val => val === null },
    { question: 'ARISX_081', negativeValue: val => val === null },
    {
      question: 'ARISX_082',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    { question: 'ARISX_083', negativeValue: val => val === null },
    {
      question: 'ARISX_084',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    {
      question: 'ARISX_085',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
  ],
  ari_daily: [
    {
      question: 'ARISXDAILY_020',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    {
      question: 'ARISXDAILY_001',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Fever',
    },
    {
      question: 'ARISXDAILY_002',
      negativeValue: val => Number(val) >= 38 && Number(val) <= 150,
    },
    {
      question: 'ARISXDAILY_003',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
    },
    {
      question: 'ARISXDAILY_004',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Cough',
    },
    {
      question: 'ARISXDAILY_005',
      negativeValue: val => val?.toLowerCase?.() === 'dry cough',
      reason: 'Dry cough',
    },
    {
      question: 'ARISXDAILY_006',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Coughing up blood',
    },
    {
      question: 'ARISXDAILY_007',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Shortness of breath',
    },
    {
      question: 'ARISXDAILY_008',
      negativeValue: val =>
        [
          'blue color in the lips or face',
          'wheezing',
          'shortness of breath worsened with exertion',
        ].includes(val?.toLowerCase?.()),
    },
    {
      question: 'ARISXDAILY_009',
      negativeValue: val =>
        [
          'chills',
          'night sweats',
          'feeling tired or run down',
          'muscle or body aches',
          'joint pain',
        ].includes(val?.toLowerCase?.()),
    },
    {
      question: 'ARISXDAILY_010',
      negativeValue: val =>
        ['nasal congestion', 'runny nose', 'sore throat'].includes(
          val?.toLowerCase?.()
        ),
    },
    {
      question: 'ARISXDAILY_011',
      negativeValue: val =>
        [
          'sneezing',
          'redness or discharge from the eyes',
          'loss of the sense of smell',
          'change in the sense of taste',
        ].includes(val?.toLowerCase?.()),
    },
    {
      question: 'ARISXDAILY_012',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Headache',
    },
    {
      question: 'ARISXDAILY_013',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Neck pain',
    },
    {
      question: 'ARISXDAILY_014',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Chest pain',
    },
    {
      question: 'ARISXDAILY_015',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Persistent chest pain',
    },
    {
      question: 'ARISXDAILY_016',
      negativeValue: val =>
        [
          'nausea and vomiting',
          'loss of appetite',
          'diarrhea',
          'constipation',
          'feeling thirsty or dehydrated',
        ].includes(val?.toLowerCase?.()),
    },
    {
      question: 'ARISXDAILY_017',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Blood in diarrhea',
    },
    {
      question: 'ARISXDAILY_018',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Darker urine',
    },
    {
      question: 'ARISXDAILY_019',
      negativeValue: val => val?.toLowerCase?.() === 'yes',
      reason: 'Close contacts with symptoms',
    },
  ],
};
