import React from 'react';

const BandFillIcon = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line
      {...props}
    >
      <path d="M12 23C5.373 23 0 18.627 0 12C0 9.68384 0.488611 7.64301 1.46583 5.96798L4.85103 9.60427C4.16475 10.7025 4 12.0599 4 13.5C4 17.5141 7.06299 20 12 20C16.937 20 20 17.5141 20 13.5C20 12.079 19.8336 10.7198 19.1389 9.61509L22.5342 5.96798C23.5114 7.64301 24 9.68384 24 12C24 18.627 18.627 23 12 23Z" />
      <path d="M17.7146 8.20979L21.3193 4.33772C19.3253 2.21617 16.2189 1 12 1C7.78112 1 4.67468 2.21617 2.68067 4.33772L6.27523 8.1989C7.47569 7.44794 9.28635 7 12 7C14.706 7 16.5141 7.45476 17.7146 8.20979Z" />
    </svg>
  );
};

export default BandFillIcon;
