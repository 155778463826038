import React, { useState } from 'react';
import style from 'containers/patient/patient.module.scss';
import { format } from 'utils/textFormats';
import { useDispatch } from 'react-redux';
import { setCurrentMeal } from 'store/currentPatient/actions';
import { Accordion, Icon } from 'semantic-ui-react';
import MealPreview from './components/MealPreview';
import MealChart from './components/MealChart';
import HoursPicker from './components/HoursPicker';
import { AddValuesToMeals } from 'utils/mealsUtils';
import { withTimeZone } from 'utils/timeToText';
import { isEmpty } from 'lodash';
const MealTimeline = ({ tz, day, data: { meals, glucose } }) => {
  const [open, setOpen] = useState(false);
  const [startHour, setStartHour] = useState('00');
  const [endHour, setEndHour] = useState('23');
  const firstMeal = meals.slice(-1)[0];
  const [hoverMeal, setHoverMeal] = useState(firstMeal);
  const dispatch = useDispatch();

  const onEnterBullet = meal => {
    setHoverMeal(meal);
  };
  const onLeaveBullet = () => setHoverMeal(null);

  const onBulletClick = meal => {
    dispatch(setCurrentMeal(meal));
  };

  const eeuuDate = format(day, 'DD-MM-YYYY');
  const dayDate = format(day, 'YYYY-MM-DD');

  const mealsAmount = meals.length;

  const mealChartProps = {
    startDate: withTimeZone(tz, dayDate, `${startHour}:00`),
    endDate: withTimeZone(tz, dayDate, `${endHour}:59`),
    meals: AddValuesToMeals(meals, glucose),
    glucose,
    onEnterBullet,
    onLeaveBullet,
    onBulletClick,
    hoverMeal,
    tz,
  };

  const pickerProps = {
    startHour,
    endHour,
    setStartHour,
    setEndHour,
  };

  return !isEmpty(meals) ? (
    <>
      <Accordion.Title onClick={setOpen.bind(this, !open)} active={open}>
        <Icon name="dropdown" />
        {`${eeuuDate} (${mealsAmount})`}
      </Accordion.Title>
      <Accordion.Content active={open}>
        <>
          <HoursPicker {...pickerProps} />
          <section className={style.mealTimelineBox}>
            <MealPreview meal={hoverMeal} />
            <MealChart {...mealChartProps} />
          </section>
        </>
      </Accordion.Content>
    </>
  ) : null;
};

export default MealTimeline;
