import React from 'react';
import style from 'containers/patient/patient.module.scss';
import DiseasesList from './DiseasesList';

const DiseasesListSection = ({ diseases }) => {
  const diseasesHalf = Math.ceil(diseases.length / 2);
  const leftDiseases = diseases.slice(0, diseasesHalf);
  const rightDiseases = diseases.slice(diseasesHalf);

  return (
    <section className={style.diseasesProgressBox}>
      <DiseasesList data={leftDiseases} />
      <DiseasesList data={rightDiseases} />
    </section>
  );
};

export default DiseasesListSection;

DiseasesListSection.defaultProps = {
  diseases: [],
};
