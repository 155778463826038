import React from 'react';
import { Button, Message } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import TableComponent from './Table';
import style from './ChartsTable.module.scss';

const transCharOptions = [
  { key: 'sleep_depth', label: 'Sleep' },
  { key: 'steps', label: 'Movement' },
  { key: 'background_hr', label: 'Heart Rate' },
  { key: 'rmssd', label: 'HRV' },
  { key: 'rmssd_conf', label: 'HRV - conf' },
  { key: 'diastolic', label: 'BP - Diastolic' },
  { key: 'systolic', label: 'BP - Systolic' },
  { key: 'weight', label: 'Weight' },
  { key: 'glucose', label: 'Glucose' },
  { key: 'basal', label: 'Insulin - basal' },
  { key: 'bolus', label: 'Insulin - bolus' },
  { key: 'resp_rate', label: 'Respiration Rate' },
  { key: 'resp_rate_conf', label: 'Respiration Rate Conf' },
  { key: 'ambient_temp', label: 'Ambient Temp.' },
  { key: 'skin_temp', label: 'Skin Temp.' },
  { key: 'spo2', label: 'SPO2' },
  { key: 'battery_level', label: 'Battery Level' },
  { key: 'band_removed', label: 'Band State' },
];

const ChartsTable = ({ charts, isLoading, date, data, patientId }) => {
  const getData = () => {
    let result = [];
    Object.entries(data).forEach(entry => {
      const [key, valuesArr] = entry;
      valuesArr.forEach(values => {
        result.push({
          [key]: values.value,
          time: values.time,
        });
      });
    });

    const grouped = _.groupBy(result, 'time');

    return Object.entries(grouped)
      .map(entry => {
        const [timeKey, valuesArr] = entry;
        let res = {};
        valuesArr.forEach(v => {
          res = { ...res, ...v };
        });
        return {
          ...res,
          time: moment(Number(timeKey)).format('LTS'),
          date: moment(Number(timeKey)).format('MMM D'),
          timestamp: Number(timeKey),
        };
      })
      .sort((a, b) => a.timestamp - b.timestamp);
  };

  const getColumns = () => {
    const chartKeys = _.pull(
      _.uniqBy(formatedData.flatMap(Object.keys)),
      'date',
      'time'
    );
    return [
      { label: 'Date', key: 'date' },
      { label: 'Time', key: 'time' },
      ...chartKeys.map(chartKey => ({
        label: transCharOptions.find(el => el.key === chartKey)?.label,
        key: chartKey,
      })),
    ].filter(obj => obj.label);
  };

  const formatedData = React.useMemo(() => getData(), [data, date]); // eslint-disable-line
  const columns = getColumns();

  const noData =
    _.isEmpty(formatedData) ||
    !charts.length ||
    !date.start ||
    !date.end ||
    isLoading;

  return noData ? (
    <div className={style.noDataContainer}>
      <Message>
        <Message.Header>No Tick Data</Message.Header>
        <p>
          We couldn't find tick data for the selected filters, please try with a
          different set.
        </p>
      </Message>
    </div>
  ) : (
    <>
      <div>
        <CSVLink
          data={formatedData}
          headers={columns}
          filename={`GeneralData-UserID=${patientId}.csv`}
        >
          <Button primary>Download CSV</Button>
        </CSVLink>
      </div>
      <div className={style.tableContainer}>
        <TableComponent data={formatedData} columns={columns}></TableComponent>
      </div>
    </>
  );
};

ChartsTable.propTypes = {
  charts: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool.isRequired,
  date: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }),
  data: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default ChartsTable;
