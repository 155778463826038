import React, { useState } from 'react';

import { Button, Modal, TextArea } from 'semantic-ui-react';
import { toast, SemanticToastContainer } from 'react-semantic-toasts';
import { HealthcloudApi } from '../../../../../../../../api';
import style from '../../../../../../patient.module.scss';

export const ContactSupport = ({ patientId }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const toggleOpen = () => {
    setOpen(!open);
  };

  const updateMessage = (_, data) => {
    setMessage(data.value);
  };

  const send = () => {
    HealthcloudApi.contactSupport(message, patientId).then(() => {
      toggleOpen();
      toast({
        type: 'info',
        icon: 'check circle outline',
        title: 'Support message sent',
        description: `We will contact you back soon`,
        time: 3000,
      });
    });
  };

  return (
    <>
      <SemanticToastContainer />
      <Modal
        open={open}
        onClose={toggleOpen}
        trigger={
          <Button
            content="Contact Tech Support"
            secondary
            onClick={toggleOpen}
            className={style.contactSupportButton}
          />
        }
      >
        <Modal.Header>Contact us</Modal.Header>
        <Modal.Content>
          <TextArea
            value={message}
            onChange={updateMessage}
            style={{
              height: '25vh',
              resize: 'none',
              width: '100%',
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={toggleOpen} content="Cancel" />
          <Button positive onClick={send} content="Send" />
        </Modal.Actions>
      </Modal>
    </>
  );
};
