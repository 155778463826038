import { createActionThunk } from 'redux-thunk-actions';
import { createAction } from '@reduxjs/toolkit';

import { FhirApi } from 'api';

export const getDaysAvailability = createActionThunk(
  'formResorces/GET_AVAILABLE_DAYS',
  async (datesArray, resource) => {
    const availabilities = await FhirApi.getDaysAvailability(
      datesArray,
      resource
    );
    return availabilities;
  }
);

export const clearDaysAvailability = createAction(
  'formResorces/CLEAR_AVAILABLE_DAYS'
);
