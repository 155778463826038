import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Accordion, Header, Loader, Message } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { AvailableDates } from 'utils/appointments/factory';
import AvailableDay from './AvailableDay';
import style from 'containers/patient/patient.module.scss';

const AppointmentDayPicker = ({ pickedDay, onSelectDay }) => {
  const { isLoading, error, data: availableDays } = useSelector(
    state => state.formsResources.availableDays
  );
  const data = new AvailableDates(availableDays);
  const [activeIndexes, setActiveIndexes] = useState([]);
  const clearActives = () => setActiveIndexes([]);

  const handleSelection = (...args) => {
    onSelectDay(...args);
    clearActives();
  };

  const togleActive = index => {
    let newActives;
    if (activeIndexes.includes(index)) {
      newActives = activeIndexes.filter(idx => idx !== index);
    } else {
      newActives = [...activeIndexes, index];
    }
    setActiveIndexes(newActives);
  };
  const pickedDate = pickedDay
    ? moment(pickedDay.start * 1000).format('YYYY/MM/DD')
    : '---';
  const pickedPeriod = pickedDay ? pickedDay.period : '---';

  if (error) return <Message content={error} />;
  return (
    <>
      <div className={style.pickerHeaders}>
        <Header content="Choose a day" as={'h3'} subheader={null} />
        <Header content={pickedDate} subheader={pickedPeriod} as={'h3'} />
      </div>
      {isLoading ? (
        <div className={style.modalLoaderBox}>
          <Loader active inline="centered" content="Checking..." />
        </div>
      ) : (
        <Accordion
          fluid
          styled
          exclusive={false}
          className={style.availableDaysBox}
        >
          {isEmpty(availableDays) ? (
            <Message content="No available days. Please try with a different date range." />
          ) : (
            data.dates.map((day, index) => (
              <AvailableDay
                key={index}
                day={day}
                active={activeIndexes.includes(index)}
                togleActive={() => togleActive(index)}
                onSelectDay={handleSelection}
              />
            ))
          )}
        </Accordion>
      )}
    </>
  );
};

export default AppointmentDayPicker;
