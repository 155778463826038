const colors = {
  heartRateLineColor: '#dd439a',
  heartRateCircleColor: '#dd439a',
  rmssdLineColor: '#f31f68',
  rmssdLineConfColor: '#a00ec4',
  rmssdCircleColor: '#f31f68',
  weightLineColor: '#009688',
  weightCircleColor: '#009688',
  movementColor: '#ff903b',
  bloodPressureColor: '#198dd0',
  sleepDeepColor: '#336cd6',
  sleepLightColor: '#4ccfe0',
  sleepRemColor: '#40a0db',
  sleepAwakeColor: '#ffcf66',
  glucoseColor: '#d578ff',
  insulinColor: '#307bf6',
  insulinBasalColor: '#8e8e8e',
  respirationColor: '#aa7eff',
  respirationConfColor: '#8864cc',
  skinTempColor: '#e4cb63',
  ambientTempColor: '#b6a24f',
  spo2Color: '#26745b',
  batteryLevelColor: '#7bbd18',
  bandStatusColor: '#ffa8a7',
};

export default {
  chart: {
    overflow: 'visible',
  },
  text: {
    fill: '#95a5a6',
  },
  line: {
    transition: 'all 0.3s ease-out',
  },
  axisLine: {
    stroke: 'darkgray',
  },
  gridLine: {
    stroke: 'darkgray',
    strokeOpacity: 0.3,
  },
  axisHorizontal: {
    textAnchor: 'middle',
  },
  axisVertical: {
    dominantBaseline: 'middle',
    textAnchor: 'end',
  },
  axisLabel: {
    textAnchor: 'middle',
    fontSize: ' 0.9em',
    letterSpacing: '0.01em',
  },
  axisTick: {
    fontSize: '0.7em',
    transition: 'all 0.3s ease-out',
    userSelect: 'none',
  },
  whiteAxisLine: {
    fill: '#fff',
    stroke: '#fff',
    strokeOpacity: 1,
  },
  movementLabel: {
    fill: colors.movementColor,
  },
  movementColor: {
    transition: 'all 0.1s ease-out',
    fill: colors.movementColor,
  },
  bloodPressureLabel: {
    fill: colors.bloodPressureColor,
  },
  bloodPressureColor: {
    transition: 'all 0.1s ease-out',
    fill: colors.bloodPressureColor,
  },
  heartRateLabel: {
    fill: colors.heartRateLineColor,
  },
  rmssdLabel: {
    fill: colors.rmssdLineColor,
  },
  weightLabel: {
    fill: colors.weightLineColor,
  },
  lineDailyTrend: {
    stroke: colors.heartRateLineColor,
    fill: 'none',
  },
  lineDailyWeightTrend: {
    stroke: colors.weightLineColor,
    fill: 'none',
  },
  heartRateLine: {
    fill: 'none',
    stroke: colors.heartRateLineColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  lineRmssdDailyTrend: {
    stroke: colors.rmssdLineColor,
    fill: 'none',
  },
  rmssdLine: {
    fill: 'none',
    stroke: colors.rmssdLineColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  rmssdConfLine: {
    fill: 'none',
    stroke: colors.rmssdLineConfColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.5,
  },
  weightLine: {
    fill: 'none',
    stroke: colors.weightLineColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  heartRateCircles: {
    fill: colors.heartRateCircleColor,
    transition: 'all 0.3s ease-out',
  },
  rmssdCircles: {
    fill: colors.rmssdCircleColor,
    transition: 'all 0.3s ease-out',
  },
  weightCircles: {
    fill: colors.weightCircleColor,
    transition: 'all 0.3s ease-out',
  },
  deepColor: {
    fill: colors.sleepDeepColor,
  },
  lightColor: {
    fill: colors.sleepLightColor,
  },
  remColor: {
    fill: colors.sleepRemColor,
  },
  awakeColor: {
    fill: colors.sleepAwakeColor,
  },
  glucoseLine: {
    fill: 'none',
    stroke: colors.glucoseColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  glucoseLineTrend: {
    fill: 'none',
    stroke: colors.glucoseColor,
  },
  glucoseCircles: {
    fill: colors.glucoseColor,
    transition: 'all 0.3s ease-out',
  },
  glucoseGridLine: {
    stroke: colors.glucoseColor,
    strokeOpacity: 0.5,
  },
  insulinLabel: {
    fill: colors.insulinColor,
  },
  insulinColor: {
    transition: 'all 0.1s ease-out',
    fill: colors.insulinColor,
  },
  insulinBasalColor: {
    transition: 'all 0.1s ease-out',
    fill: colors.insulinBasalColor,
  },
  insulinGridLine: {
    stroke: colors.insulinColor,
    strokeOpacity: 0.5,
  },
  respirationLabel: {
    fill: colors.respirationColor,
  },
  respirationLine: {
    fill: 'none',
    stroke: colors.respirationColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  respirationLineConf: {
    fill: 'none',
    stroke: colors.respirationConfColor,
    strokeWidth: '2px',
    strokeOpacity: 0.5,
  },
  respirationLineTrend: {
    fill: 'none',
    stroke: colors.respirationColor,
  },
  respirationCircles: {
    fill: colors.respirationColor,
    transition: 'all 0.3s ease-out',
  },
  temperatureLabel: {
    fill: colors.skinTempColor,
  },
  skinTempLine: {
    fill: 'none',
    stroke: colors.skinTempColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  skinTempCircles: {
    fill: colors.skinTempColor,
    transition: 'all 0.3s ease-out',
  },
  skinTempLineTrend: {
    fill: 'none',
    stroke: colors.skinTempColor,
  },
  ambientTempLine: {
    fill: 'none',
    stroke: colors.ambientTempColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  ambientTempCircles: {
    fill: colors.ambientTempColor,
    transition: 'all 0.3s ease-out',
  },
  ambientTempLineTrend: {
    fill: 'none',
    stroke: colors.ambientTempColor,
  },
  spo2Line: {
    fill: 'none',
    stroke: colors.spo2Color,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  spo2Circles: {
    fill: colors.spo2Color,
    transition: 'all 0.3s ease-out',
  },
  spo2Label: {
    fill: colors.spo2Color,
  },
  batteryLevelLabel: {
    fill: colors.batteryLevelColor,
  },
  batteryLevelLine: {
    fill: 'none',
    stroke: colors.batteryLevelColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  batteryLevelLineTrend: {
    fill: 'none',
    stroke: colors.batteryLevelColor,
  },
  batteryLevelCircles: {
    fill: colors.batteryLevelColor,
    transition: 'all 0.3s ease-out',
  },
  bandStatusLabel: {
    fill: colors.bandStatusColor,
  },
  bandStatusLine: {
    fill: 'none',
    stroke: colors.bandStatusColor,
    strokeWidth: '3px',
    strokeLinecap: 'round',
    strokeOpacity: 0.3,
  },
  bandStatusLineTrend: {
    fill: 'none',
    stroke: colors.bandStatusColor,
  },
  bandStatusCircles: {
    fill: colors.bandStatusColor,
    transition: 'all 0.3s ease-out',
  },
};
