import React from 'react';
import { Dropdown, Grid } from 'semantic-ui-react';

const DocumentsFilterOptions = ({
  setFilterEpisodeId,
  setFilterTitle,
  setFilterDate,
  documents,
}) => {
  const handleChangeEpisodeId = (_, data) => {
    setFilterEpisodeId(data.value);
  };
  const handleChangeTitle = (_, data) => {
    setFilterTitle(data.value);
  };
  const handleChangeDate = (_, data) => {
    setFilterDate(data.value);
  };

  const episodeIdOptions = documents.map(doc => ({
    key: doc.id,
    text: doc.episodeId,
    value: doc.episodeId,
  }));

  const titleOptions = documents
    .map(doc => doc.file.title)
    .reduce((a, b) => (a.includes(b) ? a : [...a, b]), [])
    .sort()
    .map((title, idx) => ({
      key: `${title}-${idx}`,
      text: title,
      value: title,
    }));

  const dateOptions = documents
    .map(doc => doc.date)
    .reduce((a, b) => (a.includes(b) ? a : [...a, b]), [])
    .sort()
    .map((date, idx) => ({
      key: `${date}-${idx}`,
      text: date,
      value: date,
    }));

  return (
    <Grid>
      <Grid.Row columns="equal" textAlign="center">
        <Grid.Column>
          <Dropdown
            search
            clearable
            selection
            options={titleOptions}
            onChange={handleChangeTitle}
            placeholder={`Title`}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            search
            clearable
            selection
            options={dateOptions}
            onChange={handleChangeDate}
            placeholder={`Date`}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            search
            clearable
            selection
            options={episodeIdOptions}
            onChange={handleChangeEpisodeId}
            placeholder={`Episode Id`}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default DocumentsFilterOptions;
