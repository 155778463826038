import React from 'react';
import { Table, Pagination } from 'semantic-ui-react';

import style from 'containers/patient/patient.module.scss';

const TablePaginationFooter = ({
  colSpan,
  handlePageChange,
  totalPages,
  children,
}) => {
  return (
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell colSpan={colSpan}>
          <section className={style.appointmentTableFooter}>
            <Pagination
              onPageChange={handlePageChange}
              boundaryRange={1}
              defaultActivePage={1}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              totalPages={totalPages}
            />
            {children}
          </section>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

export default TablePaginationFooter;
