import moment from 'moment';
import { capitalize, isEmpty, startCase } from 'lodash';
import { REPORT_DATA } from 'utils/mocks';

export const formatData = data => {
  const {
    basicInfo: { data: basicInfo },
    medications: { data: medications },
    allergyIntolerance: { data: allergyIntolerance },
    conditions,
    diseases: { data: diseases },
    diseasesEvidence: { data: diseasesEvidence },
  } = data;
  const now = moment();
  return {
    fullName: basicInfo?.name,
    name: basicInfo?.name,
    drName: data?.drName ?? 'Dr. Kevin Kim',
    drContactNumber: data?.drContactNumber ?? '(223) 324-2342',
    drContactEmail: data?.drContactEmail ?? 'kkim@all.health',
    dateToday: now.format('MM/DD/YYYY'),
    gender: basicInfo?.gender === 'F' ? 'Female &#9792,' : 'Male &#9794,',
    dob: `${moment(basicInfo.birthdate).format('MM/DD/YYYY')} (${now.diff(
      basicInfo.birthdate,
      'years'
    )})`,
    mrn: data?.mrn ?? '--',
    height: basicInfo?.height,
    weight: basicInfo?.weight,
    bmi: basicInfo?.bmi,
    diseases:
      diseases
        ?.map(disease => {
          const [[diseaseName, diseaseValue]] = Object.entries(disease);
          if (!diseaseValue) return {};
          return { name: diseaseName, value: diseaseValue.toFixed(2) };
        })
        .filter(disease => !isEmpty(disease)) ?? [],
    diseasesEvidence:
      Object.entries(diseasesEvidence)?.map(
        ([evidenceType, evidenceContent]) => {
          if (evidenceType === 'additionalInfo') return [];
          const keyRow = evidenceType.substr(2).replace(/_/g, ' ');
          const defaultRow = [
            { value: capitalize(keyRow) },
            { value: capitalize(evidenceContent).replace(/_/g, ' ') },
          ];

          if (diseasesEvidence.additionalInfo) {
            defaultRow.push({
              value: renderAdditionalInfo(
                keyRow,
                diseasesEvidence.additionalInfo
              ),
            });
          }

          return defaultRow;
        }
      ) ?? [],
    medications:
      medications?.map(medication => {
        return [
          {
            value: startCase(medication.medicationCodeableConcept),
            source: 'emr-data',
          },
          { value: startCase(medication?.dosage?.text), source: 'emr-data' },
          { value: startCase(medication.status), source: 'emr-data' },
        ];
      }) ?? [],
    allergies:
      allergyIntolerance?.map(allergie => {
        return [
          {
            value: startCase(allergie.code),
            source: 'emr-data',
          },
          { value: startCase(allergie.type), source: 'emr-data' },
          { value: startCase(allergie.criticality), source: 'emr-data' },
        ];
      }) ?? [],
    conditions:
      conditions?.map(condition => {
        const categories =
          condition?.categories?.map(startCase).join(', ') ?? '';
        const bodySites =
          condition?.body_sites?.map(startCase).join(', ') ?? '';
        return [
          { value: categories, source: 'emr-data' },
          { value: startCase(condition.severity), source: 'emr-data' },
          { value: bodySites, source: 'emr-data' },
        ];
      }) ?? [],
    medicalHistory: [],
    familyHistory: [],
    socialHistory: [],
    substanceUse: [],
    vitalSigns: [],
  };
};

export const allergiesTable = data => {
  if (!data.length) return '';
  return `
    <h4 class="header-4">Allergies</h4>
    ${renderTable({ headers: ['Name', 'Type', 'Criticality'], info: data })}`;
};

export const conditionsTable = data => {
  if (!data.length) return '';
  return `
    <h4 class="header-4">Conditions</h4>
    ${renderTable({
      headers: ['Category', 'Severity', 'Body Part'],
      info: data,
    })}`;
};

export const vitalSignsTable = data => {
  if (!data.length) return '';
  const tableData = {
    info: data,
    headers: REPORT_DATA.vitalSignsTable.headers,
  };
  return `
    <h3 class="header-3 divider">Vital Signs</h3>
    ${renderTable(tableData)}`;
};

export const medicationTable = data => {
  if (!data.length) return '';
  return `
    <h3 class="header-3 divider">Medications</h3>
    ${renderTable({ headers: ['Name', 'Dosage', 'Status'], info: data })}`;
};

export const familyHistoryTable = data => {
  if (!data.length) return '';
  const tableData = {
    info: data,
    headers: REPORT_DATA.familyHistoryTable.headers,
  };
  return `
    <h3 class="header-3 divider">Family History</h3>
    ${renderTable(tableData)}`;
};

export const socialHistoryTable = data => {
  if (!data.length) return '';
  const tableData = {
    info: data,
    headers: REPORT_DATA.socialHistoryTable.headers,
  };
  return `
    <h3 class="header-3 divider">Social History</h3>
    ${renderTable(tableData)}`;
};

export const substanceUseTable = data => {
  if (!data.length) return '';
  const tableData = {
    info: data,
    headers: REPORT_DATA.substanceUseTable.headers,
  };
  return `
    <h3 class="header-3 divider">Substance Use</h3>
    ${renderTable(tableData)}`;
};

export const diseasesEvidenceTable = data => {
  if (!data.length) return '';
  const tableData = {
    info: data,
  };
  return `
    <h3 class="header-3 divider">Diseases Evidence</h3>
    ${renderTable(tableData)}`;
};

export const medicalHistoryList = data => {
  if (!data.length) return '';
  return `
    <h3 class="header-3 divider">Past Medical History</h3>
    ${renderList(data)}
  `;
};

const renderTable = table => {
  if (!table.headers) {
    return `
        <table class="clean-table">
          ${table.info
            .map(
              row =>
                `<tr>${row
                  .map(
                    col => `<td class="color-${col.source}">${col.value}</td>`
                  )
                  .join('')}</tr>`
            )
            .join('')}
        </table>
      `;
  }

  return `
      <table class="info-table">
        <tr>${table.headers.map(col => `<th>${col}</th>`).join('')}</tr>
        ${table.info
          .map(
            row =>
              `<tr>${row
                .map(col => `<td class="color-${col.source}">${col.value}</td>`)
                .join('')}</tr>`
          )
          .join('')}
      </table>
    `;
};

const renderList = data => {
  return `
    <ul class="clean-list">
      ${data
        .map(i => `<li class="color-${i.source}">${i.value}</li>\n`)
        .join('')}
    </ul>`;
};

export const renderAdditionalInfo = (keyRow, info) => {
  const keyWithAddInfo = info[keyRow];
  const testGroups = [
    'antibody_neg',
    'antibody_pos',
    'pcr_tested_pos',
    'pcr_tested_neg',
    'pcr_test_pending',
    'ari',
  ];
  const findCovidCohort = items =>
    items.filter(item => testGroups.includes(item)).join(', ');

  const additionInfoColumn = keyWithAddInfo
    ? Object.entries(keyWithAddInfo).map(([addInfoType, addInfoContent]) => {
        if (addInfoType === 'user_cohort') {
          return `user cohort: [${findCovidCohort(
            JSON.parse(addInfoContent)
          )}]`;
        }

        return `${addInfoType.replace(/_/g, ' ')}: ${addInfoContent}\n`;
      })
    : null;

  return additionInfoColumn;
};
