import headerSection from './headerSection';
import diagnosisSection from './diagnosisSection';
import FHxSection from './FHxSection';
import NutritionSection from './NutritionSection';
import ExerciseSection from './ExerciseSection';
import MedicationSection from './MedicationSection';
import PreventionSection from './PreventionSection';
import LabSection from './LabSection';
import MonitoringSection from './MonitoringSection';

export default (data, date, tz, tickData) => {
  return `<section>
     ${headerSection(data.record, date)}
     ${diagnosisSection(data.diagnosticReports)}
     ${FHxSection(data.record)}
     ${NutritionSection(data.communications)}
     ${ExerciseSection(data.covid19)}
     ${MedicationSection(data.prescriptions)}
     ${PreventionSection(data)}
     ${LabSection(data.labExams)}
     ${MonitoringSection(date, tz, tickData)}
    </section>`;
};
