import moment from 'moment';

const REPORT_DATA = {
  fullName: 'Charles Green',
  name: 'Charles',
  drName: 'Dr. Kevin Kim',
  drContactNumber: '(223) 324-2342',
  drContactEmail: 'kkim@all.health',
  dateToday: moment().format('MM/DD/YYYY'),
  gender: 'Male &#9794;',
  dob: '09/18/1984 (35)',
  mrn: '981238943',
  height: '5\' 9"',
  weight: '200',
  bmi: '29.5',
  diabetesRisk: '66',
  vitalSignsTable: {
    headers: ['', '3/2/19 13:45', '3/16/19 15:23', '4/2/19 09:03'],
    info: [
      [
        { value: 'Lorem', source: 'jhh-data' },
        { value: 'Ipsum', source: 'jhh-data' },
        { value: 'Dolor Sit', source: 'abnormal' },
        { value: 'Amet', source: 'emr-data' },
      ],
      [
        { value: 'HR', source: 'jhh-data' },
        { value: '73', source: 'jhh-data' },
        { value: '105', source: 'abnormal' },
        { value: '67', source: 'emr-data' },
      ],
      [
        { value: 'RR', source: 'jhh-data' },
        { value: '10', source: 'jhh-data' },
        { value: '12', source: 'emr-data' },
        { value: '9', source: 'emr-data' },
      ],
      [
        { value: 'BP', source: 'jhh-data' },
        { value: '', source: 'jhh-data' },
        { value: '112/74', source: 'emr-data' },
        { value: '113/78', source: 'emr-data' },
      ],
      [
        { value: 'Temp', source: 'jhh-data' },
        { value: '', source: 'jhh-data' },
        { value: '100.4 (38.0)', source: 'abnormal' },
        { value: '98.4 (36.9)', source: 'emr-data' },
      ],
      [
        { value: 'Sp02', source: 'jhh-data' },
        { value: '99%', source: 'emr-data' },
        { value: '98%', source: 'emr-data' },
        { value: '99%', source: 'emr-data' },
      ],
    ],
  },
  medicalHistory: [
    { value: 'Lorem Ipsum Dolor', source: 'jhh-data' },
    { value: 'Psoriasis', source: 'emr-data' },
    { value: 'Osgood Schlatter', source: 'jhh-data' },
    { value: 'Exercise Induced Asthma', source: 'jhh-data' },
  ],
  medicationsTable: {
    headers: ['Drug', 'Strenght', 'Sig', 'Compliance'],
    info: [
      [
        { value: 'Calcipotriene', source: 'emr-data' },
        { value: '0.005%', source: 'emr-data' },
        { value: 'Appy BID to affected area', source: 'emr' },
        { value: '58%', source: 'abnormal' },
      ],
      [
        { value: 'Albuterol', source: 'emr-data' },
        { value: '90 mcg', source: 'emr-data' },
        { value: 'Q6H PRN for wheezing', source: 'emr-data' },
        { value: '', source: 'jhh-data' },
      ],
      [
        { value: 'Ibuprofen', source: 'jhh-data' },
        { value: '200 mg', source: 'jhh-data' },
        { value: 'TID PRN for joint pain', source: 'jhh-data' },
        { value: '', source: 'jhh-data' },
      ],
    ],
  },
  familyHistoryTable: {
    headers: ['Condition', 'Relation', 'Age', 'Deceased'],
    info: [
      [
        { value: 'Lorem', source: 'emr-data' },
        { value: 'Ipsum', source: '' },
        { value: 'Dolor Sit', source: '' },
        { value: 'Amet', source: '' },
      ],
      [
        { value: 'Hypertension', source: 'emr-data' },
        { value: '', source: '' },
        { value: '', source: '' },
        { value: '', source: '' },
      ],
      [
        { value: 'Hyperlipidemia', source: 'emr-data' },
        { value: '', source: '' },
        { value: '', source: '' },
        { value: '', source: '' },
      ],
      [
        { value: 'Myocardial Infarction', source: 'emr-data' },
        { value: 'MGF', source: 'emr-data' },
        { value: '68', source: 'emr-data' },
        { value: 'Yes', source: 'emr-data' },
      ],
      [
        { value: 'Type 2 Diabetes', source: 'jhh-data' },
        { value: 'Mother', source: 'jhh-data' },
        { value: '47', source: 'jhh-data' },
        { value: '', source: 'jhh-data' },
      ],
      [
        { value: 'Sleep Apnea', source: 'jhh-data' },
        { value: '', source: '' },
        { value: '', source: '' },
        { value: '', source: '' },
      ],
    ],
  },
  socialHistoryTable: {
    info: [
      [
        { value: 'Lorem Ipsum', source: 'jhh-data' },
        { value: 'Dolor Sit Amet', source: 'jhh-data' },
      ],
      [
        { value: 'Education', source: 'jhh-data' },
        { value: 'College/University', source: 'jhh-data' },
      ],
      [
        { value: 'Employment', source: 'jhh-data' },
        { value: 'Working full time', source: 'jhh-data' },
      ],
      [
        { value: 'Occupation', source: 'jhh-data' },
        { value: 'Software Engineer', source: 'emr-data' },
      ],
      [
        { value: 'Marital Status', source: 'jhh-data' },
        { value: 'Married/Partnered', source: 'jhh-data' },
      ],
    ],
  },
  substanceUseTable: {
    headers: ['Substance', 'Status', 'Amount', 'Frequency', 'Years'],
    info: [
      [
        { value: 'Lorem', source: 'jhh-data' },
        { value: 'Ipsum', source: 'jhh-data' },
        { value: 'Dolor', source: 'jhh-data' },
        { value: 'Sit', source: 'jhh-data' },
        { value: 'Amet', source: 'jhh-data' },
      ],
      [
        { value: 'Alcohol', source: 'jhh-data' },
        { value: 'Current', source: 'jhh-data' },
        { value: '2 drinks', source: 'jhh-data' },
        { value: 'Per Day', source: 'jhh-data' },
        { value: '15', source: 'jhh-data' },
      ],
      [
        { value: 'Smoking', source: 'jhh-data' },
        { value: '', source: '' },
        { value: '', source: '' },
        { value: '', source: '' },
        { value: '', source: '' },
      ],
      [
        { value: 'Marihuana', source: 'emr-data' },
        { value: 'Past', source: 'emr-data' },
        { value: '', source: '' },
        { value: '', source: '' },
        { value: '7', source: 'emr-data' },
      ],
      [
        { value: 'Drugs', source: 'emr-data' },
        { value: '', source: '' },
        { value: '', source: '' },
        { value: '', source: '' },
        { value: '', source: '' },
      ],
    ],
  },
  questions: [
    {
      date: '4/24/19',
      questions: [
        {
          text:
            'We noticed you waking up and moving multiple times last night. Were you going to the bathroom?',
          answer: 'Yes',
          source: 'abnormal',
        },
      ],
    },
    {
      date: '4/14/19',
      questions: [
        {
          text: 'What is your neck circumference?',
          answer: '15-17 inches (38-43 cm)',
          source: 'jhh-data',
        },
        {
          text: 'What is your waist circumference?',
          answer: 'More than 40 inches (102 cm)',
          source: 'abnormal',
        },
      ],
    },
    {
      date: '4/12/19',
      questions: [
        {
          text:
            'Over the last 7 days, how often did you consume canned or processed food products? This includes pre-made sandwiches, ready meals and canned soups.',
          answer: 'Everyday',
          source: 'abnormal',
        },
        {
          text:
            'Over the last 7 days, how often did you eat food containing processed carbohydrates (this includes white bread, white pasta, oatmeal, cereal and crackers)?',
          answer: 'Everyday',
          source: 'abnormal',
        },
        {
          text:
            'Over the last 7 days, how often did you drink sugar-sweetened beverages? This includes fruit juice, sports drinks, energy drinks and soda.',
          answer: 'At least twice a week',
          source: 'abnormal',
        },
        {
          text:
            'Over the last 7 days, how often did you have dessert or consume sweets/candy?',
          answer: 'Rarely',
          source: 'jhh-data',
        },
        {
          text:
            'Over the last 7 days, how often did you eat at a restaurant or order takeout?',
          answer: '3 to 6 times per week',
          source: 'abnormal',
        },
        {
          text:
            'Are starchy foods normally a part of your meal? This includes bread, pasta, rice and potatoes.',
          answer: 'Yes',
          source: 'abnormal',
        },
      ],
    },
    {
      date: '4/10/19',
      questions: [
        {
          text:
            'Over the past few months, have you noticed that your vision is blurred?',
          answer: 'Yes',
          source: 'abnormal',
        },
        {
          text:
            'Over the past few months, have you noticed that you are more thirsty than usual?',
          answer: 'No',
          source: 'jhh-data',
        },
        {
          text:
            'Over the past few months, have you noticed that you are more hungry than usual?',
          answer: 'No',
          source: 'jhh-data',
        },
        {
          text:
            'Over the past few months, have you noticed that you are urinating more frequently than usual?',
          answer: 'No',
          source: 'jhh-data',
        },
      ],
    },
    {
      date: '4/09/19',
      questions: [
        {
          text:
            'Over the course of a typical week, how often do you wake up to urinate during the night?',
          answer: '3-4 times a night',
          source: 'jhh-data',
        },
      ],
    },
    {
      date: '4/07/19',
      questions: [
        {
          text: 'Approximately how much did you weigh five years ago?',
          answer: '160 lbs (25% increase)',
          source: 'abnormal',
        },
      ],
    },
  ],
};

export default REPORT_DATA;
