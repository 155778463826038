import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';
import useLocalStorage from '@rehooks/local-storage';
import parseJwtToken from 'utils/parseJwtToken';
import convert from 'xml-js';
import { debounce, get, isNil } from 'lodash';
import {
  clearNewMessages,
  notifyNewMessages,
  sendPushNotification,
} from 'store/chatMessages/actions';
import './chat.scss';
import { clearWelcomeMessage } from 'store/appState/actions';

const Chat = () => {
  const dispatch = useDispatch();
  const [token] = useLocalStorage('token');
  const converseInstance = useRef(null);
  const initialized = useRef(null);
  const { welcomeMessage } = useSelector(state => state.appState);
  const [isOpen, setIsOpen] = useState(false);

  const { id: currentPatientId } = useSelector(state => state.currentPatient);
  const { first, last } = useSelector(state => state.auth.data);

  const { id: loggedInDatalogist } = parseJwtToken(token);
  const domain = 'jawbonehealth.com';
  const username = `user_${loggedInDatalogist.uid}@${domain}`;
  const password = md5(username);

  useEffect(() => {
    if (!initialized.current && !converseInstance.curent && first) {
      const nativeWS = WebSocket;
      let connection;
      window.WebSocket = url => {
        connection = new nativeWS(url);
        connection.addEventListener('message', handleMessage);
        return connection;
      };

      window.converse.initialize({
        allow_chat_pending_contacts: true,
        allow_contact_removal: false,
        allow_logout: false,
        allow_non_roster_messaging: true,
        auto_login: true,
        allow_public_bookmarks: true,
        muc_domain: `conference.${domain}`,
        auto_subscribe: true,
        auto_join_on_invite: true,
        show_controlbox_by_default: false,
        allow_reconnect: true,
        enable_muc_push: true,
        notify_all_room_messages: true,
        show_desktop_notifications: 'all',
        notification_delay: 0,
        play_sounds: true,
        keepalive: false,
        websocket_url: process.env.REACT_APP_JABBER_SERVER,
        jid: username,
        password,
        nickname: `${first}-${last}`,
        singleton: false,
        view_mode: 'embedded',
        whitelisted_plugins: ['exposeApi'],
      });

      try {
        window.converse.plugins.add('exposeApi', {
          initialize: function() {
            converseInstance.current = this._converse;
          },
        });
      } catch (error) {
        console.log('CHAT ERROR', error);
      }
      initialized.current = true;
    }
    // eslint-disable-next-line
  }, [first]);

  // Join patient room on entering Patient page
  useEffect(() => {
    if (converseInstance.current && initialized.current) {
      if (Boolean(currentPatientId)) {
        const { api } = converseInstance.current;
        const patientRoom = `user_${currentPatientId}@conference.${domain}`;
        const roomExists = api.rooms.get(patientRoom);
        if (!roomExists) {
          api.rooms.open(patientRoom);
          api.chatviews.get(patientRoom)?.el?.classList?.remove('hidden'); // eslint-disable-line no-unused-expressions
        }
      }
    }

    // eslint-disable-next-line
  }, [currentPatientId, dispatch]);

  // Clear new message notification on entering the Patient page
  useEffect(() => {
    if (currentPatientId) dispatch(clearNewMessages(btoa(currentPatientId)));
  }, [currentPatientId, dispatch]);

  useEffect(() => {
    if (converseInstance.current && initialized.current) {
      if (welcomeMessage) {
        const { env } = window.converse;
        const { api } = converseInstance.current;
        const msg = env
          .$msg({ from: username, to: welcomeMessage.to, type: 'groupchat' })
          .c('body')
          .t(welcomeMessage.message);
        api.send(msg);
        dispatch(clearWelcomeMessage());
      }
    }
  }, [welcomeMessage, dispatch, username]);

  // Send push notification on message sent
  useEffect(() => {
    if (converseInstance.current) {
      const { api } = converseInstance.current;
      api.listen.on('messageSend', message => {
        const { from } = message.attributes;
        const userId = from.match(/\d+@/g)[0].replace('@', '');
        sendPushNotificationDebounced(userId);
      });
    }
    // eslint-disable-next-line
  }, [converseInstance.current]);

  const handleMessage = event => {
    const parsedEvent = convert.xml2js(event.data, { compact: true });
    if (!isNil(parsedEvent) && parsedEvent.hasOwnProperty('message')) {
      const text = get(parsedEvent, 'message.body._text');
      const attributes = get(parsedEvent, 'message._attributes');
      const { from, to, type } = attributes;
      if (text !== '' && to.includes(username) && type === 'groupchat') {
        const id = from.match(/\d+/)[0];
        const encodedId = btoa(id);
        dispatch(notifyNewMessages(encodedId));
      }
    }
  };

  const sendPushNotificationDebounced = debounce(id => {
    dispatch(sendPushNotification(id));
  }, 5000);

  return [
    !isOpen && first ? (
      <div
        className={'hide-custom-chat-title'}
        onClick={() => setIsOpen(!isOpen)}
      >
        Chat
      </div>
    ) : null,
    <section className={isOpen ? 'chat-container' : 'hide-chat-container'}>
      <div
        className={isOpen ? 'custom-chat-title' : 'hide-custom-chat-title'}
        onClick={() => setIsOpen(!isOpen)}
      >
        Chat
      </div>
      <div id="conversejs"></div>
    </section>,
  ];
};

export default Chat;
