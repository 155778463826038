import React, { useState } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import useWindowSize from 'utils/useWindowSize';
import style from './Viz.module.scss';
import 'react-dates/lib/css/_datepicker.css';

const DatePicker = ({ date, handleChangeDate }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const { width } = useWindowSize();
  const isSmallWindow = width <= 768;

  return (
    <section className={style.daySelector}>
      <span className={style.vizControlLabel}>Date</span>
      <DateRangePicker
        displayFormat="MMM D"
        endDate={date.end}
        endDateId="date.end"
        focusedInput={focusedInput}
        isOutsideRange={date => date > moment().endOf('day')}
        onDatesChange={handleChangeDate}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        startDate={date.start}
        startDateId="date.start"
        minimumNights={0}
        orientation={isSmallWindow ? `vertical` : `horizontal`}
        showClearDates
      />
    </section>
  );
};

export default DatePicker;
