import React, { useState } from 'react';
import { Message } from 'semantic-ui-react';
import { AccordionBody } from 'components';
import AssociatedDataAccordion from 'components/AssociatedDataAccordion';
import style from 'containers/patient/patient.module.scss';
/*
dataSchema = [
  {
    field,//An object of keys and string values
    associatedData // an object of unknown data to be displayed as JSON, is made of "display" fields from FHIR Service. 
  }
  ...
] */

const ArrayOfObjectsWriter = ({ data }) => {
  const [activeAccordions, setActiveAccordions] = useState([]);

  const toggleAccordion = index => () => {
    if (activeAccordions.includes(index)) {
      setActiveAccordions([...activeAccordions.filter(item => index !== item)]);
    } else {
      setActiveAccordions([...activeAccordions, index]);
    }
  };

  return data.length ? (
    data.map((item, idx) => {
      const { fields, associatedData } = item;
      return (
        <span key={idx}>
          <section className={style.accordionData}>
            {Object.keys(fields)
              .slice()
              .sort()
              .map((key, idx2) => (
                <div
                  className={style.patientCardData}
                  key={`${fields[key]}-${idx2}`}
                >
                  <span className={style.patientCardDataLabel}>
                    {key.replace(/[A-Z]/g, v => ` ${v}`).replace(/_/g, ' ')}
                  </span>
                  <span className={style.patientCardDataValue}>
                    {fields[key] || '---'}
                  </span>
                </div>
              ))}
          </section>
          {associatedData && (
            <AccordionBody
              className={style.associatedData}
              active={activeAccordions.includes(idx)}
              onClick={toggleAccordion(idx)}
              title="Associated Data"
              index={idx}
            >
              <AssociatedDataAccordion data={associatedData} />
            </AccordionBody>
          )}
          <hr />
        </span>
      );
    })
  ) : (
    <Message
      header="Empty data"
      content="Patient has no data for this section"
    />
  );
};

export default ArrayOfObjectsWriter;
