/* eslint-disable no-underscore-dangle */
import { data as AtlasData } from 'azure-maps-control';

export default class BringDataIntoViewControl {
  constructor(options) {
    this._options = {
      style: 'light',
      padding: 100,
    };
    this._container = null;
    this._button = null;
    this._darkColor = '#011c2c';
    this._buttonCSS =
      '.atlas-map-bringDataIntoViewBtn{margin:0;padding:0;border:none;border-collapse:collapse;width:32px;height:32px;text-align:center;cursor:pointer;line-height:32px;background-repeat:no-repeat;background-size:20px;background-position:center center;z-index:200;box-shadow:0px 0px 4px rgba(0,0,0,0.16);background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAABcRAAAXEQHKJvM/AAAAB3RJTUUH4wMIFTgXULHJFAAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAhUlEQVRo3u3asQ2AIBAF0MO4p42FI1nYOKlugAUnJvL+BLzwcxGw7Md5RUO2dSmRkNZ1TPGTgICADAIptbGXNVqzUluraoGADAKZv/rIy56UqvVFartVVEu1QEBAQEBAQEBAQEAaz+xuGlXrpWr1PlvbERAQEJCIhzfEnqPYLxwgICBjQW7ewSYPr/zk7gAAAABJRU5ErkJggg==);}' +
      '.atlas-map-bringDataIntoViewBtn:hover{background-color:red;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAABcRAAAXEQHKJvM/AAAAB3RJTUUH4wMIFTcYR5bISgAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAABDklEQVRo3u2aMQ+CMBSE7wjGwURXd1cHhb8Cv1L/ikDi7Goc1UESElMXN0UiLaToXcIEbe57vFd4TbneZnsAS7RUnkSEA623mbEZH9hA+KQAPyKBDAXEeOz5rbew5mECWAA4NUxKZ+6MGZMcNQRxDuDwDQgAlHkS3foKc5HGFYCqYYkuVewCEcifg4QWP3mzDr43zJPo0isIgHNHwWWbe8GHIaVv6ZMn0fHp+eWiRWqZjszyr4tdIAIRiEAEIhCBCEQgAhHI8Hr2ugbJGDMr0vjqk9nVZjclefnqjZCc+Bb1T55Cy7md76K0Tq2+e2sVu0AEIpDOQO4e+q31RBdbny6WYhdHOFQjAhGIQIajB80LO3KY+u0wAAAAAElFTkSuQmCC);}';

    if (options) {
      this._options = { ...this._options, ...options };
    }
  }

  onAdd(map) {
    this._map = map;

    const ariaLabel = 'Bring data into view';

    let color = this._options.style || 'light';

    if (color === 'light') {
      color = 'white';
    } else if (color === 'dark') {
      color = this._darkColor;
    } else if (color === 'auto') {
      // Color will change between light and dark depending on map style.
      this._map.events.add('styledata', () => {
        this._mapStyleChanged();
      });
      color = this._getColorFromMapStyle();
    }

    // Add the CSS style for the control to the DOM.
    const style = document.createElement('style');
    style.innerHTML = this._buttonCSS;
    document.body.appendChild(style);

    // Create a button container.
    this._container = document.createElement('div');
    this._container.classList.add('azure-maps-control-container');
    this._container.setAttribute('aria-label', ariaLabel);
    this._container.style.flexDirection = 'column';

    // Create the button.
    this._button = document.createElement('button');
    this._button.classList.add('atlas-map-bringDataIntoViewBtn');
    this._button.style.backgroundColor = color;
    this._button.setAttribute('title', ariaLabel);
    this._button.setAttribute('alt', ariaLabel);
    this._button.setAttribute('type', 'button');
    this._button.addEventListener('click', () => {
      let data = [];
      this._map.sources.sources.forEach(s => {
        if (s.toJson) {
          data = data.concat(s.toJson().features);
        }
      });

      let bounds = null;

      if (data.length > 0) {
        bounds = AtlasData.BoundingBox.fromData(data);
      }
      const pos = [];
      this._map.markers.markers.forEach(marker => {
        pos.push(marker.getOptions().position);
      });

      if (pos.length > 0) {
        const b = AtlasData.BoundingBox.fromPositions(pos);
        if (bounds === null) {
          bounds = b;
        } else {
          bounds = AtlasData.BoundingBox.merge(bounds, b);
        }
      }

      const l = this._map.getLayers();
      for (let i = 0; i < l.length; i++) {
        if (l[i].getOptions) {
          const b = AtlasData.BoundingBox.fromPositions(
            l[i].getOptions().coordinates
          );

          if (bounds === null) {
            bounds = b;
          } else {
            bounds = AtlasData.BoundingBox.merge(bounds, b);
          }
        }
      }

      if (bounds !== null) {
        const w = AtlasData.BoundingBox.getWidth(bounds);
        const h = AtlasData.BoundingBox.getHeight(bounds);

        // If the bounding box is really small, likely a single point, use center/zoom.
        if (w < 0.000001 || h < 0.000001) {
          this._map.setCamera({
            center: AtlasData.BoundingBox.getCenter(bounds),
          });
        } else {
          this._map.setCamera({
            bounds,
          });
        }
      }
    });

    this._container.appendChild(this._button);
    return this._container;
  }

  onRemove() {
    if (this._container) {
      this._container.remove();
      this._container = null;
    }

    if (this._options.style === 'auto') {
      this._map.events.remove('styledata', () => {
        this._mapStyleChanged();
      });
    }
    this._map = null;
  }

  _mapStyleChanged() {
    if (this._button) {
      this._button.style.backgroundColor = this._getColorFromMapStyle();
    }
  }

  _getColorFromMapStyle() {
    const { style } = this._map.getStyle();
    let color = 'white';

    switch (style) {
      // When the style is dark (i.e. satellite, night), show the dark colored theme.
      case 'satellite':
      case 'satellite_road_labels':
      case 'grayscale_dark':
      case 'night':
        color = this._darkColor;
        break;
      // When the style is bright (i.e. road), show the light colored theme.
      case 'road':
      case 'grayscale_light':
      default:
        break;
    }

    return color;
  }
}
