import { createReducer } from '@reduxjs/toolkit';
import { handleActionError, handleActionStart } from 'store/utils';
import {
  clearCurrentPatient,
  fetchUserStatus,
  markPatientAsConfirmed,
  setCurrentPatient,
} from 'store/currentPatient/actions';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchUserStatus.STARTED]: handleActionStart,
  [fetchUserStatus.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload.data;
  },
  [markPatientAsConfirmed]: state => {
    state.isLoading = false;
    state.error = null;
    state.data = true;
  },
  [fetchUserStatus.FAILED]: handleActionError,
  [setCurrentPatient]: (_, action) => action.payload.isOnboarded,
  [clearCurrentPatient]: () => initialState,
});
