import React, { useEffect, useState } from 'react';
import { Accordion, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';

import MealTimeline from '../MealTimeline';
import { fetchFoodData } from 'store/currentPatient/actions';
import { fetchPatientTicks } from 'store/patients/actions';
import useWindowSize from 'utils/useWindowSize';
import { sortDates, populateDay, sortDateByProperty } from 'utils/mealsUtils';
import { fromDayToTzHour } from 'utils/timeToText';
import { TICK_DATA_TYPES } from 'utils/constants';
import 'react-dates/lib/css/_datepicker.css';
import style from 'containers/patient/patient.module.scss';

const Log = () => {
  const id = useSelector(state => state.currentPatient.id);
  const { isLoading: foodLoading, error, data } = useSelector(
    state => state.currentPatient.foodData
  );
  const {
    data: { tz },
  } = useSelector(state => state.currentPatient.timeZone);
  const {
    data: { glucose = [] },
    isLoading: tickLoading,
  } = useSelector(state => state.patients.tickData);
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [date, setDate] = useState({
    from_date: moment().subtract(7, 'days'),
    to_date: moment().add(1, 'day'),
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const isSmallWindow = width <= 768;
  const loadingMessage = foodLoading
    ? 'Meals loading'
    : tickLoading
    ? 'Glucose loading'
    : '';
  useEffect(() => {
    if (id) updateFoodData();
    // eslint-disable-next-line
  }, [dispatch, id, date]);

  const updateFoodData = () => {
    const [startDate, endDate] = [date.from_date, date.to_date].map(
      (date, idx) => {
        const hoursToSet = ['00:00', '23:59'];
        const dateOnTz = fromDayToTzHour(tz, date, hoursToSet[idx]);
        return new Date(dateOnTz).getTime();
      }
    );
    const mealsDates = { from_date: startDate / 1000, to_date: endDate / 1000 };
    const glucoseArgs = {
      types: [TICK_DATA_TYPES['glucose']],
      patientId: id,
      startDate,
      endDate,
    };
    dispatch(fetchFoodData(id, mealsDates));
    dispatch(fetchPatientTicks(glucoseArgs));
  };

  const handleChangeDate = ({ startDate, endDate }) => {
    const newStartDate = moment(endDate?.valueOf())?.startOf('day');
    setDate({
      from_date:
        startDate === endDate
          ? newStartDate
          : startDate?.startOf('day') ?? null,
      to_date: endDate?.endOf('day') ?? null,
    });
  };

  /*----Parsing Data---*/

  const dateObject = {};

  glucose
    .slice()
    .sort(sortDateByProperty('time'))
    .forEach(populateDay.bind(this, tz, 'time', dateObject, 'glucose'));

  data
    .slice()
    .sort(sortDateByProperty('meal_time'))
    .forEach(populateDay.bind(this, tz, 'meal_time', dateObject, 'meals'));

  const sortedDates = Object.keys(dateObject).sort(sortDates);

  /* rendering */
  return error ? (
    <p>Something went wrong fetching patient`s meal's data.</p>
  ) : (
    <>
      <div className={style.datePickerBox}>
        <DateRangePicker
          block
          displayFormat="MMM D"
          endDate={date.to_date}
          endDateId="date.to_date"
          focusedInput={focusedInput}
          isOutsideRange={date => date > moment().endOf('day')}
          onDatesChange={handleChangeDate}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          startDate={date.from_date}
          startDateId="date.from_date"
          minimumNights={0}
          orientation={isSmallWindow ? `vertical` : 'horizontal'}
        />
        {loadingMessage && (
          <Loader active inline="centered" size="mini">
            {loadingMessage}
          </Loader>
        )}
        <span>{`( ${tz || 'Local'} Time )`}</span>
      </div>

      <Accordion fluid styled exclusive={false}>
        {sortedDates.length ? (
          sortedDates.map((day, idx) => (
            <MealTimeline tz={tz} data={dateObject[day]} day={day} key={idx} />
          ))
        ) : (
          <p>This patient does not have food's data to show.</p>
        )}
      </Accordion>
    </>
  );
};

export default Log;
