import Healthcloud from './healthcloud';
import Timebandit from './timebandit';
import User from './user';
import Fhir from './fhir';
import Factuary from './factuary';
export const HealthcloudApi = new Healthcloud();
export const TimebanditApi = new Timebandit();
export const UserApi = new User();
export const FactuaryApi = new Factuary();
export const FhirApi = new Fhir();
