import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AccordionBody, EmrNotConnectedMessage } from 'components';
import HOCFieldParser from './HOCFieldParser';

const HOCAccordion = ({ source, fetcher, id, title, parser, isFhirUser }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { data, isLoading } = source;

  useEffect(() => {
    if (isFhirUser && open) {
      dispatch(fetcher(id));
    }
    // eslint-disable-next-line
  }, [open, isFhirUser]);

  const accordionProps = {
    title,
    active: open,
    onClick: () => setOpen(!open),
  };
  const Render = HOCFieldParser(...parser);
  return (
    <AccordionBody {...accordionProps}>
      {!isFhirUser ? (
        <EmrNotConnectedMessage />
      ) : (
        <>
          {isLoading && <p>loading...</p>}
          <Render data={data} />
        </>
      )}
    </AccordionBody>
  );
};

export default HOCAccordion;
