import React from 'react';
import { Grid, Header, Divider } from 'semantic-ui-react';
import style from '../../../patientSummary.module.scss';
import ReportChart from '../../../ReportChart';

const MonitoringDevices = ({ date, tz, tickData }) => {
  const charts = [
    {
      chart: 'glucose',
      content: 'Glucose',
    },

    {
      chart: 'blood_pressure',
      content: 'Blood Pressure',
    },
    {
      chart: 'weight',
      content: 'Weight',
    },
  ];
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column className={style.headerTitle}>
          <Header content="Monitoring Devices: CGM, BGM, BPM, Weight" />
          <Divider />
        </Grid.Column>
      </Grid.Row>
      {charts.map(({ chart, content }) => (
        <Grid.Row key={chart}>
          <Grid.Column className={style.headerTitle}>
            <Header content={content} as="h4" />
            <Divider />
            <ReportChart
              date={date}
              chart={chart}
              tz={tz}
              tickData={tickData}
            />
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};

export default MonitoringDevices;
