import React, { forwardRef } from 'react';
import CKEditor from 'ckeditor4-react';
const editorConfig = {
  allowedContent: true,
  toolbarGroups: [
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
    },
    { name: 'links' },
    { name: 'insert' },
    { name: 'tools' },
    { name: 'document', groups: ['document', 'doctools'] },
    { name: 'styles' },
    { name: 'colors' },
  ],
  editorUrl: 'https://cdn.ckeditor.com/4.13.1/standard-all/ckeditor.js',
  extraPlugins: ['print', 'pagebreak'],
  removePlugins: ['elementspath'],
  contentsCss: '/patientSummaryReport.css',
};

const ECRTextEditor = ({ toolbarRef, config, ...restProps }, ref) => {
  const newConfig = {
    ...editorConfig,
    ...config,
  };

  return (
    <CKEditor
      type="classic"
      config={newConfig}
      onInit={editor => {
        ref.current = editor;
      }}
      {...restProps}
    />
  );
};

export default forwardRef(ECRTextEditor);
