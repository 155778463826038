import React from 'react';
import { Grid, Header, Table, Divider } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { preventionsFactory } from 'utils/patientSummaryReport/factory';

const Preventive = () => {
  const { isLoading: immunizationsLoading, data: immunizations } = useSelector(
    state => state.currentPatient.immunizations
  );

  const { data: observations, isLoading: observationsLoading } = useSelector(
    state => state.currentPatient.observations
  );

  const { preventiveExams, headerFields } = preventionsFactory(
    immunizations,
    observations
  );
  const sectionTitle = 'Prevention';
  const isLoading = immunizationsLoading || observationsLoading;
  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <Header content={sectionTitle} />
          <Divider />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table celled fixed columns={4}>
            <Table.Header>
              <Table.Row>
                {headerFields.map(({ text }) => (
                  <Table.HeaderCell key={text}>{text}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading ? (
                <Table.Row>
                  <Table.Cell>Loading...</Table.Cell>
                </Table.Row>
              ) : (
                preventiveExams.map((item, key) => (
                  <Table.Row key={key}>
                    {headerFields.map(({ field }) => (
                      <Table.Cell key={field}>{item[field]}</Table.Cell>
                    ))}
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
export default Preventive;
