import React from 'react';
import style from 'components/Viz/Chart.module';
import { Popup } from 'semantic-ui-react';
import { utcToTzFormat } from 'utils/timezone';
const CombinedInsulinBars = ({
  data,
  keyAccessor,
  xAccessor,
  yAccessor,
  width,
  heightAccessor,
  tz,
  ...props
}) => {
  const styleObject = value =>
    value === 'bolus' ? style.insulinColor : style.insulinBasalColor;
  return data.map((d, i) => {
    const height = value => heightAccessor(d, value);
    return (
      <Popup
        key={i}
        content={
          <p>
            <b>Basal:</b>
            {` ${d.basal.toFixed(2)} U`}
            <br />
            <b>Bolus:</b>
            {` ${d.bolus.toFixed(2)} U`}
          </p>
        }
        header={utcToTzFormat(d.time, tz, 'YYYY/MM/DD h A')}
        trigger={
          <g>
            {['basal', 'bolus'].map(value => (
              <rect
                {...props}
                key={keyAccessor(value, i)}
                x={xAccessor(d, i)}
                y={yAccessor(d, value)}
                width={width}
                height={height(value)}
                style={{
                  fillOpacity: 1,
                  ...styleObject(value),
                }}
              />
            ))}
          </g>
        }
      />
    );
  });
};

export default CombinedInsulinBars;
