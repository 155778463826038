import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { fetchConfirmedPatients } from 'store/patients/actions';
import {
  alertsNotified,
  addConfirmedPatient,
  resetConfirmedPatients,
} from '../actions';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  notified: false,
  currentPage: 0,
  allowMoreConfirmed: true,
};

export default createReducer(initialState, {
  [fetchConfirmedPatients.STARTED]: handleActionStart,
  [fetchConfirmedPatients.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (state.currentPage >= action.payload.currentPage) {
      state.data = action.payload.data;
    } else {
      state.data = state.data.concat(action.payload.data);
    }
    state.currentPage = action.payload.currentPage;
    state.allowMoreConfirmed = action.payload.data?.length === 50;
  },
  [fetchConfirmedPatients.FAILED]: handleActionError,
  [alertsNotified]: state => {
    state.notified = true;
  },
  [addConfirmedPatient]: (state, action) => {
    const existPatient = state.data.find(
      patient => patient.id === action.payload.id
    );
    if (!existPatient) {
      state.data.push(action.payload);
    }
  },
  [resetConfirmedPatients]: state => {
    state.data = [];
  },
});
