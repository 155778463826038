import moment from 'moment-timezone';

export const utcDateToTimestamp = date => {
  if (typeof date === 'number') {
    let timestamp = date + '';
    return timestamp.length === 13 ? date : date * 1000;
  } else {
    return moment.utc(date).valueOf();
  }
};

// utc could be in seconds, miliseconds, or date format that will be taken as utc.
export const utcToTzMomentObject = (utc, tz) => {
  let utcTimestamp = utcDateToTimestamp(utc);
  return tz ? moment.utc(utcTimestamp).tz(tz) : moment.utc(utcTimestamp);
};

export const utcToTz = (utc, tz) => {
  let tzMomentObject = utcToTzMomentObject(utc, tz);
  return tz ? tzMomentObject.format() : tzMomentObject._d;
};

export const utcToTzFormat = (utc, tz, format = 'HH') => {
  let tzMomentObject = utcToTzMomentObject(utc, tz);
  return tzMomentObject.format(format);
};

export const dateStringToTzMoment = (dateString, tz) =>
  tz ? moment.tz(dateString, tz) : moment(dateString);

/**
 *Take two Moment dates and return an array of dates strings from start to end, on epoch format.
 *
 * @param {Moment.object} startDate
 * @param {Moment.object} endDate
 */
export const enumerateDaysBetweenDates = (startDate, endDate) => {
  if (startDate.valueOf() === endDate.valueOf()) {
    return [startDate.format('X')];
  }
  var dates = [startDate];

  var currDate = startDate.clone();

  while (currDate.add(1, 'days').diff(endDate) < 0) {
    dates.push(currDate.clone());
  }
  dates.push(endDate);

  return dates.map(date => date.format('X'));
};
