import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { clearCurrentDocument } from 'store/currentPatient/actions/fhir';
import { Modal } from 'semantic-ui-react';

const DownloadDocumentModal = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    data: { file, type },
  } = useSelector(state => state.currentPatient.currentDocument);
  const handleClose = () => dispatch(clearCurrentDocument());

  const open = isLoading || !isEmpty(file);
  useEffect(() => {
    if (file) {
      const fileDownload = new Blob([file], { type });
      const fileDownloadURL = URL.createObjectURL(fileDownload);
      window.open(fileDownloadURL, '_blank');
      setTimeout(handleClose, 1000);
    }
  });
  return (
    <Modal open={open} onClose={handleClose}>
      <Modal.Content>
        <p>{isLoading && 'Loading...'}</p>
        <p>Procesing file</p>
      </Modal.Content>
    </Modal>
  );
};

export default DownloadDocumentModal;
