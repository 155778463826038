import React from 'react';
import { Loader, Header, Icon, Button } from 'semantic-ui-react';
import FhirCard from './components/FhirCard';
import JhhCard from './components/JhhCard';
import { useSelector, useDispatch } from 'react-redux';
import { reconcilePatientEMR } from 'store/currentPatient/actions/fhir';
import style from 'containers/patient/patient.module.scss';

const PatientCard = () => {
  const dispatch = useDispatch();
  const { isLoading, data: isFhirUser } = useSelector(
    state => state.currentPatient.isFhirUser
  );
  const patientId = useSelector(state => state.currentPatient.id);

  const handleReconcile = () => {
    dispatch(reconcilePatientEMR(patientId));
  };

  if (isLoading) {
    return <Loader active />;
  }
  return (
    <>
      {isFhirUser ? (
        <div className={style.patientCardTitle}>
          <Header as="h3">Patient Card</Header>
          <Button icon size="mini" onClick={handleReconcile}>
            <Icon name="redo" />
          </Button>
        </div>
      ) : (
        <Header as="h3">Patient Card</Header>
      )}
      <section className={style.patientCard}>
        {isFhirUser ? <FhirCard /> : <JhhCard />}
      </section>
    </>
  );
};

export default PatientCard;
