import style from 'components/Viz/Viz.module.scss';

export const chartOptions = [
  {
    key: 'sleep',
    value: 'sleep',
    text: 'Sleep',
    color: style.sleepLegendColor,
  },
  {
    key: 'movement',
    value: 'movement',
    text: 'Movement',
    color: style.movementLegendColor,
  },
  { key: 'hr', value: 'hr', text: 'Heart Rate', color: style.hrvLegendColor },
  { key: 'hrv', value: 'hrv', text: 'HRV', color: style.hrvLegendColor },
  {
    key: 'blood_pressure',
    value: 'blood_pressure',
    text: 'Blood Pressure',
    color: style.bloodPressureLegendColor,
  },
  {
    key: 'weight',
    value: 'weight',
    text: 'Weight',
    color: style.weightLegendColor,
  },
  {
    key: 'glucose',
    value: 'glucose',
    text: 'Glucose',
    color: style.glucoseLegendColor,
  },
  {
    key: 'insulin',
    value: 'insulin',
    text: 'Insulin',
    isComposed: true,
    metrics: [
      { text: 'Basal', color: style.insulinBasalLegendColor },
      { text: 'Bolus', color: style.insulinLegendColor },
    ],
  },
  {
    key: 'resp_rate',
    value: 'resp_rate',
    text: 'Respiration Rate',
    color: style.respRateLegendColor,
  },
  {
    key: 'temp',
    value: 'temp',
    text: 'Temperature',
    color: style.temperatureLegendColor,
  },
  {
    key: 'spo2',
    value: 'spo2',
    text: 'SpO2',
    color: style.spo2Color,
  },
  {
    key: 'battery_level',
    value: 'battery_level',
    text: 'Battery Level',
    color: style.batteryLevelLegendColor,
  },
  {
    key: 'band_removed',
    value: 'band_removed',
    text: 'Band State',
    color: style.bandStatusLegendColor,
  },
];
