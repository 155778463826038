import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import style from 'containers/patient/patient.module.scss';

const AccordionBody = ({ children, ...props }) => {
  return (
    <>
      <Accordion.Title className={style.accordionTitle} {...props}>
        <Icon name={props.iconName || 'dropdown'} />
        {props.title}
      </Accordion.Title>
      <Accordion.Content active={props.active}>{children}</Accordion.Content>
    </>
  );
};

export default AccordionBody;
