import React from 'react';
import style from 'containers/patient/patient.module.scss';

const XAxis = ({ reference, yRange, config }) => (
  <g>
    <g
      ref={reference}
      className={style.axis}
      transform={`translate(0,${yRange[0]})`}
    ></g>
    <text transform={`translate(${config.width / 2},${config.height})`}>
      Hour
    </text>
  </g>
);

export default XAxis;
