import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';

const Radio = ({ extraText, setAnswer }) => {
  const [selectedOption, setSelectedOption] = useState(
    extraText.compiledAnswers
  );

  const handleChangeRadios = (e, { value }) => {
    setAnswer(value);
    setSelectedOption(value);
  };

  return (
    <Form>
      <Form.Group>
        {extraText.options.map(option => (
          <Form.Radio
            key={option.id}
            label={option.text}
            value={option.position - 1}
            checked={selectedOption === option.position - 1}
            onChange={handleChangeRadios}
          />
        ))}
      </Form.Group>
    </Form>
  );
};

export default Radio;
