import React from 'react';
import cx from 'classnames';
import { chartOptions } from 'utils/charts/factory';
import style from './Viz.module.scss';
import 'react-dates/lib/css/_datepicker.css';

const MetricsColorCode = ({ selectedCharts }) => {
  return (
    <section className={style.legendContainer}>
      {selectedCharts.map((chart, i) => {
        const { text, color, isComposed, metrics } =
          chartOptions.find(option => option.key === chart) || {};
        return isComposed ? (
          metrics.map(metric => (
            <div
              key={metric.text}
              className={cx(style.legend, metric.color)}
            >{`${text}/${metric.text}`}</div>
          ))
        ) : (
          <div className={cx(style.legend, color)} key={i}>
            {text}
          </div>
        );
      })}
    </section>
  );
};

export default MetricsColorCode;
