import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPatientNotes,
  updatePatientNotes,
  setCurrentPatient,
  clearCurrentPatient,
  cleanNotes,
} from 'store/currentPatient/actions';

import { handleActionStart, handleActionError } from 'store/utils';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchPatientNotes.STARTED]: handleActionStart,
  [fetchPatientNotes.SUCCEEDED]: (state, action) => {
    state.data = action.payload.data;
    state.isLoading = false;
    state.error = null;
  },
  [cleanNotes]: state => {
    if (state.data && state.data.content) {
      state.data.content = '';
    }
  },
  [setCurrentPatient]: (_, action) => action.payload.notes,
  [clearCurrentPatient]: () => initialState,
  [fetchPatientNotes.FAILED]: handleActionError,
  [updatePatientNotes.FAILED]: handleActionError,
});
