import React, { useState } from 'react';
import style from 'containers/patient/patient.module.scss';
import { Accordion, Icon } from 'semantic-ui-react';
import NotesList from 'components/NotesList';
import { useDispatch } from 'react-redux';
import {
  deleteMetadataNote,
  postMetadataNote,
} from 'store/currentPatient/actions';

const MetadataNotes = ({ notes, mealId, ...ids }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const metadataId = ids.id;

  const handleDelete = noteId => {
    const body = { metadataId, mealId, noteId };
    dispatch(deleteMetadataNote(body));
  };
  const handleCheck = () => {};
  const handleSubmit = value => {
    const body = { metadataId, mealId, note: value };
    dispatch(postMetadataNote(body));
  };
  const dataProps = {
    notes,
    ...ids,
    handleDelete,
    handleSubmit,
    handleCheck,
    canCheck: false,
  };
  return (
    <section className={style.metadataNotes}>
      <Accordion fluid styled exclusive={false}>
        <Accordion.Title active={isOpen} onClick={setOpen.bind(this, !isOpen)}>
          <Icon name={'dropdown'} />
          Notes
        </Accordion.Title>
        <Accordion.Content active={isOpen}>
          <NotesList {...dataProps} />
        </Accordion.Content>
      </Accordion>
    </section>
  );
};

MetadataNotes.defaultProps = {
  notes: [],
};

export default MetadataNotes;
