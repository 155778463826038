import React from 'react';
import { useSelector } from 'react-redux';
import { Message, Header } from 'semantic-ui-react';
import styles from 'containers/proximity-log/proximityLog.module.scss';

const QuarantineAdherence = () => {
  const {
    data: { effectiveness },
  } = useSelector(state => state.currentPatient.covid19);

  return (
    <Message className={styles.covidAlerts}>
      <Header
        as="h4"
        content={
          // eslint-disable-next-line no-restricted-globals
          isNaN(effectiveness)
            ? 'N/A'
            : `${effectiveness.toFixed(1)}% of the day at home`
        }
      />
    </Message>
  );
};

export default QuarantineAdherence;
