import React from 'react';
import { Link } from '@reach/router';
import { Dropdown, Button, Icon } from 'semantic-ui-react';
import style from '../../header.module.scss';
const MenuDropdown = ({
  getHeaderText,
  handleClose,
  patientTabs,
  location,
  getMenuText,
}) => {
  return (
    <section className={style.dropdownView}>
      <Dropdown text={getMenuText(location)}>
        <Dropdown.Menu>
          {patientTabs.map(tab => (
            <Dropdown.Item key={tab.id}>
              <Link to={`/patient/${tab.id}`}>
                {getHeaderText(tab)}
                <Button
                  icon
                  className={style.tabClose}
                  onClick={e => handleClose(e, tab.id, location)}
                >
                  <Icon name="close" />
                </Button>
              </Link>
            </Dropdown.Item>
          ))}
          {!patientTabs.length && <Dropdown.Item text="Empty" />}
        </Dropdown.Menu>
      </Dropdown>
    </section>
  );
};

export default MenuDropdown;
