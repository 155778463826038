import React from 'react';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import { Alert } from 'components';
import { getBiometricAlerts } from 'utils/alerts';
import styles from 'containers/proximity-log/proximityLog.module.scss';
const BiometricsAlerts = ({ biometrics }) => {
  const { redAlerts, greenAlerts } = getBiometricAlerts(biometrics, true);
  const allAlerts = [...redAlerts, ...greenAlerts];
  let rightAlerts = [];
  let leftAlerts = [];
  if (allAlerts.length > 5) {
    rightAlerts = allAlerts.slice(0, 5);
    leftAlerts = allAlerts.slice(5);
  } else {
    rightAlerts = allAlerts;
  }
  return (
    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column>
          {rightAlerts.map((a, idx) => (
            <Alert
              key={idx}
              {...a}
              className={cx(styles.covidAlerts, styles.biometrics)}
              haveHeaderColor
            />
          ))}
        </Grid.Column>
        <Grid.Column>
          {leftAlerts.map((a, idx) => (
            <Alert
              key={idx}
              {...a}
              className={cx(styles.covidAlerts, styles.biometrics)}
              haveHeaderColor
            />
          ))}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BiometricsAlerts;
