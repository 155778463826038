import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import * as d3 from 'd3';
import CrossLines from './CrossLines';
import MealsCircles from './MealsCircles';
import GlucoseLine from './GlucoseLine';
import XAxisComponent from './XAxis';
import YAxisComponent from './YAxis';
import { getTzFormat } from 'utils/mealsUtils';
import { utcToTz } from 'utils/timezone';
import style from 'containers/patient/patient.module.scss';

const MealChart = ({
  meals,
  glucose,
  startDate,
  tz,
  endDate,
  ...circleListProps
}) => {
  const [glucoseLine, setLine] = useState('');
  const [config, setConfig] = useState({
    width: '580',
    height: '300',
    margin: { top: 20, right: 5, bottom: 35, left: 23 },
  });
  const chartBox = useRef();
  const xAxisRef = useRef();
  const yAxisRef = useRef();
  //-------------------Layout Scales
  const xDomain = [new Date(startDate), new Date(endDate)];

  const xRange = [config.margin.left, config.width - config.margin.right];
  const xScale = d3
    .scaleTime()
    .domain(xDomain)
    .range(xRange);

  const yRange = [config.height - config.margin.bottom, config.margin.top];
  const minGlucose = d3.min(glucose, g => g.value);
  const maxGlucose = d3.max(glucose, g => g.value);
  const yScale = d3
    .scaleLinear()
    .domain([
      minGlucose < 50 ? minGlucose - 10 : 50,
      maxGlucose > 170 ? maxGlucose + 10 : 170,
    ])
    .range(yRange);
  // ---------- Axis ----------
  const xAxis = d3
    .axisBottom()
    .scale(xScale)
    .tickFormat(d => getTzFormat(d, tz));

  const yAxis = d3
    .axisLeft()
    .scale(yScale)
    .tickFormat(value => `${value}`);

  const glucoseChart = d3.line();
  glucoseChart.y(d => yScale(d.value));
  glucoseChart.x(d => xScale(new Date(utcToTz(d.time, tz))));

  const convertMealToPixels = time => xScale(new Date(utcToTz(time, tz)));
  const crossLineProps = { yScale, xRange };
  const mealsCircleProps = {
    ...circleListProps,
    meals,
    yScale,
    convertMealToPixels,
  };
  // eslint-disable-next-line
  useLayoutEffect(() => {
    let width = chartBox.current.offsetWidth;
    const height = chartBox.current.offsetHeight;
    width = width > 0 ? width - 50 : width;
    if (config.width !== width || config.height !== height) {
      setConfig(state => ({ ...state, width, height }));
    }
  });

  useEffect(() => {
    d3.select(xAxisRef.current).call(xAxis);
    d3.select(yAxisRef.current).call(yAxis);
    setLine(glucoseChart(glucose));
    // eslint-disable-next-line
  }, [meals, glucose, startDate, endDate, config]);

  return (
    <div className={style.mealTimelineChart} ref={chartBox}>
      <svg width={config.width} height={config.height}>
        <MealsCircles {...mealsCircleProps} />
        <GlucoseLine glucoseLine={glucoseLine} />
        <CrossLines {...crossLineProps} />
        <XAxisComponent reference={xAxisRef} {...{ yRange, config }} />
        <YAxisComponent reference={yAxisRef} {...{ xRange, config }} />
      </svg>
    </div>
  );
};

export default MealChart;
