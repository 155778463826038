import React from 'react';
import style from 'containers/patient/patient.module.scss';
import classNames from 'classnames';
import { Button, Icon } from 'semantic-ui-react';

const LeftIconButton = ({
  handleClick,
  name,
  children,
  containerClassName,
  ...rest
}) => {
  return (
    <div className={classNames(style.leftButtonBox, containerClassName)}>
      {children}
      <Button icon onClick={handleClick} {...rest}>
        <Icon name={name || 'trash'} />
      </Button>
    </div>
  );
};

LeftIconButton.defaultProps = {
  name: 'trash',
  size: 'mini',
  color: 'red',
};

export default LeftIconButton;
