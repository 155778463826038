import React, { createContext, useContext } from 'react';

import style from './Chart.module';

const ChartContext = createContext();
export const useChartDimensions = () => useContext(ChartContext);

const Chart = ({ dimensions, children, style: customStyle, ...props }) => (
  <ChartContext.Provider value={dimensions}>
    <svg
      style={{ ...style.chart, ...customStyle }}
      width={dimensions.width}
      height={dimensions.height}
      {...props}
    >
      <g
        transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}
      >
        {children}
      </g>
    </svg>
  </ChartContext.Provider>
);

export default Chart;
