import React from 'react';

const CrossLines = ({ xRange, yScale }) => (
  <g>
    <line
      x1={xRange[0]}
      x2={xRange[1]}
      y1={yScale(140)}
      y2={yScale(140)}
      stroke={'darkgray'}
      strokeDasharray={4}
    />
    <line
      x1={xRange[0]}
      x2={xRange[1]}
      y1={yScale(70)}
      y2={yScale(70)}
      stroke={'darkgray'}
      strokeDasharray={4}
    />
  </g>
);

export default CrossLines;
