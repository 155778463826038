import { createAction } from '@reduxjs/toolkit';
import * as Msal from 'msal';

import { UserApi } from 'api';
import parseJwtToken from 'utils/parseJwtToken';
import { deleteAllCookies } from 'utils/cookies';
import { clearTabs } from 'store/appState/actions';
import { createActionThunk } from 'redux-thunk-actions';

export const tryAuthentication = createAction('TRY_AUTHENTICATION');
export const failAunthentication = createAction('FAIL_AUTHENTICATION');
export const logoutUser = createAction('LOGOUT');
export const apiError = createAction('API_ERROR');
export const apiErrorReset = createAction('API_ERROR_RESET');

const appConfig = {
  b2cScopes: [process.env.REACT_APP_AZURE_B2C_SCOPE, 'openid'],
};

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_B2C_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_B2C_AUTHORITY,
    validateAuthority: false,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const myMSAL = new Msal.UserAgentApplication(msalConfig);

const loginRequest = {
  scopes: appConfig.b2cScopes,
};

export const fetchAuthData = createActionThunk(
  'auth/FETCH_AUTH_DATA',
  async () => {
    const data = await UserApi.getMyData();
    let savedAlerts = await UserApi.getUserSavedAlerts();
    return { ...data, savedAlerts };
  }
);

export const removeAlert = createAction('auth/REMOVE_ALERT');

export const saveUserAlerts = createActionThunk(
  'auth/SAVE_ALERTS',
  async alerts => {
    const response = await UserApi.saveUserAlerts(alerts);
    return response;
  }
);

export const postAuthSignature = createActionThunk(
  'auth/POST_SIGNATURE',
  async image_file => {
    const signature = await UserApi.postMySignature(image_file);
    return signature;
  }
);

export const authenticate = data => async dispatch => {
  dispatch(tryAuthentication());
  dispatch(clearTabs());
  return UserApi.login(data)
    .then(({ token }) => {
      const tokenData = parseJwtToken(token);
      const tenantGroups = process.env.REACT_APP_TENANT_GROUP ?? '';
      const allowedGroups = [...tenantGroups.split(','), 'jhh_datalogist'];
      const filteredGroups = tokenData.prm.filter(grp =>
        allowedGroups.includes(grp)
      );
      if (
        (tenantGroups && filteredGroups.length > 1) ||
        (!tenantGroups && filteredGroups.length === 1)
      )
        return token;
      return Promise.reject({ message: "You don't have enough permissions." });
    })
    .catch(err => {
      dispatch(
        failAunthentication(
          `ERROR:${err.message}` ||
            'Something went wrong trying to authenticate.'
        )
      );
      return Promise.reject(err);
    });
};

export const authenticateAzure = () => async dispatch => {
  dispatch(tryAuthentication());
  dispatch(clearTabs());
  return myMSAL
    .loginPopup(loginRequest)
    .then(loginResponse => {
      return UserApi.azureLogin(loginResponse.idToken.rawIdToken);
    })
    .then(({ token }) => {
      const tokenData = parseJwtToken(token);
      if (tokenData.prm.includes('jhh_datalogist')) {
        return token;
      }
      return Promise.reject({ message: 'You need datalogist permissions' });
    })
    .catch(err => {
      console.error(err);
      dispatch(
        failAunthentication(
          `ERROR:${err.message}` ||
            'Something went wrong trying to authenticate.'
        )
      );
    });
};

export const logout = () => dispatch => {
  window.localStorage.clear();
  window.sessionStorage.clear();
  deleteAllCookies();
  myMSAL.logout();
  dispatch(logoutUser());
};

export const cleanupBeforeLogin = () => dispatch => {
  window.localStorage.clear();
  window.sessionStorage.clear();
  dispatch(logoutUser());
};

export const handleApiError = errorResponse => dispatch => {
  dispatch(apiError({ error: errorResponse }));
};
