import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import { Header, Image, Loader } from 'semantic-ui-react';

import { Action } from 'components';
import {
  fetchDiseases,
  fetchPatientInteractions,
  markPatientAsConfirmed,
  refreshEffectiveness,
} from 'store/currentPatient/actions';
import { sendWelcomeMessage } from 'store/appState/actions';
import { SEVERITY_TYPES } from 'utils/constants';
import { HealthcloudApi } from 'api';
import { capitalizeWords } from 'utils/textFormats';
import {
  AccountAndInsurance,
  Diseases,
  DiseasesEvidence,
  PatientDocuments,
  PatientRecord,
} from './components';
import AlertBox from '../AlertBox';
import CovidHeader from '../CovidHeader';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import {
  resetConfirmedPatients,
  resetNewPatients,
} from '../../../../../../store/patients/actions';

const Info = ({
  id,
  basicInfo,
  isOnboarded,
  diseases,
  diseasesEvidence,
  setActiveTabIndex,
  markPatientAsConfirmed,
  fetchDiseases,
  sendWelcomeMessage,
}) => {
  const {
    data: {
      first, // eslint-disable-line
      last, // eslint-disable-line
      name,
      image,
    },
    isLoading,
    error,
  } = basicInfo;

  const { isLoading: diseasesLoading, data: diseasesData } = diseases;
  const dispatch = useDispatch();
  const timeZone = useSelector(state => state.currentPatient.timeZone.data);

  useEffect(() => {
    if (
      !diseasesLoading &&
      (isEmpty(diseasesEvidence) || isEmpty(diseasesData))
    ) {
      fetchDiseases(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (timeZone && Object.keys(timeZone).length) {
      dispatch(refreshEffectiveness());
      dispatch(fetchPatientInteractions({ patientId: id }));
    }
  }, [timeZone]); // eslint-disable-line
  const isGlucareEnv = process.env.REACT_APP_CUSTOMER_TENANT === 'glucare';
  const confirmPatient = values => {
    HealthcloudApi.confirmPatient(id)
      .then(() => {
        toast({
          type: 'info',
          icon: 'check circle outline',
          title: 'Welcome message sent',
          description: `${name} is confirmed as a new patient under your care.`,
          time: 3000,
        });
        markPatientAsConfirmed(id);
        /* dispatch(
          addConfirmedPatient({ id, 
            , last, image, onboarded: true })
        );
        dispatch(removeNewPatient(id)); */
        dispatch(resetConfirmedPatients());
        dispatch(resetNewPatients());
        sendWelcomeMessage({
          to: `user_${id}@conference.jawbonehealth.com`,
          message: values.body,
        });
      })
      .catch(() => {
        toast({
          type: 'error',
          icon: 'close',
          title: 'Confirmation failed',
          description: `Please try again later.`,
          time: 3000,
        });
      });
  };

  if (isLoading) return <Loader active />;
  if (error) return <p>Something was wrong with user data</p>;
  return (
    <>
      {isOnboarded.isLoading && <p>Loading...</p>}
      {!isOnboarded.isLoading && !isOnboarded.data && (
        <Action
          level={SEVERITY_TYPES.NEW}
          buttonText="Confirm New Patient"
          message={`New Patient: Confirm ${name}`}
          name={name}
          id={id}
          handleAction={confirmPatient}
        />
      )}
      {isGlucareEnv ? null : (
        <CovidHeader
          patientId={id}
          showInteraction
          covidClick={() => setActiveTabIndex(2)}
        />
      )}
      <AlertBox />
      <SemanticToastContainer />
      <Header as="h2">
        {Boolean(image) && <Image circular src={image} />}
        {capitalizeWords(name)}
      </Header>
      <Diseases />
      <DiseasesEvidence />
      <AccountAndInsurance />
      {isGlucareEnv ? <PatientDocuments /> : null}
      <PatientRecord />
    </>
  );
};

const mapStateToProps = state => ({
  id: state.currentPatient.id,
  basicInfo: state.currentPatient.basicInfo,
  isOnboarded: state.currentPatient.isOnboarded,
  diseases: state.currentPatient.diseases,
  diseasesEvidence: state.currentPatient.diseasesEvidence.data,
});

const mapDispatchToProps = dispatch => ({
  fetchDiseases: id => dispatch(fetchDiseases(id)),
  markPatientAsConfirmed: id => dispatch(markPatientAsConfirmed(id)),
  sendWelcomeMessage: body => dispatch(sendWelcomeMessage(body)),
  fetchPatientInteractions: patientId =>
    dispatch(fetchPatientInteractions({ patientId })),
});
export default connect(mapStateToProps, mapDispatchToProps)(Info);
