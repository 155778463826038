import React from 'react';
import { Modal, Divider } from 'semantic-ui-react';
import style from 'containers/patient/patient.module.scss';
import { labelize } from 'utils/textFormats';
import { useDispatch, useSelector } from 'react-redux';
import { getTzFormat } from 'utils/mealsUtils';
import { utcToTz } from 'utils/timezone';

import {
  clearCurrentMeal,
  updateSingleMeal,
} from 'store/currentPatient/actions';

import { isEmpty } from 'lodash';
import InfoSection from './components/InfoSection';

const SingleMeal = () => {
  const { currentMeal, timeZone } = useSelector(state => state.currentPatient);

  const { data: meal } = currentMeal;
  const {
    data: { tz },
  } = timeZone;

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(clearCurrentMeal());
    dispatch(updateSingleMeal({ id: meal.uid, data: meal }));
  };

  return isEmpty(meal) ? null : (
    <Modal size="small" onClose={handleCancel} open={true} closeIcon>
      <Modal.Header>View/Edit</Modal.Header>
      <Modal.Content scrolling>
        <div className={style.singleMealbox}>
          <img
            src={meal.image_url}
            alt={meal.meal_type}
            className={style.mealImage}
          />
          <section className={style.singleMealSection}>
            {['meal_type', 'upload_time', 'meal_time'].map((field, idx) => (
              <p className={style.mealItem} key={idx}>
                <b>{labelize(field)}</b>
                {['upload_time', 'meal_time'].includes(field)
                  ? ` ${getTzFormat(
                      utcToTz(meal[field], tz),
                      tz,
                      'YYYY-MM-DD HH:mm'
                    )}`
                  : ` ${meal[field]}`}
              </p>
            ))}
          </section>
          <Divider />
          <InfoSection />
        </div>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  );
};

export default SingleMeal;
