const parseSlope = (name, slope) => {
  if (slope < 0) return { name, direction: 'down' };
  if (slope > 0) return { name, direction: 'up' };
};

const getIndicators = (facts = {}, isAlert = false, isExpanded = false) => {
  const {
    h_hrv_sma_slope_7d: hrvSlope7d = 0,
    s_duration_sma_slope_7d: sleepDurationSlope7d = 0,
    s_deep_sma_slope_7d: deepSleepSlope7d = 0,
    m_active_time_sma_slope_7d: moveActiveTimeSlope7d = 0,
    h_hr_sma_slope_7d: hrSlope7d = 0,
    h_rr_sma_slope_7d: rrSlope7d = 0,
    t_skin_slope_7d: tempSkinSlope7d = 0,
    b_spo2_sma_slope_7d: spo2Slope7d = 0,
  } = facts;
  const fields = [
    'HRV',
    'Sleep Duration',
    'SWS',
    'Activity',
    'HR',
    'RHR',
    'RR',
    'T°',
    'SpO2',
  ];
  const indicators = [];
  indicators.push(parseSlope(fields[0], hrvSlope7d));
  indicators.push(parseSlope(fields[1], sleepDurationSlope7d));
  indicators.push(parseSlope(fields[2], deepSleepSlope7d));
  indicators.push(parseSlope(fields[3], moveActiveTimeSlope7d));
  indicators.push(parseSlope(fields[4], hrSlope7d));
  indicators.push(parseSlope(fields[6], rrSlope7d));
  indicators.push(parseSlope(fields[7], tempSkinSlope7d));
  indicators.push(parseSlope(fields[8], spo2Slope7d));

  return indicators.filter(indicator => !!indicator);
};

export default getIndicators;
