import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { fetchPatientTicksSummary } from 'store/reports/actions';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchPatientTicksSummary.STARTED]: handleActionStart,
  [fetchPatientTicksSummary.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload;
  },
  [fetchPatientTicksSummary.FAILED]: handleActionError,
});
