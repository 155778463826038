import moment from 'moment';
import { createAction } from '@reduxjs/toolkit';
import { createActionThunk } from 'redux-thunk-actions';

import { HealthcloudApi, TimebanditApi, UserApi } from 'api';
import { BAND_STATUS } from 'utils/constants';
import { getParsedDiseases, makeDiseasesAlerts } from 'utils/alerts';
import { areClose } from 'utils/geofencing';

/* --------------------------- newPatients --------------------------------- */

export const fetchNewPatients = createActionThunk(
  'FETCH_NEW_PATIENTS',
  async (currentPage = 0) => {
    const newPatientsIds = await HealthcloudApi.getNewPatientIds(currentPage);
    if (newPatientsIds.length) {
      const data = await UserApi.getByIds(newPatientsIds);
      return {
        data,
        currentPage,
      };
    }
    return {
      data: [],
    };
  }
);

export const removeNewPatient = createAction('REMOVE_NEW_PATIENT');

/* --------------------------- confirmedPatients --------------------------------- */

export const fetchConfirmedPatients = createActionThunk(
  'FETCH_CONFIRMED_PATIENTS',
  async (currentPage = 0) => {
    const confirmedPatientsIds = await HealthcloudApi.getConfirmedPatientIds(
      currentPage
    );
    let data;
    if (confirmedPatientsIds && confirmedPatientsIds.length) {
      data = await UserApi.getByIds(confirmedPatientsIds);
      return {
        data,
        currentPage,
      };
    }
    return {
      data: [],
      currentPage,
    };
  }
);

export const resetConfirmedPatients = createAction('RESET_CONFIRMED_PATIENTS');

export const resetNewPatients = createAction('RESET_NEW_PATIENTS');

export const alertsNotified = createAction('ALERTS_NOTIFIED');

export const addConfirmedPatient = createAction('ADD_CONFIRMED_PATIENT');

/* --------------------------- cachedPatients --------------------------------- */

export const pushCachedPatient = createAction('PUSH_CACHED_PATIENT');

export const updateCachedPatient = createActionThunk(
  'patients/UPDATE_CACHED_PATIENT',
  ({ dispatch, getState }) => {
    const { currentPatient } = getState();
    dispatch(pushCachedPatient(currentPatient));
  }
);

/* --------------------------- tickData && summaryData --------------------------------- */

const removeDuplicateTicks = ticks => {
  const newTicks = [];

  const isSameTick = baseTick => compareTick => {
    return (
      baseTick.value[0] === compareTick.value[0] &&
      baseTick.value[1] === compareTick.value[1]
    );
  };

  ticks.forEach(tick => {
    if (!newTicks.filter(isSameTick(tick)).length) {
      newTicks.push(tick);
    }
  });
  return newTicks;
};

export const fetchPatientTicks = createActionThunk(
  'FETCH_PATIENT_TICKS',
  async ({ patientId, types, startDate, endDate }) => {
    const parsedStartDate = moment(startDate);
    const parsedEndDate = moment(endDate);
    const startTimestamp = parsedStartDate.format('X');
    const endTimestamp = parsedEndDate.format('X');
    const tickData = await TimebanditApi.getTickData({
      patientId,
      types,
      startDate: startTimestamp,
      endDate: endTimestamp,
    });
    if (types.indexOf('location') !== -1) {
      tickData.location = removeDuplicateTicks(tickData.location);
    }

    return { data: tickData };
  }
);

export const fetchTickSummary = createActionThunk(
  'FETCH_TICK_SUMMARY',
  async ({ patientId, tick, startDate, endDate }) => {
    const requestPerType = {
      steps: 'getMoveSummary',
      sleep_depth: 'getSleepSummary',
      background_hr: 'getHeartRateSummary',
    };

    const summaryRequest = requestPerType[tick];

    let summaryData = {};
    if (summaryRequest) {
      const [summaryResponse] = await TimebanditApi[summaryRequest]({
        patientId,
        startDate,
        endDate,
      });
      summaryData = summaryResponse;
    }
    return { data: summaryData };
  }
);
/* --------------------------- Proximity Log --------------------------- */

const makeCovidStatus = patientGroups => {
  if (patientGroups.includes('pcr_tested_pos')) return 'Positive';
  if (patientGroups.includes('pcr_tested_neg')) return 'Negative';
  if (patientGroups.includes('antibody_pos')) return 'Antibody Positive';
  if (patientGroups.includes('antibody_neg')) return 'Antibody Negative';
  if (patientGroups.includes('pcr_test_pending')) return 'Pending';
  return 'N/A';
};

const getQuarantineType = groups => {
  if (groups.includes('quarantine')) return 'Quarantine';
  if (groups.includes('social_distancing')) return 'Social Distancing';
  if (groups.includes('isolation')) return 'Isolation';
  return 'N/A';
};

const getPatient = (patients, patientId) =>
  patients.find(patient => patient.id === patientId);

export const fetchPatientInfoFull = createActionThunk(
  'FETCH_PATIENT_INFO_FULL',
  async (patientId, { dispatch, getState }) => {
    await dispatch(fetchPatientInfo(patientId));

    const patients = getState().patients.confirmedPatients.data;
    const patient = getPatient(patients, patientId);

    let alerts;
    if (patient.facts) {
      const parsedDiseases = getParsedDiseases(patient.facts);
      alerts = makeDiseasesAlerts(
        parsedDiseases,
        patient.facts,
        patient.surveys,
        undefined
      );
    }

    return {
      id: patientId,
      data: {
        alerts,
      },
    };
  }
);

export const setCovidStatus = createAction('SET_COVID_STATUS');

export const fetchPatientInfo = createActionThunk(
  'FETCH_PATIENT_INFO',
  async (patientId, { getState }) => {
    try {
      const geofence = await HealthcloudApi.getGeofencing(patientId);
      const patientGroups = await UserApi.getUserGroups(patientId);
      const covidStatus = makeCovidStatus(patientGroups);
      const quarantineType = getQuarantineType(patientGroups);
      const healthPassport = await HealthcloudApi.getHealthPassport(patientId);
      const alerts =
        getState().patients.logPatients.data[patientId]?.alerts ?? [];
      const patients = getState().patients.confirmedPatients.data;
      const {
        b_last_battery_level_90d,
        b_last_band_status_90d,
        has_ever_synced_band,
        p_people_near_14d,
        b_last_band_status_day_90d,
      } = getPatient(patients, patientId).facts;

      const latestBatteryLevelUpdate = b_last_battery_level_90d;
      const today = moment().format('YYYY-MM-DD');
      const bandStatus =
        b_last_band_status_90d !== null &&
        b_last_band_status_90d !== undefined &&
        b_last_band_status_day_90d === today
          ? BAND_STATUS[b_last_band_status_90d]
          : 'N/A';
      const hasBeenUsingBand = !!has_ever_synced_band;

      const locations = [];

      const geofencePoint = [geofence.latitude, geofence.longitude];
      const outsideGeofenceValues = locations.filter(
        ({ value }) => !areClose(geofencePoint, value)
      );
      const insideGeofenceValues = locations.filter(({ value }) =>
        areClose(geofencePoint, value)
      );

      return {
        id: patientId,
        data: {
          alerts,
          covidStatus,
          bandStatus,
          quarantineType,
          proximity: p_people_near_14d,
          interactions: {
            outsideGeofenceValues,
            insideGeofenceValues,
          },
          healthPassport: {
            status: healthPassport.i_covid_19 || 0,
            overridden: healthPassport.overridden,
          },
          hasBeenUsingBand,
          bandBatteryLevel: latestBatteryLevelUpdate,
        },
      };
    } catch (e) {
      throw new Error(patientId);
    }
  },
  true
);

export const fetchNewPatientInfo = createActionThunk(
  'FETCH_NEW_PATIENT_INFO',
  async patientId => {
    try {
      const basicInfo = UserApi.getById(patientId);

      return {
        id: patientId,
        data: {
          ...basicInfo,
        },
      };
    } catch (e) {
      throw new Error(patientId);
    }
  },
  true
);
