import configuredAxios from 'api/axios.config';

const FHIR_ENDPOINT = process.env.REACT_APP_FHIR_API_URL;
const PATIENTS_ENDPOINT = '/patients';
const APPOINTMENT_RESOURCES = `${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/appointments`;

class FhirApi {
  reconcileEMR(jhhId, reconcileConfig = {}) {
    return configuredAxios
      .patch(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/reconcile`, {
        Patient: true,
        Encounter: true,
        AllergyIntolerance: false,
        MedicationRequest: false,
        ClinicalImpression: false,
        Communication: false,
        DiagnosticReport: false,
        Immunization: false,
        MedicationAdministration: false,
        Procedure: false,
        Observation_Laboratory: true,
        Observation_VitalSigns: false,
        Observation_Examination: false,
        DocumentReference: false,
        ...reconcileConfig,
      })
      .then(() => true)
      .catch(err => false);
  }

  getPatientRecord(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}?`)
      .catch(err => ({}));
  }

  getPatientAllergyIntolerance(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/allergy_intolerance`)
      .catch(err => []);
  }

  getPatientDiagnosticReports(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/diagnostic_reports`)
      .catch(err => []);
  }

  getPatientMedications(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/medications`)
      .catch(err => []);
  }

  getPatientPrescriptions(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/prescriptions`)
      .catch(err => []);
  }

  getPatientClinicalImpressions(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/clinical_impressions`)
      .catch(err => []);
  }

  getPatientObservations(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/observations`)
      .catch(err => []);
  }

  getPatientImmunizations(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/immunizations`)
      .catch(err => []);
  }

  getPatientProcedures(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/procedures`)
      .catch(err => []);
  }

  getPatientLabExams(jhhId) {
    return configuredAxios
      .get(
        `${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/observations?category=laboratory`
      )
      .catch(err => []);
  }

  getPatientLabTicks(jhhId, types = [], startDate, endDate) {
    /*
    should parse the service response into a object of metrics by label.
    {
      [labelName]:[
        //array of all the ticks for this label
        {
        time://timestamp,
        value://number,
        duration://set to 60 by default
        }
      ]
    }
     */
    const params = new URLSearchParams();
    params.append('domain', 'Observation');
    params.append('category', 'laboratory');
    params.append('labels', types.join(','));
    params.append('start_date', startDate);
    params.append('end_date', endDate);
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/timeseries?${params}`)
      .then(res =>
        res.reduce((obj, currentTick) => {
          const labels = Object.keys(obj);
          const { date, label, value } = currentTick;
          const time = date * 1000;
          const newTick = { time, value, duration: 60 };
          if (labels.includes(label)) {
            obj[label].push(newTick);
          } else {
            obj[label] = [newTick];
          }
          return obj;
        }, {})
      )
      .catch(err => ({}));
  }

  getPatientCommunications(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/communications`)
      .catch(err => []);
  }

  getPatientDocuments(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/documents`)
      .catch(err => []);
  }

  getPatientDocumentsContent(jhhId, docId) {
    return configuredAxios({
      url: `${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/documents/${docId}/content`,
      method: 'GET',
      responseType: 'blob',
    }).catch(err => null);
  }

  /**
   * ------------Encounters------------
   */
  getPatientEncounters(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/encounters`)
      .catch(err => []);
  }

  getSingleEncounterClinicalImpressions(jhhId, encounterId) {
    return configuredAxios
      .get(
        `${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/encounters/${encounterId}/clinical_impressions`
      )
      .catch(err => []);
  }

  createSingleEncounterClinicalImpressions(jhhId, encounterId, body) {
    return configuredAxios
      .post(
        `${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/encounters/${encounterId}/clinical_impressions`,
        body
      )
      .catch(err => ({}));
  }
  /**
   * ------------Apoointments------------
   */

  getPatientAppointments(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/appointments`)
      .catch(err => []);
  }

  requestAppointment(jhhId, newAppointment) {
    return configuredAxios
      .post(
        `${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/appointments`,
        newAppointment
      )
      .catch(err => ({}));
  }

  /**
   * ------- Appointment Resources
   */

  getDaysAvailability(datesArray, resource) {
    const params = new URLSearchParams();
    params.append('dates', datesArray.join(','));
    params.append('resource_type_id', resource);
    return configuredAxios
      .get(`${APPOINTMENT_RESOURCES}/availability?${params}`)
      .catch(err => []);
  }

  async getAppointmentsFormStaticData() {
    const resourceTypes = await configuredAxios
      .get(`${APPOINTMENT_RESOURCES}/resource-types`)
      .catch(err => []);
    const visitReasons = await configuredAxios
      .get(`${APPOINTMENT_RESOURCES}/visit-reasons`)
      .catch(err => []);
    const cancellationReasons = await configuredAxios
      .get(`${APPOINTMENT_RESOURCES}/cancellation-reasons`)
      .catch(err => []);
    /**/

    return Promise.resolve({
      resourceTypes,
      visitReasons,
      cancellationReasons,
    });
  }

  getPractitioner() {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}/practitioner`)
      .catch(err => []);
  }

  isFhirUser(jhhId) {
    return configuredAxios
      .get(`${FHIR_ENDPOINT}${PATIENTS_ENDPOINT}/${jhhId}/connected`)
      .then(data => !!(data.emr_connected || data.emr_found))
      .catch(err => false);
  }
}

export default FhirApi;
