import React from 'react';
import * as d3 from 'd3';

const Arc = ({ data, index, createArc, colors }) => (
  <g key={index} className="arc">
    <path className="arc" d={createArc(data)} fill={colors(index)} />
  </g>
);

const PieLegend = ({ data, index, colors }) => {
  return (
    <div className="legend-container">
      <div className="square" style={{ background: colors(index) }} />
      <span className="text">
        {data[index].value} {data[index].legend}
      </span>
      <div className="text">{data[index].indicator}</div>
      {data[index].indicator ? <div className="arrow up" /> : null}
    </div>
  );
};

const PieChart = props => {
  const createPie = d3
    .pie()
    .value(d => d.value)
    .sort(null);
  const createArc = d3
    .arc()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius);
  const ordinalScale = d3
    .scaleOrdinal()
    .domain(props.data)
    .range(props.colors);
  const data = createPie(props.data);

  return (
    <div className="chart-container">
      <svg width={props.width} height={props.height}>
        <g transform={`translate(${props.outerRadius} ${props.outerRadius})`}>
          {data.map((d, i) => (
            <Arc
              key={i}
              data={d}
              index={i}
              createArc={createArc}
              colors={ordinalScale}
            />
          ))}
        </g>
      </svg>
      <div className="legends">
        {data.map((d, i) => (
          <PieLegend
            data={props.data}
            index={i}
            key={i}
            colors={ordinalScale}
          />
        ))}
      </div>
    </div>
  );
};

export default PieChart;
