import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { labelize } from 'utils/textFormats';

import { SingleChart } from 'components';

export default (date, tz, tickData) => {
  const chartWrapper = ({ title, chart }) => {
    return ` <section class='report-sub-section chart'>
    <h3>${title}</h3>
    ${renderToStaticMarkup(
      <SingleChart
        chart={chart}
        date={date}
        tz={tz}
        tickData={tickData}
        forcedDimensions={{
          width: 900,
          height: 300,
          boundedHeight: 280,
          boundedWidth: 845,
        }}
      />
    )}
    </section>`;
  };

  const charts = ['glucose', 'blood_pressure', 'weight'].map(value => ({
    title: labelize(value, false),
    chart: value,
  }));

  const chartsSections = charts.map(chart => chartWrapper(chart)).join('');

  return `<section class='report-section'>
    <h2 class='section-title'> Monitoring Devices: CGM, BGM </h2>
   ${chartsSections}
    </section>`;
};
