import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPatientInteractions,
  fetchPatientBiometrics,
  setCurrentPatient,
  clearCurrentPatient,
  getHealthPassport,
  setSelectedLocation,
  clearSelectedLocation,
  refreshEffectiveness,
} from 'store/currentPatient/actions';

import { handleActionStart, handleActionError } from 'store/utils';

const initialState = {
  data: {
    location: [],
    otherLocation: [],
    selectedOtherLocation: [],
    effectiveness: 0,
    interactions: {
      total: 0,
      diagnosed: 0,
      healthCareWorkers: 0,
      atRisk: 0,
    },
    biometrics: {},
  },
  lastUpdate: 0,
  healthPassport: { status: 0, overridden: false },
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchPatientInteractions.STARTED]: handleActionStart,
  [fetchPatientInteractions.SUCCEEDED]: (state, action) => {
    state.data.interactions = action.payload.interactions;
    state.data.effectiveness = action.payload.effectiveness;
    state.data.location = action.payload.location;
    state.data.otherLocation = action.payload.otherLocation;
    state.lastUpdate = action.payload.lastUpdate;
    state.isLoading = false;
    state.error = null;
  },
  [fetchPatientBiometrics.SUCCEEDED]: (state, action) => {
    state.data.biometrics = action.payload.biometrics;
    state.isLoading = false;
  },
  [getHealthPassport.SUCCEEDED]: (state, action) => {
    state.healthPassport = action.payload;
    state.isLoading = false;
  },
  [setSelectedLocation]: (state, action) => {
    state.data.selectedOtherLocation = action.payload;
  },
  [clearSelectedLocation]: state => {
    state.data.selectedOtherLocation = [];
  },
  [setCurrentPatient]: (_, action) => action.payload.covid19,
  [clearCurrentPatient]: () => initialState,
  [fetchPatientInteractions.FAILED]: handleActionError,
  [refreshEffectiveness]: state => {
    state.lastUpdate = 0;
  },
});
