import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { isEmpty } from 'lodash';
import {
  selectCurrentEncounter,
  clearCurrentEncounter,
  createClinicalImpression,
} from 'store/currentPatient/actions/fhir';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [clearCurrentEncounter]: () => initialState,
  [selectCurrentEncounter.STARTED]: handleActionStart,
  [createClinicalImpression.STARTED]: handleActionStart,
  [selectCurrentEncounter.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload.data;
  },
  [createClinicalImpression.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data) && !isEmpty(state.data))
      state.data.clinicalImpressions = [
        ...state.data.clinicalImpressions,
        action.payload.data,
      ];
  },

  [selectCurrentEncounter.FAILED]: handleActionError,
  [createClinicalImpression.FAILED]: handleActionError,
});
