import { isEmpty } from 'lodash';

const getInRangeTime = (tickArray, outRangeTime) => {
  const minToMaxTime = tickArray.slice().sort((a, b) => a.time - b.time);
  const minTime = minToMaxTime[0].time;
  const maxTime = minToMaxTime.slice(-1)[0].time;
  return maxTime - minTime - outRangeTime;
};
const MiliSecToHs = value => {
  const miliSecOnHour = 3600000;
  const miliSecOnMin = 60000;
  const hours = Math.round(value / miliSecOnHour);
  const min = Math.round((value % miliSecOnHour) / miliSecOnMin);
  return `${hours}:${min} hs`;
};

const getMinAndMaxFromTickArray = tickArray => {
  const sortedArray = tickArray.slice().sort((a, b) => b.value - a.value);
  const max = sortedArray[0].value;
  const min = sortedArray.slice(-1)[0].value;
  return { min, max };
};

const getTimeInRange = (tickArray, rangeMin, rangeMax) => {
  const minToMaxTime = tickArray.slice().sort((a, b) => a.time - b.time);
  let outRangeTime = 0;
  for (let i = 0; i < minToMaxTime.length - 1; i++) {
    const time = minToMaxTime[i + 1].time - minToMaxTime[i].time;
    if (
      (rangeMin && minToMaxTime[i].value <= rangeMin) ||
      (rangeMax && minToMaxTime[i].value >= rangeMax)
    ) {
      outRangeTime += time;
    }
  }
  const inRangeTime = getInRangeTime(tickArray, outRangeTime);
  return { outRangeTime, inRangeTime };
};

const minMaxValues = (title, minVal, maxVal) => ({
  topLeftText: minVal,
  topLeftValue: 'Min',
  centerValue: title,
  topRightText: maxVal,
  topRightValue: 'Max',
});
const timeRange = (inRange, outRange) => ({
  topLeftText: inRange,
  topLeftValue: 'In Range',
  centerValue: 'Time/Range Variation',
  topRightText: outRange,
  topRightValue: 'Out Range',
});

export const glucoseSummary = tickData => {
  const { glucose } = tickData.data;
  const summaryRows = [];
  if (!isEmpty(glucose)) {
    const { min, max } = getMinAndMaxFromTickArray(glucose);
    summaryRows.push(minMaxValues('Glucose', min + ' mg/dL', max + ' mg/dL'));
    const { inRangeTime, outRangeTime } = getTimeInRange(glucose, 70, 140);
    summaryRows.push(
      timeRange(MiliSecToHs(inRangeTime), MiliSecToHs(outRangeTime))
    );
  }
  return summaryRows;
};

export const bpSummary = tickData => {
  const { systolic = [], diastolic = [] } = tickData.data;
  const summaryRows = [];
  let globalOutOfRange = 0;

  if (!isEmpty(systolic)) {
    const { min, max } = getMinAndMaxFromTickArray(systolic);
    summaryRows.push(minMaxValues('Systolic', min + ' mmHg', max + ' mmHg'));
    const { outRangeTime } = getTimeInRange(systolic, null, 120);
    globalOutOfRange += outRangeTime;
  }
  if (!isEmpty(diastolic)) {
    const { min, max } = getMinAndMaxFromTickArray(diastolic);
    summaryRows.push(minMaxValues('Diastolic', min + ' mmHg', max + ' mmHg'));
    const { outRangeTime } = getTimeInRange(systolic, 80, null);
    globalOutOfRange += outRangeTime;
  }
  const allTicks = [...systolic, ...diastolic];
  if (allTicks.length > 1) {
    //globalOutOfRange divide by two because are outRanges counted twice (one for sys and one for dias) for the same time lapse
    const globalInRangeTime = getInRangeTime(allTicks, globalOutOfRange / 2);
    summaryRows.push(
      timeRange(MiliSecToHs(globalInRangeTime), MiliSecToHs(globalOutOfRange))
    );
  }
  return summaryRows;
};

export const defaultSummary = (metric, name, tickData, unit = '') => {
  const ticks = tickData.data?.[metric] || [];
  const summaryRows = [];
  if (!isEmpty(ticks)) {
    const { min, max } = getMinAndMaxFromTickArray(ticks);
    summaryRows.push(minMaxValues(name, `${min} ${unit}`, `${max} ${unit}`));
  }
  return summaryRows;
};
