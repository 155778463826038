import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Icon, Table, Button } from 'semantic-ui-react';
import { documentsParser } from 'utils/fhirData/parsingFunctions';
import {
  fetchFhirDocuments,
  fetchFhirDocumentContent,
} from 'store/currentPatient/actions/fhir';
import { TablePaginationFooter } from 'components';
import DownloadDocumentModal from './DownloadDocumentModal';
import DocumentsFilterOptions from './DocumentsFilterOptions';
import style from 'containers/patient/patient.module.scss';

const PatientDocuments = () => {
  const [active, setActive] = useState(false);
  const [filterTitle, setFilterTitle] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterEpisodeId, setFilterEpisodeId] = useState('');
  const [activePage, setActivePage] = useState(1);

  const dispatch = useDispatch();
  const id = useSelector(state => state.currentPatient.id);
  const { data: documents, isLoading } = useSelector(
    state => state.currentPatient.documents
  );
  useEffect(() => {
    if (active && id) {
      dispatch(fetchFhirDocuments(id));
    }
  }, [id, dispatch, active]);
  const parsedDocs = documents.map(item => documentsParser(item));

  const handlePageChange = (_, { activePage }) => setActivePage(activePage);

  const handleDownloadClick = (docId, type) => {
    dispatch(fetchFhirDocumentContent(id, docId, type));
  };

  const filterProps = {
    setFilterDate,
    setFilterEpisodeId,
    setFilterTitle,
    documents: parsedDocs,
  };

  const filteredDocs = parsedDocs.filter(doc => {
    if (filterEpisodeId && doc?.episodeId !== filterEpisodeId) return false;
    if (filterDate && doc?.date !== filterDate) return false;
    return doc?.file?.title?.includes?.(filterTitle);
  });
  /**
   * ----Pagination
   */
  const documentsByPage = 5;
  const totalPages = Math.ceil(filteredDocs.length / documentsByPage);
  const from = (activePage - 1) * documentsByPage;
  const to = activePage * documentsByPage;
  const documentsRange = filteredDocs.slice(from, to);
  const headerFields = [
    { content: 'Title', colSpan: 2 },
    { content: 'Type', colSpan: 2 },
    { content: 'Date', colSpan: 2 },
    { content: 'Status', colSpan: 2 },
    { content: 'Episode id', colSpan: 2 },
    { content: 'Preview', colSpan: 1 },
  ];

  const footerColSpan = headerFields.reduce((a, b) => a + b.colSpan, -1);

  return (
    <section className={style.infoSection}>
      <Accordion fluid styled>
        <Accordion.Title
          active={active}
          onClick={() => setActive(!active)}
          className={cx(style.patientDocumentsTitle, active && style.active)}
        >
          <Icon name="dropdown" />
          Documents
        </Accordion.Title>
        <Accordion.Content active={active}>
          <DocumentsFilterOptions {...filterProps} />
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <Table celled fixed columns={6}>
              <Table.Header>
                <Table.Row>
                  {headerFields.map((props, idx) => (
                    <Table.HeaderCell {...props} key={idx} />
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {documentsRange.map(doc => (
                  <Table.Row key={doc.id}>
                    <Table.Cell colSpan={2}>{doc.file.title}</Table.Cell>
                    <Table.Cell colSpan={2}>{doc.file.contentType}</Table.Cell>
                    <Table.Cell colSpan={2}>{doc.date}</Table.Cell>
                    <Table.Cell colSpan={2}>{doc.status}</Table.Cell>
                    <Table.Cell colSpan={2}>{doc.episodeId}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        icon
                        primary
                        size="small"
                        onClick={() =>
                          handleDownloadClick(doc.id, doc.file.contentType)
                        }
                      >
                        <Icon name="download" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <TablePaginationFooter
                colSpan={footerColSpan}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
            </Table>
          )}
        </Accordion.Content>
      </Accordion>
      <DownloadDocumentModal />
    </section>
  );
};

export default PatientDocuments;
