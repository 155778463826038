import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Header, Divider } from 'semantic-ui-react';
import { familyHistoryFactory } from 'utils/patientSummaryReport/factory';

import style from '../../../patientSummary.module.scss';

const FamilyHistory = () => {
  const { data, isLoading } = useSelector(state => state.currentPatient.record);
  const familyHistory = familyHistoryFactory(data);

  const sectionTitle = 'FHx';
  return (
    <Grid.Row>
      <Grid.Column>
        <div className={style.headerTitle}>
          <Header content={sectionTitle} />
        </div>
        <Divider />
        {isLoading ? <p>loading family history...</p> : null}
        <Grid>
          <Grid.Row>
            <Grid.Column>{familyHistory}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

export default FamilyHistory;
