import React from 'react';
import { Button, Grid, Image, Header } from 'semantic-ui-react';

const MainContent = ({ image, authSignature, file, handleUploadImage }) => {
  return (
    <Grid columns="2">
      <Grid.Row>
        <Grid.Column>
          <Image
            src={
              image ||
              authSignature ||
              'https://www.cvjp.org.ni/assets/no-image.png'
            }
            alt={file?.name || 'none'}
          />
        </Grid.Column>
        <Grid.Column>
          <Button secondary onClick={handleUploadImage} content={'Upload'} />
          <Header
            size="tiny"
            content={
              file?.name || (authSignature ? 'Your signature' : 'File Name')
            }
            subheader={file?.type || 'format'}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default MainContent;
