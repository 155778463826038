import dailyCircles from './dailyCircles';

export default ({ from, to, circleArray }) => {
  const circles = circleArray.reduce((a, b) => a + dailyCircles(b), '');
  return `<div class="risk-week-stats">
<p>${from} - ${to}</p>
<div class="week-circle-box">
${circles}
</div>  
</div>`;
};
