import React from 'react';
import cx from 'classnames';
import { Feed, Dimmer, Divider, Header, Icon } from 'semantic-ui-react';

import style from './Feed.module.scss';

const iconByType = {
  MESSAGE: 'envelope outline',
  ALERT: 'flag outline',
  BATTERY_LOW: 'battery low',
};

const FeedEvent = ({ event, onClick }) => (
  <Feed.Event
    className={style.feedEvent}
    date={event.date}
    summary={event.summary}
    extraText={event.details}
    onClick={onClick}
    icon={
      event.type && (
        <Feed.Label>
          <Icon
            name={iconByType[event.type]}
            className={cx(style.baseColor, {
              [style.redColor]: event.severity === 'RED',
              [style.yellowColor]: event.severity === 'YELLOW',
              [style.greenColor]: event.severity === 'GREEN',
            })}
          />
        </Feed.Label>
      )
    }
  />
);

const ActivityFeed = ({
  next,
  activity,
  activityDisabled,
  className,
  onEventClick,
}) => {
  const activityContent = activity.map(event => (
    <FeedEvent
      key={`${event.id}-${event.type}`}
      event={event}
      onClick={onEventClick({
        id: event.id,
        name: event.name,
        alert: event,
      })}
    />
  ));

  return (
    <section id="feedContainer" className={className}>
      <Header>Next Steps</Header>
      <Divider />
      <Feed size="small">
        {next.map(event => (
          <FeedEvent
            key={event.id}
            event={event}
            onClick={onEventClick({
              id: event.patientId,
              name: event.patientName,
              alert: event,
            })}
          />
        ))}
      </Feed>
      <Header>Activity</Header>
      <Divider />
      <Feed size="small">
        {activityDisabled ? (
          <Dimmer.Dimmable dimmed>
            <Dimmer active inverted>
              <Header as="h2" color="grey">
                Coming Soon.
              </Header>
            </Dimmer>
            {activityContent}
          </Dimmer.Dimmable>
        ) : (
          activityContent
        )}
      </Feed>
    </section>
  );
};

export default ActivityFeed;
