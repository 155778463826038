import React from 'react';
import style from 'containers/patient/patient.module.scss';

const CardInfo = ({ fieldName, fieldValue }) => (
  <div className={style.patientCardData}>
    <span className={style.patientCardDataLabel}>{fieldName}</span>
    <span className={style.patientCardDataValue}>{fieldValue}</span>
  </div>
);

CardInfo.defaultProps = {
  fieldName: 'Field',
  fieldValue: 'Not found',
};

export default CardInfo;
