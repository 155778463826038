import moment from 'moment';
import { createActionThunk } from 'redux-thunk-actions';
import { TimebanditApi, FactuaryApi } from 'api';
import { TICK_DATA_TYPES } from 'utils/constants';

export const fetchPatientTicks = createActionThunk(
  'reports/FETCH_PATIENT_TICKS',
  async ({ patientId, startDate, endDate }) => {
    try {
      const parsedStartDate = moment(startDate);
      const parsedEndDate = moment(endDate);
      const startTimestamp = parsedStartDate.format('X');
      const endTimestamp = parsedEndDate.format('X');
      const data = await TimebanditApi.getTickData({
        patientId,
        types: [
          'sleep_depth',
          'steps',
          'background_hr',
          'systolic',
          'diastolic',
          'weight',
          'glucose',
          'bolus',
          'basal',
        ],
        startDate: startTimestamp,
        endDate: endTimestamp,
      });
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const fetchPatientAllTicks = createActionThunk(
  'reports/FETCH_PATIENT_ALL_TICKS',
  async ({ patientId, startDate, endDate }) => {
    try {
      const allTypes = Object.values(TICK_DATA_TYPES).flatMap(val => val);
      const parsedStartDate = moment(startDate);
      const parsedEndDate = moment(endDate);
      const startTimestamp = parsedStartDate.format('X');
      const endTimestamp = parsedEndDate.format('X');
      const data = await TimebanditApi.getTickData({
        patientId,
        types: allTypes,
        startDate: startTimestamp,
        endDate: endTimestamp,
      });
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const fetchPatientSurveys = createActionThunk(
  'reports/FETCH_PATIENT_SURVEYS',
  async ({ patientId, startDate, endDate }) => {
    const surveyAnswers = await FactuaryApi.getAnswers(
      patientId,
      startDate,
      endDate
    );
    return surveyAnswers;
  }
);

export const fetchPatientTicksSummary = createActionThunk(
  'reports/FETCH_PATIENT_TICK_SUMMARY',
  async ({ patientId, startDate, endDate }) => {
    try {
      const parsedStartDate = moment(startDate);
      const parsedEndDate = moment(endDate);
      const formattedStartDate = parsedStartDate.format('YYYY-MM-DD');
      const formattedEndDate = parsedEndDate.format('YYYY-MM-DD');

      const stepsSummary = await TimebanditApi.getMoveSummary({
        patientId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      const sleepSummary = await TimebanditApi.getSleepSummary({
        patientId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      const hrSummary = await TimebanditApi.getHeartRateSummary({
        patientId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
      return {
        steps: stepsSummary,
        sleep: sleepSummary,
        hr: hrSummary,
      };
    } catch (error) {
      return error;
    }
  }
);
