import React, { useState } from 'react';
import { Button, Icon, Form, TextArea } from 'semantic-ui-react';
import { formatDate } from 'utils/fhirData';
import style from 'containers/patient/components/CentralInfo/components/Appointments/appointments.module.scss';

const EncounterNotes = ({ encounter, handleSubmit }) => {
  const [value, setValue] = useState('');
  const { clinicalImpressions = [] } = encounter;
  const handleTextChange = (e, { value }) => setValue(value);
  const handleNotesSubmit = () => {
    if (value !== '') {
      handleSubmit(value);
      setValue('');
    }
  };
  const notes = clinicalImpressions
    .slice()
    .filter(item => item?.summary)
    .sort((a, b) => a?.date ?? 0 - b?.date ?? 0);
  return (
    <section className={style.encounterNotesSection}>
      {notes.map(({ assessor, summary, date, id }) => (
        <div key={id} className={style.noteBox}>
          <p className={style.noteOwner}>
            <b className={style.clinicianNote}>
              {`${assessor?.display || 'Practitioner'} - ${
                date ? formatDate(date) : 'No date'
              }`}
            </b>
          </p>
          <p className={style.userComment}>{summary}</p>
        </div>
      ))}
      <p>
        <b>Add a note</b>
      </p>
      <Form>
        <TextArea
          placeholder={'add a note'}
          value={value}
          onChange={handleTextChange}
        />
        <Button
          color="green"
          onClick={handleNotesSubmit}
          className={style.saveNoteButton}
          disabled={!encounter.id}
        >
          <Icon name="checkmark" /> Save
        </Button>
      </Form>
    </section>
  );
};

export default EncounterNotes;
