import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { isEmpty } from 'lodash';
import {
  setCurrentPatient,
  clearCurrentPatient,
} from 'store/currentPatient/actions';

import {
  fetchAppointments,
  requestAppointment,
} from 'store/currentPatient/actions/fhir';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchAppointments.STARTED]: handleActionStart,
  [requestAppointment.STARTED]: handleActionStart,
  [fetchAppointments.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload.data || [];
  },
  [requestAppointment.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data = [...state.data, action.payload.data];
    }
  },
  [fetchAppointments.FAILED]: handleActionError,
  [requestAppointment.FAILED]: handleActionError,
  [setCurrentPatient]: (_, action) => action.payload.appointments,
  [clearCurrentPatient]: () => initialState,
});
