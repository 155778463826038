import React, { useState } from 'react';
import { Button, Icon, Form, TextArea } from 'semantic-ui-react';
import style from 'containers/patient/patient.module.scss';
import LockAndDeleteButtons from 'components/LockAndDeleteButtons';
const NotesList = ({
  notes,
  clinicianId,
  ownerId,
  handleDelete,
  handleCheck,
  handleSubmit,
  canCheck,
}) => {
  const [value, setValue] = useState('');
  const handleTextChange = (e, { value }) => setValue(value);
  const handleNotesSubmit = () => {
    if (value !== '') {
      handleSubmit(value);
      setValue('');
    }
  };
  return (
    <>
      {notes.map((note, idx) => {
        const isLastNote = idx + 1 === notes.length;
        const isUserNote = note.user.id === ownerId;
        const isClinicianNote = note.user.id === clinicianId;
        return (
          <section className={style.singleMealSection} key={idx}>
            <LockAndDeleteButtons
              checked={note.published}
              canDelete={isLastNote && isClinicianNote}
              canCheck={canCheck}
              handleDelete={handleDelete.bind(this, note.id)}
              handleCheck={handleCheck.bind(this, note.id)}
            >
              <p className={style.noteOwner}>
                <b
                  className={
                    isUserNote ? style.patientNote : style.clinicianNote
                  }
                >
                  {note.user.name}
                </b>
              </p>
            </LockAndDeleteButtons>
            <p className={style.userComment}>{note.note}</p>
          </section>
        );
      })}
      <section className={style.singleMealSection}>
        <p>
          <b>Add a note</b>
        </p>
        <Form>
          <TextArea
            placeholder={'add a note'}
            value={value}
            onChange={handleTextChange}
          />
          <Button
            color="green"
            onClick={handleNotesSubmit}
            className={style.saveNoteButton}
          >
            <Icon name="checkmark" /> Save
          </Button>
        </Form>
      </section>
    </>
  );
};

export default NotesList;

NotesList.defaultProps = {
  notes: [],
};
