import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';

import { fetchPatientTicks } from 'store/patients/actions';
import {
  setCurrentPatient,
  clearCurrentPatient,
} from 'store/currentPatient/actions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchPatientTicks.STARTED]: handleActionStart,
  [fetchPatientTicks.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action?.payload?.data ?? {};
  },
  [fetchPatientTicks.FAILED]: handleActionError,
  [setCurrentPatient]: () => initialState,
  [clearCurrentPatient]: () => initialState,
});
