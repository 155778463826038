import React from 'react';
import style from 'containers/patient/patient.module.scss';
import { labelize } from 'utils/textFormats';

const RecursiveListRender = ({ data = [] }) => {
  return (
    <ul>
      {Array.isArray(data)
        ? data.map((item, itemIdx) => (
            <li key={itemIdx}>
              <b>{labelize(item.display)}</b>
              {item.type === 'node' ? (
                item.value
              ) : (
                <RecursiveListRender data={item.value} />
              )}
            </li>
          ))
        : null}
    </ul>
  );
};

const AssociatedDataAccordion = ({ data }) => {
  return data ? (
    <section>
      <div className={style.patientCardData}>
        {Object.keys(data).map((displayName, idx) => (
          <div key={idx}>
            <h5>{labelize(displayName)}</h5>
            <RecursiveListRender data={data[displayName]} />
          </div>
        ))}
      </div>
    </section>
  ) : (
    <p>No data available</p>
  );
};

export default AssociatedDataAccordion;
