import { combineReducers } from 'redux';

import id from './id';
import basicInfo from './basicInfo';
import foodData from './foodData';
import appointments from './appointments';
import record from './record';
import isFhirUser from './isFhirUser';
import diseases from './diseases';
import surveys from './surveys';
import diseasesEvidence from './diseasesEvidence';
import diseasesAlerts from './diseasesAlerts';
import isOnboarded from './isOnboarded';
import notes from './notes';
import currentMeal from './currentMeal';
import currentEncounter from './currentEncounter';
import geofencing from './geofencing';
import timeZone from './timeZone';
import covid19 from './covid19';
import groups from './groups';
import allergyIntolerance from './allergyIntolerance';
import diagnosticReports from './diagnosticReports';
import medications from './medications';
import prescriptions from './prescriptions';
import observations from './observations';
import clinicalImpressions from './clinicalImpressions';
import procedures from './procedures';
import immunizations from './immunizations';
import communications from './communications';
import encounters from './encounters';
import documents from './documents';
import currentDocument from './currentDocument';
import labExams from './labExams';
import emrTicks from './emrTicks';

export default combineReducers({
  id,
  appointments,
  foodData,
  basicInfo,
  record,
  isFhirUser,
  diseases,
  surveys,
  diseasesEvidence,
  diseasesAlerts,
  isOnboarded,
  notes,
  currentEncounter,
  currentMeal,
  timeZone,
  geofencing,
  covid19,
  groups,
  allergyIntolerance,
  diagnosticReports,
  medications,
  prescriptions,
  observations,
  clinicalImpressions,
  procedures,
  immunizations,
  communications,
  encounters,
  documents,
  currentDocument,
  labExams,
  emrTicks,
});
