import React from 'react';
import * as d3 from 'd3';

import { alignAxis } from 'utils/chartUtils';
import Axis from './Axis';
import Dots from './Dots';
import Line from './Line';
import style from './Chart.module';

const BatteryLevelChart = ({
  data = [],
  dimensions,
  xData,
  axisAmount,
  axisIndex,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key="bl-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.batteryLevelLabel}
      labelLine
      formatTick={tick => tick}
      {...axisAligment}
    />,
    <Line
      style={{ ...style.line, ...style.batteryLevelLine }}
      data={data}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
  ];
};

const BatteryLevelChartDayTrend = ({
  data = [],
  dimensions,
  xData,
  axisAmount,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const keyAccessor = (_, i) => i;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  return [
    <Axis
      key="bl-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.batteryLevelLabel}
      labelLine
      formatTick={tick => tick}
    />,
    data.length !== 1 ? (
      <Line
        style={{ ...style.line, ...style.batteryLevelLineTrend }}
        data={data}
        xAccessor={xAccessorScaled}
        yAccessor={yAccessorScaled}
      />
    ) : (
      <Dots
        style={style.batteryLevelCircles}
        data={data}
        keyAccessor={keyAccessor}
        xAccessor={xAccessorScaled}
        yAccessor={yAccessorScaled}
        radius={4}
      />
    ),
  ];
};

export { BatteryLevelChart, BatteryLevelChartDayTrend };
