import moment from 'moment-timezone';
import questions from 'utils/surveyQuestionsConfig';
import { utcToTzFormat } from 'utils/timezone';

const findAriDailysAnswers = surveys =>
  surveys.filter(answer => /ARISXDAILY_/.test(answer.question_name));

const getTimeAnswered = answer => {
  let timeAnswered = null;
  if (answer.care_team && answer.care_team.length > 0) {
    timeAnswered = answer.care_team[0].time_answered;
  } else if (answer.patient && answer.patient.length > 0) {
    timeAnswered = answer.patient[0].time_answered;
  }
  return timeAnswered;
};

const findAnswersByDay = (surveys, day, tz, dateFormat = 'YYYY DD MM') =>
  surveys.filter(
    item =>
      utcToTzFormat(getTimeAnswered(item), tz, dateFormat) ===
      utcToTzFormat(day, tz, dateFormat)
  );

export const makeCirclesArray = (date, sortedSurveys = [], tz) => {
  let reasons = '';
  const surveyQuestions = questions.ari_daily;
  const ariDailysAnswers = findAriDailysAnswers(sortedSurveys);
  const from = moment(date.from);
  const circleArray = [];
  let completedSurveys = 0;
  for (let i = 0; i <= 6; i++) {
    const weekDay = from
      .clone()
      .add(i, 'day')
      .endOf('day');
    const day = weekDay?.format?.('dddd', tz)?.[0] ?? '';
    const ariSurvey = findAnswersByDay(ariDailysAnswers, weekDay.unix(), tz);
    if (!ariSurvey || ariSurvey.length === 0) {
      circleArray.push({ day, color: 'none' });
    } else {
      completedSurveys++;
      const negativeAnswer = findFirstNegativeAnswer(
        ariSurvey,
        surveyQuestions
      );
      reasons = getFactorsFromNegativeAnswers(ariSurvey, surveyQuestions);

      const color = negativeAnswer ? 'negative' : 'positive';
      circleArray.push({ day, color });
    }
  }
  return { circleArray, completedSurveys, reasons };
};

/**
 * Filter Answers
 */

const filterAnswers = (surveys, name) => {
  if (name === 'ari') {
    return surveys.filter(answer => /ARISX_/.test(answer.question_name));
  } else {
    return surveys.filter(answer => /COVID19/.test(answer.question_name));
  }
};

const getAnswerText = answer => {
  let answerText = null;
  if (answer.care_team && answer.care_team.length > 0) {
    answerText = answer.care_team[0].answer_text;
  } else if (answer.patient && answer.patient.length > 0) {
    answerText = answer.patient[0].answer_text;
  }
  return answerText;
};

const findFirstNegativeAnswer = (particularSurvey, surveyQuestions) => {
  return particularSurvey.find(answer => {
    const answerText = getAnswerText(answer);
    return !!surveyQuestions.find(
      questionItem =>
        questionItem.question === answer.question_name &&
        questionItem.negativeValue(answerText)
    );
  });
};

const getFactorsFromNegativeAnswers = (particularSurvey, surveyQuestions) => {
  let reasons = '';
  surveyQuestions.forEach(questionItem => {
    const answer = particularSurvey.find(
      answer =>
        questionItem.question === answer.question_name &&
        questionItem.negativeValue(getAnswerText(answer)) &&
        questionItem.question !== 'ARISXDAILY_020' &&
        questionItem.question !== 'ARISXDAILY_003' &&
        questionItem.question !== 'ARISXDAILY_002' &&
        questionItem.question !== 'ARISX_002'
    );
    if (answer) {
      const answerText = getAnswerText(answer);
      const newReason =
        !questionItem.reason &&
        answerText.toLowerCase() !== 'yes' &&
        answerText.toLowerCase() !== 'no'
          ? answerText
          : questionItem.reason;
      if (newReason) {
        if (!reasons) {
          reasons = newReason;
        } else {
          reasons = reasons + ' | ' + newReason;
        }
      }
    }
  });
  return reasons;
};

export const makeSymptomsConcept = (sortedSurveys, name) => {
  const surveyQuestions = questions[name];
  let totalQuestions = 18;
  if (name === 'ari') {
    totalQuestions = 85;
  }

  const particularSurvey = filterAnswers(sortedSurveys, name);
  if (!particularSurvey) {
    return {
      status: 'positive',
      message: `0/${totalQuestions} complete`,
    };
  }
  const answered = particularSurvey.length;
  let complete = `${answered}/${totalQuestions} complete`;

  const factor = findFirstNegativeAnswer(particularSurvey, surveyQuestions);
  const reasons = getFactorsFromNegativeAnswers(
    particularSurvey,
    surveyQuestions
  );

  return {
    status: Boolean(factor) ? 'negative' : 'positive',
    message: `${complete}`,
    reasons,
  };
};
