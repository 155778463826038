import React from 'react';
import { Feed } from 'components';
import { noop } from 'lodash';
import style from 'containers/patient/patient.module.scss';

const Activity = () => {
  const activity = [
    {
      id: 1,
      summary: 'Follow up re: diabetes risk increase',
      details: 'Notify patient and PCP of risk adjustment',
      date: '09/18/19',
      type: 'RED',
    },
    {
      id: 2,
      summary: 'Possible risk of diabetes detected',
      details: 'Nocturnal survey sent',
      date: '09/15/19',
    },
    {
      id: 4,
      summary: 'Smart Coach found no risk of AFIB',
      date: '09/03/19',
    },
    {
      id: 5,
      summary: 'Confirmed as a new patient',
      date: '09/01/19',
    },
  ];
  const next = [];

  return (
    <Feed
      next={next}
      activity={activity}
      className={style.patientFeed}
      activityDisabled
      onEventClick={noop}
    />
  );
};

export default Activity;
