import {
  dataTypeOf,
  rootExtensionToField,
  formatDate,
  addFromCodeObject,
  joinArray,
  mapToValue,
  isArrayOfStrings,
  channelParser,
} from 'utils/fhirData';
import moment from 'moment-timezone';

const isDate = date => {
  let check = true;
  if (!isNaN(date)) {
    check = (date + '').length > 9;
  }
  return check && moment(date).isValid();
};

const handleExtensions = (data, newFields) => {
  data.forEach(([fieldName, fieldValue]) => {
    newFields[fieldName] = isDate(fieldValue)
      ? formatDate(fieldValue)
      : fieldValue;
  });
};

const handleEncounterExtensions = (data, newFields) => {
  const dateFormat = {
    arrivalTime: 'HH:mm',
    attendedTime: 'HH:mm',
    episodeDate: 'YYYY MM DD',
  };
  const isEncounterDate = field => Object.keys(dateFormat).includes(field);

  data.forEach(([fieldName, fieldValue]) => {
    let value = fieldValue;
    if (isEncounterDate(fieldName)) {
      value = formatDate(fieldValue, dateFormat[fieldName]);
    } else if (isDate(fieldValue)) {
      value = formatDate(fieldValue);
    }
    newFields[fieldName] = value;
  });
};

const contactsHandleFunc = (data, key, name, newFields) => {
  const addedFields = channelParser(data[key]);
  for (key in addedFields) {
    newFields[key] = addedFields[key];
  }
};

const prescriptionHandleFunc = (data, key, name, newFields) => {
  switch (key) {
    case 'dispenseRequest':
      Object.keys(data[key]).forEach(fieldName => {
        newFields[fieldName] = JSON.stringify(data[key][fieldName]);
      });
      break;
    case 'dosageInstruction':
      data[key].forEach((dosage, id) => {
        newFields[`${name}-${id}`] = `${dosage.text ||
          ''}, ${dosage.patientInstruction || ''}`;
      });
      break;
    case 'requester':
      newFields[name] = data[key].display || 'unknown';
      break;
    case 'identifier':
      newFields[name] = joinArray(mapToValue(data[key], 'value')) || 'unknown';
      break;
    default:
      break;
  }
};

const diagnosticReportsHandleFunc = (data, key, name, newFields) => {
  switch (key) {
    case 'code':
      addFromCodeObject(data[key], name, newFields);
      break;
    case 'identifier':
      newFields[name] = joinArray(mapToValue(data[key], 'value')) || 'unknown';
      break;
    default:
      break;
  }
};
const documentsHandleFunc = (data, key, name, newFields) => {
  switch (key) {
    case 'content':
      newFields.file = data[key]?.[0]?.attachment || {};
      break;
    case 'id':
      newFields.id = data[key];
      break;
    default:
      break;
  }
};
const encountersHandleFunc = (data, key, name, newFields) => {
  switch (key) {
    case 'class':
      newFields[name] = data[key]?.display || '---';
      break;
    case 'id':
      newFields[name] = data[key];
      break;
    case 'identifier':
      newFields[name] = joinArray(mapToValue(data[key], 'value')) || 'unknown';
      break;
    default:
      break;
  }
};

const immunizationsHandleFunc = (data, key, name, newFields) => {
  switch (key) {
    case 'vaccineCode':
      addFromCodeObject(data[key], name, newFields);
      break;
    default:
      break;
  }
};
const recordHandleFunc = (data, key, name, newFields) => {
  switch (key) {
    case 'age':
      newFields[key] = data[key]?.text || data[key]?.value;
      break;
    default:
      break;
  }
};
const observationsHandleFunc = (data, key, name, newFields) => {
  switch (key) {
    case 'code':
      addFromCodeObject(data[key], name, newFields);
      break;
    case 'category':
      data[key].forEach((category, idx) =>
        addFromCodeObject(category, `${name}(${idx})`, newFields)
      );
      break;
    default:
      break;
  }
};

const makeParser = (
  specialFields = [],
  handleFunc,
  nullValue = '[null]',
  extensionsHandler = handleExtensions
) => {
  const parser = (data, newFields = {}, parent = '') => {
    // eslint-disable-next-line
    for (const key in data) {
      const name = parent ? `${parent}_${key}` : key;
      let extensions = [];
      if (specialFields.includes(key) && dataTypeOf(data[key]) !== 'unknown') {
        handleFunc(data, key, name, newFields);
      } else {
        switch (dataTypeOf(data[key])) {
          case 'string':
            if (isDate(data[key])) {
              newFields[name] = formatDate(data[key]);
            } else if (name !== 'id') {
              newFields[name] = data[key];
            }
            break;
          case 'object':
          case 'array':
            if (isArrayOfStrings(data[key])) {
              newFields[name] = joinArray(data[key]);
            } else if (key === 'extension') {
              extensions = rootExtensionToField(data[key]);
              if (extensions && extensions.length) {
                extensionsHandler(extensions, newFields);
              }
            } else if (key !== 'display') {
              parser(data[key], newFields, name);
            }
            break;
          case 'unknown':
            newFields[name] = data[key] ?? nullValue;
            break;
          default:
            break;
        }
      }
    }
    return newFields;
  };
  return parser;
};

export const defaultParser = makeParser();
export const prescriptionsParser = makeParser(
  ['dispenseRequest', 'dosageInstruction', 'requester', 'identifier'],
  prescriptionHandleFunc
);
export const contactsParser = makeParser(['telecom'], contactsHandleFunc);
export const diagnosticReportsParser = makeParser(
  ['code', 'identifier'],
  diagnosticReportsHandleFunc
);
export const immunizationsParser = makeParser(
  ['vaccineCode'],
  immunizationsHandleFunc
);
export const observationsParser = makeParser(
  ['code', 'category'],
  observationsHandleFunc
);
export const parseRecord = makeParser(
  ['contact', 'age'],
  recordHandleFunc,
  'Not found'
);
export const encountersParser = makeParser(
  ['class', 'identifier', 'id'],
  encountersHandleFunc,
  'null',
  handleEncounterExtensions
);
export const documentsParser = makeParser(
  ['content', 'id'],
  documentsHandleFunc
);
