import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { isEmpty } from 'lodash';
import { chartOptions } from 'utils/charts/factory';
import { TICK_DATA_TYPES } from 'utils/constants';
import ChartSelector from 'components/Viz/ChartSelector';

export default (data, date, ticksData) => {
  const tz = data.timeZone?.data?.tz || '';
  const selectedCharts = [
    'movement',
    'sleep',
    'blood_pressure',
    'weight',
    'glucose',
    'insulin',
  ];
  if (isEmpty(ticksData.ticks.data)) {
    return '';
  }

  const ticks = ticksData.ticks.data;

  const chartWrapper = ({ title, charts, data, footer }) => {
    return `
      <h3 class="header-3 divider">${title} Graphs</h2>
      ${renderToStaticMarkup(
        <ChartSelector
          charts={charts}
          date={date}
          tz={tz}
          data={data}
          forcedDimensions={{ width: 1000 }}
          showDailyTrends
        />
      )}
      ${footer || ''}`;
  };
  const makeData = chart => {
    const metrics = TICK_DATA_TYPES[chart];
    if (Array.isArray(metrics)) {
      return metrics.reduce(
        (obj, metric) => ({ ...obj, [metric]: ticks[metric] || [] }),
        {}
      );
    }
    return { [metrics]: ticks[metrics] || [] };
  };
  const charts = selectedCharts
    .map(value => chartOptions.find(item => item.value === value))
    .map(({ text, value }) => ({
      title: text,
      charts: [value],
      data: makeData(value),
      footer:
        text === 'Sleep'
          ? `<p>
    Please note the awakenings marked in yellow on the top line might correlate with the movement steps shown in orange
    on the bottom line.
  </p>`
          : null,
    }));

  const chartsSections = charts.map(chart => chartWrapper(chart)).join('');

  return chartsSections;
};
