import React from 'react';
import { Button } from 'semantic-ui-react';
import style from '../../patientSummary.module.scss';

const LinkedButtons = ({ buttonList, children }) => {
  return (
    <section>
      <div className={style.linkedButtonsBox}>
        {buttonList.map(buttonProps => (
          <Button {...buttonProps} />
        ))}
      </div>
      {children}
    </section>
  );
};

export default LinkedButtons;
