import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Header,
  Icon,
  Image,
  Input,
  Modal,
  Form,
} from 'semantic-ui-react';

import { updateBasicInfo } from 'store/currentPatient/actions';
import DefaultImage from 'assets/default-image.png';
import style from 'containers/patient/patient.module.scss';
import { ContactSupport } from './components/ContactSupport';
import { DischargePatient } from './components/DischargePatient';

const genderMapping = (currentGender, toDisplay) => {
  if (toDisplay) {
    switch (currentGender) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      default:
        return 'Other';
    }
  } else {
    switch (currentGender) {
      case 'Male':
        return 'M';
      case 'Female':
        return 'F';
      default:
        return '';
    }
  }
};

const genderOptions = [
  {
    key: 'M',
    text: 'Male',
    value: 'Male',
  },
  {
    key: 'F',
    text: 'Female',
    value: 'Female',
  },
  {
    key: 'O',
    text: 'Other',
    value: 'Other',
  },
];

const raceOptions = [
  {
    key: 'Asian',
    text: 'Asian',
    value: 'Asian',
  },
  {
    key: 'BlackOrAfricanAmerican',
    text: 'Black Or African American',
    value: 'Black or African American',
  },
  {
    key: 'HispanicOrLatino',
    text: 'Hispanic or Latino',
    value: 'Hispanic or Latino',
  },
  {
    key: 'PacificIslander',
    text: 'Pacific Islander',
    value: 'Pacific Islander',
  },
  {
    key: 'AmericanIndianOrAlaskaNative',
    text: 'American Indian Or Alaska Native',
    value: 'American Indian Or Alaska Native',
  },
  {
    key: 'White',
    text: 'White',
    value: 'White',
  },
  {
    key: 'Other',
    text: 'Other',
    value: 'Other',
  },
];

const ethnicityOptions = [
  {
    key: 'Hispanic',
    text: 'Hispanic',
    value: 'Hispanic',
  },
  {
    key: 'NotHispanic',
    text: 'Not Hispanic',
    value: 'Not Hispanic',
  },
  {
    key: 'Other',
    text: 'Other',
    value: 'Other',
  },
];

const EditablePatientInfoModal = ({
  form,
  onChange,
  onSelectChange,
  onClose,
  onSave,
  open,
  genderOptions,
  raceOptions,
  ethnicityOptions,
  otherRaceField,
  invalidDate,
}) => {
  const [unit, subUnit] = form.isMetric ? ['m', 'cm'] : ['ft', 'inch'];
  return (
    <Modal open={open} centered={false}>
      <Header as="h3">{form.name}</Header>
      <Modal.Content>
        <Form size="large" onSubmit={onSave}>
          <Form.Group widths={2}>
            <Form.Field
              id="patient-info-birthdate"
              control={Input}
              name="birthdate"
              label="BirthDate:"
              value={form.birthdate || ''}
              onChange={onChange}
              error={invalidDate}
              type="date"
              required
            />
            <Form.Select
              id="patient-info-gender"
              className={`${style.secondaryInfoValue} ${style.secondaryInfoSelect}`}
              name="gender"
              value={form.gender || ''}
              onChange={onSelectChange}
              options={genderOptions}
              label="Sex:"
              fluid
              required
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Group widths={2} className={style.heightField}>
              <Form.Field
                id="patient-info-height-unit"
                control={Input}
                className={style.secondaryInfoValue}
                name="heightUnit"
                label={`Height: (${unit})`}
                value={form.heightUnit || ''}
                onChange={onChange}
                type="text"
                required
              />
              <Form.Field
                id="patient-info-height-subunit"
                control={Input}
                className={style.secondaryInfoValue}
                name="heightSubunit"
                label={`(${subUnit})`}
                value={form.heightSubunit || ''}
                onChange={onChange}
                type="text"
                required
              />
            </Form.Group>
            <Form.Select
              id="patient-info-ethnicity"
              className={`${style.secondaryInfoValue} ${style.secondaryInfoSelect}`}
              name="ethnicity"
              label="Ethnicity:"
              value={form.ethnicity || ''}
              onChange={onSelectChange}
              options={ethnicityOptions}
              fluid
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field
              id="patient-info-emr"
              control={Input}
              className={style.secondaryInfoValue}
              name="erm"
              label="EMR:"
              value={form.emr || ''}
              onChange={onChange}
              type="number"
            />
            {otherRaceField ? (
              <Form.Field
                id="patient-info-race"
                control={Input}
                name="race"
                label="Race:"
                value={form.race || ''}
                onChange={onChange}
                type="text"
              />
            ) : (
              <Form.Select
                id="patient-info-race"
                className={`${style.secondaryInfoValue} ${style.secondaryInfoSelect}`}
                name="race"
                label="Race:"
                value={form.race || ''}
                onChange={onSelectChange}
                options={raceOptions}
                fluid
              />
            )}
          </Form.Group>
          <div className={style.saveButtonContainer}>
            <Button
              disabled={invalidDate}
              className={style.saveButton}
              type="submit"
            >
              <Icon name="checkmark" /> Save
            </Button>
            <Button color="red" onClick={onClose}>
              <Icon name="cancel" /> Cancel
            </Button>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const PatientInfo = () => {
  const { isLoading, error, data } = useSelector(
    state => state.currentPatient.basicInfo
  );

  const confirmedPatient = useSelector(
    state => !!state.currentPatient.isOnboarded?.data
  );

  const now = moment();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [editableForm, setEditableForm] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [otherRaceField, setOtherRace] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);

  useEffect(() => {
    if (!isEmpty(data)) {
      const [unit, subunit] = data.isMetric
        ? data.height.split('', 1)
        : data.height.split(`'`);
      const gender = genderMapping(data.gender, true);
      setForm({ ...data, gender });
      setEditableForm({
        ...data,
        gender,
        heightUnit: unit,
        heightSubunit: subunit,
      });
    }
  }, [data]);

  const handleModalOpen = () => setOpenModal(true);

  const handleModalClose = () => setOpenModal(false);

  const handleFormSubmit = () => {
    const metricHeight = (() => {
      if (!editableForm.isMetric) {
        const [feetHeight, inchHeight] = [
          editableForm.heightUnit,
          editableForm.heightSubunit,
        ];

        return Math.round(
          parseInt(feetHeight, 10) * 30.48 + parseInt(inchHeight, 10) * 2.54
        );
      }
      return parseInt(
        editableForm.heightUnit.concat(editableForm.heightSubunit),
        10
      );
    })();

    const parsedBirthDate = moment(editableForm.birthdate).format('YYYY-MM-DD');

    const parsedGender = genderMapping(editableForm.gender, false);

    const parsedHeight = editableForm.isMetric
      ? `${editableForm.heightUnit
          .trim()
          .concat(editableForm.heightSubunit.trim())} cm`
      : `${editableForm.heightUnit.trim()}'${editableForm.heightSubunit.trim()}`;

    const updatedLocalData = {
      ...editableForm,
      birthdate: parsedBirthDate,
      gender: parsedGender,
      height: parsedHeight,
      metricHeight,
    };
    dispatch(updateBasicInfo(updatedLocalData.id, updatedLocalData));
    setForm({ ...editableForm, height: parsedHeight });
    setOpenModal(false);
    setOtherRace(false);
  };

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === 'birthdate') {
      const [year] = value.split('-', 1);
      setInvalidDate(year > 2000);
    }
    setEditableForm({ ...editableForm, [name]: value });
  };

  const handleSelectChange = (_, { name, value }) => {
    if (name === 'race' && value === 'Other') {
      setOtherRace(true);
    }
    setEditableForm({ ...editableForm, [name]: value });
  };

  const timedBirthDate = form.birthdate
    ? `${moment(form.birthdate).format('MM/DD/YYYY')}`
    : '---';

  const timedAge =
    form.age || form.birthdate
      ? `${now.diff(form.birthdate, 'years')} Years`
      : '---';

  const image = form.image ? form.image : DefaultImage;

  return (
    <>
      <Button
        onClick={handleModalOpen}
        className={style.editButton}
        icon
        basic
        size="mini"
      >
        <Icon name="pencil" />
      </Button>
      {error && <p>Something was wrong with the patient`s user information</p>}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <EditablePatientInfoModal
            form={editableForm}
            onChange={handleInputChange}
            onSelectChange={handleSelectChange}
            open={openModal}
            onClose={handleModalClose}
            onSave={handleFormSubmit}
            genderOptions={genderOptions}
            raceOptions={raceOptions}
            ethnicityOptions={ethnicityOptions}
            otherRaceField={otherRaceField}
            invalidDate={invalidDate}
          />
          <div className={style.nameContainer}>
            <Image size="tiny" src={image} avatar />
            <Header as="h2">{form.name}</Header>
          </div>
          <section className={style.secondaryInfo}>
            <div className={style.secondaryInfoDivider}>
              <span className={style.secondaryInfoLabel}>Id:</span>
              <span className={style.secondaryInfoValue}>
                {form.id || '---'}
              </span>
            </div>
            <div className={style.secondaryInfoDivider}>
              <span className={style.secondaryInfoLabel}>Sex:</span>
              <span className={style.secondaryInfoValue}>
                {form.gender || '---'}
              </span>
            </div>
            <div className={style.secondaryInfoDivider}>
              <span className={style.secondaryInfoLabel}>Birthdate:</span>
              <span className={style.secondaryInfoValue}>{timedBirthDate}</span>
            </div>
            <div className={style.secondaryInfoDivider}>
              <span className={style.secondaryInfoLabel}>Age:</span>
              <span className={style.secondaryInfoValue}>{timedAge}</span>
            </div>
            <div className={style.secondaryInfoDivider}>
              <span className={style.secondaryInfoLabel}>Height:</span>
              <span className={style.secondaryInfoValue}>
                {form.height || '---'}
              </span>
            </div>
            <div className={style.secondaryInfoDivider}>
              <span className={style.secondaryInfoLabel}>Race:</span>
              <span className={style.secondaryInfoValue}>
                {form.race || '---'}
              </span>
            </div>
            <div className={style.secondaryInfoDivider}>
              <span className={style.secondaryInfoLabel}>Ethnicity:</span>
              <span className={style.secondaryInfoValue}>
                {form.ethnicity || '---'}
              </span>
            </div>
            <div className={style.secondaryInfoDivider}>
              <span className={style.secondaryInfoLabel}>EMR:</span>
              <span className={style.secondaryInfoValue}>
                {form.emr || '---'}
              </span>
            </div>
          </section>
          {confirmedPatient ? (
            <DischargePatient patientId={form.id} name={form.name} />
          ) : null}
          <ContactSupport patientId={form.id} />
        </>
      )}
    </>
  );
};

export default PatientInfo;
