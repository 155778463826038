import React from 'react';
import style from 'containers/patient/patient.module.scss';

const RowFlexInfoSection = ({ title, children }) => {
  return (
    <>
      <h4>{title}</h4>
      <div className={style.metadataInfo}>{children}</div>
    </>
  );
};

RowFlexInfoSection.defaultProps = {
  title: 'info',
};

export default RowFlexInfoSection;
