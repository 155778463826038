import React from 'react';
import { Icon, Button } from 'semantic-ui-react';

const ShowLocation = ({ showInteraction, covidClick }) => {
  return showInteraction ? (
    <>
      <Button icon basic onClick={covidClick} fluid>
        <Icon name="map marker alternate" size="large" />
        View Location
      </Button>
    </>
  ) : null;
};

export default ShowLocation;
