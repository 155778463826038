import { defaultParser } from 'utils/fhirData/parsingFunctions';
import { labelize } from 'utils/textFormats';

export default (communications = {}) => {
  const { data } = communications;

  const dietitianCommunication = data
    .map(item => defaultParser(item))
    .find(item => item.sender_display === 'Dietitian');

  let communication = {};

  if (dietitianCommunication) {
    const {
      sender_display,
      episodeId,
      status,
      ...rest
    } = dietitianCommunication;
    communication = { sender_display, episodeId, status };
    // eslint-disable-next-line
    for (let key in rest) {
      if (
        key.toLowerCase().includes('dietician') ||
        key.toLowerCase().includes('dietitian')
      ) {
        communication[key] = rest[key];
      }
    }
  }

  return `<section class='report-section'>
      <h2 class='section-title'> Nutrition Plan </h2>
      <div class='data-container'>
      ${Object.entries(communication)
        .map(
          ([fieldName, fieldValue]) =>
            `<div>
            <p><b>${labelize(fieldName)}</b></p>
            <p> ${fieldValue}</p>
        </div>`
        )
        .join('')}
      </div>
      </section>`;
};
