import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import style from 'containers/patient/components/CentralInfo/components/Appointments/appointments.module.scss';

const StatusIcon = ({ text, color }) => {
  const iconStyle = {
    color,
  };

  return (
    <Popup
      trigger={
        <Icon
          name={'circle'}
          size="large"
          className={style.statusIcon}
          style={iconStyle}
        />
      }
      content={text}
    />
  );
};

export default StatusIcon;
