import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'semantic-ui-react';

import styles from './biometrics.module.scss';

const renderBiometrics = ({
  biometrics = [],
  expand = false,
  textColor = '',
  iconColor = '',
}) => {
  if (!biometrics.length) return null;

  if (expand) {
    return biometrics.map((biometric, key) => {
      const iconName =
        biometric.direction !== 'min'
          ? `arrow ${biometric.direction}`
          : 'minus';
      return (
        <div
          className={cx(styles.biometricsItem, textColor)}
          key={`${key + 1}-${biometric.direction}`}
        >
          {biometric.value} <Icon className={iconColor} name={iconName} />
        </div>
      );
    });
  }

  const [firstBio, ...extraBios] = biometrics;
  return (
    <div className={textColor}>
      {firstBio.value}{' '}
      <Icon className={iconColor} name={`arrow ${firstBio.direction}`} />
      {Boolean(extraBios.length) && `+ ${extraBios.length}`}
    </div>
  );
};

const Biometrics = ({ biometrics, expanded, icon, showGreen, onHeader }) => {
  const generalIconColor = (() => {
    if (!biometrics.red.length) return styles.lowValue;
    if (biometrics.red.length === 1) return styles.mediumValue;
    if (biometrics.red.length > 1) return styles.highValue;
  })();

  const redIconColor = (() => {
    if (biometrics.red.length > 1) return styles.highValue;
    return styles.noneValue;
  })();

  const redTextColor = (() => {
    if (biometrics.red.length > 1) return styles.highValue;
    return '';
  })();

  const onHeaderStyle = onHeader ? `${styles.biometricsOnHeader}` : '';

  return (
    <div className={cx(styles.biometricsContainer, onHeaderStyle)}>
      {!onHeader ? <Icon name={icon} className={generalIconColor} /> : null}
      {renderBiometrics({
        biometrics: biometrics.red,
        expand: expanded,
        iconColor: redIconColor,
        textColor: redTextColor,
      })}{' '}
      {showGreen &&
        renderBiometrics({
          biometrics: biometrics.green,
          expand: expanded,
          iconColor: styles.lowValue,
        })}
    </div>
  );
};

Biometrics.defaultProps = {
  biometrics: {
    red: [],
    green: [],
  },
  expanded: false,
  icon: 'circle',
  showGreen: false,
  onHeader: false,
};

Biometrics.propTypes = {
  biometrics: PropTypes.shape({
    red: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        direction: PropTypes.string,
      })
    ),
    green: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        direction: PropTypes.string,
      })
    ),
  }),
  expanded: PropTypes.bool,
  icon: PropTypes.string,
  showGreen: PropTypes.bool,
  onHeader: PropTypes.bool,
};

export default Biometrics;
