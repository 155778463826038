import { labsFactory } from 'utils/patientSummaryReport/factory';
export default labExamsData => {
  const {
    examsNames,
    episodeDates,
    episodeNames,
    episodesObject,
  } = labsFactory(labExamsData?.data || []);

  return `<section class='report-section'>
    <h2 class='section-title'> Labs </h2>
    <table class='info-table'>
        <thead>
          <tr>
            ${['Value', ...episodeDates]
              .map(name => `<th>${name}</th>`)
              .join('')}
          </tr>
        </thead>
        <tbody>
        ${examsNames
          .map(
            name =>
              `<tr >
              <td>${name}</td>
              ${episodeNames
                .map(
                  episodeId =>
                    `<td>
                  ${episodesObject[episodeId]?.[name] || '---'}
                </td>`
                )
                .join('')}
            </tr>`
          )
          .join('')}
        </tbody>
    </table>
    </section>`;
};
