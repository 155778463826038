/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { Message, Button } from 'semantic-ui-react';

import ErrorIcon from 'assets/icons/ErrorIcon';

import styles from './errorBoundary.module.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorFront: '',
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    const strError = error.toString();
    this.setState({ hasError: true });

    if (strError.includes(':')) {
      const errorFront = strError.split(':')[0];
      this.setState({ errorFront });
    }
  }

  goHome() {
    window.location.replace('/today');
  }

  render() {
    const { children } = this.props;
    const { hasError, errorFront } = this.state;

    if (hasError) {
      return (
        <section className={styles.error}>
          <Message floating className={styles.message}>
            <article className={styles.reason}>
              <h2>Oops! Houston, we have a problem</h2>
              <p>Please try again or later</p>
              <p>
                <code>{errorFront}</code>
              </p>
              <Button primary onClick={this.goHome}>
                Go Back
              </Button>
            </article>
            <aside className={styles.icon}>
              <ErrorIcon />
            </aside>
          </Message>
        </section>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
