import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Grid } from 'semantic-ui-react';
import { Checkbox, Form, Input, TextArea } from 'formik-semantic-ui';
import style from './Action.module.scss';
import { SEVERITY_TYPES } from 'utils/constants';

const errorMessages = {
  required: 'This field is required',
};

const validate = values => {
  let errors = {};
  if (!values.from) {
    errors.from = errorMessages.required;
  }
  if (!values.to) {
    errors.to = errorMessages.required;
  }
  if (!values.subject) {
    errors.subject = errorMessages.required;
  }
  if (!values.body) {
    errors.body = errorMessages.required;
  }
  if (!values.confirm) {
    errors.confirm = true;
  }
  return errors;
};

const SendMessage = ({ patientName, onSubmit, notificationType, level }) => {
  const handleSubmit = (values, formikInstance) => {
    const finishSubmission = () => formikInstance.setSubmitting(false);
    const errors = validate(values);
    if (isEmpty(errors)) {
      return onSubmit(values, finishSubmission);
    }
    formikInstance.setErrors(errors);
    finishSubmission();
  };

  return (
    <Form
      className="actionForm"
      onSubmit={handleSubmit}
      initialValues={{
        from: '',
        to: patientName,
        bcc: '',
        subject: '',
        body: '',
        confirm: false,
      }}
    >
      <Grid divided="vertically">
        <Grid.Row columns={2}>
          <Grid.Column>
            <Input label="From" name="from" required />
            <Input label="To" name="to" required />
            <Input label="BCC" name="bcc" inputProps={{ disabled: true }} />
            <Input label="Subject" name="subject" required />
          </Grid.Column>
          <Grid.Column className="bodyColumn">
            <TextArea className={style.bodyInputField} required name="body" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <section id={style.bottomControls}>
        {Boolean(level === SEVERITY_TYPES.NEW) && (
          <Checkbox
            label={`Confirm ${patientName} is under your care.`}
            name="confirm"
          />
        )}
        <Button type="submit" color={notificationType.color}>
          Send
        </Button>
      </section>
    </Form>
  );
};

export default SendMessage;
