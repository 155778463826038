import React, { useState } from 'react';
import { Accordion, Form, Button, Popup } from 'semantic-ui-react';

import DayTitle from './DayTitle';

const AvailableDay = ({ day, onSelectDay, active, togleActive }) => {
  const { date, periods } = day;
  const [period, setPeriod] = useState('MORNING');
  const handleSelect = () => {
    onSelectDay(date, period);
    setPeriod('MORNING');
  };
  const handleChange = (e, { value }) => setPeriod(value);

  return (
    <>
      <DayTitle day={day} active={active} onClick={togleActive} />
      <Accordion.Content active={active}>
        <Form>
          <Form.Group inline>
            {periods.map(({ slot, availability, color }) => (
              <Popup
                size="tiny"
                content={availability}
                trigger={
                  <Form.Radio
                    label={slot}
                    value={slot}
                    checked={period === slot}
                    onChange={handleChange}
                  />
                }
              />
            ))}
          </Form.Group>
        </Form>
        <br />
        <Button onClick={handleSelect}>Select</Button>
      </Accordion.Content>
    </>
  );
};

export default AvailableDay;
