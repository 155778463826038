import { exercisePlanFactory } from 'utils/patientSummaryReport/factory';

export default (covid19 = {}) => {
  const {
    data: { biometrics },
  } = covid19;

  const { averages, label } = exercisePlanFactory(biometrics);

  return `<section class='report-section'>
      <h2 class='section-title'>${label}</h2>
      ${averages
        .map(
          ({ content, subheader }) => `<div >
            <p><b>${content}</b></p>
            <p>${subheader}</p>
        </div>`
        )
        .join('')}
      </section>`;
};
