import React from 'react';
import * as d3 from 'd3';

import { alignAxis } from 'utils/chartUtils';
import Axis from './Axis';
import Dots from './Dots';
import Line from './Line';
import style from './Chart.module';

const Hba1cChart = ({
  data = [],
  dimensions,
  xData,
  axisIndex,
  axisAmount,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const keyAccessor = (_, i) => i;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(data, yAccessor))
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key="hba1c-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.heartRateLabel}
      labelLine
      {...axisAligment}
    />,
    <Line
      style={{ ...style.line, ...style.heartRateLine }}
      data={data}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
    <Dots
      style={style.heartRateCircles}
      data={data}
      keyAccessor={keyAccessor}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      radius={3}
    />,
  ];
};

export default Hba1cChart;
