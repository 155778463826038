import React, { useState, useEffect } from 'react';
import { Grid, Header, Message } from 'semantic-ui-react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { findAlert } from 'utils/alerts';
import { BiometricsAlerts } from 'components';
import IconLabel from './components/IconLabel';
import HealthPassport from './components/HealthPassport';
import CovidTest from './components/CovidTest';
import ShowLocation from './components/ShowLocation';
import QuarantineAdherence from './components/QuarantineAdherence';
import QuarantineStatus from './components/QuarantineStatus';
import style from 'containers/patient/components/CentralInfo/components/covid.module.scss';
import styles from 'containers/proximity-log/proximityLog.module.scss';

const CovidHeader = ({ showInteraction, covidClick, patientId }) => {
  const [covidExposure, setCovidExposure] = useState({});
  const [covidVulnerability, setCovidVulnerability] = useState({});

  const { isLoading, data: diseases } = useSelector(
    state => state.currentPatient.diseases
  );

  const {
    data: { biometrics },
  } = useSelector(state => state.currentPatient.covid19);

  const hasBeenUsingBand = useSelector(
    state =>
      state.patients.logPatients.data?.[patientId]?.hasBeenUsingBand ?? false
  );

  useEffect(() => {
    if (!isLoading) {
      if (!isEmpty(diseases)) {
        const diseasesList = diseases.flatMap(d => Object.entries(d));
        const covidDisease = diseasesList.find(
          ([diseaseName]) => diseaseName === 'Covid 19'
        );
        if (covidDisease) {
          const alert = findAlert(...covidDisease);
          const exposureColor = (() => {
            if (!alert.severity) return style.noneValue;
            if (alert.severity === 'GREEN') return style.lowValue;
            if (alert.severity === 'YELLOW') return style.mediumValue;
            return style.highValue;
          })();
          setCovidExposure({ ...alert, color: exposureColor });
        }
      } else {
        setCovidExposure({
          risk: 'N/A',
          color: 'gray',
        });
      }
    }
    // eslint-disable-next-line
  }, [diseases, isLoading]);

  useEffect(() => {
    const vulnerabilty = biometrics?.['i_vuln'] ?? null;
    const vulnerabiltyText = (() => {
      if (!vulnerabilty) return 'N/A';
      if (vulnerabilty === 'GREEN') return 'Low';
      if (vulnerabilty === 'YELLOW') return 'Medium';
      return 'High';
    })();
    const vulnerabiltyColor = (() => {
      if (!vulnerabilty) return style.noneValue;
      if (vulnerabilty === 'GREEN') return style.lowValue;
      if (vulnerabilty === 'YELLOW') return style.mediumValue;
      return style.highValue;
    })();
    setCovidVulnerability({
      text: vulnerabiltyText,
      color: vulnerabiltyColor,
    });
  }, [biometrics]);

  const fields = [
    {
      label: 'Vulnerability Risk',
      text: covidVulnerability.text,
      color: covidVulnerability.color,
    },
    {
      label: 'Exposure Risk',
      text: covidExposure.risk,
      color: covidExposure.color,
    },
  ];

  return (
    <Grid className={style.covidHeaderContainer}>
      <Grid.Row
        className={cx(style.covidHeaderRow, style.underlined, style.hightLight)}
      >
        {fields.map((field, key) => (
          <IconLabel key={key} {...field} />
        ))}
        <HealthPassport patientId={patientId} />
      </Grid.Row>
      <Grid.Row
        className={cx(style.covidHeaderRow, style.underlined, style.title)}
      >
        <Grid.Column width={10}>
          <p>Pre-Symptomatic Detection</p>
        </Grid.Column>
        <Grid.Column width={6}>
          <p>Covid Test Status</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={10}>
          {hasBeenUsingBand ? (
            <BiometricsAlerts biometrics={biometrics} />
          ) : (
            <Message className={styles.covidAlerts}>
              <Header as="h4" content="N/A" />
            </Message>
          )}
        </Grid.Column>
        <Grid.Column width={6}>
          <CovidTest patientId={patientId} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        className={cx(style.covidHeaderRow, style.underlined, style.title)}
      >
        <Grid.Column width={5}>
          <p>Quarantine</p>
        </Grid.Column>
        <Grid.Column width={5}>
          <p>Quarantine Adherence</p>
        </Grid.Column>
        <Grid.Column width={6}>
          {showInteraction && <p>Location</p>}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column verticalAlign="middle" width={5}>
          <QuarantineStatus patientId={patientId} />
        </Grid.Column>
        <Grid.Column verticalAlign="middle" width={5}>
          <QuarantineAdherence />
        </Grid.Column>
        <Grid.Column verticalAlign="middle" width={6}>
          <ShowLocation
            showInteraction={showInteraction}
            covidClick={covidClick}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CovidHeader;
