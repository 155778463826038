import React from 'react';
import { Accordion, Icon, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { EmrNotConnectedMessage } from 'components';
import style from 'containers/patient/patient.module.scss';

const AccordionData = ({ fields, title, iconName, ...rest }) => {
  const { isLoading: idLoading, data: isFhirUser } = useSelector(
    state => state.currentPatient.isFhirUser
  );

  return (
    <>
      <Accordion.Title {...rest}>
        <Icon name={iconName} />
        {title}
      </Accordion.Title>
      <Accordion.Content active={rest.active}>
        {idLoading && <Loader active />}
        {!isFhirUser ? (
          <EmrNotConnectedMessage />
        ) : (
          <section className={style.accordionData}>
            {fields.map(field => (
              <div className={style.patientCardData} key={field.label}>
                <span className={style.patientCardDataLabel}>
                  {field.label}
                </span>
                <span className={style.patientCardDataValue}>
                  {field.value || '---'}
                </span>
              </div>
            ))}
          </section>
        )}
      </Accordion.Content>
    </>
  );
};

export default AccordionData;
