import React from 'react';
import { Link } from '@reach/router';
import { Dropdown } from 'semantic-ui-react';
import style from '../../header.module.scss';
const MenuDropdown = () => {
  return (
    <section className={style.dropdownView}>
      <Dropdown text="Menu">
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/today" text="Today" />
          <Dropdown.Item as={Link} to="/panel" text="Panel View" />
        </Dropdown.Menu>
      </Dropdown>
    </section>
  );
};

export default MenuDropdown;
