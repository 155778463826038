import React from 'react';
import Log from './components/Log';
import SingleMeal from './components/SingleMeal';

const Meals = () => {
  return (
    <>
      <Log />
      <SingleMeal />
    </>
  );
};

export default Meals;
