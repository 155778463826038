import React from 'react';
import * as d3 from 'd3';

import { alignAxis } from 'utils/chartUtils';
import Axis from './Axis';
import Bars from './Bars';
import style from './Chart.module';

const MovementChart = ({
  data = [],
  dimensions,
  xData,
  axisIndex,
  axisAmount,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleLinear()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth])
    .nice();
  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(data, yAccessor)])
    .range([dimensions.boundedHeight, 0])
    .nice();
  const opacityScale = d3
    .scaleLinear()
    .domain([0, d3.max(data, yAccessor)])
    .range([0.1, 1]);

  const barPadding = 4;

  const xAccessorScaled = d => xScale(xAccessor(d)) + barPadding;
  const yAccessorScaled = d => yScale(yAccessor(d));
  const heightAccessorScaled = d =>
    dimensions.boundedHeight - yScale(yAccessor(d));
  const keyAccessor = (d, i) => i;

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key="movement-y-axis"
      dimension="y"
      scale={yScale}
      labelLine
      tickColor={style.movementLabel}
      {...axisAligment}
    />,
    <Bars
      style={style.movementColor}
      data={data}
      fill={opacityScale}
      heightAccessor={heightAccessorScaled}
      keyAccessor={keyAccessor}
      rounded
      width={4}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
  ];
};

const MovementChartDayTrend = ({
  data = [],
  dimensions,
  xData,
  axisAmount,
  axisIndex,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleLinear()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);
  const yScale = d3
    .scaleLinear()
    .domain([0, d3.max(data, yAccessor)])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const barPadding = 2;

  const xAccessorScaled = d => xScale(xAccessor(d)) + barPadding;
  const yAccessorScaled = d => yScale(yAccessor(d));
  const heightAccessorScaled = d =>
    dimensions.boundedHeight - yScale(yAccessor(d));
  const keyAccessor = (_, i) => i;

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key={`overlayed-movement-y-axis-${axisIndex}`}
      dimension="y"
      scale={yScale}
      labelLine
      tickColor={style.movementLabel}
      {...axisAligment}
    />,
    <Bars
      style={style.movementColor}
      data={data}
      heightAccessor={heightAccessorScaled}
      keyAccessor={keyAccessor}
      rounded
      width={2}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
  ];
};

export { MovementChart, MovementChartDayTrend };
