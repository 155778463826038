import { formatDate } from 'utils/fhirData';
import { labelize } from 'utils/textFormats';

export default (record, date) => {
  const { data: headerInfo } = record;
  const getValue = field => {
    switch (field) {
      case 'age':
        return headerInfo[field]?.text || '---';
      case 'birthDate':
        return formatDate(headerInfo[field]);
      default:
        return headerInfo[field] || '---';
    }
  };

  const makeInfo = field => `<p>
  <b>${labelize(field, true)}</b>
    ${getValue(field)}
</p>`;

  return `<section class="header-section">
      <div class="title">
        <h1 >Patient Summary Report</h1>
        <img class="image" alt="glucare-logo" src="/glucare-logo-small.png" />
      </div>
      <p><b>From:</b> ${formatDate(date.start)} - <b>To:</b> ${formatDate(
    date.end
  )}</p>
        <div class='header-info'>
          <div>
            ${makeInfo('name')}
            ${makeInfo('gender')}
          </div>
          <div>
            ${makeInfo('age')}
            ${makeInfo('birthDate')}
          </div>
        </div>
      
    </section>`;
};
