import { createReducer } from '@reduxjs/toolkit';
import {
  placePatientId,
  setCurrentPatient,
  clearCurrentPatient,
} from 'store/currentPatient/actions';

const initialState = null;

export default createReducer(initialState, {
  [placePatientId]: (_, action) => action.payload,
  [setCurrentPatient]: (_, action) => action.payload.id,
  [clearCurrentPatient]: () => initialState,
});
