import React from 'react';
import * as d3 from 'd3';

import { alignAxis } from 'utils/chartUtils';
import Axis from '../Axis';
import Dots from '../Dots';
import Line from '../Line';
import style from '../Chart.module';

const RmssdChart = ({
  data,
  dimensions,
  xData,
  tz = {},
  axisIndex,
  axisAmount,
}) => {
  if (data.length !== 2) return null;
  const [rmssd] = data;

  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const keyAccessor = (_, i) => i;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const maxRmssdValue = d3.max(rmssd, yAccessor);

  const yScale = d3
    .scaleLinear()
    .domain([0, maxRmssdValue])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key="hrv-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.rmssdLabel}
      labelLine
      {...axisAligment}
    />,
    <Line
      style={{ ...style.line, ...style.rmssdLine }}
      data={rmssd}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      interpolation={d3.curveLinear}
    />,
    <Dots
      style={style.rmssdCircles}
      data={rmssd}
      keyAccessor={keyAccessor}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      radius={3}
      tz={tz}
    />,
  ];
};

const RmssdChartDayTrend = ({
  data,
  dimensions,
  xData,
  axisAmount,
  axisIndex,
}) => {
  if (data.length !== 2) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const [rmssd] = data;
  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const maxRmssdValue = d3.max(rmssd, yAccessor);

  const yScale = d3
    .scaleLinear()
    .domain([0, maxRmssdValue])
    .range([dimensions.boundedHeight, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const axisAligment = alignAxis(axisIndex, axisAmount);

  return [
    <Axis
      key={`overlayed-hrv-y-axis-${axisIndex}`}
      dimension="y"
      scale={yScale}
      tickColor={style.rmssdLabel}
      labelLine
      {...axisAligment}
    />,
    <Line
      style={style.lineRmssdDailyTrend}
      data={rmssd}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      interpolation={d3.curveLinear}
    />,
  ];
};

export { RmssdChart, RmssdChartDayTrend };
