import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import { Button, Header, Loader } from 'semantic-ui-react';

import { PatientCard } from 'components';
import {
  fetchPatientInfo,
  fetchConfirmedPatients,
} from 'store/patients/actions';
import { createTab } from 'store/appState/actions';
import { REHYDRATE_INTERVAL_TIME } from 'utils/constants';
import styles from './proximityLog.module.scss';

const Today = () => {
  const dispatch = useDispatch();
  const {
    confirmedPatients: {
      data: confirmedPatients,
      currentPage,
      allowMoreConfirmed,
      isLoading: loadingConfirmed,
    },
  } = useSelector(state => state.patients);
  const [currentPageConfirmed, setCurrentPageConfirmed] = useState(currentPage);
  const [handleStartRefreshing, setHandleStartRefreshing] = useState(false);
  const pageSize = 10;
  const { data: patientsInfo } = useSelector(
    state => state.patients.logPatients
  );
  const { patientTabs } = useSelector(state => state.appState);

  useEffect(() => {
    const hasCacheData = !!confirmedPatients.length;

    if (!hasCacheData) {
      dispatch(fetchConfirmedPatients(0));
    }

    return () => {
      if (setHandleStartRefreshing) {
        fetchConfirmedPatientsDataToday({ to: currentPageConfirmed + 1 });
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    let timer;
    if (handleStartRefreshing) {
      timer = setInterval(() => {
        fetchConfirmedPatientsData({ to: currentPageConfirmed + 1 });
      }, REHYDRATE_INTERVAL_TIME);
    }
    return () => {
      if (handleStartRefreshing && timer) {
        clearInterval(timer);
      }
    };
  }, [handleStartRefreshing]); // eslint-disable-line

  const callApi = () => {
    fetchConfirmedPatientsData({ from: 0, to: currentPageConfirmed + 1 });
    setHandleStartRefreshing(true);
    setCurrentPageConfirmed(currentPageConfirmed + 1);
  };

  useEffect(() => {
    const hasCacheData = !!confirmedPatients.length;
    let timerFirstRender;

    if (hasCacheData) {
      if (currentPageConfirmed === 0 && !handleStartRefreshing) {
        timerFirstRender = setTimeout(() => {
          callApi();
        }, REHYDRATE_INTERVAL_TIME);
      } else {
        callApi();
      }
    } else {
      callApi();
    }

    return () => clearTimeout(timerFirstRender);
    // eslint-disable-next-line
  }, [confirmedPatients.length]);

  const fetchConfirmedPatientsDataToday = debounce(({ from = 0, to }) => {
    confirmedPatients
      .slice(from * pageSize, to * pageSize)
      .forEach(patient => dispatch(fetchPatientInfo(patient.id)));
  }, 1000);

  const fetchConfirmedPatientsData = debounce(({ from = 0, to }) => {
    confirmedPatients
      .slice(from * pageSize, to * pageSize)
      .forEach(patient => dispatch(fetchPatientInfo(patient.id)));
  }, 1000);

  const openTab = ({ id, name }) => {
    dispatch(createTab({ id, name }));
  };

  const handlePatientClick = ({ id, name }) => () => {
    const encodedId = btoa(id);
    const existingTab = patientTabs.find(tab => tab.id === encodedId);
    if (!existingTab) {
      openTab({ id: encodedId, name });
    }
    navigate(`/patient/${encodedId}`);
  };

  const handleLoadMore = () => {
    const nextPage = currentPageConfirmed + 1;
    fetchConfirmedPatientsData({
      from: currentPageConfirmed,
      to: nextPage,
    });
    dispatch(fetchConfirmedPatients(nextPage));
    setCurrentPageConfirmed(nextPage);
  };

  const confirmedPatientsBody = confirmedPatients.map(patient => {
    const patientKey = patient.id;
    const patientInfo = patientsInfo[patientKey];

    if (!patientKey) return null;

    return (
      <PatientCard
        key={`p-${patientKey}`}
        basicData={patient}
        data={patientInfo}
        onClick={handlePatientClick({ id: patientKey, name: patient.first })}
      />
    );
  });

  const todayDate = moment().format('MMMM Do, YYYY');

  return (
    <div className={styles.container}>
      <section className={styles.bodyContainer}>
        <Header as="h1" className={styles.title}>
          {todayDate}
        </Header>
        <section className={styles.bodyContainer}>
          <div className={styles.proximityTable}>{confirmedPatientsBody}</div>
          {allowMoreConfirmed && (
            <>
              {loadingConfirmed && <Loader />}
              <div className={styles.loadMoreContainer}>
                <Button
                  disabled={loadingConfirmed}
                  onClick={handleLoadMore}
                  basic
                >
                  Load more
                </Button>
              </div>
            </>
          )}
        </section>
      </section>
    </div>
  );
};

export default Today;
