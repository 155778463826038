import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { setAppointmentStaticData } from 'store/staticData/actions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [setAppointmentStaticData.STARTED]: handleActionStart,
  [setAppointmentStaticData.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload;
  },
  [setAppointmentStaticData.FAILED]: handleActionError,
});
