import { createAction } from '@reduxjs/toolkit';
import { createActionThunk } from 'redux-thunk-actions';
import { isEmpty, meanBy, isNaN } from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { HealthcloudApi, UserApi, FactuaryApi, TimebanditApi } from 'api';
import { pushCachedPatient, updateCachedPatient } from 'store/patients/actions';
import {
  makeDiseasesAlerts,
  parseDisease,
  getParsedDiseases,
} from 'utils/alerts';
import { areClose } from 'utils/geofencing';
import { checkForFhirUser } from './fhir';

// Because is only called from patients dashboard
export const markPatientAsConfirmed = createAction(
  'currentPatient/MARK_PATIENT_CONFIRMED'
);
export const setCurrentPatient = createAction(
  'currentPatient/SET_CURRENT_PATIENT'
);
export const updateCacheAndClearCurrent = createActionThunk(
  'currentPatient/UPDATE_CACHE_AND_CLEAR_CURRENT_PATIENT',
  async ({ dispatch }) => {
    await dispatch(updateCachedPatient());
    dispatch(clearCurrentPatient());
  }
);
export const clearCurrentPatient = createAction(
  'currentPatient/CLEAR_CURRENT_PATIENT'
);
export const placePatientId = createAction('currentPatient/PLACE_PATIENT_ID');

export const fetchBasicInfo = createActionThunk(
  'currentPatient/GET_BASIC_INFO',
  async id => {
    const basicInfo = await UserApi.getById(id);
    return { id, data: basicInfo };
  }
);

export const updateBasicInfo = createActionThunk(
  'currentPatient/UPDATE_BASIC_INFO',
  async (id, data) => {
    await UserApi.updatePatientInfo(id, data);
    return {
      id,
      data,
    };
  }
);

export const fetchDiseasesAlerts = createActionThunk(
  'currentPatient/GET_DISEASES_ALERTS',
  async id => {
    const diseases = await HealthcloudApi.getDiseases(id);
    const parsedDiseases = getParsedDiseases(diseases);
    const biometrics = await FactuaryApi.getUserFacts(id);
    const surveys = await FactuaryApi.getPatientCompletedSurveys(id);
    const alerts = makeDiseasesAlerts(
      parsedDiseases,
      biometrics,
      undefined,
      surveys
    );
    return { data: alerts };
  }
);

export const fetchDiseases = createActionThunk(
  'currentPatient/GET_DISEASES',
  async (id, { getState }) => {
    const diseases = await HealthcloudApi.getDiseases(id);
    const prunedDiseases = [];
    const prunedEvidence = {
      ...diseases,
    };

    Object.keys(diseases).forEach(key => {
      if (key.slice(0, 2) !== 'e_') {
        if (key.slice(0, 5) === 'feat_' && !isEmpty(diseases[key])) {
          const keyCleaned = key.substr(5).replace(/_/g, ' ');

          prunedEvidence.additionalInfo = {
            ...prunedEvidence.additionalInfo,
            [keyCleaned]: diseases[key],
          };
        }

        delete prunedEvidence[key];
      }

      if (key.slice(0, 2) === 'i_') {
        const diseaseItem = parseDisease(key, diseases[key]);
        prunedDiseases.push(diseaseItem);
      }
    });
    const diseasesAlerts =
      getState().patients?.logPatients?.data[id]?.alerts || [];
    return {
      id,
      data: {
        diseases: prunedDiseases,
        diseasesAlerts,
        diseasesEvidence: prunedEvidence,
      },
    };
  }
);

export const fetchUserGroups = createActionThunk(
  'currentPatient/GET_USER_GROUPS',
  async id => {
    const userGroups = await UserApi.getUserGroups(id);
    return { id, data: userGroups };
  }
);

export const updateCovidCohort = createAction(
  'currentPatient/UPDATE_COVID_COHORT',
  data => {
    return {
      payload: data,
    };
  }
);

export const updateUserGroups = createActionThunk(
  'currentPatient/POST_USER_GROUPS',
  async ({ id, add, remove }, { dispatch, getState }) => {
    const groups = await UserApi.updateUserGroups(id, add, remove);
    const additionalInfo = getState().currentPatient.diseasesEvidence.data
      ?.additionalInfo;

    if (additionalInfo && additionalInfo['covid 19']) {
      dispatch(updateCovidCohort(JSON.stringify(groups)));
    }

    return {
      id,
      data: groups,
    };
  }
);

export const fetchUserStatus = createActionThunk(
  'currentPatient/GET_USER_STATUS',
  async id => {
    const userStatus = await HealthcloudApi.getPatientById(id);
    return { id, data: userStatus.onboarded };
  }
);

export const fetchPatientTimeZone = createActionThunk(
  'currentPatient/GET_PATIENT_TIME_ZONE',
  async id => {
    const timeZone = await UserApi.getPatientTimeZone(id);
    return { id, data: timeZone };
  }
);

export const fetchCompletedSurveys = createActionThunk(
  'currentPatient/FETCH_COMPLETED_SURVEYS',
  async id => {
    const surveys = await FactuaryApi.getPatientCompletedSurveys(id);
    return { id, data: surveys };
  }
);

export const saveAnsweredSurveys = createActionThunk(
  'currentPatient/SAVE_ANSWERED_SURVEYS',
  async (id, surveyId, body) => {
    const data = await FactuaryApi.setAnswersSurveys(id, surveyId, body);
    return { id, data };
  }
);

export const fetchPatientSurveysAnswers = createActionThunk(
  'reports/FETCH_PATIENT_SURVEYS_ANSWERS',
  async ({
    patientId,
    startDate = moment(0),
    endDate = moment().add(3, 'day'),
  }) => {
    const surveyAnswers = await FactuaryApi.getAnswers(
      patientId,
      startDate,
      endDate
    );
    return surveyAnswers;
  }
);

/*
 * ----------- Biometrics ----------
 */

export const fetchPatientBiometrics = createActionThunk(
  'currentPatient/FETCH_PATIENT_BIOMETRICS',
  async patientId => {
    try {
      const biometrics = await FactuaryApi.getUserFacts(patientId);
      return {
        biometrics,
      };
    } catch (e) {
      throw new Error(e.message);
    }
  }
);

/*
  ---------- Geofencing ----------
*/
export const fetchPatientGeofencing = createActionThunk(
  'currentPatient/GET_PATIENT_GEOFENCING',
  async id => {
    const geofence = await HealthcloudApi.getGeofencing(id);
    if (isEmpty(geofence)) {
      const newGeofence = await HealthcloudApi.createGeofencing(id, {
        latitude: 0,
        longitude: 0,
      });
      return {
        id,
        data: newGeofence,
      };
    }
    return {
      id,
      data: geofence,
    };
  }
);

export const updatePatientGeofencing = createActionThunk(
  'currentPatient/UPDATE_PATIENT_GEOFENCING',
  async (id, data) => {
    const geofence = await HealthcloudApi.updateGeofencing(id, data);
    return {
      id,
      data: geofence,
    };
  }
);

export const setSelectedLocation = createAction(
  'currentPatient/SET_SELECTED_LOCATION'
);

export const clearSelectedLocation = createAction(
  'currentPatient/CLEAR_SELECTED_LOCATION'
);

export const clearPatientGeofencing = createAction(
  'currentPatient/CLEAR_GEOFENCING'
);

export const fetchInitialPatientData = createActionThunk(
  'currentPatient/FETCH_INITIAL_PATIENT_DATA',
  (id, { dispatch }) => {
    dispatch(fetchPatientTimeZone(id));
    dispatch(placePatientId(id));
    dispatch(pushCachedPatient({ id }));
    dispatch(fetchBasicInfo(id));
    dispatch(fetchUserStatus(id));
    dispatch(fetchDiseases(id));
    dispatch(checkForFhirUser(id));
    dispatch(fetchPatientGeofencing(id));
    dispatch(fetchPatientBiometrics(id));
    dispatch(fetchUserGroups(id));
  }
);

export const updateCachedData = createActionThunk(
  'currentPatient/UPDATE_CACHED_PATIENT_DATA',
  (id, { dispatch }) => {
    dispatch(fetchUserStatus(id));
    dispatch(fetchDiseases(id));
    dispatch(fetchPatientTimeZone(id));
    dispatch(fetchPatientGeofencing(id));
    dispatch(fetchPatientBiometrics(id));
    dispatch(fetchUserGroups(id));
    dispatch(fetchPatientInteractions({ patientId: id }));
  }
);

/*
  ---------- Notes ----------
*/
export const fetchPatientNotes = createActionThunk(
  'currentPatient/GET_PATIENT_NOTES',
  async id => {
    const notes = await HealthcloudApi.getPatientNotes(id);
    if (!notes.length) {
      let newNotes = await HealthcloudApi.createPatientNotes(id, '');
      if (!newNotes || newNotes.length === 0) {
        newNotes = [null];
      }
      return {
        id,
        data: newNotes[0],
      };
    }
    return {
      id,
      data: notes[0],
    };
  }
);

export const updatePatientNotes = createActionThunk(
  'currentPatient/UPDATE_PATIENT_NOTES',
  async (id, content) => {
    const notes = await HealthcloudApi.updatePatientNotes(id, content);
    return {
      id,
      data: notes,
    };
  }
);

export const cleanNotes = createAction('currentPatient/CLEAN_NOTES');

/*
------------------------------------------------------------------------------------
  ---------- Food Data ----------
------------------------------------------------------------------------------------
*/
export const fetchFoodData = createActionThunk(
  'currentPatient/GET_FOOD_DATA',
  async (id, date = {}) => {
    const foodData = await UserApi.getPatientFoodLogging(id, date);
    return { id, data: foodData };
  }
);

export const updateSingleMeal = createAction(
  'currentPatient/UPDATE_SINGLE_MEAL'
);
/*
  ---------- Current Meal ----------
*/
export const setCurrentMeal = createAction('currentPatient/SET_CURRENT_MEAL');

export const clearCurrentMeal = createAction(
  'currentPatient/CLEAR_CURRENT_MEAL'
);

/*
 ---------- Meal Note ----------
*/
export const postMealNote = createActionThunk(
  'currentPatient/POST_MEAL_NOTE',
  async body => {
    const newNote = await UserApi.postMealNote(body);
    return { data: newNote };
  }
);

export const setPublishedMealNote = createActionThunk(
  'currentPatient/SET_PUBLISH_MEAL_NOTE',
  async body => {
    // data = is an object with a noteId property
    const data = await UserApi.setPublishedMealNote(body);
    return { data };
  }
);

export const deleteMealNote = createActionThunk(
  'currentPatient/DELETE_MEAL_NOTE',
  async body => {
    // data = is an object with a noteId property
    const data = await UserApi.deleteMealNote(body);
    return { data };
  }
);

/*
 ---------- Meal Metada ----------
*/

export const postMealMetadata = createActionThunk(
  'currentPatient/POST_MEAL_METADATA',
  async body => {
    const newMetadata = await UserApi.postMealMetadata(body);
    return { data: newMetadata };
  }
);

export const setPublishedMealMetadata = createActionThunk(
  'currentPatient/SET_PUBLISH_MEAL_METADATA',
  async body => {
    // data = is an object with a metadataId property
    const data = await UserApi.setPublishedMealMetadata(body);
    return { data };
  }
);

export const deleteMealMetadata = createActionThunk(
  'currentPatient/DELETE_MEAL_METADATA',
  async body => {
    // data = is an object with a metadataId property
    const data = await UserApi.deleteMealMetadata(body);
    return { data };
  }
);

/*
 ---------- Meal Metada Notes ----------
*/
export const postMetadataNote = createActionThunk(
  'currentPatient/POST_METADATA_NOTE',
  async body => {
    // data = is an object with a metadataId and a note properties
    const data = await UserApi.postMetadataNote(body);
    return { data };
  }
);

export const deleteMetadataNote = createActionThunk(
  'currentPatient/DELETE_METADATA_NOTE',
  async body => {
    // data = is an object with a metadataId,noteId property
    const data = await UserApi.deleteMetadataNote(body);
    return { data };
  }
);

/*
 * ---------- Covid19 ----------
 */

export const setHealthPassport = createActionThunk(
  'currentPatient/SET_PATIENT_HEALTH_PASSPORT',
  async ({ patientId, status }, { dispatch }) => {
    await HealthcloudApi.setHealthPassport(patientId, status);
    dispatch(getHealthPassport(patientId));
  }
);

export const getHealthPassport = createActionThunk(
  'currentPatient/FETCH_PATIENT_HEALTH_PASSPORT',
  async id => {
    const data = await HealthcloudApi.getHealthPassport(id);

    const response = {
      status: data?.i_covid_19 || 0,
      overridden: data?.overridden || false,
    };
    return response;
  }
);

export const refreshEffectiveness = createAction(
  'currentPatient/REMOVE_LASTUPDATE'
);

export const fetchPatientInteractions = createActionThunk(
  'currentPatient/FETCH_PATIENT_INTERACTIONS',
  async ({ patientId }, { getState, dispatch }) => {
    dispatch(getHealthPassport(patientId));

    const currentState = getState();
    const { lastUpdate, data, error } = currentState.currentPatient.covid19;
    if (
      lastUpdate &&
      moment().diff(moment(lastUpdate), 'minutes') < 10 &&
      (data.location.length || error)
    ) {
      return {
        location: data.location,
        otherLocation: data.otherLocation,
        effectiveness: data.effectiveness,
        interactions: data.interactions,
        lastUpdate: data.lastUpdate,
      };
    }

    try {
      const {
        timeZone: {
          data: { tz = '' },
        },
        geofencing: { data: geofencing },
      } = currentState.currentPatient;
      const startDate = momentTz()
        .tz(tz)
        .subtract(13, 'days')
        .startOf('day');
      const endDate = momentTz()
        .tz(tz)
        .endOf('day');
      const startTimestamp = startDate.format('X');
      const endTimestamp = endDate.format('X');
      const {
        location = [],
        device_beacon: deviceBeacon = [],
      } = await TimebanditApi.getTickData({
        patientId,
        types: ['location', 'device_beacon'],
        startDate: startTimestamp,
        endDate: endTimestamp,
      });

      /* Location calculations */

      const locations = [];

      const geofencePoint = [geofencing.latitude, geofencing.longitude];
      const amountDays = endDate.diff(startDate, 'days');
      for (let i = 0; i <= amountDays; i++) {
        const actualDay = moment(startDate).add(i, 'days');
        const startDay = actualDay.startOf('day').valueOf();
        const endDay = actualDay.endOf('day').valueOf();
        const dayValues = location.filter(
          value => value.time >= startDay && value.time <= endDay
        );

        const outsideGeofenceValues = dayValues.filter(
          ({ value }) => !areClose(geofencePoint, value)
        );

        const otherLocations = [];
        outsideGeofenceValues.forEach(value => {
          const lastLocationIndex = otherLocations.length - 1;
          const lastLocation = otherLocations[lastLocationIndex];
          if (lastLocation && areClose(lastLocation.center, value.value)) {
            otherLocations[lastLocationIndex].endTime = value.time;
            otherLocations[lastLocationIndex].points.push(value);
          } else {
            otherLocations.push({
              center: value.value,
              startTime: value.time,
              endTime: Number(
                moment(value.time).add(value.duration, 'seconds')
              ),
              interactions: 0,
              points: [value],
            });
          }
        });

        const timeOutside = otherLocations
          .map(({ startTime, endTime }) => ({ startTime, endTime }))
          .reduce((curr, next) => {
            const duration = moment(next.endTime).diff(
              next.startTime,
              'minutes'
            );
            return curr + duration;
          }, 0);

        const dayEffectiveness = 100 - (timeOutside * 100) / 1440;

        locations.push({
          date: actualDay.valueOf(),
          data: dayValues,
          effectiveness: dayValues.length ? dayEffectiveness : 0,
          otherLocations,
        });
      }

      const locationsLast7Days = locations.slice(-7);

      const daysWithEffectiveness = locationsLast7Days.filter(day =>
        Boolean(day.effectiveness)
      );
      const generalEffectiveness =
        daysWithEffectiveness.length > 0
          ? meanBy(daysWithEffectiveness, 'effectiveness')
          : 0;

      /* Interactions calculations */

      const uniqueBeacons = [];
      deviceBeacon.forEach(beacon => {
        // eslint-disable-next-line
        const beaconId = ((beacon.value[0] & 0x00ff) << 16) + beacon.value[1];
        if (
          uniqueBeacons.findIndex(value => value.beaconId === beaconId) === -1
        ) {
          uniqueBeacons.push({ beaconId, beacon });
        }
      });

      return {
        location: locationsLast7Days,
        effectiveness: isNaN(generalEffectiveness) ? 0 : generalEffectiveness,
        interactions: {
          total: uniqueBeacons.length,
          diagnosed: 0,
          healthCareWorkers: 0,
          atRisk: 0,
        },
        lastUpdate: moment().valueOf(),
      };
    } catch (e) {
      throw new Error(e.message);
    }
  },
  true
);
