import React, { useState, useEffect } from 'react';
import { Accordion, Header, Pagination } from 'semantic-ui-react';
import style from 'containers/patient/components/RightInfo/patientRightInfo.module.scss';
import SingleSurvey from '../SingleSurvey';

const SurveyList = ({ surveys }) => {
  const surveysByPage = 5;
  const totalPages = Math.ceil(surveys.length / surveysByPage);
  const [activePage, setActivePage] = useState(1);
  const from = (activePage - 1) * surveysByPage;
  const to = activePage * surveysByPage;
  const surveyRange = surveys.slice(from, to);

  useEffect(() => {
    setActivePage(1);
  }, [surveys]);

  const handlePageChange = (_, { activePage }) => setActivePage(activePage);
  return (
    <section className={style.surveyListBox}>
      <Header>Surveys</Header>
      <Accordion fluid styled>
        {!surveys.length && <p>Patient has no completed surveys.</p>}
        {surveyRange.map((survey, index) => (
          <SingleSurvey survey={survey} key={index} />
        ))}
      </Accordion>
      <div className={style.surveyPaginationBox}>
        <Pagination
          onPageChange={handlePageChange}
          boundaryRange={1}
          defaultActivePage={1}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={totalPages}
          activePage={activePage}
        />
      </div>
    </section>
  );
};

SurveyList.defaultProps = {
  surveys: [],
};
export default SurveyList;
