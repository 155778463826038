import { ALERT_TRESHOLDS, SEVERITY_TYPES, ALERT_TYPES } from 'utils/constants';
import { labelize, capitalizeWords } from 'utils/textFormats';
import getBiometrics from 'utils/biometrics';
import moment from 'moment';

/**
 * Take a disease Name and return an alert for that disease or undefined in case there is not any
 * @param {string} diseaseName
 * @param {number} diseaseValue
 */
export const findAlert = (diseaseName, diseaseValue) => {
  const alertSpecification = ALERT_TRESHOLDS[diseaseName] || [];
  return alertSpecification
    .slice()
    .filter(({ threshold }) => threshold <= diseaseValue)
    .reverse()[0];
};

/**
 * Parse a original disease
 * Remove i_ and underscores
 * Change string value to number from 1 to 100
 * @param {string} diseaseOriginalName
 * @param {string} diseaseOriginalValue
 */
export const parseDisease = (diseaseOriginalName, diseaseOriginalValue) => {
  const diseaseName = capitalizeWords(
    labelize(diseaseOriginalName.slice(2), false)
  ).trim();
  const diseaseValue = (diseaseOriginalValue - 0) * 100;
  return { [diseaseName]: diseaseValue };
};

export const parseBiometricValue = (biometricName, biometricValue) => {
  const thresholds = ALERT_TRESHOLDS[biometricName];
  if (thresholds) {
    const alert = thresholds.find(
      threshold => threshold.severity === biometricValue
    );
    if (alert) {
      return alert.threshold;
    }
  }
  return null;
};

export const getBiometricBrowserAlerts = (facts = {}, expanded = false) => {
  const { red } = getBiometrics(facts, true, expanded);
  const alerts = red
    .map(item => {
      const threshold = parseBiometricValue(item.value, SEVERITY_TYPES.RED);
      if (threshold) {
        return findAlert(item.value, threshold);
      }
      return undefined;
    })
    .filter(alert => !!alert);
  return alerts;
};

export const getSurveyAlertFromSurveys = surveys => {
  const filteredSurveys = surveys.filter(
    item =>
      item.status === 'completed' && /ARISXDAILY_/.test(item.questions[0].name)
  );
  let lastSurveyDate;
  if (filteredSurveys && filteredSurveys.length > 0) {
    lastSurveyDate = filteredSurveys
      .map(item => item.time_completed)
      .reduce((a, b) => Math.max(a, b));
    const hoursAgo = moment()
      .subtract(36, 'hour')
      .format('X');
    if (lastSurveyDate <= hoursAgo) {
      return {
        threshold: 0,
        floatThreshold: 0,
        severity: SEVERITY_TYPES.YW,
        risk: 'Med',
        type: ALERT_TYPES.P0,
        message: 'COVID-19 Daily Symptoms Survey Not Completed.',
      };
    }
  } else {
    return {
      threshold: 0,
      floatThreshold: 0,
      severity: SEVERITY_TYPES.YW,
      risk: 'Med',
      type: ALERT_TYPES.P0,
      message: 'COVID-19 Daily Symptoms Survey Not Completed.',
    };
  }
  return null;
};

export const getSurveyAlertsFromFacts = surveys => {
  if (!surveys || surveys.length === 0) {
    return {
      threshold: 0,
      floatThreshold: 0,
      severity: SEVERITY_TYPES.YW,
      risk: 'Med',
      type: ALERT_TYPES.P0,
      message: 'COVID-19 Daily Symptoms Survey Not Completed.',
    };
  }
  return null;
};

export const getAfibAlertsFromFacts = facts => {
  if (facts && facts?.i_atrial_fibrilation > 0.12) {
    const percentage = facts.i_atrial_fibrilation * 100;
    return {
      threshold: 12,
      floatThreshold: 0.12,
      severity: SEVERITY_TYPES.YW,
      risk: 'Med',
      type: ALERT_TYPES.P0,
      message: `Afib status has changed to ${percentage.toFixed(1)}%`,
    };
  }
  return null;
};

/**
 * take an array of disease object and return an array of alerts
 * @param {array} diseases
 */
export const makeDiseasesAlerts = (
  diseases = [],
  biometrics = [],
  surveyFacts,
  surveys
) => {
  const alerts = [];
  const diseasesList = diseases.flatMap(d => Object.entries(d));
  diseasesList.forEach(item => {
    const diseaseName = item[0];
    const isCovid = diseaseName === 'Covid 19';
    const alert = findAlert(...item);
    if (alert) {
      if (!isCovid) {
        alerts.push(alert);
      }
    }
  });
  const vulnerabilityValue = biometrics.i_vuln;
  const threshold = parseBiometricValue('i_vuln', vulnerabilityValue);
  if (threshold) {
    const alert = findAlert('i_vuln', threshold);
    if (alert) {
      alerts.push(alert);
    }
  }
  let surveyAlert;
  if (surveyFacts) {
    surveyAlert = getSurveyAlertsFromFacts(surveyFacts);
  } else if (surveys) {
    surveyAlert = getSurveyAlertFromSurveys(surveys);
  }
  if (surveyAlert) {
    alerts.push(surveyAlert);
  }

  let aFibAlert = getAfibAlertsFromFacts(biometrics);
  if (aFibAlert) {
    alerts.push(aFibAlert);
  }
  const bioAlerts = getBiometricBrowserAlerts(biometrics);
  return [...alerts, ...bioAlerts];
};

/**
 * takes a diseases object and return parsed diseases taking on count only the ones that start width i_
 * @param {object} diseases
 */
export const getParsedDiseases = (diseases = {}) => {
  const parsedDiseases = [];
  Object.keys(diseases).forEach(key => {
    if (key.slice(0, 2) === 'i_') {
      const diseaseItem = parseDisease(key, diseases[key]);
      parsedDiseases.push(diseaseItem);
    }
  });
  return parsedDiseases;
};

const makeBiometricAlert = ({ value, direction = '', color = 'red' }) => {
  const message = `${value}`;
  const type = `${direction.toUpperCase()}_${color.toUpperCase()}`;

  return {
    threshold: 0,
    severity: SEVERITY_TYPES[type],
    risk: 'High',
    message,
  };
};

export const getBiometricAlerts = (facts = {}, expanded = false) => {
  const { red, green } = getBiometrics(facts, true, expanded);
  const redAlerts = red.map(item =>
    makeBiometricAlert({ ...item, color: 'red' })
  );
  const greenAlerts = green.map(item =>
    makeBiometricAlert({ ...item, color: 'green' })
  );
  return { redAlerts, greenAlerts };
};
