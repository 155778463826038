import { defaultParser } from 'utils/fhirData/parsingFunctions';

const maxToMin = (a, b) => b - a;

export default labExams => {
  const examsNames = [
    'Haemoglobin A1c',
    'LDL',
    'Triglycerides',
    'Creatinine',
    'eGFR (MDRD)',
    'AST',
    'ALT',
    'Uric acid',
    'Urine microalbumin',
    'Urine creatinine',
    'TSH',
    'Vitamin D',
  ];

  const examsNamesLowerCase = examsNames.map(item => item.toLowerCase());

  const parsedLabs = labExams.map(item => defaultParser(item));
  const episodesObject = {};

  parsedLabs.forEach(
    ({
      testName,
      episodeId,
      resultValue,
      normalRangeMin,
      normalRangeMax,
      resultUnit,
      episodeDate,
    }) => {
      const examIndex = examsNamesLowerCase.indexOf(testName?.toLowerCase?.());
      if (examIndex !== -1) {
        const exam = examsNames[examIndex];
        let formattedResultValue = `${resultValue} ${resultUnit}`;
        if (normalRangeMin !== undefined && normalRangeMax !== undefined) {
          formattedResultValue += ` (${normalRangeMin}-${normalRangeMax})`;
        }
        if (episodesObject[episodeId]) {
          episodesObject[episodeId][exam] = formattedResultValue;
        } else {
          episodesObject[episodeId] = {
            [exam]: formattedResultValue,
            episodeDate,
          };
        }
      }
    }
  );
  //Make an object of episodesId

  const lastThreeEpisodes = Object.keys(episodesObject)
    .sort(maxToMin)
    .slice(0, 3);

  const episodeNames = lastThreeEpisodes
    .concat(['null', 'null', 'null'])
    .slice(0, 3);
  const episodeDates = episodeNames.map(
    episodeId => episodesObject[episodeId]?.episodeDate || 'null'
  );

  return {
    examsNames,
    episodeDates,
    episodeNames,
    episodesObject,
  };
};
