import React from 'react';
import moment from 'moment-timezone';
import { renderToStaticMarkup } from 'react-dom/server';
import { isEmpty } from 'lodash';
import { chartOptions } from 'utils/charts/factory';
import { TICK_DATA_TYPES } from 'utils/constants';
import { sectionFooter } from 'components/EmployeeCovidReport/textComponents';
import ChartSelector from 'components/Viz/ChartSelector';

export default (data, date, ticksData) => {
  const tz = data.timeZone?.data?.tz || '';
  const chartDate = {
    start: moment(date.from),
    end: moment(date.to),
  };

  if (isEmpty(ticksData.ticks.data)) {
    return '';
  }

  const ticks = ticksData.ticks.data;

  const chartWrapper = ({ title, charts, data }) => {
    return `<section class="report-section">
    <h2 class="section-title">${title}</h2>
    ${renderToStaticMarkup(
      <ChartSelector
        charts={charts}
        date={chartDate}
        tz={tz}
        data={data}
        forcedDimensions={{ width: 1000 }}
        showDailyTrends
      />
    )}
    ${sectionFooter()}
    </section>`;
  };
  const makeData = chart => {
    const metrics = TICK_DATA_TYPES[chart];
    if (Array.isArray(metrics)) {
      return metrics.reduce(
        (obj, metric) => ({ ...obj, [metric]: ticks[metric] || [] }),
        {}
      );
    }
    return { [metrics]: ticks[metrics] || [] };
  };
  const charts = chartOptions.map(({ text, value }) => ({
    title: text,
    charts: [value],
    data: makeData(value),
  }));

  const chartsSections = charts.map(chart => chartWrapper(chart)).join('');

  return chartsSections;
};
