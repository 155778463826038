import { createReducer } from '@reduxjs/toolkit';
import { handleActionStart, handleActionError } from 'store/utils';
import { isEmpty } from 'lodash';
import {
  fetchBasicInfo,
  setCurrentPatient,
  clearCurrentPatient,
  updateBasicInfo,
} from 'store/currentPatient/actions';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [fetchBasicInfo.STARTED]: handleActionStart,
  [fetchBasicInfo.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    state.data = action.payload.data;
  },
  [updateBasicInfo.SUCCEEDED]: (state, action) => {
    state.isLoading = false;
    state.error = null;
    if (!isEmpty(action.payload.data)) {
      state.data = {
        ...state.data,
        ...action.payload.data,
      };
    }
  },
  [fetchBasicInfo.FAILED]: handleActionError,
  [setCurrentPatient]: (_, action) => action.payload.basicInfo,
  [clearCurrentPatient]: () => initialState,
});
