import parseJwtToken from 'utils/parseJwtToken';

export default (data, signatureUrl) => {
  const token = localStorage.getItem('token');
  let clinician = {
    first: 'John',
    last: 'Doe',
    active_credential: 'jdoe@jawbonehealth.com',
    phone: '---',
  };
  if (token) {
    const tokenData = parseJwtToken(token);
    clinician = { ...clinician, ...tokenData.id };
  }
  const { name } = data?.basicInfo?.data;
  const diseases = data?.diseases?.data || [];
  const diseasesList = diseases.flatMap(d => Object.entries(d));
  const covidDisease =
    diseasesList
      ?.find(([diseaseName]) => diseaseName === 'Covid 19')?.[1]
      ?.toFixed?.(2) ?? 0;
  const signatureData = signatureUrl
    ? `<img class="signature-image" src=${signatureUrl}>`
    : `<div class="signature-text">/${clinician.first} ${clinician.last}, NREMT/</div>`;
  return `<section class="intro-letter-section">
      <h3 >${name}</h3>
      <p>Dear Dr.---</p>
      <p>
        Your patient ${name} is a member of the all.health service and we have
        screened them for the risk of COVID-19. We collected important
        information included below about their medical history as well as
        wearable and survey data. Based on this information, <b>${name} has a
        ${covidDisease}% risk of COVID-19</b>. Our COVID-19 screen has a 84% positive predictive
        value.
      </p>
      <p></p>
      <p>Thank you.</p>
      <div class="close">
        ${signatureData}
        <p>Dr. ${clinician.first} ${clinician.last}<br>
        ${clinician.phone}<br>
        ${clinician.active_credential}</p>
      </div>
    </section>`;
};
