import React from 'react';
import { Popup } from 'semantic-ui-react';
import { utcToTzFormat } from 'utils/timezone';

const BarAndCircleList = ({ values, xScale, yScale, tz }) =>
  values.map(({ time, diastolic, systolic }, idx) => {
    return (
      <Popup
        key={idx}
        content={
          <p>
            <b>Systolic:</b>
            {` ${systolic}`}
            <br />
            <b>Diastolic:</b>
            {` ${diastolic}`}
          </p>
        }
        header={utcToTzFormat(time, tz, 'MM/DD hh:mm A')}
        trigger={
          <g key={idx}>
            <circle
              cx={xScale(time)}
              cy={yScale(systolic)}
              r="5"
              fill="#198dd0"
            />
            <line
              x1={xScale(time)}
              x2={xScale(time)}
              y1={yScale(systolic)}
              y2={yScale(diastolic)}
              stroke="#198dd0"
            />
            <circle
              cx={xScale(time)}
              cy={yScale(diastolic)}
              r="5"
              fill="#198dd0"
            />
          </g>
        }
      />
    );
  });

export default BarAndCircleList;
