import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { postAuthSignature } from 'store/authState/actions';
import ImageError from './components/ImageError';
import MainContent from './components/MainContent';

const Signature = () => {
  const [open, setOpen] = useState(false);
  const imageInput = useRef();
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();

  const { signature: authSignature } = useSelector(state => state.auth.data);

  useEffect(() => {
    if (file && file.name) {
      let reader = new FileReader();
      reader.onload = function(e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const handleUploadImage = () => {
    return imageInput?.current?.click();
  };
  const toggleOpen = () => {
    setOpen(!open);
    setImage(null);
    setError('');
    setFile(null);
  };
  const onFileChange = e => {
    let file = e.target.files[0];
    let error = '';
    if (!file) {
      error = 'Signature is missing';
    } else if (
      (!file.type.includes('image') && file.type !== 'text/plain') ||
      file.size > 2000000000
    ) {
      error =
        'Signature must be an image or plain text and less than 2GB in size';
    }
    setFile(file);
    setError(error);
  };
  const handleSubmit = () => {
    if (!error && file?.name) {
      let image_file = new FormData();
      image_file.append('image_file', file, file.name);
      dispatch(postAuthSignature(image_file));
      toggleOpen();
    } else {
      setError('You must upload a signature');
    }
  };
  const mainContentProps = { image, authSignature, file, handleUploadImage };
  return (
    <Modal
      open={open}
      onClose={toggleOpen}
      trigger={
        <Button
          icon="signup"
          primary
          content="signature"
          onClick={toggleOpen}
        />
      }
    >
      <Modal.Header>Upload your signature</Modal.Header>
      <Modal.Content>
        <input
          type="file"
          max="1"
          ref={imageInput}
          style={{
            visibility: 'hidden',
            position: 'absolute',
          }}
          accept=".jpg,.jpeg,.png,.txt"
          name="file"
          onChange={onFileChange}
        />
        <ImageError error={error} />
        <MainContent {...mainContentProps} />
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={toggleOpen} content={'Cancel'} />
        <Button positive content="Save" onClick={handleSubmit} />
      </Modal.Actions>
    </Modal>
  );
};

export default Signature;
