import React from 'react';
import cx from 'classnames';

import style from '../../covid.module.scss';

const PeopleInteracted = ({ interactions }) => {
  const getPeopeInteracted = data => {
    return [
      {
        value: data.total,
        label: 'People',
      },
      {
        value: data.diagnosed,
        label: 'Diagnosed',
      },
      {
        value: data.healthCareWorkers,
        label: 'Health Care Workers',
      },
      {
        value: data.atRisk,
        label: 'At-Risk',
      },
    ];
  };

  const renderItem = ({ value, label }) => {
    return (
      <div key={label} className={style.bar}>
        <span className={style.barValue}>{value}</span>
        <span className={style.barLabel}>{label}</span>
      </div>
    );
  };

  const items = getPeopeInteracted(interactions);

  return (
    <section className={cx(style.covidSection, style.peopleInteracted)}>
      <div className={style.barsContainer}>{items.map(renderItem)}</div>
    </section>
  );
};

export default PeopleInteracted;
