import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { capitalize, isEmpty } from 'lodash';
import { Dimmer, Icon, Image, Loader } from 'semantic-ui-react';

import { Biometrics } from 'components';
import getBiometrics from 'utils/biometrics';
import BandIcon from 'assets/icons/BandFill';
import DefaultImage from 'assets/default-image.png';
import styles from './patientCard.module.scss';

const COLOR_STYLES = {
  none: styles.noneValue,
  low: styles.lowValue,
  medium: styles.mediumValue,
  high: styles.highValue,
};

const COVID_STATUS_COLOR = {
  Positive: styles.highValue,
  'Antibody Positive': styles.highValue,
  Negative: styles.lowValue,
  'Antibody Negative': styles.lowValue,
};

const PatientCard = ({ basicData, data, onClick }) => {
  const renderEmptyCard = ({ key, name, image, isLoading }) => (
    <div className={styles.cardContainer} onClick={onClick}>
      <section className={styles.headerContainer}>
        <Image key={`${key}-img`} src={image} avatar />
        <span key={`${key}-name`} className={styles.name}>
          {name}
        </span>
      </section>
      {isLoading && (
        <section className={cx(styles.bodyContainer, styles.bodyLoader)}>
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        </section>
      )}
    </div>
  );

  const renderErrorCard = ({ key, name, image }) => (
    <div className={styles.cardContainer} onClick={onClick}>
      <section className={styles.headerContainer}>
        <Image key={`${key}-img`} src={image} avatar />
        <span key={`${key}-name`} className={styles.name}>
          {name}
        </span>
      </section>
      <section className={styles.bodyContainer}>
        <span className={styles.highValue}>
          Something went wrong fetching data for this patient.
        </span>
      </section>
    </div>
  );

  const patientKey = basicData.id;
  const name = `${capitalize(basicData.last)}, ${capitalize(basicData.first)}`;
  const image = basicData.image ?? DefaultImage;

  if (!data || isEmpty(data))
    return renderEmptyCard({
      key: patientKey,
      name,
      image,
      isLoading: true,
    });

  if (data.error) return renderErrorCard({ key: patientKey, name, image });

  const biometrics = getBiometrics(basicData.facts, true);
  let biometricsValue;
  if (data.hasBeenUsingBand) {
    biometricsValue = (
      <Biometrics
        icon="exclamation triangle"
        biometrics={biometrics}
        expanded
        showGreen
      />
    );
  } else {
    biometricsValue = <span className={styles.noneValue}>N/A</span>;
  }
  const biometricsColor = (() => {
    if (!biometrics.red.length) return styles.lowValue;
    if (biometrics.red.length === 1) return styles.mediumValue;
    return styles.highValue;
  })();

  const vulnerabilityLevel = (() => {
    const patientCovidVulnerability = basicData?.facts?.['i_vuln'] ?? null;
    if (!patientCovidVulnerability && patientCovidVulnerability !== 0)
      return 'none';
    if (patientCovidVulnerability === 'GREEN') return 'low';
    if (patientCovidVulnerability === 'YELLOW') return 'medium';
    return 'high';
  })();
  const vulnerabilityColor =
    COLOR_STYLES[vulnerabilityLevel] ?? styles.noneValue;

  const exposureLevel = (() => {
    const patientCovidExposure = basicData?.facts?.['i_covid_19'] ?? null;
    if (!patientCovidExposure && patientCovidExposure !== 0) return 'none';
    if (patientCovidExposure < 0.33) return 'low';
    if (patientCovidExposure < 0.66) return 'medium';
    return 'high';
  })();
  const exposureColor = COLOR_STYLES[exposureLevel] ?? styles.noneValue;

  const bandColor = (() => {
    if (data.bandStatus === 'WEARING') return styles.bandGreen;
    if (data.bandStatus === 'CHARGING') return styles.bandYellow;
    if (data.bandStatus === 'REMOVED') return styles.bandRed;
    return '';
  })();

  const cardColor = (() => {
    const vulnerability = basicData?.facts?.['i_vuln'] ?? null;
    const exposure = basicData?.facts?.['i_covid_19'] ?? null;
    if (
      biometrics.red.length > 0 ||
      vulnerability === 'RED' ||
      exposure > 0.6 ||
      data.covidStatus === 'Positive' ||
      data.covidStatus === 'Antibody Positive' ||
      data.quarantineEffectiveness > 80
    )
      return styles.cardRed;
    return null;
  })();

  const covidColor = COVID_STATUS_COLOR[data.covidStatus] ?? styles.noneValue;

  const quarantineTypeColor = (() => {
    if (data.quarantineEffectiveness > 90) return styles.lowValue;
    if (data.quarantineEffectiveness > 80) return styles.mediumValue;
    return styles.highValue;
  })();

  const metrics = [
    {
      key: 'biometrics',
      text: 'Biometrics',
      color: biometricsColor,
    },
    {
      key: 'vulnerabilty',
      text: 'Vulnerability',
      color: vulnerabilityColor,
    },
    {
      key: 'exposure',
      text: 'Exposure',
      color: exposureColor,
    },
  ];

  return (
    <div className={cx(styles.cardContainer, cardColor)} onClick={onClick}>
      <section className={styles.headerContainer}>
        <div>
          <Image src={image} avatar />
          <span className={styles.name}>{name}</span>
        </div>
        <BandIcon className={cx(styles.bandIcon, bandColor)} />
      </section>
      <section className={styles.bodyContainer}>
        <div className={styles.metricsContainer}>
          {metrics.map(metric => (
            <div key={metric.key} className={styles.metricContainer}>
              <Icon name="circle" className={metric.color} />
              <span className={styles.name}>{metric.text}</span>
            </div>
          ))}
        </div>
        <div className={styles.otherValueContainer}>
          <Icon name="circle" className={covidColor} />
          Covid Test: {data.covidStatus}
        </div>
        <div className={styles.otherValueContainer}>
          <Icon name="circle" className={quarantineTypeColor} />
          {data.quarantineType ? data.quarantineType : 'Quarantine Type'}
        </div>
      </section>
      {(Boolean(biometrics.red.length) || Boolean(biometrics.green.length)) && (
        <section className={styles.footerContainer}>{biometricsValue}</section>
      )}
    </div>
  );
};

PatientCard.propTypes = {
  basicData: PropTypes.shape({}),
  data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  onClick: PropTypes.func,
};

PatientCard.defaultProps = {
  basicData: {},
  data: {},
  onClick: null,
};

export default PatientCard;
