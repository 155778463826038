import { createAction } from '@reduxjs/toolkit';
import { createActionThunk } from 'redux-thunk-actions';

import { HealthcloudApi } from 'api';

export const clearNewMessages = createAction('CLEAR_NEW_MESSAGES');
export const notifyNewMessages = createAction('NOTIFY_NEW_MESSAGES');
export const sendPushNotification = createActionThunk(
  'SEND_PUSH_NOTIFICATION',
  id => {
    return HealthcloudApi.sendPushNotification(id);
  }
);
