import React from 'react';
import AppointmentsTable from './components/AppointmentsTable';
import EncountersTable from './components/EncountersTable';
import SingleEncounterModal from './components/SingleEncounterModal';

const Appointments = () => {
  return (
    <>
      <AppointmentsTable />
      <EncountersTable />
      <SingleEncounterModal />
    </>
  );
};

export default Appointments;
