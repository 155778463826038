import React from 'react';
import { Dropdown, Header } from 'semantic-ui-react';
import style from 'containers/patient/components/RightInfo/patientRightInfo.module.scss';

const SurveyFilters = ({
  headersProps,
  typesProps,
  idProps,
  statusProps,
  orderByProps,
}) => {
  return (
    <section className={style.filtersSection}>
      <Header>Filters</Header>
      <div className={style.dropdownBox}>
        <Dropdown search selection {...statusProps} placeholder="Status" />
        <Dropdown
          multiple
          search
          clearable
          selection
          {...headersProps}
          placeholder="Header"
        />
        <Dropdown
          multiple
          search
          clearable
          selection
          {...typesProps}
          placeholder="Type"
        />
        <Dropdown clearable search selection {...idProps} placeholder="Id" />
        <Dropdown selection {...orderByProps} placeholder="Order by" />
      </div>
    </section>
  );
};

export default SurveyFilters;
