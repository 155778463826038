import {
  immunizationsParser,
  observationsParser,
} from 'utils/fhirData/parsingFunctions';

const eyeParser = (observation, name) => ({
  name,
  description: observation.eyeExaminationDetails,
  date: observation.date || observation.episodeId,
  status: observation.eyeExaminationDone,
});

const footParser = (observation, name) => {
  const status = Boolean(observation.anyOtherFeatures);
  const description = status ? `${observation.anyOtherFeatures}` : '';
  return {
    name,
    description,
    date: observation.date || observation.episodeId,
    status: status ? 'Done' : 'Pending',
  };
};

const tobacoParser = (observation, name) => ({
  name,
  description: observation.smokingStatus,
  date: observation.date || observation.episodeId,
  status: observation.smokingAdviceGiven,
});

const makeTemplate = name => ({
  name,
  description: '---',
  date: '---',
  status: 'Not found',
});

export default (immunizations, observations) => {
  const parsedImmunizations = immunizations
    .map(item => {
      const parsedItem = immunizationsParser(item);
      return {
        name: parsedItem.vaccineCodeValues || '---',
        description: 'Vaccine',
        date: parsedItem.occurrenceDateTime || '---',
        status: parsedItem.status || '---',
      };
    })
    .filter(item => ['Influenza', 'Pneumococcal'].includes(item.name));

  const parsedObservations = observations
    .map(item => observationsParser(item))
    .sort((a, b) => b.episodeId - a.episodeId);

  const exams = [
    {
      name: 'Eye Exam',
      checkField: 'eyeExaminationDone',
      parser: eyeParser,
    },
    {
      name: 'Comprehensive Foot Exam',
      checkField: 'anyOtherFeatures',
      parser: footParser,
    },
    {
      name: 'Tobacco Usage',
      checkField: 'smokingFrequency',
      parser: tobacoParser,
    },
  ];

  const observationsExams = exams.map(item => {
    const lastExam = parsedObservations.find(
      observation => observation[item.checkField]
    );
    return lastExam
      ? item.parser(lastExam, item.name)
      : makeTemplate(item.name);
  });

  const preventiveExams = [...parsedImmunizations, ...observationsExams];

  const headerFields = [
    { text: 'Preventive', field: 'name' },
    { text: 'Description', field: 'description' },
    { text: 'Date / Episode', field: 'date' },
    { text: 'Status', field: 'status' },
  ];
  return {
    parsedImmunizations,
    parsedObservations,
    preventiveExams,
    headerFields,
  };
};
