import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Modal, Header, Button, Icon, Divider } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';

import { requestAppointment } from 'store/currentPatient/actions/fhir';
import { staticToOptions } from 'utils/appointments/factory';
import {
  FirstStep,
  AvailabilityChecker,
  AppointmentDayPicker,
} from './components';

const AppointmentForm = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [daysVisibility, setDaysVisibility] = useState(false);

  const { isLoading, data: staticData } = useSelector(
    state => state.staticData.appointments
  );

  const DEFAULT_RESOUOCE_TYPE_ID = 1;
  const getDiabetologistId = () => {
    const diabetologist = staticToOptions(staticData.resourceTypes).filter(
      data => data.text === 'Diabetologist'
    );
    if (diabetologist === undefined) {
      return DEFAULT_RESOUOCE_TYPE_ID;
    }
    const diabetologistItem = diabetologist[0];
    return diabetologistItem === undefined
      ? DEFAULT_RESOUOCE_TYPE_ID
      : diabetologistItem.value;
  };

  const initialValue = {
    patient_comment: '',
    resource_type_id: getDiabetologistId(),
    visit_reason_id: 1,
    date: null,
  };
  const [formState, setFormState] = useState(initialValue);

  const featureAvailable =
    process.env.REACT_APP_APPOINTMENTS_AVAILABLES === 'true';

  const id = useSelector(state => state.currentPatient.id);

  const handleClose = () => {
    setFormState(initialValue);
    setOpen(false);
  };
  const handleChange = (_, { value, name }) => {
    setFormState(state => ({ ...state, [name]: value }));
  };

  const handleSave = () => {
    dispatch(requestAppointment(id, formState));
    handleClose();
  };
  const clearDate = () => setFormState(state => ({ ...state, date: null }));
  const onSelectDay = (dateSelection, period) => {
    const date = {
      start: Number(dateSelection.format('X')),
      type: 'SPECIFIC',
      period,
    };
    setFormState(state => ({ ...state, date }));
  };
  const firstStepProps = {
    resourceOptions: staticToOptions(staticData.resourceTypes),
    visitOptions: staticToOptions(staticData.visitReasons),
    handleChange,
    formState,
  };
  const pickerProps = {
    onSelectDay,
    pickedDay: formState.date,
  };
  const checkerProps = {
    resource_type: formState.resource_type_id,
    clearDate,
    setDaysVisibility,
  };
  const isDateMissing = !formState.date;

  return (
    <Modal
      size="tiny"
      open={open}
      trigger={
        <Button
          floated="right"
          disabled={!featureAvailable}
          icon
          labelPosition="left"
          primary
          size="small"
          onClick={() => setOpen(true)}
        >
          <Icon name="user" /> Request Appointment
        </Button>
      }
      closeIcon
      onClose={handleClose}
    >
      <Header icon="plus" size="small" content="Request Appointment" />
      <Modal.Content>
        {isLoading || isEmpty(staticData) ? (
          <p>Loading...</p>
        ) : (
          <>
            <FirstStep {...firstStepProps} />
            <Divider />
            <AvailabilityChecker {...checkerProps} />
            <Divider />
            {daysVisibility ? <AppointmentDayPicker {...pickerProps} /> : null}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          type="button"
          onClick={handleSave}
          disabled={isDateMissing}
        >
          <Icon name="checkmark" /> Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AppointmentForm;
