import React from 'react';
import * as d3 from 'd3';

import { alignAxis } from 'utils/chartUtils';
import Dots from './Dots';
import GridLine from './GridLine';
import Line from './Line';
import style from './Chart.module';
import Axis from './Axis';

const GlucoseChart = ({
  data = [],
  dimensions,
  xData,
  tz = {},
  axisIndex,
  axisAmount,
}) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;
  const keyAccessor = (_, i) => i;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const sectionSize = dimensions.boundedHeight / 4;

  const yScale = d3
    .scaleLinear()
    .domain([0, 70, 140, 400])
    .range([sectionSize * 4, sectionSize * 3, sectionSize, 0]);

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));
  const axisAligment = alignAxis(axisIndex, axisAmount);
  return [
    <GridLine
      lineClass={style.glucoseGridLine}
      position={sectionSize * 1}
      label="140 mg/dL"
      dashed
      labelRightAligned
    />,
    <GridLine
      lineClass={style.glucoseGridLine}
      position={sectionSize * 3}
      label="70 mg/dL"
      dashed
      labelRightAligned
    />,
    <Axis
      key="glucose-y-axis"
      dimension="y"
      scale={yScale}
      tickColor={style.glucoseGridLine}
      labelLine
      {...axisAligment}
    />,
    <Line
      style={{ ...style.line, ...style.glucoseLine }}
      data={data}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
    <Dots
      style={style.glucoseCircles}
      data={data}
      keyAccessor={keyAccessor}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
      radius={4}
      tz={tz}
    />,
  ];
};

const GlucoseChartDayTrend = ({ data = [], dimensions, xData, axisAmount }) => {
  if (!data.length) return null;
  const xAccessor = d => d.time;
  const yAccessor = d => d.value;

  const lowerXRange = axisAmount > 1 ? 30 : 0;

  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([lowerXRange, dimensions.boundedWidth]);

  const sectionSize = dimensions.boundedHeight / 4;

  const yScale = d3
    .scaleLinear()
    .domain([0, 70, 140, 400])
    .range([sectionSize * 4, sectionSize * 3, sectionSize, 0])
    .nice();

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  return [
    <GridLine
      lineClass={style.glucoseGridLine}
      position={sectionSize * 1}
      label="140 mg/dL"
      dashed
      labelRightAligned
    />,
    <GridLine
      lineClass={style.glucoseGridLine}
      position={sectionSize * 3}
      label="70 mg/dL"
      dashed
      labelRightAligned
    />,
    <Line
      style={style.glucoseLineTrend}
      data={data}
      xAccessor={xAccessorScaled}
      yAccessor={yAccessorScaled}
    />,
  ];
};

export { GlucoseChart, GlucoseChartDayTrend };
