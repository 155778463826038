import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Location, navigate } from '@reach/router';
import { Button, Icon, Popup } from 'semantic-ui-react';

import MenuTab from './components/MenuTab';
import MenuDropdown from './components/MenuDropdown';
import PatientsTab from './components/PatientsTab';
import PatientsDropdown from './components/PatientsDropdown';
import { Logo } from 'components';
import { closeTab } from 'store/appState/actions';
import { logout } from 'store/authState/actions';

import style from './header.module.scss';
import Signature from './components/Signature';

const Header = () => {
  const dispatch = useDispatch();
  const { data: chatMessages } = useSelector(state => state.chatMessages);
  const { patientTabs } = useSelector(state => state.appState);

  const handleClose = (e, id, location) => {
    e.preventDefault();
    const { pathname } = location;
    const locationId = pathname.split('/')[2];
    if (locationId === id) {
      navigate('/today');
    }
    dispatch(closeTab(id));
  };

  const getMenuText = location => {
    const { pathname } = location;
    const locationId = pathname.split('/')[2];
    const currentPatient = patientTabs.find(tab => locationId === tab.id);
    return currentPatient ? getHeaderText(currentPatient) : 'Patients';
  };

  const getHeaderText = tab => {
    const encodedId = tab.id;
    const hasNewMessages = chatMessages[encodedId]?.newMessages;
    const name = tab.name || '(?)';
    if (hasNewMessages) {
      return (
        <span>
          {name} <Icon color="blue" circular name="envelope" />
        </span>
      );
    } else {
      return name;
    }
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  const patientTabsProps = {
    patientTabs,
    getHeaderText,
    handleClose,
    getMenuText,
  };

  return (
    <Location>
      {({ location }) => (
        <nav className={style.navHeader}>
          <Logo />
          <MenuTab />
          <MenuDropdown />
          <PatientsTab {...patientTabsProps} location={location} />
          <PatientsDropdown {...patientTabsProps} location={location} />
          <div className={style.divider}></div>
          <Signature />
          <Popup
            content="Log Out"
            trigger={
              <Button
                className={style.logoutButton}
                icon="sign out alternate"
                onClick={handleLogout}
              />
            }
          />
        </nav>
      )}
    </Location>
  );
};

export default Header;
